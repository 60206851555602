import React, { useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Build, LocalBar, Refresh, ViewColumn } from "@material-ui/icons";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ToggleButton from "../ToggleButton";

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TableTopPanel({
  addRow,
  currentRow,
  customMenuItems,
  hasExportToExcel,
  hasAddButton,
  hasColumnSelect,
  hasOperations,
  hasRowFilter,
  hasRowSelect,
  onAddRowClick,
  onClose,
  onExportToExcelClick,
  onOperationsClick,
  onMouseLeave,
  onRefreshClick,
  onRowSelectClick,
  onRowFilterClick,
  onColumnSelectClick,
  PE,
  rowFilter,
  rowSelect,
  selectedRows,
  showOperationsColumn,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [toolbarSwitch, setToolbarSwitch] = useState();
  const classes = useStyles();

  const toggleAddRow = () => onAddRowClick(!addRow);

  const handleSwitch = (event, newSwitch) => {
    if (newSwitch[0] !== "exportToExcel") onClose();
    setToolbarSwitch(newSwitch);
  };

  const handleExportToExcelClick = async () => {
    setIsLoading(true);
    await onExportToExcelClick();
    setIsLoading(false);
    onClose();
  };

  return (
    <div className="container">
      <div className="row">
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup
            value={toolbarSwitch}
            onChange={handleSwitch}
            onMouseLeave={onMouseLeave}
            aria-label="table opertation"
            size="small"
          >
            <ToggleButton
              className="panel-item"
              value="refresh"
              selected={false}
              aria-label="refresh"
              icon={<Refresh />}
              tooltip={_.get(PE, "Refresh") || "Refresh"}
              onClick={() => onRefreshClick()}
            />
            {hasRowSelect && (
              <ToggleButton
                className="panel-item"
                value="rowSelect"
                selected={rowSelect}
                aria-label="rowSelect"
                icon={<CheckCircleOutlineIcon />}
                tooltip={_.get(PE, "SelectRow") || "Select Row"}
                onClick={() => onRowSelectClick(!rowSelect)}
              />
            )}
            {hasColumnSelect && (
              <ToggleButton
                className="panel-item"
                value="columnSelect"
                selected={false}
                aria-label="columnSelect"
                icon={<ViewColumn />}
                tooltip={_.get(PE, "SelectColumn") || "Select Column"}
                onClick={() => onColumnSelectClick(true)}
              />
            )}
            {hasRowFilter && (
              <ToggleButton
                className="panel-item"
                value="rowFilter"
                selected={rowFilter}
                aria-label="rowFilter"
                icon={<LocalBar />}
                tooltip={_.get(PE, "Filter") || "Filter"}
                onClick={() => onRowFilterClick(!rowFilter)}
              />
            )}
            {hasAddButton && (
              <ToggleButton
                className="panel-item"
                value="addRow"
                selected={false}
                aria-label="addRow"
                icon={<Add />}
                tooltip={_.get(PE, "Create") || "Create"}
                onClick={() => toggleAddRow()}
              />
            )}
            {hasOperations && (
              <ToggleButton
                className="panel-item"
                value="operationsColumn"
                selected={showOperationsColumn}
                aria-label="operationsColumn"
                icon={<Build />}
                tooltip={_.get(PE, "Operations") || "Operations"}
                onClick={() => onOperationsClick(!showOperationsColumn)}
              />
            )}
            {hasExportToExcel && (
              <ToggleButton
                className="panel-item"
                value="exportToExcel"
                selected={false}
                aria-label="export"
                icon={<i className="fa fa-file-excel-o fa-lg" />}
                tooltip={_.get(PE, "ExportToExcel") || "Export to Excel"}
                showLoading={isLoading}
                onClick={handleExportToExcelClick}
              />
            )}
            {customMenuItems?.length > 0 &&
              customMenuItems.map((menuItem) => {
                if (
                  _.isNil(menuItem.displayIf) ||
                  menuItem.displayIf(currentRow)
                )
                  return (
                    <ToggleButton
                      className="panel-item"
                      key={menuItem.name}
                      value={menuItem.name}
                      selected={false}
                      aria-label={menuItem.name}
                      icon={menuItem.icon}
                      tooltip={menuItem.toolTip}
                      onClick={() => menuItem.onClick(selectedRows)}
                    />
                  );

                return null;
              })}
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
}
