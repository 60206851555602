import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import MainContainer from "Jarvis/JarvisComponents/Containers/MainContainer";
import MainContainerBody from "Jarvis/JarvisComponents/Containers/MainContainerBody";
import { PatronCellphoneNotVerifiedRetrieve } from "../../Services/PatronCellphoneService";
import Table from "Jarvis/JarvisComponents/Table";
import InputForm from "./InputForm";
import Wait from "Jarvis/JarvisComponents/Wait";
import { RetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/PageElementService";
import PatronSelect from "../CustomSelect/PatronSelect";

export default function CellphoneConfirmation({
  isIndependentForm,
  cellphoneData,
  onShowInputForm,
  langDataParent,
}) {
  const { language } = useSelector((state) => state.settings);
  const { PE } = useContext(GlobalContext) || {};
  const { control } = useForm();
  const [editData, setEditData] = useState(null);
  const [isLoadingRetrieve, setIsLoadingRetrieve] = useState(false);
  const [langData, setLangData] = useState(null);
  const [pagination, setPagination] = useState({});
  const [selectedPatron, setSelectedPatron] = useState(null);
  const [showPatronHint, setShowPatronHint] = useState(false);
  const [showInputForm, setShowInputForm] = useState(
    isIndependentForm ?? false
  );
  const [tableDataInfo, setTableDataInfo] = useState(null);
  const [tableRefreshInfo, setTableRefreshInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await RetrievePublic({
        langId: language.LangId,
        moduleCodeStrings: "PmsPatronCellphone",
      });
      if (_.get(response, "ErrorHandling.IsSuccessful"))
        setLangData(
          _.merge(
            _.get(PE, "PubPublicElems") || langDataParent,
            _.get(response, "Data.PE")
          )
        );
    })();
  }, [language.LangId, PE, langDataParent]);

  const fetchData = useCallback(async () => {
    if (selectedPatron) {
      setIsLoadingRetrieve(true);
      const response = await PatronCellphoneNotVerifiedRetrieve({
        ...pagination,
        patronId: _.get(selectedPatron, "PatronId"),
        VT: _.get(selectedPatron, "VT"),
      });
      setIsLoadingRetrieve(false);
      const { ErrorHandling } = response;
      if (_.get(ErrorHandling, "IsSuccessful")) {
        setTableDataInfo({
          data: response.Data,
          totalRecords: response.TotalRecords,
        });
      }
    } else
      setTableDataInfo({
        data: [],
        totalRecords: 0,
      });
  }, [pagination, selectedPatron]);

  useEffect(() => {
    if (!isIndependentForm) fetchData();
  }, [fetchData, isIndependentForm, selectedPatron]);

  const onSubmit = async () => {
    setTableRefreshInfo({
      isEditMode: true,
      newRecord: {},
    });
  };

  if (!langData) return <Wait showProgressOnly={isIndependentForm} />;

  const handleShowInputForm = (open, data) => {
    if (!selectedPatron) {
      setShowPatronHint(true);
      return;
    }
    setEditData(data);
    setShowInputForm(open);
  };

  const handlePatronChange = (value) => {
    if (value && showPatronHint) setShowPatronHint(false);
    setSelectedPatron(value);
  };

  const inputForm = (
    <InputForm
      cellphoneData={isIndependentForm ? cellphoneData : editData}
      langData={langData}
      isIndependentForm={isIndependentForm}
      onShowInputForm={onShowInputForm || setShowInputForm}
      onSubmitParent={onSubmit}
      selectedPatronParent={selectedPatron}
      setShowInputForm={setShowInputForm}
      showInputForm={showInputForm}
    />
  );

  if (isIndependentForm) {
    return inputForm;
  }

  const columns = [
    {
      label: _.get(langData, "Cellphone"),
      path: "Cellphone",
      cellAlign: "center",
    },
    {
      label: _.get(
        langData,
        "CellphoneCallingCodeStateDivisionLangDescription"
      ),
      path: "StateDivisionLangDescription",
      cellAlign: "center",
    },
    {
      label: _.get(langData, "IsMain"),
      path: "PatronCellphoneIsMain",
      type: "checkbox",
      widthScale: 0.5,
    },
  ];

  return (
    <MainContainer>
      <MainContainerBody>
        <div className="row mb-2">
          <div className="col-md-5 col-sm-12">
            <PatronSelect
              className="mb-2"
              label={_.get(langData, "PmsPatron")}
              control={control}
              name="PatronSelect"
              hint={
                showPatronHint ? _.get(langData, "PatronId_IsNull") : undefined
              }
              hintColor="error"
              onChange={handlePatronChange}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataInfo={tableDataInfo}
          idFieldName="PatronCellphoneId"
          inputForm={inputForm}
          isLoading={isLoadingRetrieve}
          onShowInputForm={handleShowInputForm}
          onPaginationChange={setPagination}
          refreshInfo={tableRefreshInfo}
          showInputForm={showInputForm}
          title={_.get(langData, "PmsPatronCellphoneNotVerifiedRetrieve")}
        />
      </MainContainerBody>
    </MainContainer>
  );
}
