import React, { useEffect, useState } from "react";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import RoomIcon from "@material-ui/icons/Room";
import { convertGregorianTo } from "Jarvis/JarvisServices/JarvisCommonService";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import Typography from "Jarvis/JarvisComponents/Typography";
import { PatronViewRetrieve } from "../../Services/PatronService";

export default function PatronView({
  data,
  langData,
  patronProps,
  isDirectionRTL,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [patronData, setPatronData] = useState(data);

  useEffect(() => {
    (async () => {
      if (_.get(patronProps, "PatronId")) {
        setIsLoading(true);
        const response = await PatronViewRetrieve(patronProps);
        setIsLoading(false);
        const { ErrorHandling } = response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          setPatronData(response.Data);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFullName = () => {
    if (_.get(patronData, "PatronType") === "R") {
      let fullName = `${_.get(patronData, "PatronRealLangTitle") || ""} ${
        _.get(patronData, "PatronRealLangFirstName") || ""
      } ${_.get(patronData, "PatronRealLangMiddleName") || ""} ${
        _.get(patronData, "PatronRealLangLastName") || ""
      }`;
      if (!isNullOrWhiteSpace(_.get(patronData, "PatronRealLangNickName")))
        fullName += ` (${_.get(patronData, "PatronRealLangNickName")})`;
      return fullName;
    }
  };

  const getCellphones = () => {
    let cellphones = "";
    let stateDivisionCode = "";
    let PatronCellphones =
      _.get(
        patronData,
        patronProps ? "ExtraInfo.PatronCellphones" : "PatronCellphones"
      ) || [];

    if (PatronCellphones.length > 0) {
      PatronCellphones.forEach((patronCellphone) => {
        stateDivisionCode = _.get(patronCellphone, "StateDivisionCode");
        if (isDirectionRTL) {
          cellphones += ` ,${_.get(patronCellphone, "Cellphone") || ""}`;
          if (!isNullOrWhiteSpace(stateDivisionCode))
            cellphones += ` ${stateDivisionCode}+`;
        } else {
          if (!isNullOrWhiteSpace(stateDivisionCode))
            cellphones += `+${stateDivisionCode} `;
          cellphones += `${_.get(patronCellphone, "Cellphone") || ""},  `;
        }
      });
    }

    if (isDirectionRTL) return cellphones.substring(cellphones.length, 2);

    return cellphones.substring(0, cellphones.length - 3);
  };

  const getPhones = () => {
    let phones = "";
    const PatronPhones = _.get(
      patronData,
      patronProps ? "ExtraInfo.PatronPhones" : "PatronPhones"
    );
    if (PatronPhones.length > 0) {
      PatronPhones.forEach((patronPhone) => {
        if (isDirectionRTL) {
          phones += ` ,${_.get(patronPhone, "Phone") || ""}`;
          phones += ` ${_.get(patronPhone, "PhoneCallingCode") || ""}`;
        } else {
          phones += `${_.get(patronPhone, "PhoneCallingCode") || ""} ${
            _.get(patronPhone, "Phone") || ""
          },  `;
        }
      });
    }
    if (isDirectionRTL) return phones.substring(phones.length, 2);
    return phones.substring(0, phones.length - 3);
  };

  const getEmails = () => {
    let emails = "";
    const PatronEmails = _.get(
      patronData,
      patronProps ? "ExtraInfo.PatronEmails" : "PatronEmails"
    );
    if (PatronEmails.length > 0) {
      PatronEmails.forEach((patronEmail) => {
        emails += `${_.get(patronEmail, "Email") || ""},  `;
      });
    }
    return emails.substring(0, emails.length - 3);
  };

  const getAddresses = () => {
    let addresses = "";
    const PatronAddresses = _.get(
      patronData,
      patronProps ? "ExtraInfo.PatronAddresses" : "PatronAddresses"
    );
    if (PatronAddresses.length > 0) {
      PatronAddresses.forEach((patronAddress) => {
        if (!isNullOrWhiteSpace(_.get(patronAddress, "PatronAddressCity")))
          addresses += `${_.get(patronAddress, "PatronAddressCity")} - `;
        addresses += _.get(patronAddress, "PatronAddressLangDescription") || "";
        if (
          !isNullOrWhiteSpace(_.get(patronAddress, "PatronAddressPostalCode"))
        )
          addresses += `, ${_.get(patronAddress, "PatronAddressPostalCode")}`;
        addresses += "\n";
      });
    }
    return addresses;
  };

  const getGroups = () => {
    let groups = "";
    const PatronGroups = _.get(
      patronData,
      patronProps ? "ExtraInfo.PatronGroups" : "PatronGroups"
    );
    if (PatronGroups.length > 0) {
      PatronGroups.forEach((patronGroup) => {
        groups += `${_.get(patronGroup, "PatronGroupTypeSystemLangName")},  `;
      });
    }
    return groups.substring(0, groups.length - 3);
  };

  const getJobs = () => {
    let jobs = "";
    const PatronJobs = _.get(
      patronData,
      patronProps ? "ExtraInfo.PatronJobs" : "PatronJobs"
    );
    if (PatronJobs.length > 0) {
      PatronJobs.forEach((patronJob) => {
        jobs += _.get(patronJob, "JobActivityTypeLangName");
        if (patronData.PatronType === "R") {
          if (_.get(patronJob, "JobPositionTypeLangName"))
            jobs += ` (${_.get(patronJob, "JobPositionTypeLangName")})`;
          if (_.get(patronJob, "PatronLegalLangDescription"))
            jobs += ` - ${_.get(patronJob, "PatronLegalLangDescription")}`;
          if (_.get(patronJob, "PatronRealJobLangDescription"))
            jobs += ` - ${_.get(patronJob, "PatronRealJobLangDescription")}`;
        }
        jobs += ",  ";
      });
    }
    return jobs.substring(0, jobs.length - 3);
  };

  const getDetails = () => {
    let details = "";
    const AccDetails = _.get(
      patronData,
      patronProps ? "ExtraInfo.AccDetails" : "AccDetails"
    );
    if (AccDetails.length > 0) {
      AccDetails.forEach((detail) => {
        details += _.get(detail, "DetailLangName");
        details += ",  ";
      });
    }
    return details.substring(0, details.length - 3);
  };

  if (!patronData || isLoading)
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );

  return (
    <div className="container">
      {_.get(patronData, "PatronType") === "R" ? (
        <Card raised>
          <CardContent>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealLangFullName")}: `}
                />
                <Typography label={getFullName()} />
              </div>
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealIDNo")}: `}
                />
                <Typography label={_.get(patronData, "PatronRealIDNo")} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealLangFatherName")}: `}
                />
                <Typography
                  label={_.get(patronData, "PatronRealLangFatherName")}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealRegistrationNo")}: `}
                />
                <Typography
                  label={_.get(patronData, "PatronRealRegistrationNo")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "GenderLangDescription")}: `}
                />
                <Typography
                  label={
                    _.get(patronData, "Gender.PublicCodeLangDescription") ||
                    _.get(patronData, "GenderLangDescription")
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealBirthPlace")}: `}
                />
                <Typography
                  label={
                    _.get(
                      patronData,
                      "PatronRealBirthPlace.StateDivisionLangDescription"
                    ) || _.get(patronData, "PatronRealBirthPlace")
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealBirthDate")}: `}
                />
                <Typography
                  label={convertGregorianTo(
                    _.get(patronData, "PatronRealBirthDate")
                  )}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronRealIssueDate")}: `}
                />
                <Typography
                  label={convertGregorianTo(
                    _.get(patronData, "PatronRealIssueDate")
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        _.get(patronData, "PatronType") === "L" && (
          <Card raised>
            <CardContent>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(langData, "PatronLegalLangDescription")}: `}
                  />
                  <Typography
                    label={_.get(patronData, "PatronLegalLangDescription")}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(
                      langData,
                      "PatronLegalRegisterPlaceStateDivision"
                    )}: `}
                  />
                  <Typography
                    label={
                      _.get(
                        patronData,
                        "PatronLegalRegisterPlace.StateDivisionLangDescription"
                      ) || _.get(patronData, "PatronLegalRegisterPlace")
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(langData, "PatronLegalIDNo")}: `}
                  />
                  <Typography label={_.get(patronData, "PatronLegalIDNo")} />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(
                      langData,
                      "PatronLegalMinistryOfFinanceNo"
                    )}: `}
                  />
                  <Typography
                    label={_.get(patronData, "PatronLegalMinistryOfFinanceNo")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(langData, "PatronLegalRegistrationNo")}: `}
                  />
                  <Typography
                    label={_.get(patronData, "PatronLegalRegistrationNo")}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Typography
                    color="textSecondary"
                    display="inline"
                    label={`${_.get(langData, "PatronLegalRegisterDate")}: `}
                  />
                  <Typography
                    label={convertGregorianTo(
                      _.get(patronData, "PatronLegalRegisterDate")
                    )}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        )
      )}
      <Card className="mt-3" raised>
        <CardContent>
          {_.get(patronData, "PatronType") === "O" && (
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronOtherLangDescription")}: `}
                />
                <Typography
                  label={_.get(patronData, "PatronOtherLangDescription")}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Typography
                color="textSecondary"
                display="inline"
                label={`${_.get(langData, "PatronCode")}: `}
              />
              <Typography label={_.get(patronData, "PatronCode")} />
            </div>
            <div className="col-md-6 col-sm-12">
              <Typography
                color="textSecondary"
                display="inline"
                label={`${_.get(langData, "PatronSearchKey")}: `}
              />
              <Typography label={_.get(patronData, "PatronSearchKey")} />
            </div>
          </div>
          {_.get(
            patronData,
            patronProps ? "ExtraInfo.PatronGroups" : "PatronGroups"
          )?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PatronGroupTypeLangName")}: `}
                />
                <Typography label={getGroups()} />
              </div>
            </div>
          )}
          {_.get(
            patronData,
            patronProps ? "ExtraInfo.PatronJobs" : "PatronJobs"
          )?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "PmsPatron_Job")}: `}
                />
                <Typography label={getJobs()} />
              </div>
            </div>
          )}
          {_.get(
            patronData,
            patronProps ? "ExtraInfo.AccDetails" : "AccDetails"
          )?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <Typography
                  color="textSecondary"
                  display="inline"
                  label={`${_.get(langData, "AccDetails")}: `}
                />
                <Typography label={getDetails()} />
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      {_.get(patronData, "PatronType") !== "O" && (
        <Card className="mt-3" raised>
          <CardContent>
            {_.get(
              patronData,
              patronProps ? "ExtraInfo.PatronCellphones" : "PatronCellphones"
            )?.length > 0 && (
              <div className="row">
                <PhoneAndroidIcon className="mx-2" color="disabled" />
                <Typography
                  style={{ whiteSpace: "pre-wrap" }}
                  gutterBottom
                  label={getCellphones()}
                />
              </div>
            )}
            {_.get(
              patronData,
              patronProps ? "ExtraInfo.PatronPhones" : "PatronPhones"
            )?.length > 0 && (
              <div className="row">
                <PhoneIcon className="mx-2" color="disabled" />
                <Typography
                  style={{ whiteSpace: "pre-wrap" }}
                  gutterBottom
                  label={getPhones()}
                />
              </div>
            )}
            {_.get(
              patronData,
              patronProps ? "ExtraInfo.PatronEmails" : "PatronEmails"
            )?.length > 0 && (
              <div className="row">
                <MailOutlineIcon className="mx-2" color="disabled" />
                <Typography
                  style={{ whiteSpace: "pre-wrap" }}
                  gutterBottom
                  label={getEmails()}
                />
              </div>
            )}
            {_.get(
              patronData,
              patronProps ? "ExtraInfo.PatronAddresses" : "PatronAddresses"
            )?.length > 0 && (
              <div className="row">
                <RoomIcon className="mx-2" color="disabled" />
                <Typography
                  style={{ whiteSpace: "pre-wrap" }}
                  gutterBottom
                  label={getAddresses()}
                />
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
}
