import React from "react";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { ifNullOrWhiteSpace, isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import _ from "lodash";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "Jarvis/JarvisUtil/asyncComponent";
import { getSubSystem } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export const MenuRetrieve = async ({
  orderBy,
  orderBys,
  orderMethod,
  where,
  node,
  retrieveAsList,
  hierarchyShowMode,
  LatestNodeLevel,
  isDeepSearchNeeded,
  systemId,
  VTSystemId,
  subSystemId,
  VTSubSystemId,
  exceptedMenuId,
}) => {
  const retrieveService = !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true ? "Pub/Menu/RetrieveAsList" : "Pub/Menu/Retrieve";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(LatestNodeLevel, null),
    NodeId: node?.["MenuId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      hierarchy,
      orderBy,
      orderBys,
      orderMethod,
      where,
      systemId,
      VTSystemId,
      subSystemId,
      VTSubSystemId,
      exceptedMenuId,
    },
  });

  return data;
};

export const MenuCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Menu/Create",
    data: _data,
  });
  return data;
};

export const MenuUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Menu/Update",
    data: _data,
  });
  return data;
};

export const MenuDelete = async ({ MenuId, VT, MenuLastUpdateDateTime }) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Menu/Delete",
    data: { MenuId, VT, MenuLastUpdateDateTime },
  });
  return data;
};

const env = JarvisBaseSettingsService.getEnvironment();
const handleOpenInNewTab = (e, url) => {
  e.stopPropagation();
  e.preventDefault();
  let urlToOpenMenuInAnotherTab = `${window.location.protocol}//${window.location.host}${url}`;
  window.open(urlToOpenMenuInAnotherTab);
  return false;
};
export const GenerateMenu = ({ menuData, handleClick }) => {
  if (!menuData) return null;
  let menusCreated = [];
  const lang = getLanguage();

  const renderMenuItems = (menuDataOriginal, menuData) => {
    return menuData.map((item) => {
      const {
        MenuId,
        // MenuIdParent,
        // MenuCode,
        // MenuCodeMustBeShown,
        MenuIcon,
        MenuLangDescription,
        MenuIconColor,
        //ModuleUrl,
        ChildrenCount,
        ControllerName,
        ActionName,
        SystemKey,
        UIComponentPath,
      } = item;
      const found = _.find(menusCreated, (x) => x === MenuId);
      if (found) return null;
      menusCreated.push(MenuId);
      if (ChildrenCount === 0) {
        const subSystem = getSubSystem();
        let url = `/${env}/${SystemKey}/<replacement>/${MenuId}?SSId=${subSystem.subSystemId}&VTSSId=${subSystem.vtSubSystemId}&SSKey=${subSystem.subSystemKey}`;
        if (!isNullOrWhiteSpace(UIComponentPath)) {
          url = url.replace("<replacement>", UIComponentPath);
        } else url = url.replace("<replacement>", `${ControllerName ?? MenuId}${ActionName ? "/" + ActionName : ""}`);
        return (
          <li
            className="menu no-arrow"
            key={`${MenuId}`}
            url={url}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <NavLink to={url} key={`${MenuId}`}>
              {MenuIcon && <i onClick={(e) => handleOpenInNewTab(e, url)} className={`${MenuIcon}`} style={{ color: MenuIconColor }} />}
              <span className={`nav-text ${lang.JarvisFontClass}`}>{MenuLangDescription}</span>
            </NavLink>
          </li>
        );
      } else {
        const children = menuDataOriginal.filter((x) => x.MenuIdParent === MenuId);
        return (
          <li
            className="menu"
            key={`${MenuId}`}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <Button>
              {MenuIcon && <i className={`${MenuIcon}`} style={{ color: MenuIconColor }} />}
              <span className={`nav-text ${lang.JarvisFontClass}`}>{MenuLangDescription}</span>
            </Button>
            {renderSubMenu(children)}
          </li>
        );
      }
    });
  };

  const renderSubMenu = (children) => {
    if (children && children.length > 0) {
      return <ul className="sub-menu">{renderMenuItems([...menuData], children)}</ul>;
    }
  };
  return renderMenuItems([...menuData], menuData);
};

export const GenerateRoutes = ({ menuData }) => {
  if (!menuData) return null;
  return (
    <Switch>
      {menuData.map((item) => {
        if (!isNullOrWhiteSpace(item.UIComponentPath)) {
          const url = `/${env}/${item.SystemKey}/${item.UIComponentPath}/${item.MenuId}`;
          const url1 = `/${env}/${item.SystemKey}/${item.UIComponentPath}`;
          return (
            <Route
              key={item.MenuId}
              path={url}
              component={asyncComponent(
                async () => {
                  document.title = item.MenuLangDescription ?? "Jarvis" + url1;
                  if (isNullOrWhiteSpace(_.get(item, "PrivatePath"))) return await import(`Jarvis/JarvisSystems/${item.SystemKey}/Components/${item.UIComponentPath}`);
                  const privatePath = isNullOrWhiteSpace(_.get(item, "PrivatePath")) ? "" : _.get(item, "PrivatePath") + "/";
                  return await require(`JarvisCustomSystems/${privatePath}${item.SystemKey}/Components/${item.UIComponentPath}`);
                },
                { ...item.MenuJsonModel?.ExternalParams }
              )}
            />
          );
        } else if (isNullOrWhiteSpace(item.ControllerName) === false) {
          const url = `/${env}/${item.SystemKey}/${item.ControllerName}${item.ActionName ? "/" + item.ActionName : ""}/${item.MenuId}`;
          const url1 = `/${env}/${item.SystemKey}/${item.ControllerName}${item.ActionName ? "/" + item.ActionName : ""}`;
          return (
            <Route
              key={item.MenuId}
              path={url}
              component={asyncComponent(
                async () => {
                  document.title = item.MenuLangDescription ?? "Jarvis" + url1;
                  if (isNullOrWhiteSpace(_.get(item, "PrivatePath")))
                    return await import(`Jarvis/JarvisSystems/${item.SystemKey}/Components/${item.ControllerName}${item.ActionName ? "/" + item.ActionName : ""}`);
                  const privatePath = isNullOrWhiteSpace(_.get(item, "PrivatePath")) ? "" : _.get(item, "PrivatePath") + "/";
                  return await require(`JarvisCustomSystems/${privatePath}${item.SystemKey}/Components/${item.ControllerName}${item.ActionName ? "/" + item.ActionName : ""}`);
                },
                { ...item.MenuJsonModel?.ExternalParams }
              )}
            />
          );
        }
        return undefined;
      })}
    </Switch>
  );
};
