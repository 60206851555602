import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useForm } from "react-hook-form";
import TextField from "Jarvis/JarvisComponents/TextField";
import Button from "Jarvis/JarvisComponents/Button";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { RetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/PageElementService";
import { PasswordExpired } from "Jarvis/JarvisSystems/Sec/Services/AccountService";
import { UserChangePassword } from "Jarvis/JarvisSystems/Sec/Services/UserService";

export default function ChangePassword({
  hasCloseBtn = true,
  hideAuthLoader,
  onClose,
  showAuthLoader,
  userInfo,
}) {
  const { language } = useSelector((state) => state.settings);
  const { control, errors, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [langData, setLangData] = useState();
  const [
    showOldPasswordAndNewPasswordAreEqual,
    setShowOldPasswordAndNewPasswordAreEqual,
  ] = useState(false);
  const [showNotMatchPasswordHint, setShowNotMatchPasswordHint] = useState(
    false
  );

  useEffect(() => {
    (async () => {
      if (showAuthLoader) showAuthLoader();
      const response = await RetrievePublic({
        langId: language.LangId,
        moduleCodeStrings: "SecUserChangePassword",
      });
      if (hideAuthLoader) hideAuthLoader();
      if (_.get(response, "ErrorHandling.IsSuccessful"))
        setLangData(_.get(response, "Data.PE"), _.get(response, "Data.GTC"));
    })();
  }, [language.LangId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    if (data.ConfirmPassword === data.NewPassword) {
      {
        if (data.OldPassword === data.NewPassword) {
          setShowOldPasswordAndNewPasswordAreEqual(true);
          return;
        }
        if (showOldPasswordAndNewPasswordAreEqual)
          setShowOldPasswordAndNewPasswordAreEqual(false);
        if (showAuthLoader) showAuthLoader();
        else setIsLoading(true);
        if (showNotMatchPasswordHint) setShowNotMatchPasswordHint(false);
        let iModel = {
          LangId: getLanguage().LangId,
          UserId: userInfo?.UserId,
          VT: userInfo?.VT,
          OldPassword: data.OldPassword,
          NewPassword: data.NewPassword,
          ConfirmPassword: data.ConfirmPassword,
        };
        const { ErrorHandling } = iModel.UserId
          ? await PasswordExpired(iModel)
          : await UserChangePassword(iModel);

        if (_.get(ErrorHandling, "IsSuccessful")) {
          onClose();
        }
      }
      if (!hideAuthLoader) setIsLoading(false);
      else hideAuthLoader();
    } else setShowNotMatchPasswordHint(true);
  };

  if (!langData)
    if (hasCloseBtn)
      return (
        <div className="container">
          <CircularProgress />
        </div>
      );
    else return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12">
          <TextField
            label={_.get(langData, "OldPassword")}
            control={control}
            name="OldPassword"
            errors={errors}
            rules={{
              required: _.get(langData, "Password_IsNull") || "required",
            }}
            type="password"
            hintColor="error"
            autoFocus
            fullWidth
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <TextField
            label={_.get(langData, "NewPassword")}
            control={control}
            name="NewPassword"
            errors={errors}
            rules={{
              required: _.get(langData, "Password_IsNull") || "required",
            }}
            type="password"
            hintColor="error"
            hint={
              showOldPasswordAndNewPasswordAreEqual
                ? _.get(langData, "OldPasswordAndNewPasswordAreEqual") ||
                  "old password and new password are equal"
                : undefined
            }
            fullWidth
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <TextField
            label={_.get(langData, "SecUser_UserPasswordRepeat")}
            control={control}
            name="ConfirmPassword"
            errors={errors}
            rules={{
              required: _.get(langData, "Password_IsNull") || "required",
            }}
            type="password"
            hint={
              showNotMatchPasswordHint
                ? _.get(langData, "UserPasswordRepeat_NotEqual") || "Not Match"
                : undefined
            }
            hintColor="error"
            fullWidth
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <Button
            mode="save"
            label={
              hasCloseBtn
                ? _.get(langData, "Save")
                : _.get(langData, "SecUserChangePassword")
            }
            showLoading={isLoading}
          />
          {hasCloseBtn && (
            <Button
              className="mx-3"
              mode="close"
              label={_.get(langData, "Close")}
              onClick={onClose}
            />
          )}
        </div>
      </div>
    </form>
  );
}
