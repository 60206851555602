import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService, {
  jarvisCustomServiceMustBeReplaced,
} from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  generateJarvisOutput,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export const SystemRetrieve = async ({
  pageIndex,
  pageSize,
  orderMethod,
  orderBy,
  orderBys,
  where,
  retrieveAsList,
  UserId,
  VTUserId,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pub/System/RetrieveAsList"
      : "Pub/System/Retrieve";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      orderBy,
      orderBys,
      orderMethod,
      where,
      UserId,
      VTUserId,
    },
  });

  return data;
};

export const SystemCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/System/Create",
    data: _data,
  });
  return data;
};

export const SystemUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/System/Update",
    data: _data,
  });
  return data;
};

export const SystemDelete = async ({
  SystemId,
  VT,
  SystemLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/System/Delete",
    data: { SystemId, VT, SystemLastUpdateDateTime },
  });
  return data;
};

export const SystemRetrieveIncludeSubSystem = async () => {
  const customServiceName = "SystemService.SystemRetrieveIncludeSubSystem";
  const res = jarvisCustomServiceMustBeReplaced(customServiceName);
  if (res.mustBeReplaced === false) {
    const { data } = await JarvisHttpService.post({
      url:
        JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
        "Pub/System/RetrieveIncludeSubSystem",
    });
    return data;
  }
  const langKey = getLanguage().LangKey;
  return generateJarvisOutput({
    data: require(`JarvisCustom/JarvisServices/Locale/${langKey}/${customServiceName}.json`),
  });
};
