import _ from "lodash";
import {
  isNullOrWhiteSpace,
  ifNullOrWhiteSpace,
  isEqual,
} from "./JarvisCommonService";
import { getLanguage } from "./JarvisLocalStorageService";

const JarvisBaseSettings = require("./JarvisBaseSettings.json");

export default class JarvisBaseSettingsService {
  static getService() {
    return this;
  }

  static getEnvironment() {
    return ifNullOrWhiteSpace(JarvisBaseSettings.Environment, "Dev");
  }
  
  static getJarvisUIXUrl() {
    return ifNullOrWhiteSpace(_.get(JarvisBaseSettings,"jarvis-uix-url"), "http://localhost:3001");
  }

  static getApiEndPoint(systemKey) {
    let environment = JarvisBaseSettings.Environment;
    if (!environment) environment = "DEV";
    const system = JarvisBaseSettings.Systems.find((item) => {
      return item.Key.toLowerCase() === systemKey.toLowerCase();
    });
    if (!system) return null;
    const api = system.Api.find((item) => {
      return item.Environment.toLowerCase() === environment.toLowerCase();
    });
    return ifNullOrWhiteSpace(api.EndPoint, window.location.origin + "/");
  }

  static getCustomApiEndpoint(apiKey) {
    const jarvisApiSetting = require("JarvisCustom/JarvisApiSettings.json");
    let environment = JarvisBaseSettings.Environment;
    if (!environment) environment = "DEV";
    const find = jarvisApiSetting.Apis.find((item) => {
      return item.Key.toLowerCase() === apiKey.toLowerCase();
    });

    if (!find) return null;
    const api = find.Api.find((item) => {
      return item.Environment.toLowerCase() === environment.toLowerCase();
    });

    return ifNullOrWhiteSpace(api.Endpoint, window.location.origin + "/");
  }
}

export const jarvisComponentMustBeExcluded = (componentName) => {
  const jarvisCustomSetting = require("JarvisCustom/JarvisCustomSettings.json");
  const find = jarvisCustomSetting.components.find(
    (x) => isEqual(x.name, componentName) && x.mustBeExcluded === true
  );
  if (find) return true;
  return false;
};

export const jarvisCustomServiceMustBeReplaced = (serviceName) => {
  const jarvisCustomSetting = require("JarvisCustom/JarvisCustomSettings.json");
  const find = jarvisCustomSetting.customServices.find((x) =>
    isEqual(x.name, serviceName)
  );
  let result = _.pick(find, ["mustBeReplaced", "apiKey", "url"]);
  result.mustBeReplaced = ifNullOrWhiteSpace(result.mustBeReplaced, false);
  return result;
};

export const getJarvisCustomLocale = ({
  moduleCodeStrings,
  config,
  systemMessageCodeStrings,
  withSeparateModuleCodeString,
  langKey,
}) => {
  langKey = ifNullOrWhiteSpace(langKey, getLanguage().LangKey);
  let result = {};
  if (isNullOrWhiteSpace(moduleCodeStrings) === false) {
    let pageElem = {};
    _.split(moduleCodeStrings, ",").forEach((moduleCodeString) => {
      const jsonDataPageElem = require(`JarvisCustom/JarvisLocale/${langKey}/PageElement/${moduleCodeString}.json`);
      if (withSeparateModuleCodeString === true)
        pageElem = _.merge(pageElem, { [moduleCodeString]: jsonDataPageElem });
      else pageElem = _.merge(pageElem, jsonDataPageElem);
    });
    result.PE = pageElem;
  }

  if (isNullOrWhiteSpace(systemMessageCodeStrings) === false) {
    const jsonDataSystemMessage = require(`JarvisCustom/JarvisLocale/${langKey}/SystemMessage.json`);
    result.SM = _.pick(
      jsonDataSystemMessage,
      _.split(systemMessageCodeStrings, ",")
    );
  }

  if (isNullOrWhiteSpace(config) === false && _.isArray(config)) {
    const jsonDataConfig = require(`JarvisCustom/JarvisLocale/${langKey}/Config.json`);
    result.Config = _.pick(
      jsonDataConfig,
      config.map((conf) => {
        return conf.ConfigCode;
      })
    );
  }
  return result;
};
