import React from "react";
import _ from "lodash";
import SyncIcon from "@material-ui/icons/Sync";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AddIcon from "@material-ui/icons/Add";
import { notify } from "Jarvis/JarvisComponents/Notification";
import Table from "Jarvis/JarvisComponents/Table";
import MainContainerBody from "Jarvis/JarvisComponents/Containers/MainContainerBody";

export default function PatronDuplicate({
  duplicateData,
  langData,
  onClose,
  onSubmit,
  partonType,
}) {
  const realColumns = [
    {
      label: _.get(langData, "PatronRealLangFullName"),
      path: "PatronRealLangFullName",
    },
    {
      label: _.get(langData, "PatronRealIDNo"),
      path: "PatronRealIDNo",
    },
    {
      label: _.get(langData, "Cellphone"),
      path: "Cellphone",
    },
    {
      label: _.get(langData, "Phone"),
      path: "Phone",
    },
    {
      label: _.get(langData, "Email"),
      path: "Email",
    },
  ];

  const legalColumns = [
    {
      label: _.get(langData, "PatronLegalLangDescription"),
      path: "PatronLegalLangDescription",
    },
    {
      label: _.get(langData, "PatronLegalIDNo"),
      path: "PatronLegalIDNo",
    },
    {
      label: _.get(langData, "Phone"),
      path: "Phone",
    },
    {
      label: _.get(langData, "Email"),
      path: "Email",
    },
    {
      label: _.get(langData, "Cellphone"),
      path: "Cellphone",
    },
  ];

  const otherColumns = [
    {
      label: _.get(langData, "PatronOtherLangDescription"),
      path: "PatronOtherLangDescription",
    },
    {
      label: _.get(langData, "PatronCode"),
      path: "PatronCode",
    },
  ];

  const handleSubmit = (mode, selectedRows) => {
    if (selectedRows?.length === 0) {
      notify({
        type: "warning",
        message: _.get(langData, "NoRowsSelected"),
      });
      return;
    }
    onSubmit(mode, _.first(selectedRows));
    onClose();
  };

  return (
    <MainContainerBody>
      <Table
        columns={
          partonType === "R"
            ? realColumns
            : partonType === "L"
            ? legalColumns
            : otherColumns
        }
        columnsStackSize={5}
        customSelectMenuItems={[
          {
            name: "Replace",
            icon: <BorderColorIcon fontSize="small" />,
            label: _.get(langData, "Replace"),
            onClick: (rows) => handleSubmit("Replace", rows),
          },
          {
            name: "Merge",
            icon: <SyncIcon fontSize="small" />,
            label: _.get(langData, "Merge"),
            onClick: (rows) => handleSubmit("Merge", rows),
          },
          {
            name: "Add",
            icon: <AddIcon fontSize="small" />,
            label: _.get(langData, "Add"),
            onClick: () => handleSubmit("Create"),
          },
        ]}
        dataInfo={{
          data: duplicateData,
          totalRecords: duplicateData.length,
        }}
        displayMode="select"
        multiSelect={false}
      />
    </MainContainerBody>
  );
}
