import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { SubSystemRetrieve } from "Jarvis/JarvisSystems/Pub/Services/SubSystemService";

export default function SubSystemSelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={SubSystemRetrieve}
      serviceParams={{
        ...serviceParams,
        retrieveAsList: true,
      }}
      displayFieldName="SubSystemLangDescription"
      valueFieldName="SubSystemId"
      {...rest}
    />
  );
}
