import { all, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "Jarvis/JarvisConstants/ActionTypes";

import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from "Jarvis/JarvisActions/Auth";
import { setPrevUsername } from "Jarvis/JarvisServices/JarvisLocalStorageService";

function* fnSignIn(userInfo) {
  try {
    localStorage.setItem("userInfo", JSON.stringify(userInfo.payload));
    setPrevUsername(userInfo.payload?.username);
    yield put(userSignInSuccess(userInfo.payload));
  } catch (error) {
    yield put(showAuthMessage(error).message);
  }
}

function* signOut() {
  try {
    localStorage.removeItem("userInfo");
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, fnSignIn);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
