import React from "react";
import { DialogActions } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Button from "Jarvis/JarvisComponents/Button";

export const ConfirmationDialog = ({
  acceptText,
  closeText,
  content,
  extraContent,
  isLoading,
  onAcceptBtnClick,
  onCloseBtnClick,
  open,
  showDeleteIcon,
  showAcceptButton,
  title,
  warningColor,
  children,
}) => {
  const contentText = `${content ?? ""}${
    extraContent ? "\r\n\r\n" + extraContent : ""
  }`;
  return (
    <Dialog
      width="40%"
      showDialogAction={false}
      open={open}
      title={title}
      contentText={contentText}
      onClose={onCloseBtnClick}
      warningColor={warningColor}
    >
      {children}
      <DialogActions>
        {showAcceptButton && (
          <Button
            label={acceptText || "Yes"}
            color="secondary"
            showLoading={isLoading}
            size="small"
            startIcon={showDeleteIcon ? <DeleteIcon /> : undefined}
            variant="contained"
            onClick={onAcceptBtnClick}
            autoFocus
          />
        )}
        <Button
          label={closeText || "No"}
          size="small"
          variant="outlined"
          onClick={onCloseBtnClick}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  showAcceptButton: true,
};
