import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const UserRetrieve = async ({ pageIndex, pageSize, totalRecords, orderBy, orderBys, orderMethod, where, retrieveAsList, systemId, VTSystemId }) => {
  const retrieveService = !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true ? "Sec/User/RetrieveAsList" : "Sec/User/Retrieve";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      orderBy,
      orderBys,
      orderMethod,
      where,
      systemId,
      VTSystemId,
    },
  });

  return data;
};

export const UserRetrieveFull = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/RetrieveFull",
    data: _data,
  });
  return data;
};

export const RetrieveMembership = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/RetrieveMembership",
    data: _data,
  });
  return data;
};

export const UserCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/Create",
    data: _data,
  });
  return data;
};

export const UserUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/Update",
    data: _data,
  });
  return data;
};

export const UserDelete = async ({ UserId, VT, UserLastUpdateDateTime }) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/Delete",
    data: { UserId, VT, UserLastUpdateDateTime },
  });
  return data;
};

export const UserChangePassword = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/ChangePassword",
    data: _data,
  });

  return data;
};

export const UserLogout = async () => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/Logout",
  });
  return data;
};

export const UserExportToExcel = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/ExportToExcel",
    data: _data,
  });
  return data;
};

export const UserExtraInfoRetrieve2FactorAuth = async (iModel) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/UserExtraInfoRetrieve2FactorAuth",
    data: iModel,
  });
  return data;
};

export const SetTwoFactorAuth = async (_data) => {
  _data = { ..._data, customUserId: _data.UserId, vtCustomUserId: _data.VT };
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/SetTwoFactorAuth",
    data: _data,
  });
  return data;
};

export const UserPatronRetrieve = async ({ pageIndex, pageSize, orderBy, orderBys, orderMethod, where }) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/UserPatronRetrieve",
    data: {
      pageIndex,
      pageSize,
      orderBy,
      orderBys,
      orderMethod,
      where,
    },
  });

  return data;
};

// retrieve all patrons for one active user
export const RetrieveUserPatron = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/RetrieveUserPatron",
    data: _data,
  });
  return data;
};

export const SwitchProfile = async ({ PatronId, VT }) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Sec/User/SwitchProfile",
    data: { PatronId, VT },
  });
  return data;
};
