import React, { useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  AccountTree,
  Add,
  Build,
  CheckBoxOutlineBlank,
  CheckCircleOutline,
  ControlCamera,
  Label,
  LocalBar,
  Refresh,
  SelectAll,
  UnfoldLess,
  ViewColumn,
} from "@material-ui/icons";
import ToggleButton from "../ToggleButton";

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TopPanel({
  currentRow,
  customMenuItems,
  draggable,
  hasBadge,
  hasColumnSelect,
  hasExportToExcel,
  hasOperations,
  hasRowSelect,
  hasRowFilter,
  multiSelect,
  onAddClick,
  onBadgeClick,
  onClose,
  onColumnSelectClick,
  onCollapseAllClick,
  onDragClick,
  onExportClick,
  onOperationsClick,
  onRefreshGridClick,
  onRowFilterClick,
  onRowSelectClick,
  onSelectAllClick,
  onSelectChildrenClick,
  PE,
  showAdd,
  rowFilter,
  showOperationsColumn,
  rowSelect,
  selectAll,
  selectChildren,
  selectedNodes,
  showBadge,
  showDragHandler,
}) {
  const [toolbarSwitch, setToolbarSwitch] = useState();
  const classes = useStyles();

  const handleSwitch = (event, newSwitch) => {
    onClose();
    setToolbarSwitch(newSwitch);
  };

  return (
    <div className="container">
      <div className="row">
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup
            value={toolbarSwitch}
            onChange={handleSwitch}
            aria-label="table opertation"
            size="small"
          >
            <ToggleButton
              className="panel-item"
              value="refresh"
              selected={false}
              aria-label="refresh"
              icon={<Refresh />}
              tooltip={_.get(PE, "Refresh") || "Refresh"}
              onClick={() => onRefreshGridClick()}
            />

            {hasRowSelect && (
              <ToggleButton
                className="panel-item"
                value="rowSelect"
                selected={rowSelect}
                aria-label="rowSelect"
                icon={<CheckCircleOutline />}
                tooltip={_.get(PE, "SelectRow") || "Select Row"}
                onClick={onRowSelectClick}
              />
            )}
            {rowSelect && multiSelect && (
              <ToggleButton
                className="panel-item"
                value="selectChildren"
                selected={selectChildren}
                aria-label="selectChildren"
                icon={<AccountTree />}
                tooltip={_.get(PE, "SelectSubset") || "Select Subtree"}
                onClick={() => onSelectChildrenClick(!selectChildren)}
              />
            )}
            {rowSelect && multiSelect && !selectAll && (
              <ToggleButton
                className="panel-item"
                value="selectAll"
                selected={false}
                aria-label="selectAll"
                icon={<SelectAll />}
                tooltip={_.get(PE, "SelectAll") || "Select all"}
                onClick={() => onSelectAllClick(null, true)}
              />
            )}
            {selectAll && (
              <ToggleButton
                className="panel-item"
                value="cancelSelect"
                selected={false}
                aria-label="cancelSelect"
                icon={<CheckBoxOutlineBlank />}
                tooltip={_.get(PE, "CancelSelect") || "Cancel select"}
                onClick={() => onSelectAllClick(null, false)}
              />
            )}
            {customMenuItems?.length > 0 &&
              customMenuItems.map((menuItem) => {
                if (
                  _.isNil(menuItem.displayIf) ||
                  menuItem.displayIf(currentRow)
                )
                  return (
                    <ToggleButton
                      className="panel-item"
                      value={menuItem.name}
                      selected={false}
                      aria-label={menuItem.name}
                      key={menuItem.name}
                      icon={menuItem.icon}
                      tooltip={menuItem.toolTip || ""}
                      onClick={() =>
                        menuItem.onClick(
                          selectedNodes.filter((node) => !_.isEmpty(node))
                        )
                      }
                    />
                  );

                return null;
              })}
            {hasColumnSelect && (
              <ToggleButton
                className="panel-item"
                value="columnSelect"
                selected={false}
                aria-label="columnSelect"
                icon={<ViewColumn />}
                tooltip={_.get(PE, "SelectColumn") || "Select Column"}
                onClick={() => onColumnSelectClick(true)}
              />
            )}
            {showAdd && (
              <ToggleButton
                className="panel-item"
                value="addNode"
                selected={false}
                aria-label="addNode"
                icon={<Add />}
                tooltip={_.get(PE, "Add") || "Add"}
                onClick={() => onAddClick(true, null)}
              />
            )}
            {hasOperations && (
              <ToggleButton
                className="panel-item"
                value="operationsColumn"
                selected={showOperationsColumn}
                aria-label="operationsColumn"
                icon={<Build />}
                tooltip={_.get(PE, "Operations") || "Operations"}
                onClick={() => onOperationsClick(!showOperationsColumn)}
              />
            )}
            {draggable && (
              <ToggleButton
                className="panel-item"
                value="drag"
                selected={showDragHandler}
                aria-label="drag"
                icon={<ControlCamera />}
                tooltip={_.get(PE, "Drag") || "Drag nodes to reorder"}
                onClick={() => onDragClick(!showDragHandler)}
              />
            )}
            {hasRowFilter && (
              <ToggleButton
                className="panel-item"
                value="rowFilter"
                selected={rowFilter}
                aria-label="rowFilter"
                icon={<LocalBar />}
                tooltip={_.get(PE, "Filter") || "Filter"}
                onClick={() => onRowFilterClick(!rowFilter)}
              />
            )}
            {hasBadge && (
              <ToggleButton
                className="panel-item"
                value="badge"
                selected={showBadge}
                aria-label="badge"
                icon={<Label />}
                tooltip={_.get(PE, "Badge") || "Number of Subsets"}
                onClick={() => onBadgeClick(!showBadge)}
              />
            )}
            <ToggleButton
              className="panel-item"
              value="collapseAll"
              selected={false}
              aria-label="collapseAll"
              icon={<UnfoldLess />}
              tooltip={_.get(PE, "CollapseAll") || "Close Subsets"}
              onClick={() => onCollapseAllClick()}
            />
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
}
