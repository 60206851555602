import React from "react";
import { FinancialTermRetrieve } from "Jarvis/JarvisSystems/Pub/Services/FinancialTermService";
import Select from "Jarvis/JarvisComponents/Select";

export default function FinancialTermSelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={FinancialTermRetrieve}
      serviceParams={{ ...serviceParams, retrieveAsList: true }}
      displayFieldName="FinancialTermLangDescription"
      valueFieldName="FinancialTermId"
      {...rest}
    />
  );
}
