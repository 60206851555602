import _ from "lodash";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService, {
  getJarvisCustomLocale,
  jarvisCustomServiceMustBeReplaced,
} from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  generateJarvisErrorHandling,
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import { getGraphQLUrl } from "Jarvis/JarvisServices/JarvisGraphQLService";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export const CompanyRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  retrieveAsList,
  hierarchyShowMode,
  latestNodeLevel,
  isDeepSearchNeeded,
  CompanyIsOperational,
  node,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pub/Company/RetrieveAsList"
      : "Pub/Company/Retrieve";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    NodeId: node?.["CompanyId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  let newWhere = where || [];
  if (CompanyIsOperational) {
    newWhere = [
      ...newWhere,
      {
        FieldName: "CompanyIsOperational",
        DataType: "BIT",
        Operator: "=",
        Value: "1",
      },
    ];
  }
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      orderBy,
      orderBys,
      orderMethod,
      where: newWhere,
      hierarchy,
    },
  });

  return data;
};

export const CompanyCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Company/Create",
    data: _data,
  });
  return data;
};

export const CompanyUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Company/Update",
    data: _data,
  });
  return data;
};

export const CompanyDelete = async ({
  CompanyId,
  VT,
  CompanyLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Company/Delete",
    data: { CompanyId, VT, CompanyLastUpdateDateTime },
  });
  return data;
};

export const CompanySelectionGetPrerequisitesGraphQL = async () => {
  const customServiceName =
    "CompanyService.CompanySelectionGetPrerequisitesGraphQL";
  const res = jarvisCustomServiceMustBeReplaced(customServiceName);
  if (res.mustBeReplaced === false) {
    const { data } = await JarvisHttpService.post({
      url: getGraphQLUrl(),
      data: {
        query: `
        {
          companies:companyRetrieveIncludesFinancialTerm{
            data{
              CompanyId:companyId,
              CompanyLangDescription:companyLangDescription,
             VT:vt,
             FinancialTerms:financialTerms{
              FinancialTermId:financialTermId,
              VT:vt,
              FinancialTermLangDescription:financialTermLangDescription,
              FinancialTermStartDate:financialTermStartDate,
              FinancialTermEndDate:financialTermEndDate
             }
            }
          },
          langData:pageElementRetrievePublic(iModel:{systemMessageCodeStrings:"PubCompanySelection_Title,PubCompany,PubFinancialTerm"}){
            data
          }
        }`,
      },
    });
    return {
      errorHandling: _.get(data, "ErrorHandling"),
      companies: _.get(data, "Data.data.companies"),
      langData: JSON.parse(
        ifNullOrWhiteSpace(_.get(data, "Data.data.langData.data"), null)
      ),
    };
  }
  const langKey = getLanguage().LangKey;
  return {
    errorHandling: generateJarvisErrorHandling({}),
    companies: {
      data: _.get(
        require(`JarvisCustom/JarvisServices/Locale/${langKey}/${customServiceName}.json`),
        "Companies"
      ),
    },
    langData: getJarvisCustomLocale({
      systemMessageCodeStrings:
        "PubCompanySelection_Title,PubCompany,PubFinancialTerm",
    }),
  };
};

export const SwitchCompanyFinancialTerm = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/Company/SwitchCompanyFinancialTerm",
    data: _data,
  });
  return data;
};
