import React from "react";
import _ from "lodash";
import { SwitchLanguage } from "Jarvis/JarvisSystems/Pub/Services/LangService";
import { setCalendar } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export default function LanguageItem({
  language,
  switchLanguage,
  handleRequestClose,
  onChangeLanguage,
  mustBeBold,
}) {
  const icon = language.LangFlagIcon;
  const name = language.LangName;

  return (
    <li
      className="pointer"
      onClick={async () => {
        handleRequestClose();
        const result = await SwitchLanguage({
          langId: language.LangId,
          langKey: language.LangKey,
          vt: language.VT,
        });
        if (_.get(result, "ErrorHandling.IsSuccessful") === true) {
          const {
            CalendarId,
            CalendarKey,
            CalendarDefaultFormat,
          } = result?.Data;
          setCalendar({
            calendarId: CalendarId,
            calendarKey: CalendarKey,
            calendarFormat: CalendarDefaultFormat,
          });
          await onChangeLanguage(language);
          switchLanguage(language);
        }
      }}
    >
      <div className="d-flex align-items-center">
        <i
          className={
            icon
              ? `flag flag-24 flag-${icon}`
              : "zmdi zmdi-blur-circular zmdi-hc-fw"
          }
        />
        <h4
          className={`mb-0 ml-2 ${language.JarvisFontClass} Jarvis-Font-Size-8rem`}
        >
          {mustBeBold === true ? <strong>{name}</strong> : name}
        </h4>
      </div>
    </li>
  );
}
