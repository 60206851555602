import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { switchFinancialTerm } from "Jarvis/JarvisActions/Setting";
import {
  getCompany,
  setCompany,
} from "Jarvis/JarvisServices/JarvisLocalStorageService";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import { SwitchCompanyFinancialTerm } from "Jarvis/JarvisSystems/Pub/Services/CompanyService";
import { jarvisComponentMustBeExcluded } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import InputForm from "./InputForm";
import Typography from "Jarvis/JarvisComponents/Typography";

export default function CompanyFinancialTerm() {
  if (jarvisComponentMustBeExcluded("CompanySelection")) return null;
  return <Index />;
}

function Index() {
  const dispatch = useDispatch();
  const { PE } = useContext(GlobalContext);
  const [stateObject, setStateObject] = useState({});
  const [showInputForm, setShowInputForm] = useState(false);
  const selectedCompanyFinancialTerm = getCompany();
  const langData = _.pick(PE.PubPublicElems, [
    "PubCompany",
    "PubFinancialTerm",
  ]);

  useEffect(() => {
    (async () => {
      setStateObject(
        _.pick(selectedCompanyFinancialTerm, [
          "CompanyLangDescription",
          "FinancialTermLangDescription",
        ])
      );

      const { ErrorHandling } = await SwitchCompanyFinancialTerm(
        selectedCompanyFinancialTerm
      );
      if (_.get(ErrorHandling, "IsSuccessful")) {
        if (_.get(selectedCompanyFinancialTerm, "FinancialTermId"))
          dispatch(
            switchFinancialTerm(selectedCompanyFinancialTerm.FinancialTermId)
          );
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    setShowInputForm(true);
  };

  const handleSubmit = async (data, extraData) => {
    const { ErrorHandling } = await SwitchCompanyFinancialTerm(data);

    if (_.get(ErrorHandling, "IsSuccessful")) {
      setStateObject({
        CompanyLangDescription: _.get(extraData, "CompanyLangDescription"),
        FinancialTermLangDescription: _.get(
          extraData,
          "FinancialTermLangDescription"
        ),
      });
      setCompany(_.merge(data, extraData));
      if (data.FinancialTermId)
        dispatch(switchFinancialTerm(data.FinancialTermId));
    }
    return _.get(ErrorHandling, "IsSuccessful");
  };

  return (
    <>
      <div
        className="zmdi zmdi-balance"
        style={{ fontSize: "24px", cursor: "pointer" }}
        onClick={handleClick}
      >
        <Typography
          className="mx-2"
          label={stateObject.CompanyLangDescription}
        />
        <br />
        <Typography
          label={stateObject.FinancialTermLangDescription}
          fontSize={8}
        />
      </div>
      {showInputForm && (
        <InputForm
          langData={langData}
          onClose={() => setShowInputForm(false)}
          onSubmitParent={handleSubmit}
          selectedCompanyFinancialTerm={selectedCompanyFinancialTerm}
          showInputForm={showInputForm}
        />
      )}
    </>
  );
}
