import React, { useState, useEffect } from "react";
import _ from "lodash";
import Dialog from "../Dialog";
import Button from "../Button";
import Draggable from "react-draggable";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { blueGrey } from "@material-ui/core/colors";
import Checkbox from "../Checkbox";
import { ConfigModuleCreate } from "Jarvis/JarvisSystems/Pub/Services/ConfigModuleService";

export default function ColumnSelect({
  columns,
  configs,
  getColumns,
  moduleCodeString,
  onClose,
  PE,
  showColumnSelect,
}) {
  const [addRowIndex, setAddRowIndex] = useState([]);
  const [columnsOrder] = useState(
    !_.isEmpty(_.get(configs, `${moduleCodeString}.Table.Columns`))
      ? _.get(configs, `${moduleCodeString}.Table.Columns`)?.split(",")
      : []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [rows, setRows] = useState(null);
  const [target, setTarget] = useState({ index: 0, deltaY: 0 });
  const len = columns.length;
  const height = 30;

  useEffect(() => {
    let rowsTemp = [...columns];
    if (!_.isEmpty(columnsOrder)) {
      const result = [];
      let index = -1;
      columnsOrder.forEach((colName) => {
        index = rowsTemp.findIndex((row) => row.path === colName);
        if (index !== -1) {
          result.push(rowsTemp[index]);
          rowsTemp.splice(index, 1);
        }
      });
      rowsTemp = [...result, ...rowsTemp];
    }
    setRows(rowsTemp);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (save) => {
    if (rows.length > 0) {
      setReset(false);
      let columnNames = "";
      if (!_.isEmpty(columnsOrder)) {
        for (let i = 0; i < rows.length; i++) {
          if (columnsOrder.find((columnName) => columnName === rows[i].path)) {
            if (addRowIndex.indexOf(i) === -1)
              columnNames += `${rows[i].path},`;
          } else if (addRowIndex.indexOf(i) !== -1)
            columnNames += `${rows[i].path},`;
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (
            (rows[i].visible !== false && addRowIndex.indexOf(i) === -1) ||
            (rows[i].visible === false && addRowIndex.indexOf(i) !== -1)
          )
            columnNames += `${rows[i].path},`;
        }
      }
      columnNames = columnNames.substring(0, columnNames.length - 1);

      let response = null;
      if (save) {
        setIsLoading(true);
        response = await ConfigModuleCreate({
          ModuleCodeString: moduleCodeString,
          ConfigModuleCode: "Table",
          ConfigModuleValue: JSON.stringify({ Columns: columnNames }),
        });
        setIsLoading(false);
      }
      if (!save || _.get(response, "ErrorHandling.IsSuccessful")) {
        _.set(configs, `${moduleCodeString}.Table.Columns`, columnNames);
        getColumns();
        onClose();
      }
    }
  };

  const getCheckboxValue = (row) => {
    if (!_.isEmpty(columnsOrder)) {
      return (
        columnsOrder.findIndex((columnName) => columnName === row.path) !== -1
      );
    }
    return row.visible !== false;
  };

  const handleResetConfigs = async () => {
    setReset(true);
    setIsLoading(true);
    const { ErrorHandling } = await ConfigModuleCreate({
      ModuleCodeString: moduleCodeString,
      ConfigModuleCode: "Table",
      ConfigModuleValue: "",
    });
    setIsLoading(false);

    if (_.get(ErrorHandling, "IsSuccessful")) {
      _.set(configs, `${moduleCodeString}.Table.Columns`, "");
      getColumns();
      onClose();
    }
  };

  const handleVisibleChange = (e, index) => {
    const addRowIndexTemp = [...addRowIndex];
    if (e.target.value === "true") {
      const i = addRowIndexTemp.indexOf(index);
      if (i !== -1) {
        addRowIndexTemp.splice(i, 1);
      } else addRowIndexTemp.push(index);
    } else addRowIndexTemp.push(index);

    setAddRowIndex(addRowIndexTemp);
  };

  const handleDragStart = (e, ui, i) => {
    setTarget({ index: i, deltaY: ui.lastY });
  };

  const handleDragStop = (e, ui) => {
    const d = (ui.lastY - target.deltaY) / height;
    if (d !== 0) {
      const indices = _.range(0, len);
      let temp = 0;
      if (d > 0) {
        for (let i = target.index, j = 1; j <= d; j++, i++) {
          temp = indices[i];
          indices[i] = indices[i + 1];
          indices[i + 1] = temp;
        }
      } else {
        for (let i = target.index, j = 1; j <= Math.abs(d); j++, i--) {
          temp = indices[i];
          indices[i] = indices[i - 1];
          indices[i - 1] = temp;
        }
      }
      const rowsTemp = [];
      for (let i = 0; i < len; i++) {
        rowsTemp.push(rows[indices[i]]);
      }
      setRows(rowsTemp);
    }
  };

  return (
    <Dialog
      width="40%"
      open={showColumnSelect}
      title={_.get(PE, "SelectColumn") || "Select Column"}
      showDialogAction={false}
      maxWidth={false}
      onClose={onClose}
    >
      <div className="container">
        {rows?.map((row, i) => (
          <Draggable
            key={row.path}
            handle=".handle"
            axis="y"
            bounds={{ top: -i * height, bottom: (len - i - 1) * height }}
            position={{ x: 0, y: 0 }}
            grid={[height, height]}
            defaultClassNameDragging="dragging"
            onStart={(e, ui) => handleDragStart(e, ui, i)}
            onStop={handleDragStop}
          >
            <div className="box w-100 d-flex align-items-center">
              <DragIndicatorIcon
                style={{ color: blueGrey[400], cursor: "move" }}
                className="handle"
                fontSize="small"
              />
              <Checkbox
                className="mx-1"
                label={row.label}
                value={getCheckboxValue(row)}
                onChange={(e) => handleVisibleChange(e, i)}
              />
            </div>
          </Draggable>
        ))}
      </div>

      <div className="row mt-3">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <Button
            label={_.get(PE, "Display")}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(false)}
          />
          <Button
            label={_.get(PE, "Save")}
            className="mx-3"
            variant="contained"
            color="primary"
            showLoading={isLoading && !reset}
            disabled={isLoading && reset}
            onClick={() => handleSubmit(true)}
          />
          <Button
            label={_.get(PE, "DefaultSettings")}
            variant="contained"
            color="primary"
            showLoading={isLoading && reset}
            disabled={isLoading && !reset}
            onClick={handleResetConfigs}
          />
        </div>
      </div>
    </Dialog>
  );
}
