import React, { useState } from "react";
import _ from "lodash";
import TextField from "Jarvis/JarvisComponents/TextField";
import PublicCodeSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/PublicCodeSelect";

export default function TTMSPatron({
  control,
  errors,
  isEditMode,
  langData,
  patronData,
  preData,
}) {
  const [isBuyerType, setIsBuyerType] = useState(false);
  const [isSellerType, setIsSellerType] = useState(false);

  const patronTTMS = _.get(patronData, "TTMSPatron") || {};

  const handleBuyerTypeChange = (value) => {
    if (value) {
      if (!isBuyerType) setIsBuyerType(true);
    } else setIsBuyerType(false);
  };

  const handleSellerTypeChange = (value) => {
    if (value) {
      if (!isSellerType) setIsSellerType(true);
    } else setIsSellerType(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <PublicCodeSelect
            label={_.get(langData, "AccTTMSBuyerType")}
            control={control}
            name="BuyerType"
            errors={errors}
            rules={preData.rules}
            code="AccTTMSBuyerType"
            value={
              isEditMode
                ? {
                    PublicCodeId: _.get(patronTTMS, "BuyerTypeId"),
                    PublicCodeLangDescription: _.get(
                      patronTTMS,
                      "BuyerTypeLangName"
                    ),
                    VT: _.get(patronTTMS, "VTBuyerTypeId"),
                  }
                : patronData.BuyerType
            }
            onChange={handleBuyerTypeChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 col-sm-12">
          <PublicCodeSelect
            label={_.get(langData, "AccTTMSCommodityType")}
            control={control}
            name="BuyerCommodity"
            errors={errors}
            required={isBuyerType}
            rules={
              isBuyerType
                ? {
                    required: _.get(langData, "Required") || "required",
                  }
                : undefined
            }
            code="AccTTMSCommodityType"
            value={
              isEditMode
                ? {
                    PublicCodeId: _.get(patronTTMS, "BuyerCommodityTypeId"),
                    PublicCodeLangDescription: _.get(
                      patronTTMS,
                      "BuyerCommodityLangName"
                    ),
                    VT: _.get(patronTTMS, "VTBuyerCommodityTypeId"),
                  }
                : patronData.BuyerCommodity
            }
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <TextField
            label={_.get(langData, "DefaultProduct")}
            control={control}
            name="BuyerDefaultProduct"
            errors={errors}
            required={isBuyerType}
            rules={
              isBuyerType
                ? {
                    required: _.get(langData, "Required") || "required",
                  }
                : undefined
            }
            value={
              isEditMode
                ? _.get(patronTTMS, "BuyerDefaultProduct")
                : patronData.BuyerDefaultProduct
            }
            fullWidth
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6 col-sm-12">
          <PublicCodeSelect
            label={_.get(langData, "AccTTMSSellerType")}
            control={control}
            name="SellerType"
            errors={errors}
            code="AccTTMSSellerType"
            value={
              isEditMode
                ? {
                    PublicCodeId: _.get(patronTTMS, "SellerTypeId"),
                    PublicCodeLangDescription: _.get(
                      patronTTMS,
                      "SellerTypeLangName"
                    ),
                    VT: _.get(patronTTMS, "VTSellerTypeId"),
                  }
                : patronData.SellerType
            }
            onChange={handleSellerTypeChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6 col-sm-12">
          <PublicCodeSelect
            label={_.get(langData, "AccTTMSCommodityType")}
            control={control}
            name="SellerCommodity"
            errors={errors}
            required={isSellerType}
            rules={
              isSellerType
                ? {
                    required: _.get(langData, "Required") || "required",
                  }
                : undefined
            }
            code="AccTTMSCommodityType"
            value={
              isEditMode
                ? {
                    PublicCodeId: _.get(patronTTMS, "SellerCommodityTypeId"),
                    PublicCodeLangDescription: _.get(
                      patronTTMS,
                      "SellerCommodityLangName"
                    ),
                    VT: _.get(patronTTMS, "VTSellerCommodityTypeId"),
                  }
                : patronData.SellerCommodity
            }
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <TextField
            label={_.get(langData, "DefaultProduct")}
            control={control}
            name="SellerDefaultProduct"
            errors={errors}
            required={isSellerType}
            rules={
              isSellerType
                ? {
                    required: _.get(langData, "Required") || "required",
                  }
                : undefined
            }
            value={
              isEditMode
                ? _.get(patronTTMS, "SellerDefaultProduct")
                : patronData.SellerDefaultProduct
            }
            fullWidth
          />
        </div>
      </div>
    </div>
  );
}
