import React, { useState } from "react";
import _ from "lodash";
import { green, grey } from "@material-ui/core/colors";
import { checkClassName } from "Jarvis/JarvisServices/JarvisCommonService";
import TextField from "Jarvis/JarvisComponents/TextField";
import Chip from "Jarvis/JarvisComponents/Chip";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import PatronSelect from "../CustomSelect/PatronSelect";
import PublicCodeSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/PublicCodeSelect";
import JobPositionTypeSelect from "../CustomSelect/JobPositionTypeSelect";
import JobActivityTypeSelect from "../CustomSelect/JobActivityTypeSelect";
import { Done, PostAdd } from "@material-ui/icons";
import IconButton from "Jarvis/JarvisComponents/IconButton";

export default function PatronFurtherInformation({
  control,
  errors,
  getValues,
  langData,
  patronData,
  setPatronData,
  preData,
  setValue,
}) {
  const [showJobActivityTypeHint, setShowJobActivityTypeHint] = useState(false);
  const [showPatronHint, setShowPatronHint] = useState(false);
  const [showPatronRelationTypeHint, setShowPatronRelationTypeHint] = useState(
    false
  );
  let { PatronJobs, PatronRelations } = patronData;
  if (!PatronJobs) PatronJobs = [];
  if (!PatronRelations) PatronRelations = [];

  //#region ------------- Job -------------
  const handleAddPatronJob = () => {
    const JobActivityType = getValues("JobActivityType");
    if (JobActivityType) {
      const index = PatronJobs.findIndex(
        (patronJob) =>
          patronJob.JobActivityTypeId === JobActivityType.JobActivityTypeId
      );
      if (index === -1) {
        let patronJob = {
          JobActivityTypeId: JobActivityType.JobActivityTypeId,
        };
        patronJob.VTJobActivityType = JobActivityType.VT;
        if (patronData.PatronType === "R") {
          const {
            JobPositionType,
            PatronRealJobPlaceLangName,
            PatronRealJobLangDescription,
          } = getValues([
            "JobPositionType",
            "PatronRealJobPlaceLangName",
            "PatronRealJobLangDescription",
          ]);
          patronJob = _.merge(patronJob, {
            ..._.pick(JobPositionType, [
              "JobPositionTypeId",
              "JobPositionTypeLangName",
            ]),
            PatronRealJobActivityTypeLangName:
              JobActivityType.JobActivityTypeLangName,
            PatronRealJobPlaceLangName,
            PatronRealJobLangDescription,
            IsActive: true,
          });
          patronJob.VTJobPositionType = JobPositionType?.VT;
        } else
          patronJob.PatronLegalJobActivityTypeLangName =
            JobActivityType.JobActivityTypeLangName;
        setPatronData((patronData) => ({
          ...patronData,
          PatronJobs: [...PatronJobs, patronJob],
        }));
      }
      setValue("JobActivityType", null);
      if (patronData.PatronType === "R") {
        setValue("JobPositionType", null);
        setValue("PatronRealJobPlaceLangName", "");
        setValue("PatronRealJobLangDescription", "");
      }
      if (showJobActivityTypeHint) setShowJobActivityTypeHint(false);
    } else {
      if (!showJobActivityTypeHint) setShowJobActivityTypeHint(true);
    }
  };

  const handleDeletePatronJob = (patronJob) => {
    let newPatronJobs = [...PatronJobs];
    const index = PatronJobs.indexOf(patronJob);
    newPatronJobs.splice(index, 1);
    setPatronData((patronData) => ({
      ...patronData,
      PatronJobs: newPatronJobs,
    }));
  };

  const handleActivePatronJob = (e, patronJob) => {
    e.stopPropagation();
    let newPatronJobs = [...PatronJobs];
    const index = PatronJobs.indexOf(patronJob);
    newPatronJobs[index].IsActive = !patronJob.IsActive;
    setPatronData((patronData) => ({
      ...patronData,
      PatronJobs: newPatronJobs,
    }));
  };

  const getPatronJobIsActiveIcon = (patronJob) => (
    <Done
      style={{
        color: patronJob.IsActive ? green[500] : grey[400],
        cursor: "pointer",
      }}
      onClick={(e) => handleActivePatronJob(e, patronJob)}
    />
  );

  const handlePatronJobClick = (patronJob) => {
    setValue("JobActivityType", {
      JobActivityTypeId: patronJob.JobActivityTypeId,
      JobActivityTypeLangName:
        patronData.PatronType === "R"
          ? patronJob.PatronRealJobActivityTypeLangName
          : patronJob.PatronLegalJobActivityTypeLangName,
      VT: patronJob.VTJobActivityType,
    });
    if (patronData.PatronType === "R") {
      setValue("JobPositionType", {
        JobPositionTypeId: patronJob.JobPositionTypeId,
        JobPositionTypeLangName: patronJob.JobPositionTypeLangName,
        VT: patronJob.VTJobPositionType,
      });
      setValue(
        "PatronRealJobPlaceLangName",
        patronJob.PatronRealJobPlaceLangName
      );
      setValue(
        "PatronRealJobLangDescription",
        patronJob.PatronRealJobLangDescription
      );
    }
  };
  //#endregion

  //#region ------------- Relation -------------
  const handleAddPatronRelation = () => {
    const Patron = getValues("Patron");
    if (Patron) {
      const index = PatronRelations.findIndex(
        (patronRelation) => patronRelation.PatronIdTo === Patron.PatronId
      );
      if (index === -1) {
        const PatronRelationType = getValues("PatronRelationType");
        if (!PatronRelationType) {
          setShowPatronRelationTypeHint(true);
          return;
        }
        const patronRelation = {
          PatronIdTo: Patron.PatronId,
          PatronLangFullNameTo: Patron.PatronLangFullName,
          VTPatronTo: Patron.VT,
          PatronRelationTypeId: PatronRelationType.PublicCodeId,
          PatronRelationTypeLangName:
            PatronRelationType.PublicCodeLangDescription,
          VTPatronRelationType: PatronRelationType.VT,
          IsActive: true,
        };
        setPatronData((patronData) => ({
          ...patronData,
          PatronRelations: [...PatronRelations, patronRelation],
        }));
      }
      setValue("Patron", null);
      setValue("PatronRelationType", null);
      if (showPatronHint) setShowPatronHint(false);
      if (showPatronRelationTypeHint) setShowPatronRelationTypeHint(false);
    } else {
      if (!showPatronHint) setShowPatronHint(true);
      if (!showPatronRelationTypeHint) setShowPatronRelationTypeHint(true);
    }
  };

  const handleDeletePatronRelation = (patronRelation) => {
    let newPatronRelations = [...PatronRelations];
    const index = PatronRelations.indexOf(patronRelation);
    newPatronRelations.splice(index, 1);
    setPatronData((patronData) => ({
      ...patronData,
      PatronRelations: newPatronRelations,
    }));
  };

  const handleActivePatronRelation = (e, patronRelation) => {
    e.stopPropagation();
    let newPatronRelations = [...PatronRelations];
    const index = PatronRelations.indexOf(patronRelation);
    newPatronRelations[index].IsActive = !patronRelation.IsActive;
    setPatronData((patronData) => ({
      ...patronData,
      PatronRelations: newPatronRelations,
    }));
  };

  const getPatronRelationIsActiveIcon = (patronRelation) => (
    <Done
      style={{
        color: patronRelation.IsActive ? green[500] : grey[400],
        cursor: "pointer",
      }}
      onClick={(e) => handleActivePatronRelation(e, patronRelation)}
    />
  );

  const handlePatronRelationClick = (patronRelation) => {
    setValue("Patron", {
      PatronId: patronRelation.PatronIdTo,
      PatronLangFullName: patronRelation.PatronLangFullNameTo,
      VT: patronRelation.VTPatronTo,
    });
    setValue("PatronRelationType", {
      PublicCodeId: patronRelation.PatronRelationTypeId,
      PublicCodeLangDescription: patronRelation.PatronRelationTypeLangName,
      VT: patronRelation.VTPatronRelationType,
    });
  };
  //#endregion

  return (
    <div>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronSearchKey")}
            control={control}
            name="PatronSearchKey"
            errors={errors}
            rules={preData.rules}
            value={_.get(patronData, "PatronSearchKey")}
            fullWidth
          />
        </div>
      </div>
      <hr />
      {patronData.PatronType === "R" ? (
        <div
          className={checkClassName(
            "",
            "PatronRealJobActivityTypeLangName",
            preData
          )}
        >
          <div className="row mt-2">
            <div className="col-md-5 col-sm-12">
              <JobActivityTypeSelect
                label={_.get(langData, "PatronRealJobActivityTypeLangName")}
                control={control}
                name="JobActivityType"
                columnName="PatronRealJobActivityTypeLangName"
                errors={errors}
                rules={preData.rules}
                checkRules={false}
                hint={
                  showJobActivityTypeHint
                    ? _.get(langData, "PatronJobActivityId_IsNull")
                    : undefined
                }
                hintColor="error"
                error={showJobActivityTypeHint}
              />
            </div>
            <div className="col-md-5 col-sm-12">
              <JobPositionTypeSelect
                label={_.get(langData, "JobPositionTypeLangName")}
                control={control}
                name="JobPositionType"
                columnName="JobPositionTypeLangName"
                errors={errors}
                checkRules={false}
                rules={preData.rules}
              />
            </div>
          </div>
          <div className="row mt-md-1 d-flex align-items-center">
            <div
              className={checkClassName(
                "col-md-5 col-sm-12",
                "PatronLegalLangDescription",
                preData
              )}
            >
              <TextField
                label={_.get(langData, "PatronRealJobPlaceLangName")}
                control={control}
                name="PatronRealJobPlaceLangName"
                errors={errors}
                rules={preData.rules}
                checkRules={false}
                fullWidth
              />
            </div>
            <div className="col-md-5 col-sm-12">
              <TextField
                label={_.get(langData, "PatronRealJobLangDescription")}
                control={control}
                name="PatronRealJobLangDescription"
                errors={errors}
                rules={preData.rules}
                checkRules={false}
                fullWidth
              />
            </div>
            <IconButton
              className="jarvisIconHover"
              size="small"
              icon={<PostAdd />}
              color="primary"
              tooltip={_.get(langData, "AddToList")}
              onClick={handleAddPatronJob}
            />
          </div>
        </div>
      ) : (
        <div
          className={checkClassName(
            "row mt-3",
            "PatronLegalJobActivityTypeLangName",
            preData
          )}
        >
          <div className="col-md-5 col-sm-12">
            <JobActivityTypeSelect
              label={_.get(langData, "PatronLegalJobActivityTypeLangName")}
              control={control}
              name="JobActivityType"
              columnName="PatronLegalJobActivityTypeLangName"
              errors={errors}
              rules={preData.rules}
              checkRules={false}
              hint={
                showJobActivityTypeHint
                  ? _.get(langData, "PatronJobActivityId_IsNull")
                  : undefined
              }
              hintColor="error"
              error={showJobActivityTypeHint}
            />
          </div>
          <IconButton
            className="jarvisIconHover"
            size="small"
            icon={<PostAdd />}
            color="primary"
            tooltip={_.get(langData, "AddToList")}
            onClick={handleAddPatronJob}
          />
        </div>
      )}
      <DynamicColumn
        columns={
          patronData.PatronType === "R"
            ? _.get(preData, "GTCD.PmsPatronRealJob")
            : _.get(preData, "GTCD.PmsPatronLegalJob")
        }
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
      <div className="row mt-2">
        {PatronJobs.map((patronJob) => (
          <Chip
            className="mx-1"
            key={_.get(patronJob, "JobActivityTypeId")}
            value={patronJob}
            color="default"
            label={_.get(
              patronJob,
              patronData.PatronType === "R"
                ? "PatronRealJobActivityTypeLangName"
                : "PatronLegalJobActivityTypeLangName"
            )}
            leftIcon={getPatronJobIsActiveIcon(patronJob)}
            onClick={handlePatronJobClick}
            onRightIconClick={handleDeletePatronJob}
            clickable
          />
        ))}
      </div>
      <hr />
      <div
        className={checkClassName(
          "row mt-2 d-flex align-items-center",
          "PatronLangFullNameRelated",
          preData
        )}
      >
        <div className="col-md-5 col-sm-12">
          <PatronSelect
            label={_.get(langData, "PatronLangFullNameRelated")}
            control={control}
            name="Patron"
            columnName="PatronLangFullNameRelated"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            hint={
              showPatronHint ? _.get(langData, "PatronId_IsNULL") : undefined
            }
            hintColor="error"
            error={showPatronHint}
          />
        </div>
        <div className="col-md-5 col-sm-12">
          <PublicCodeSelect
            label={_.get(langData, "PatronRelationTypeLangName")}
            control={control}
            name="PatronRelationType"
            columnName="PatronRelationTypeLangName"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            code="PmsPatronRelationType"
            hint={
              showPatronRelationTypeHint
                ? _.get(langData, "PatronRelationTypeId_IsNULL")
                : undefined
            }
            hintColor="error"
            error={showPatronRelationTypeHint}
          />
        </div>
        <IconButton
          className="jarvisIconHover"
          size="small"
          icon={<PostAdd />}
          color="primary"
          tooltip={_.get(langData, "AddToList")}
          onClick={handleAddPatronRelation}
        />
      </div>

      <DynamicColumn
        columns={_.get(preData, "GTCD.PmsPatronRelation")}
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
      <div className="row mt-2">
        {PatronRelations.map((patronRelation) => (
          <Chip
            className="mx-1"
            key={_.get(patronRelation, "PatronId")}
            value={patronRelation}
            color="default"
            label={_.get(patronRelation, "PatronLangFullNameTo")}
            leftIcon={getPatronRelationIsActiveIcon(patronRelation)}
            onClick={handlePatronRelationClick}
            onRightIconClick={handleDeletePatronRelation}
            clickable
          />
        ))}
      </div>
    </div>
  );
}
