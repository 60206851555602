/*------------
Version: 3.1
------------*/
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Flags from "country-flag-icons/react/3x2";
import RefreshIcon from "@material-ui/icons/Refresh";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import {
  isNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import Typography from "Jarvis/JarvisComponents/Typography";
import { StateDivisionRetrieve } from "Jarvis/JarvisSystems/Pub/Services/StateDivisionService";
import "./index.scss";

const displayFieldName = "StateDivisionLangDescription";
const valueFieldName = "StateDivisionId";
const defaultStateObject = {
  active: false,
  options: [],
  optionsTemp: [],
  pageIndex: 1,
  stateDivisions: [],
  totalRecords: 0,
  totalFilteredRecords: 0,
  serviceParams: null,
};

export default function StateDivisionSelect({
  checkRules,
  className,
  columnName,
  control,
  data,
  dependencies,
  errors,
  fontSize,
  hint,
  label,
  langData,
  latestNodeLevel,
  limitTags,
  margin,
  multiSelect,
  name,
  onInputChange,
  onChange,
  pageSize,
  placeholder,
  required,
  rules,
  service,
  serviceParams,
  showCallingCode,
  showFlag,
  showFullPath,
  size,
  totalRecordsInput,
  typeOnly,
  value,
  variant,
  width,
  ...rest
}) {
  const [countryFlags, setCountryFlags] = useState(null);
  const { isDirectionRTL, language } =
    useSelector((state) => state.settings) || {};
  let PE = _.get(useContext(GlobalContext), "PE.PubPublicElems");
  if (langData) PE = langData;
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [scrollTop, setScrollTop] = useState(0);
  const [showRequired, setShowRequired] = useState(
    (required && isNullOrWhiteSpace(value)) || _.isArray(rules)
  );
  const [stateObject, setStateObject] = useState(defaultStateObject);
  const [where, setWhere] = useState([]);

  let ruleIndex = -1;
  if (_.isArray(rules)) {
    ruleIndex = rules.findIndex(
      (rule) => _.get(rule, "ColumnName") === columnName ?? name
    );
  }

  const isLoading =
    open &&
    (!stateObject.active ||
      !_.isEqual(serviceParams, stateObject.serviceParams));

  let selectStyle = rest.style ?? {};
  if (!isNullOrWhiteSpace(width)) selectStyle.width = width;

  let customRequired =
    ruleIndex !== -1
      ? rules[ruleIndex].IsRequired &&
        isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
      : false;

  useEffect(() => {
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    setId(`stateDivisionSelect-${uuid()}`);
    if (
      value &&
      !isNullOrWhiteSpace(
        multiSelect ? _.get(value[0], valueFieldName) : value[valueFieldName]
      )
    ) {
      if (multiSelect)
        setStateObject((stateObject) => ({
          ...stateObject,
          options: [...value],
        }));
      else
        setStateObject((stateObject) => ({
          ...stateObject,
          options: [value],
        }));
    }
    window.addEventListener("scroll", handleScroll);

    if (showFlag)
      setCountryFlags({
        Flags: Flags,
      });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    if (!_.isEqual(serviceParams, stateObject.serviceParams)) {
      setStateObject({
        ...defaultStateObject,
      });
    }
  }, [serviceParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    if (
      !isNullOrWhiteSpace(data) &&
      where.length === 0 &&
      (data.length === 0 || !isEqual(data, stateObject.options))
    ) {
      setStateObject({
        active: true,
        options: data,
        totalRecords: totalRecordsInput ?? data.length,
        serviceParams: serviceParams,
      });
      return undefined;
    }

    if (!isLoading) {
      return undefined;
    }

    if (dependencies) {
      for (let i = 0; i < dependencies.length; i++) {
        if (isNullOrWhiteSpace(dependencies[i])) {
          setStateObject({
            ...defaultStateObject,
            active: true,
            serviceParams: serviceParams,
          });
          return undefined;
        }
      }
    }

    if (typeOnly && where.length === 0) {
      setStateObject((stateObject) => ({
        ...stateObject,
        active: true,
        serviceParams: serviceParams,
      }));
      return undefined;
    }

    (async () => {
      let params = { pageIndex: stateObject.pageIndex, pageSize };
      if (where.length > 0) params.where = where;
      params = {
        hierarchyShowMode: "Table",
        retrieveAsList: true,
        latestNodeLevel,
        ...params,
        ...serviceParams,
      };

      const response = service
        ? await service(params)
        : await StateDivisionRetrieve(params);

      if (response) {
        const { ErrorHandling, Data, TotalRecords } = response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          let options = [...stateObject.options];
          let newItems = Data ?? [];
          // update value
          if (value && !multiSelect && newItems.length > 0) {
            const index = newItems.findIndex(
              (item) => item[valueFieldName] === value[valueFieldName]
            );
            if (index !== -1) {
              options[0] = newItems[index];
              newItems.splice(index, 1);
            }
          }
          if (_.isNull(stateObject.serviceParams) || stateObject.pageIndex > 1)
            options.push(...newItems);
          else options = [...newItems];

          if (latestNodeLevel === 1 && showCallingCode)
            options.forEach(
              (option) =>
                (option.PhoneCode = _.get(
                  JSON.parse(option.StateDivisionJsonModel),
                  "PhoneCode"
                ))
            );

          setStateObject((stateObject) => ({
            ...stateObject,
            active: true,
            pageIndex: stateObject.pageIndex ?? 1,
            options: options,
            totalRecords:
              where.length === 0 ? TotalRecords : stateObject.totalRecords,
            totalFilteredRecords: where.length !== 0 ? TotalRecords : 0,
            serviceParams: serviceParams,
          }));

          if (id && document.getElementById(id))
            document.getElementById(id).scrollTop = scrollTop;
        }
      } else {
        if (
          stateObject.options.length > 0 &&
          !_.isEqual(serviceParams, stateObject.serviceParams)
        )
          setStateObject({
            ...defaultStateObject,
            active: true,
            serviceParams: serviceParams,
          });
      }
    })();
  }, [isLoading, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (ruleIndex !== -1 && rules[ruleIndex].Display === false) return null;

  const isEqual = (data1, data2) => {
    if (data1.length !== data2?.length) return false;
    if (!_.isEqual(data1[0], data2[0])) return false;
    if (_.differenceWith(data1, data2, _.isEqual).length !== 0) return false;

    return true;
  };

  const getFlag = (isoCode) => {
    if (!isNullOrWhiteSpace(isoCode)) {
      const CountryFlag = countryFlags["Flags"][isoCode];
      return <CountryFlag style={{ width: "21px", height: "14px" }} />;
    }
    return "";
  };

  const handleOptionLabel = (option) => {
    let optionLabel = _.get(option, displayFieldName) || "";
    let phoneCode = _.get(option, "PhoneCode");
    if (stateObject.active) {
      const index = stateObject.options.findIndex(
        (stateDivision) =>
          stateDivision[valueFieldName] === option[valueFieldName]
      );
      if (index !== -1) {
        option = stateObject.options[index];
      }
    }
    if (isDirectionRTL) {
      if (showCallingCode && !isNullOrWhiteSpace(phoneCode)) {
        optionLabel += ` ${phoneCode}`;
        if (latestNodeLevel === 1) optionLabel += "+";
      }
    } else {
      if (showCallingCode) optionLabel += latestNodeLevel === 1 ? " +" : " ";
      if (showCallingCode && !isNullOrWhiteSpace(phoneCode))
        optionLabel += phoneCode;
    }

    return optionLabel.trim();
  };

  const getStateDivisionPhoneCode = (index) => {
    let result = " ";
    const phoneCode = _.get(stateObject.options[index], "PhoneCode");

    if (isDirectionRTL) {
      if (showCallingCode && !isNullOrWhiteSpace(phoneCode))
        result += phoneCode;
      if (showCallingCode && latestNodeLevel === 1) result += "+";
    } else {
      if (showCallingCode && latestNodeLevel === 1) result += "+";
      if (showCallingCode && !isNullOrWhiteSpace(phoneCode))
        result += phoneCode;
    }
    return result;
  };

  const handleRenderOption = (option) => {
    if (open) {
      const index = stateObject.options.findIndex(
        (stateDivision) =>
          stateDivision[valueFieldName] === option[valueFieldName]
      );
      if (index !== -1) {
        return (
          <React.Fragment>
            {showFlag && (
              <span className="mr-3">
                {getFlag(
                  _.get(stateObject.options[index], "StateDivisionCode") || ""
                )}
              </span>
            )}
            {showFullPath &&
              `${_.get(stateObject.options[index], "FullPath") || ""} - `}
            {_.get(option, displayFieldName) || ""}
            {getStateDivisionPhoneCode(index)}
          </React.Fragment>
        );
      }
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => {
      if (!isNaN(parseInt(inputValue))) return _.get(option, "PhoneCode") || "";
      return _.get(option, displayFieldName) || "";
    },
  });

  const filterOptionsInSearch = (option) => option;

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return <Typography fontSize={7} component="span" label={helperText} />;
  };

  const getDefaultValue = () => {
    if (value && !isNullOrWhiteSpace(value[valueFieldName])) return value;
    return multiSelect ? [] : null;
  };

  const getPopupIcon = () => {
    if (typeOnly) return null;
  };

  const getRules = () => {
    if (checkRules) {
      let customRules = {};
      if (ruleIndex !== -1) {
        if (
          rules[ruleIndex].IsRequired &&
          isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
        )
          customRules.required = _.get(PE, "Required") ?? "required";
      } else customRules = rules;

      if (_.isEmpty(customRules)) return undefined;

      return customRules;
    }
  };

  const handleInputChange = (event, value, reason) => {
    const bound =
      stateObject.totalFilteredRecords > 0
        ? stateObject.totalFilteredRecords
        : stateObject.totalRecords;
    if (reason === "input") {
      if (value === "") {
        setWhere([]);
        setStateObject((stateObject) => ({
          ...stateObject,
          options:
            !typeOnly && stateObject.optionsTemp.length === 0
              ? stateObject.options
              : stateObject.optionsTemp,
          optionsTemp: [],
          totalFilteredRecords: 0,
        }));
      } else {
        if (
          (typeOnly || _.isUndefined(bound) || bound > 0) &&
          value.length >= 3 &&
          !inputValue.startsWith(value) &&
          !/[ .,/#!$%^&*;:{}=\-_`~()|]/.test(_.last(value))
        ) {
          if (stateObject.options.length < bound || typeOnly) {
            let FieldName = displayFieldName;
            let Value = value;
            if (showCallingCode && !isNaN(parseInt(Value))) {
              FieldName = "StateDivisionJsonModel";
              Value = parseInt(Value).toString();
            }
            const DataType = "STRING";
            const Operator = "LIKE";
            setWhere([{ FieldName, DataType, Operator, Value }]);
            setStateObject((stateObject) => ({
              ...stateObject,
              optionsTemp:
                !typeOnly && stateObject.optionsTemp.length === 0
                  ? stateObject.options
                  : stateObject.optionsTemp,
              pageIndex: 1,
              active: false,
            }));
          }
        }
      }
    } else if (reason === "reset") {
      if (where.length > 0) {
        setWhere([]);
        setInputValue("");
        setStateObject((stateObject) => ({
          ...stateObject,
          options:
            stateObject.optionsTemp.length === 0
              ? stateObject.options
              : stateObject.optionsTemp,
          totalFilteredRecords: 0,
          active: true,
        }));
      }
    }
    if (required || customRequired)
      if (value !== "") {
        if (showRequired) setShowRequired(false);
      } else if (!showRequired) setShowRequired(true);
    if (onInputChange) onInputChange(value);
    setInputValue(value);
  };

  const handleScroll = (e) => {
    if (stateObject.active) {
      const bound =
        stateObject.totalFilteredRecords > 0
          ? stateObject.totalFilteredRecords
          : stateObject.totalRecords;
      if (stateObject.options.length !== bound) {
        const bottom =
          e.target.scrollHeight -
            Math.ceil(e.target.scrollTop) -
            e.target.clientHeight <=
          1;
        if (bottom) {
          if (
            stateObject.pageIndex <
            Math.ceil(
              stateObject.totalFilteredRecords > 0
                ? stateObject.totalFilteredRecords
                : stateObject.totalRecords / pageSize
            )
          ) {
            setStateObject((stateObject) => ({
              ...stateObject,
              active: false,
              pageIndex: stateObject.pageIndex + 1,
            }));
            setScrollTop(e.target.scrollTop);
          }
        }
      }
    }
  };

  const handleRefresh = () => {
    setStateObject((stateObject) => ({
      ...stateObject,
      active: false,
      pageIndex: 1,
      options: [],
      totalFilteredRecords: 0,
      totalRecords: 0,
    }));
    setWhere([]);
    setInputValue("");
  };

  const autocompleteProps = {
    autoHighlight: true,
    autoSelect: typeOnly,
    className: clsx(className, "jarvisSelect"),
    clearOnBlur: true,
    getOptionLabel: (option) => handleOptionLabel(option),
    getOptionSelected: (option, value) =>
      _.isEqual(option[valueFieldName], value[valueFieldName]),
    id: id,
    inputValue: inputValue,
    loadingText: (
      <span
        className={clsx(
          language.JarvisFontClass,
          `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
        )}
      >
        {_.get(PE, "Loading") || "Loading..."}
      </span>
    ),
    loading: isLoading,
    ListboxProps: {
      onScroll: handleScroll,
      className: clsx(
        "MuiAutocomplete-listbox",
        language.JarvisFontClass,
        `Jarvis-Font-Size-${fontSize}rem`
      ),
    },
    noOptionsText:
      inputValue.length > 2 ? (
        <span
          className={clsx(
            language.JarvisFontClass,
            `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
          )}
        >
          {_.get(PE, "NoItems") || "No items found"}
        </span>
      ) : (
        ""
      ),
    onInputChange: (event, Value, reason) =>
      handleInputChange(event, Value, reason),
    open: open,
    openText: "",
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    options: stateObject.options,
    popupIcon: getPopupIcon(),
    renderOption: (option) => handleRenderOption(option),
    selectOnFocus: true,
    size: size,
    style: selectStyle,
    ...rest,
    renderInput: (params) => (
      <TextField
        {...params}
        className="jarvisTextField"
        error={errors && errors[name] ? true : false}
        fullWidth
        helperText={renderHelperText()}
        label={
          (required || customRequired) && showRequired ? `${label} *` : label
        }
        margin={margin}
        placeholder={
          placeholder ??
          (typeOnly
            ? _.get(PE, "TypeOnlyPlaceholder") ?? "Type to search"
            : undefined)
        }
        variant={variant}
        InputLabelProps={{
          className: clsx(
            language.JarvisFontClass,
            `Jarvis-Font-Size-${fontSize}rem`
          ),
        }}
        InputProps={{
          ...params.InputProps,
          className: clsx(
            params.InputProps.className,
            language.JarvisFontClass,
            `Jarvis-Font-Size-${fontSize}rem`
          ),
          endAdornment: (
            <React.Fragment>
              {!isLoading && stateObject.active && (
                <RefreshIcon
                  className="refresh"
                  color="primary"
                  onClick={handleRefresh}
                />
              )}
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    ),
  };

  if (stateObject.totalFilteredRecords > 0)
    autocompleteProps.filterOptions = filterOptionsInSearch;
  else autocompleteProps.filterOptions = filterOptions;

  if (multiSelect) {
    autocompleteProps.multiple = true;
    autocompleteProps.limitTags = limitTags;
    autocompleteProps.disableCloseOnSelect = true;
    autocompleteProps.renderOption = (option, { selected }) => (
      <React.Fragment>
        {!isLoading && (
          <Checkbox
            checked={selected}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            size="small"
          />
        )}
        {option[displayFieldName]}
      </React.Fragment>
    );
  }

  if (control)
    return (
      <Controller
        control={control}
        render={(props) => (
          <Autocomplete
            {...props}
            {...autocompleteProps}
            onChange={(_, _data) => {
              if (onChange) onChange(_data);
              if (
                !data &&
                !typeOnly &&
                _data &&
                stateObject.optionsTemp.length > 0 &&
                !stateObject.optionsTemp.find(
                  (option) => option[valueFieldName] === _data[valueFieldName]
                )
              )
                stateObject.optionsTemp.splice(0, 0, _data);
              return props.onChange(_data);
            }}
          />
        )}
        name={name}
        rules={getRules()}
        defaultValue={getDefaultValue()}
      />
    );

  return <Autocomplete onChange={onChange} {...autocompleteProps} />;
}

StateDivisionSelect.propTypes = {
  checkRules: PropTypes.bool,
  dependencies: PropTypes.array,
  fontSize: PropTypes.number,
  hint: PropTypes.string,
  label: PropTypes.string,
  latestNodeLevel: PropTypes.number,
  limitTags: PropTypes.number,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  multiSelect: PropTypes.bool,
  pageSize: PropTypes.number,
  showCallingCode: PropTypes.bool,
  showFlag: PropTypes.bool,
  showFullPath: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  totalRecordsInput: PropTypes.number,
  typeOnly: PropTypes.bool,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  width: PropTypes.string,
};

StateDivisionSelect.defaultProps = {
  checkRules: true,
  fontSize: 9,
  limitTags: 2,
  label: "State Division",
  multiSelect: false,
  pageSize: 25,
  showCallingCode: false,
  showFlag: false,
  showFullPath: false,
  size: "small",
  typeOnly: true,
};
