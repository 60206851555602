import _ from "lodash";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import { getGraphQLUrl } from "Jarvis/JarvisServices/JarvisGraphQLService";

export const DetailRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  node,
  retrieveLastChild,
  retrieveAsList,
  retrieveAsTree,
  hierarchyShowMode,
  latestNodeLevel,
  isDeepSearchNeeded,
  isForIssuanceOfDoc,
  isLatestNode,
  codeId,
  VT,
  detailKeyName,
}) => {
  let retrieveService = "Acc/Detail/Retrieve";
  if (retrieveLastChild) retrieveService = "Acc/Detail/RetrieveLastChild";
  else if (retrieveAsList) retrieveService = "Acc/Detail/RetrieveAsList";
  else if (retrieveAsTree) retrieveService = "Acc/Detail/RetrieveAsTree";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    NodeId: node?.["DetailId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      hierarchy,
      orderBy,
      orderBys,
      orderMethod,
      where,
      isForIssuanceOfDoc,
      isLatestNode,
      codeId,
      VT,
      detailKeyName,
    },
  });

  if (
    !isNullOrWhiteSpace(retrieveLastChild) &&
    retrieveLastChild === true &&
    !isNullOrWhiteSpace(_.get(data, "Data"))
  ) {
    _.get(data, "Data").forEach(
      (detail) =>
        (detail.DetailKey = isNullOrWhiteSpace(detail.DetailIdLinked)
          ? detail.DetailId
          : `${detail.DetailId}_${detail.DetailIdLinked}}`)
    );
  }

  return data;
};

export const RetrieveNewCode = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Acc/Detail/RetrieveNewCode",
    data: _data,
  });
  return data;
};

export const DetailCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Acc/Detail/Create",
    data: _data,
  });
  return data;
};

export const DetailUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Acc/Detail/Update",
    data: _data,
  });
  return data;
};

export const DetailUpdateAndArchive = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Acc/Detail/UpdateAndArchive",
    data: _data,
  });
  return data;
};

export const DetailDelete = async ({
  DetailId,
  VT,
  DetailLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Acc/Detail/Delete",
    data: { DetailId, VT, DetailLastUpdateDateTime },
  });
  return data;
};

export const DetailPrerequisitesGraphQL = async () => {
  const { data } = await JarvisHttpService.post({
    url: getGraphQLUrl(),
    data: {
      query: `
        {
          langData:pageElementRetrievePublic(iModel:{moduleCodeStrings:"AccDetail",config:{configs:"AccDetailCodeDecoration,AccDetailFirstLevelCodeLength"}}){data}
        }`,
    },
  });
  return {
    errorHandling: _.get(data, "ErrorHandling"),
    financialTerms: _.first(_.get(data, "Data.data.financialTerms.data"))
      ?.financialTerms,
    langData: JSON.parse(
      ifNullOrWhiteSpace(_.get(data, "Data.data.langData.data"), null)
    ),
  };
};

export const DetailRetrieveFinancialTerm = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Acc/Detail/RetrieveFinancialTerm",
    data: _data,
  });
  return data;
};
