import React from "react";
import LanguageItem from "./LanguageItem";
import CustomScrollbars from "Jarvis/JarvisComponents/Scrollbars/CustomScrollbars";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export default function LanguageSelector({
  switchLanguage,
  handleRequestClose,
  languageData,
  onChangeLanguage,
}) {
  const { LangKey: langKey } = getLanguage();

  return (
    <CustomScrollbars
      className="messages-list language-list scrollbar"
      style={{ height: 150 }}
    >
      <ul className="list-unstyled">
        {languageData.map((language, index) => (
          <LanguageItem
            onChangeLanguage={onChangeLanguage}
            key={index}
            language={language}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
            mustBeBold={langKey === language.LangKey}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
}
