import React, { useState } from "react";
import _ from "lodash";
import Select from "Jarvis/JarvisComponents/Select";
import { PublicCodeRetrieve } from "Jarvis/JarvisSystems/Pub/Services/PublicCodeService";
import InputForm from "./InputForm";

export default function PublicCodeSelect({
  hasAddItem,
  code,
  control,
  label,
  langData,
  name,
  serviceParams,
  ...rest
}) {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {showForm ? (
        <InputForm
          langData={langData}
          showForm={showForm}
          onShowForm={setShowForm}
        />
      ) : (
        <Select
          label={label}
          control={control}
          name={name}
          service={PublicCodeRetrieve}
          serviceParams={{
            ...serviceParams,
            code,
            hierarchyShowMode: _.isUndefined(serviceParams?.hierarchyShowMode)
              ? "Table"
              : serviceParams.hierarchyShowMode,
          }}
          displayFieldName="PublicCodeLangDescription"
          valueFieldName="PublicCodeId"
          onAddClick={hasAddItem ? () => setShowForm(true) : undefined}
          {...rest}
        />
      )}
    </>
  );
}
