import React, { useState } from "react";
import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Typography from "Jarvis/JarvisComponents/Typography";
import TextField from "Jarvis/JarvisComponents/TextField";
import DatePicker from "Jarvis/JarvisComponents/DatePicker";
import {
  checkClassName,
  convertGregorianTo,
} from "Jarvis/JarvisServices/JarvisCommonService";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import StateDivisionSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/StateDivisionSelect";

export default function LegalMainInfo({
  control,
  errors,
  isEditMode,
  langData,
  patronData,
  preData,
  setValue,
}) {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const handleChangeExtraInfo = () => setShowExtraInfo(!showExtraInfo);

  return (
    <div>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronLegalLangDescription")}
            control={control}
            name="PatronLegalLangDescription"
            errors={errors}
            required
            rules={{
              required: _.get(langData, "Required") || "required",
            }}
            value={patronData.PatronLegalLangDescription}
            autoFocus
            fullWidth
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronLegalIDNo")}
            control={control}
            name={"PatronLegalIDNo"}
            errors={errors}
            rules={preData.rules}
            value={patronData.PatronLegalIDNo}
            type="number"
            textAlign="left"
            showIcon={false}
            fullWidth
          />
        </div>
      </div>
      <Accordion onChange={handleChangeExtraInfo}>
        <AccordionSummary>
          <Typography
            label={
              showExtraInfo ? _.get(langData, "Less") : _.get(langData, "More")
            }
            color="primary"
          />
          {showExtraInfo ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="primary" />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} className="row">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronLegalRegistrationNo",
                  preData
                )}
              >
                <TextField
                  label={_.get(langData, "PatronLegalRegistrationNo")}
                  control={control}
                  name={"PatronLegalRegistrationNo"}
                  errors={errors}
                  rules={preData.rules}
                  value={patronData.PatronLegalRegistrationNo}
                  type="number"
                  textAlign="left"
                  fullWidth
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <TextField
                  label={_.get(langData, "PatronLegalMinistryOfFinanceNo")}
                  control={control}
                  name="PatronLegalMinistryOfFinanceNo"
                  errors={errors}
                  rules={preData.rules}
                  value={patronData.PatronLegalMinistryOfFinanceNo}
                  type="number"
                  textAlign="left"
                  fullWidth
                />
              </div>
            </div>
            <div style={{ width: "100%" }} className="row">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronLegalRegisterDate",
                  preData
                )}
              >
                <DatePicker
                  label={_.get(langData, "PatronLegalRegisterDate")}
                  control={control}
                  setValue={setValue}
                  name="PatronLegalRegisterDate"
                  errors={errors}
                  rules={preData.rules}
                  value={convertGregorianTo(patronData.PatronLegalRegisterDate)}
                  fullWidth
                  inputVariant="standard"
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <StateDivisionSelect
                  label={_.get(
                    langData,
                    "PatronLegalRegisterPlaceStateDivision"
                  )}
                  control={control}
                  name="PatronLegalRegisterPlace"
                  columnName="PatronLegalRegisterPlaceStateDivision"
                  errors={errors}
                  rules={preData.rules}
                  value={
                    isEditMode
                      ? {
                          StateDivisionId:
                            patronData.PatronLegalRegisterPlaceStateDivisionId,
                          StateDivisionLangDescription:
                            patronData.PatronLegalRegisterPlace,
                        }
                      : patronData.PatronLegalRegisterPlaceStateDivision
                  }
                  latestNodeLevel={3}
                  showFullPath
                />
              </div>
            </div>

            <DynamicColumn
              columns={_.get(preData, "GTCD.PmsPatronLegal")}
              control={control}
              errors={errors}
              setValue={setValue}
              rules={preData.rules}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
