/*------------
Version: 2.1
------------*/
import React, { useState } from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "../Typography";

export default function Checkbox({
  className,
  control,
  disabled,
  errors,
  fontSize,
  hint,
  hintColor,
  isTriple,
  label,
  name,
  onChange,
  rules,
  size,
  textAlign,
  value,
  ...rest
}) {
  const [checkboxValue, setCheckboxValue] = useState(
    isTriple
      ? value !== undefined
        ? value
        : ""
      : value !== undefined
      ? value
      : false
  );
  const [counter, setCounter] = useState(1);

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return (
      <Typography
        component={"span"}
        fontSize={7}
        label={helperText}
        color={hintColor}
      />
    );
  };

  const getCheckboxProps = () => {
    let result = {};
    if (!isTriple) return result;

    if (checkboxValue === true) {
      if (counter > 3) {
        result.icon = <CheckBoxIcon color="secondary" />;
      }
      result.indeterminate = false;
    } else if (checkboxValue === false) {
      result.indeterminate = true;
      result.color = "default";
    } else {
      if (counter > 3) {
        result.color = "default";
        result.checkedIcon = <CheckBoxOutlineBlankIcon />;
      }
      result.indeterminate = false;
    }
    return result;
  };

  const getChecked = (value) => {
    if (!isTriple || value === true) return value;
    return false;
  };

  const handleChangeUncontrolled = (e) => {
    if (isTriple) {
      if (checkboxValue === true) {
        setCounter(counter + 1);
        setCheckboxValue(false);
        if (onChange) onChange(e, false);
        return false;
      } else if (checkboxValue === false) {
        setCounter(counter + 1);
        setCheckboxValue("");
        if (onChange) onChange(e, "");
        return "";
      } else {
        setCounter(counter + 1);
        setCheckboxValue(true);
        if (onChange) onChange(e, true);
        return true;
      }
    } else {
      setCheckboxValue(e.target.checked);
      if (onChange) onChange(e, e.target.checked);
      return e.target.checked;
    }
  };

  const handleChange = (e, onChangeProp) => {
    if (isTriple) {
      if (checkboxValue === true) {
        setCounter(counter + 1);
        setCheckboxValue(false);
        if (onChange) onChange(false);
        return onChangeProp(false);
      } else if (checkboxValue === false) {
        setCounter(counter + 1);
        setCheckboxValue("");
        if (onChange) onChange("");
        return onChangeProp("");
      } else {
        setCounter(counter + 1);
        setCheckboxValue(true);
        if (onChange) onChange(true);
        return onChangeProp(true);
      }
    } else {
      if (onChange) onChange(e.target.checked);
      return onChangeProp(e.target.checked);
    }
  };

  if (control === undefined) {
    if (label)
      return (
        <FormControlLabel
          className={clsx(className, "jarvisLabel")}
          control={
            <MuiCheckbox
              {...getCheckboxProps()}
              {...rest}
              className="jarvisLabel"
              size={size}
              onChange={(e) => handleChangeUncontrolled(e)}
              value={checkboxValue}
              checked={getChecked(checkboxValue)}
              disabled={disabled}
            />
          }
          label={
            <Typography label={label} fontSize={fontSize} disabled={disabled} />
          }
        />
      );
    else {
      return (
        <MuiCheckbox
          {...getCheckboxProps()}
          {...rest}
          className={className}
          size={size}
          onChange={(e) => handleChangeUncontrolled(e)}
          disabled={disabled}
          value={checkboxValue}
          checked={getChecked(value)}
        />
      );
    }
  }

  if (label)
    return (
      <FormControl size={size} error={errors && errors[name] ? true : false}>
        <Controller
          control={control}
          render={({ onChange, value }) => (
            <FormControlLabel
              className={clsx(className, "jarvisLabel")}
              control={
                <MuiCheckbox
                  {...getCheckboxProps()}
                  {...rest}
                  className="jarvisLabel"
                  size={size}
                  onChange={(e) => handleChange(e, onChange)}
                  value={value}
                  checked={getChecked(value)}
                  disabled={disabled}
                />
              }
              label={
                <Typography
                  label={label}
                  fontSize={fontSize}
                  disabled={disabled}
                />
              }
            />
          )}
          type="checkbox"
          rules={rules}
          name={name}
          defaultValue={checkboxValue}
        />
        <FormHelperText error={errors && errors[name] ? true : false}>
          {renderHelperText()}
        </FormHelperText>
      </FormControl>
    );
  else {
    return (
      <MuiCheckbox
        className={clsx(className, "jarvisLabel")}
        control={control}
        name={name}
        size={size}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    );
  }
}

Checkbox.propTypes = {
  fontSize: PropTypes.number,
  hint: PropTypes.string,
  hintColor: PropTypes.string,
  isTriple: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  textAlign: PropTypes.string,
};

Checkbox.defaultProps = {
  isTriple: false,
  size: "small",
};
