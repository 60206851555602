/*------------
Version: 1.8
------------*/
import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Button as MuiButton, CircularProgress } from "@material-ui/core";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import Tooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Button({
  className,
  disabled,
  fontSize,
  label,
  mode,
  showLoading,
  size,
  tooltip,
  ...rest
}) {
  const classes = useStyles();
  const { language } = useSelector((state) => state.settings) ?? {};
  const { PE } = useContext(GlobalContext) ?? {};
  const buttonClassName = clsx(
    className,
    language?.JarvisFontClass,
    "jarvisButton",
    !isNullOrWhiteSpace(fontSize) && `Jarvis-Font-Size-${fontSize}rem`
  );

  let buttonLabel = label;
  let buttonProps = { size: size };

  if (mode === "save" || mode === "submit") {
    buttonLabel = ifNullOrWhiteSpace(label, _.get(PE, "PubPublicElems.Save"));
    buttonProps = {
      type: "submit",
      variant: "contained",
      color: "primary",
      size: size,
    };
  }
  if (mode === "close") {
    buttonLabel = ifNullOrWhiteSpace(label, _.get(PE, "PubPublicElems.Close"));
    buttonProps = {
      variant: "outlined",
      size: size,
    };
  }

  if (tooltip && !disabled)
    return (
      <Tooltip label={tooltip}>
        <MuiButton className={buttonClassName} {...buttonProps} {...rest}>
          {buttonLabel}
          {showLoading && (
            <CircularProgress className={classes.buttonProgress} size={24} />
          )}
        </MuiButton>
      </Tooltip>
    );

  return (
    <MuiButton
      className={buttonClassName}
      disabled={disabled || showLoading}
      {...buttonProps}
      {...rest}
    >
      {buttonLabel}
      {showLoading && (
        <CircularProgress className={classes.buttonProgress} size={24} />
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  mode: PropTypes.oneOf(["close", "standard", "save"]),
  fontSize: PropTypes.number,
  showLoading: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  tooltip: PropTypes.string,
};

Button.defaultProps = {
  fontSize: 8,
  mode: "standard",
  size: "small",
};
