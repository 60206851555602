import React, { useState } from "react";
import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import TextField from "Jarvis/JarvisComponents/TextField";
import Chip from "Jarvis/JarvisComponents/Chip";
import Tooltip from "Jarvis/JarvisComponents/Tooltip";
import Typography from "Jarvis/JarvisComponents/Typography";
import {
  checkClassName,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import StateDivisionSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/StateDivisionSelect";
import PublicCodeSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/PublicCodeSelect";
import IconButton from "Jarvis/JarvisComponents/IconButton";
import { Done, ExpandLess, ExpandMore, PostAdd } from "@material-ui/icons";

export default function PatronContactInfo({
  control,
  errors,
  getValues,
  isDirectionRTL,
  langData,
  patronData,
  preData,
  setPatronData,
  setValue,
}) {
  const [showEmailHint, setShowEmailHint] = useState(false);
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  let {
    PatronCellphones,
    PatronPhones,
    PatronEmails,
    PatronAddresses,
  } = patronData;
  if (!PatronCellphones) PatronCellphones = [];
  if (!PatronPhones) PatronPhones = [];
  if (!PatronEmails) PatronEmails = [];
  if (!PatronAddresses) PatronAddresses = [];

  //#region  ---------------- Cellhone ----------------
  const handleAddPatronCellphone = () => {
    let cellphone = parseInt(getValues("Cellphone"));
    const index = PatronCellphones.findIndex(
      (patronCellphone) => patronCellphone.Cellphone === cellphone
    );
    if (index === -1) {
      const {
        StateDivisionId: CellphoneCallingCodeStateDivisionId,
        StateDivisionLangDescription: CellphoneCallingCode,
        VT: VTCellphoneCallingCode,
        StateDivisionJsonModel,
      } = getValues("CellphoneCallingCode") | {};
      const patronCellphone = {
        Cellphone: cellphone,
        CellphoneCallingCodeStateDivisionId,
        CellphoneCallingCode,
        VTCellphoneCallingCode,
        StateDivisionJsonModel,
        PatronCellphoneIsMain: PatronCellphones.length === 0,
      };

      setPatronData((patronData) => ({
        ...patronData,
        PatronCellphones: [...PatronCellphones, patronCellphone],
      }));
    }
    setValue("Cellphone", "");
  };

  const handleDeletePatronCellphone = (patronCellphone) => {
    let newPatronCellphones = [...PatronCellphones];
    const index = PatronCellphones.indexOf(patronCellphone);
    newPatronCellphones.splice(index, 1);
    if (newPatronCellphones.length === 1)
      newPatronCellphones[0].PatronCellphoneIsMain = true;
    setPatronData((patronData) => ({
      ...patronData,
      PatronCellphones: newPatronCellphones,
    }));
  };

  const getPatronCellphoneIsMainIcon = (patronCellphone) => (
    <Tooltip label={_.get(langData, "IsMain")}>
      <Done
        style={{
          color: patronCellphone.PatronCellphoneIsMain ? green[500] : grey[400],
          cursor: "pointer",
        }}
        onClick={(e) => handleActivePatronCellphone(e, patronCellphone)}
      />
    </Tooltip>
  );

  const handleActivePatronCellphone = (e, patronCellphone) => {
    e.stopPropagation();
    let newPatronCellphones = [...PatronCellphones];
    const index = PatronCellphones.indexOf(patronCellphone);

    if (!patronCellphone.PatronCellphoneIsMain) {
      for (let i = 0; i < newPatronCellphones.length; i++) {
        if (i === index) newPatronCellphones[i].PatronCellphoneIsMain = true;
        else newPatronCellphones[i].PatronCellphoneIsMain = false;
      }
    } else newPatronCellphones[index].PatronCellphoneIsMain = false;

    setPatronData((patronData) => ({
      ...patronData,
      PatronCellphones: newPatronCellphones,
    }));
  };

  const handleCellphoneClick = (patronCellphone) => {
    const {
      CellphoneCallingCodeStateDivisionId,
      CellphoneCallingCode,
      VTCellphoneCallingCode,
      StateDivisionJsonModel,
    } = patronCellphone;
    if (CellphoneCallingCodeStateDivisionId) {
      const cellphoneCallingCode = {
        StateDivisionId: CellphoneCallingCodeStateDivisionId,
        StateDivisionLangDescription: CellphoneCallingCode,
        VT: VTCellphoneCallingCode,
        StateDivisionJsonModel,
      };
      setValue("CellphoneCallingCode", cellphoneCallingCode);
    }
    setValue("Cellphone", patronCellphone.Cellphone.toString());
  };

  const getPatronCellphoneLabel = (patronCellphone) => {
    let cellphone = patronCellphone.Cellphone.toString();
    let stateDivisionCode = patronCellphone.StateDivisionJsonModel;
    if (stateDivisionCode) {
      stateDivisionCode = _.get(
        JSON.parse(patronCellphone.StateDivisionJsonModel),
        "PhoneCode"
      );
    }
    if (stateDivisionCode) {
      if (isDirectionRTL) return `${cellphone} (${stateDivisionCode})`;
      else return `(${stateDivisionCode}) ${cellphone}`;
    }

    return cellphone;
  };
  //#endregion

  //#region  ---------------- Phone ----------------
  const handleAddPatronPhone = () => {
    let phone = parseInt(getValues("Phone"));
    const index = PatronPhones.findIndex(
      (patronPhone) => patronPhone.Phone === phone
    );
    if (index === -1) {
      const {
        PublicCodeId: LocationTypeId,
        PublicCodeLangDescription: PhoneLocationType,
        VT: VTLocationTypeId,
      } = getValues("PhoneLocationType") || {};
      const patronPhone = {
        Phone: phone,
        PhoneCallingCode: getValues("PhoneCallingCode"),
        LocationTypeId,
        PhoneLocationType,
        VTLocationTypeId,
        PatronPhoneIsMain: PatronPhones.length === 0,
      };

      setPatronData((patronData) => ({
        ...patronData,
        PatronPhones: [...PatronPhones, patronPhone],
      }));
    }
    setValue("Phone", "");
    setValue("PhoneLocationType", null);
    setValue("PhoneCallingCode", "");
  };

  const handleDeletePatronPhone = (patronPhone) => {
    let newPatronPhones = [...PatronPhones];
    const index = PatronPhones.indexOf(patronPhone);
    newPatronPhones.splice(index, 1);
    if (newPatronPhones.length === 1)
      newPatronPhones[0].PatronPhoneIsMain = true;
    setPatronData((patronData) => ({
      ...patronData,
      PatronPhones: newPatronPhones,
    }));
  };

  const getPatronPhoneIsMainIcon = (patronPhone) => (
    <Tooltip label={_.get(langData, "IsMain")}>
      <Done
        style={{
          color: patronPhone.PatronPhoneIsMain ? green[500] : grey[400],
          cursor: "pointer",
        }}
        onClick={(e) => handleActivePatronPhone(e, patronPhone)}
      />
    </Tooltip>
  );

  const handleActivePatronPhone = (e, patronPhone) => {
    e.stopPropagation();
    if (!patronPhone.PatronPhoneIsMain) {
      let newPatronPhones = [...PatronPhones];
      const index = PatronPhones.indexOf(patronPhone);
      for (let i = 0; i < newPatronPhones.length; i++) {
        if (i === index) newPatronPhones[i].PatronPhoneIsMain = true;
        else newPatronPhones[i].PatronPhoneIsMain = false;
      }
      setPatronData((patronData) => ({
        ...patronData,
        PatronPhones: newPatronPhones,
      }));
    }
  };

  const handlePhoneClick = (patronPhone) => {
    const { LocationTypeId, PhoneLocationType, VTLocationTypeId } = patronPhone;
    if (LocationTypeId) {
      const phoneLocationType = {
        PublicCodeId: LocationTypeId,
        PublicCodeLangDescription: PhoneLocationType,
        VT: VTLocationTypeId,
      };
      setValue("PhoneLocationType", phoneLocationType);
    }
    setValue("Phone", patronPhone.Phone.toString());
    setValue("PhoneCallingCode", _.get(patronPhone, "PhoneCallingCode"));
  };

  const getPatronPhoneLabel = (patronPhone) => {
    let phone = patronPhone.Phone.toString();
    const phoneCallingCode = patronPhone.PhoneCallingCode;
    if (phoneCallingCode) {
      if (isDirectionRTL) return `${phone} (${phoneCallingCode})`;
      else return `(${phoneCallingCode}) ${phone}`;
    }

    return phone;
  };
  //#endregion

  //#region  ---------------- Email ----------------
  const handleAddPatronEmail = () => {
    const email = getValues("Email");
    if (!isNullOrWhiteSpace(email)) {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (emailPattern.test(email)) {
        const index = PatronEmails.findIndex(
          (patronEmail) => patronEmail.Email === email
        );
        if (index === -1) {
          const patronEmail = {
            Email: email,
            PatronEmailIsMain: PatronEmails.length === 0,
          };

          setPatronData((patronData) => ({
            ...patronData,
            PatronEmails: [...PatronEmails, patronEmail],
          }));
        }
        setValue("Email", "");
      } else setShowEmailHint(true);
    }
  };

  const handleDeletePatronEmail = (patronEmail) => {
    let newPatronEmails = [...PatronEmails];
    const index = PatronEmails.indexOf(patronEmail);
    newPatronEmails.splice(index, 1);
    if (newPatronEmails.length === 1)
      newPatronEmails[0].PatronEmailIsMain = true;
    setPatronData((patronData) => ({
      ...patronData,
      PatronEmails: newPatronEmails,
    }));
  };

  const getPatronEmailIsMainIcon = (patronEmail) => (
    <Tooltip label={_.get(langData, "IsMain")}>
      <Done
        style={{
          color: patronEmail.PatronEmailIsMain ? green[500] : grey[400],
          cursor: "pointer",
        }}
        onClick={(e) => handleActivePatronEmail(e, patronEmail)}
      />
    </Tooltip>
  );

  const handleActivePatronEmail = (e, patronEmail) => {
    e.stopPropagation();
    if (!patronEmail.PatronEmailIsMain) {
      let newPatronEmails = [...PatronEmails];
      const index = PatronEmails.indexOf(patronEmail);
      for (let i = 0; i < newPatronEmails.length; i++) {
        if (i === index) newPatronEmails[i].PatronEmailIsMain = true;
        else newPatronEmails[i].PatronEmailIsMain = false;
      }
      setPatronData((patronData) => ({
        ...patronData,
        PatronEmails: newPatronEmails,
      }));
    }
  };

  const handleEmailClick = (patronEmail) => {
    setValue("Email", patronEmail.Email);
  };
  //#endregion

  //#region  ---------------- Address ----------------
  const handleAddPatronAddress = () => {
    const patronAddressLangDescription = getValues(
      "PatronAddressLangDescription"
    );
    if (!isNullOrWhiteSpace(patronAddressLangDescription)) {
      const index = PatronAddresses.findIndex(
        (patronAddress) =>
          patronAddress.PatronAddressLangDescription ===
          patronAddressLangDescription
      );
      if (index === -1) {
        const {
          StateDivisionId: PatronAddressCityStateDivisionId,
          StateDivisionLangDescription: PatronAddressCity,
          VT: VTPatronAddressCity,
        } = getValues("PatronAddressCity") || {};
        const {
          PublicCodeId: LocationTypeId,
          PublicCodeLangDescription: PatronAddressLocationType,
          VT: VTLocationTypeId,
        } = getValues("PatronAddressLocationType") || {};
        const patronAddress = {
          PatronAddressCityStateDivisionId,
          PatronAddressCity,
          VTPatronAddressCity,
          PatronAddressLangDescription: patronAddressLangDescription,
          PatronAddressPostalCode: parseInt(
            getValues("PatronAddressPostalCode")
          ),
          LocationTypeId,
          PatronAddressLocationType,
          VTLocationTypeId,
          PatronAddressIsMain: PatronAddresses.length === 0,
        };

        setPatronData((patronData) => ({
          ...patronData,
          PatronAddresses: [...PatronAddresses, patronAddress],
        }));
      }
      setValue("PatronAddressCity", null);
      setValue("PatronAddressLangDescription", "");
      setValue("PatronAddressPostalCode", "");
      setValue("PatronAddressLocationType", null);
    }
  };

  const handleDeletePatronAddress = (patronAddress) => {
    let newPatronAddresses = [...PatronAddresses];
    const index = PatronAddresses.indexOf(patronAddress);
    newPatronAddresses.splice(index, 1);
    if (newPatronAddresses.length === 1)
      newPatronAddresses[0].PatronAddressIsMain = true;
    setPatronData((patronData) => ({
      ...patronData,
      PatronAddresses: newPatronAddresses,
    }));
  };

  const getPatronAddressIsMainIcon = (patronAddress) => (
    <Tooltip label={_.get(langData, "IsMain")}>
      <Done
        style={{
          color: patronAddress.PatronAddressIsMain ? green[500] : grey[400],
          cursor: "pointer",
        }}
        onClick={(e) => handleActivePatronAddress(e, patronAddress)}
      />
    </Tooltip>
  );

  const handleActivePatronAddress = (e, patronAddress) => {
    e.stopPropagation();
    if (!patronAddress.PatronAddressIsMain) {
      let newPatronAddresses = [...PatronAddresses];
      const index = PatronAddresses.indexOf(patronAddress);
      for (let i = 0; i < newPatronAddresses.length; i++) {
        if (i === index) newPatronAddresses[i].PatronAddressIsMain = true;
        else newPatronAddresses[i].PatronAddressIsMain = false;
      }
      setPatronData((patronData) => ({
        ...patronData,
        PatronAddresses: newPatronAddresses,
      }));
    }
  };

  const handleAddressClick = (patronAddress) => {
    const {
      PatronAddressCityStateDivisionId,
      PatronAddressCity,
      VTPatronAddressCity,
    } = patronAddress;
    if (PatronAddressCityStateDivisionId) {
      const patronAddressCity = {
        StateDivisionId: PatronAddressCityStateDivisionId,
        StateDivisionLangDescription: PatronAddressCity,
        VT: VTPatronAddressCity,
      };
      setValue("PatronAddressCity", patronAddressCity);
    }

    const {
      LocationTypeId,
      PatronAddressLocation,
      VTLocationTypeId,
    } = patronAddress;
    if (LocationTypeId) {
      const patronAddressLocationType = {
        PublicCodeId: LocationTypeId,
        PublicCodeLangDescription: PatronAddressLocation,
        VT: VTLocationTypeId,
      };
      setValue("PatronAddressLocationType", patronAddressLocationType);
    }

    setValue(
      "PatronAddressLangDescription",
      patronAddress.PatronAddressLangDescription
    );
    setValue(
      "PatronAddressPostalCode",
      patronAddress.PatronAddressPostalCode?.toString()
    );
  };

  const getPatronAddressLabel = (patronAddress) => {
    return `${patronAddress.PatronAddressCity || ""} ${
      patronAddress.PatronAddressLangDescription
    }`.trim();
  };
  //#endregion

  const handleChangeExtraInfo = () => setShowExtraInfo(!showExtraInfo);

  const handleCellphoneKeyDown = (e) => {
    if (e.key === "Enter" && e.ctrlKey) return;
    if (e.key === "Enter") handleAddPatronCellphone();
  };

  const handlePhoneKeyDown = (e) => {
    if (e.key === "Enter" && e.ctrlKey) return;
    if (e.key === "Enter") handleAddPatronPhone();
  };

  const handleEmailKeyDown = (e) => {
    if (e.key === "Enter" && e.ctrlKey) return;
    if (e.key === "Enter") handleAddPatronEmail();
  };

  return (
    <div>
      <div
        className={checkClassName(
          "row d-flex align-items-center",
          "Cellphone",
          preData
        )}
      >
        <div className="col-md-5 col-sm-12">
          <StateDivisionSelect
            label={_.get(langData, "CellphoneCallingCode")}
            control={control}
            name="CellphoneCallingCode"
            columnName="CellphoneCallingCode"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            showCallingCode
            showFlag
          />
        </div>
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "Cellphone")}
            control={control}
            name="Cellphone"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            type="mobile"
            textAlign="left"
            showIcon={!isDirectionRTL}
            fullWidth
            autoFocus
            onKeyDown={(e) => handleCellphoneKeyDown(e)}
          />
        </div>
        <IconButton
          className="jarvisIconHover mt-2"
          size="small"
          icon={<PostAdd />}
          color="primary"
          tooltip={_.get(langData, "AddToList")}
          onClick={handleAddPatronCellphone}
        />
      </div>
      <DynamicColumn
        columns={_.get(preData, "GTCD.PmsPatronCellphone")}
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
      <div className="row mt-2">
        {PatronCellphones?.map((patronCellphone) => (
          <Chip
            className="mx-1"
            key={_.get(patronCellphone, "Cellphone")}
            value={patronCellphone}
            color="default"
            label={getPatronCellphoneLabel(patronCellphone)}
            leftIcon={getPatronCellphoneIsMainIcon(patronCellphone)}
            onClick={handleCellphoneClick}
            onRightIconClick={handleDeletePatronCellphone}
            clickable
          />
        ))}
      </div>

      <div
        className={checkClassName(
          "row d-flex align-items-center mt-2",
          "Phone",
          preData
        )}
      >
        <div
          className={checkClassName(
            "col-md-2 col-sm-12",
            "PhoneLocationTypeLangDescription",
            preData
          )}
        >
          <PublicCodeSelect
            label={_.get(langData, "PhoneLocationTypeLangDescription")}
            control={control}
            name="PhoneLocationType"
            columnName="PhoneLocationTypeLangDescription"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            code="PubLocationType"
          />
        </div>
        <div
          className={
            checkClassName("", "PhoneLocationTypeLangDescription", preData)
              ? checkClassName(
                  "col-md-5 col-sm-12",
                  "PhoneCallingCode",
                  preData
                )
              : "col-md-3 col-sm-12"
          }
        >
          <TextField
            label={_.get(langData, "PhoneCallingCode")}
            control={control}
            name="PhoneCallingCode"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            type="tel"
            showIcon={false}
            textAlign="left"
            fullWidth
          />
        </div>
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "Phone")}
            control={control}
            name="Phone"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            type="tel"
            textAlign="left"
            showIcon={!isDirectionRTL}
            fullWidth
            onKeyDown={(e) => handlePhoneKeyDown(e)}
          />
        </div>
        <IconButton
          className="jarvisIconHover mt-2"
          size="small"
          icon={<PostAdd />}
          color="primary"
          tooltip={_.get(langData, "AddToList")}
          onClick={handleAddPatronPhone}
        />
      </div>
      <DynamicColumn
        columns={_.get(preData, "GTCD.PmsPatronPhone")}
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
      <div className="row mt-2">
        {PatronPhones?.map((patronPhone) => (
          <Chip
            className="mx-1"
            key={_.get(patronPhone, "Phone")}
            value={patronPhone}
            color="default"
            clickable
            label={getPatronPhoneLabel(patronPhone)}
            leftIcon={getPatronPhoneIsMainIcon(patronPhone)}
            onClick={handlePhoneClick}
            onRightIconClick={handleDeletePatronPhone}
          />
        ))}
      </div>

      <div
        className={checkClassName(
          "row d-flex align-items-center mt-2",
          "Email",
          preData
        )}
      >
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "Email")}
            control={control}
            name="Email"
            errors={errors}
            rules={preData.rules}
            checkRules={false}
            type="email"
            hint={showEmailHint ? "example@domain.com" : undefined}
            textAlign="left"
            showIcon={!isDirectionRTL}
            fullWidth
            onKeyDown={(e) => handleEmailKeyDown(e)}
          />
        </div>
        <IconButton
          className="jarvisIconHover mt-2"
          size="small"
          icon={<PostAdd />}
          color="primary"
          tooltip={_.get(langData, "AddToList")}
          onClick={handleAddPatronEmail}
        />
      </div>
      <DynamicColumn
        columns={_.get(preData, "GTCD.PmsPatronEmail")}
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
      <div className="row mt-2">
        {PatronEmails?.map((patronEmail) => (
          <Chip
            className="mx-1"
            key={_.get(patronEmail, "Email")}
            value={patronEmail}
            color="default"
            clickable
            label={_.get(patronEmail, "Email")}
            leftIcon={getPatronEmailIsMainIcon(patronEmail)}
            onClick={handleEmailClick}
            onRightIconClick={handleDeletePatronEmail}
          />
        ))}
      </div>

      <Accordion
        className={checkClassName("", "PatronAddressLangDescription", preData)}
        onChange={handleChangeExtraInfo}
      >
        <AccordionSummary>
          <Typography
            label={_.get(langData, "PatronAddressLangDescription")}
            color="primary"
          />
          {showExtraInfo ? (
            <ExpandLess color="primary" />
          ) : (
            <ExpandMore color="primary" />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} className="row">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronAddressCity",
                  preData
                )}
              >
                <StateDivisionSelect
                  label={_.get(langData, "PatronAddressCity")}
                  control={control}
                  name="PatronAddressCity"
                  columnName="PatronAddressCity"
                  errors={errors}
                  rules={preData.rules}
                  checkRules={false}
                  latestNodeLevel={3}
                  showFullPath
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <TextField
                  label={_.get(langData, "PatronAddressLangDescription")}
                  control={control}
                  name="PatronAddressLangDescription"
                  errors={errors}
                  rules={preData.rules}
                  checkRules={false}
                  multiline
                  fullWidth
                />
              </div>
            </div>
            <div
              style={{ width: "100%" }}
              className="row d-flex align-items-center mt-2"
            >
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronAddressPostalCode",
                  preData
                )}
              >
                <TextField
                  label={_.get(langData, "PatronAddressPostalCode")}
                  control={control}
                  name="PatronAddressPostalCode"
                  errors={errors}
                  rules={preData.rules}
                  checkRules={false}
                  type="tel"
                  textAlign="left"
                  showIcon={false}
                  fullWidth
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <PublicCodeSelect
                  label={_.get(langData, "PatronAddressLocation")}
                  control={control}
                  name="PatronAddressLocationType"
                  columnName="PatronAddressLocation"
                  errors={errors}
                  rules={preData.rules}
                  checkRules={false}
                  code="PubLocationType"
                />
              </div>
              <IconButton
                className="jarvisIconHover mt-2"
                size="small"
                icon={<PostAdd />}
                color="primary"
                tooltip={_.get(langData, "AddToList")}
                onClick={handleAddPatronAddress}
              />
            </div>
            <DynamicColumn
              columns={_.get(preData, "GTCD.PmsPatronAddress")}
              control={control}
              errors={errors}
              setValue={setValue}
              rules={preData.rules}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <div className="row mt-2">
        {PatronAddresses?.map((patronAddress) => (
          <Chip
            className="mx-1"
            key={patronAddress.PatronAddressLangDescription}
            value={patronAddress}
            color="default"
            clickable
            label={getPatronAddressLabel(patronAddress)}
            leftIcon={getPatronAddressIsMainIcon(patronAddress)}
            onClick={handleAddressClick}
            onRightIconClick={handleDeletePatronAddress}
          />
        ))}
      </div>
    </div>
  );
}
