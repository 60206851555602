import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch, Router } from "react-router-dom";
import configureStore, { history } from "Jarvis/JarvisRedux/store";
//استایلهای مربوط به Jarvis
import "Jarvis/JarvisStyles/index.scss";
import { LangRetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/LangService";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import { setCalendar } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import Routes from "./Routes";
import LanguageContext from "Jarvis/JarvisContexts/LanguageContext";

export default function Prerequisites() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function callApi() {
      const data = await LangRetrievePublic();
      setData(data);
    }
    callApi();
  }, []);

  if (data == null) return "Loading...";
  const { IsSuccessful } = data.ErrorHandling;

  if (!IsSuccessful)
    return <pre>{JSON.stringify(data.ErrorHandling, null, 2)}</pre>;

  const langs = data.Data ?? [];
  const selectedLanguage = JSON.parse(localStorage.getItem("selectedLanguage"));
  let langSelected = langs.find((x) => x.LangId === selectedLanguage?.LangId);

  if (isNullOrWhiteSpace(langSelected)) {
    langSelected = langs.find((x) => x.LangIsDefault);
    if (isNullOrWhiteSpace(langSelected) || langs.length === 1)
      langSelected = langs[0];
    if (isNullOrWhiteSpace(langSelected.CalendarId))
      return (
        <p>
          In order to use the language, the calendar info is needed. Please
          assgin the respective calendar to the lang table
        </p>
      );
  }

  setCalendar({
    calendarId: langSelected.CalendarId,
    calendarKey: langSelected.CalendarKey,
    calendarFormat: langSelected.CalendarDefaultFormat,
  });

  const lang = { payload: langSelected, type: "switch-language" };
  localStorage.setItem("selectedLanguage", JSON.stringify(langSelected));
  const store = configureStore(lang);

  return (
    <LanguageContext.Provider value={langs}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path={`/`} component={Routes} />
          </Switch>
        </Router>
      </Provider>
    </LanguageContext.Provider>
  );
}
