import React, { Fragment, useState } from "react";
import _ from "lodash";
import { green, grey } from "@material-ui/core/colors";
import { Done, PostAdd } from "@material-ui/icons";
import { checkClassName } from "Jarvis/JarvisServices/JarvisCommonService";
import Checkbox from "Jarvis/JarvisComponents/Checkbox";
import Chip from "Jarvis/JarvisComponents/Chip";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import PatronGroupTypeSystemSelect from "../CustomSelect/PatronGroupTypeSystemSelect";
import DetailSelect from "Jarvis/JarvisSystems/Acc/Components/CustomSelect/DetailSelect";
import IconButton from "Jarvis/JarvisComponents/IconButton";

export default function PatronSystemCommunication({
  control,
  errors,
  getValues,
  langData,
  patronData,
  setPatronData,
  preData,
  setValue,
}) {
  const [showAccDetails, setShowAccDetails] = useState(
    _.get(patronData, "AccDetails")?.length > 0
  );
  let { PatronGroups, AccDetails } = patronData ?? {};
  if (!PatronGroups) PatronGroups = [];
  if (!AccDetails) AccDetails = [];

  //#region ------------- Detail -------------
  const handleChangeAcc = (value) => {
    if (!value && AccDetails.length > 0) {
      setPatronData((patronData) => ({
        ...patronData,
        AccDetails: [],
      }));
    }
    setShowAccDetails(!showAccDetails);
  };

  const handleAddDetail = () => {
    const Detail = getValues("Detail");
    if (Detail) {
      const index = AccDetails.findIndex(
        (detail) => detail.DetailId === Detail.DetailId
      );
      if (index === -1) {
        setPatronData((patronData) => ({
          ...patronData,
          AccDetails: [...AccDetails, Detail],
        }));
      }
      setValue("Detail", null);
    }
  };

  const handleDeleteDetail = (Detail) => {
    let newDetails = [...AccDetails];
    const index = AccDetails.indexOf(Detail);
    newDetails.splice(index, 1);

    setPatronData((patronData) => ({
      ...patronData,
      AccDetails: newDetails,
    }));
  };
  //#endregion

  //#region ------------- Group -------------
  const handleAddPatronGroup = () => {
    const group = getValues("PatronGroupTypeSystem");
    if (group) {
      const index = PatronGroups.findIndex(
        (patronGroup) =>
          patronGroup.PatronGroupTypeSystemId === group.PatronGroupTypeSystemId
      );
      if (index === -1) {
        const patronGroup = {
          PatronGroupTypeSystemId: group.PatronGroupTypeSystemId,
          PatronGroupTypeSystemLangName: group.PatronGroupTypeSystemLangName,
          VTPatronGroupTypeSystemId: group.VT,
          IsActive: true,
        };
        setPatronData((patronData) => ({
          ...patronData,
          PatronGroups: [...PatronGroups, patronGroup],
        }));
      }
      setValue("PatronGroupTypeSystem", null);
    }
  };

  const handleDeletePatronGroup = (patronGroup) => {
    let newPatronGroups = [...PatronGroups];
    const index = PatronGroups.indexOf(patronGroup);
    newPatronGroups.splice(index, 1);
    setPatronData((patronData) => ({
      ...patronData,
      PatronGroups: newPatronGroups,
    }));
  };

  const handleActivePatronGroup = (e, patronGroup) => {
    e.stopPropagation();
    let newPatronGroups = [...PatronGroups];
    const index = PatronGroups.indexOf(patronGroup);
    newPatronGroups[index].IsActive = !patronGroup.IsActive;
    setPatronData((patronData) => ({
      ...patronData,
      PatronGroups: newPatronGroups,
    }));
  };

  const getPatronGroupIsActiveIcon = (patronGroup) => (
    <Done
      style={{
        color: patronGroup.IsActive ? green[500] : grey[400],
        cursor: "pointer",
      }}
      onClick={(e) => handleActivePatronGroup(e, patronGroup)}
    />
  );
  //#endregion

  return (
    <div>
      {_.get(preData, "prerequisites.dbJarvisAccExists") && (
        <Fragment>
          <div className={checkClassName("", "DetailLangName", preData)}>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <Checkbox
                  className="mt-2"
                  label={_.get(langData, "Acc")}
                  control={control}
                  name="IsAccSelected"
                  onChange={handleChangeAcc}
                  value={
                    _.get(patronData, "AccDetails")?.length > 0 ||
                    _.get(patronData, "IsAccSelected")
                  }
                />
              </div>
            </div>
            {(showAccDetails || _.get(patronData, "IsAccSelected")) && (
              <div className="row d-flex align-items-center">
                <div className="col-md-5 col-sm-12">
                  <DetailSelect
                    label={_.get(langData, "AccDetails")}
                    control={control}
                    name="Detail"
                    errors={errors}
                    rules={preData.rules}
                    checkRules={false}
                    serviceParams={{
                      hierarchyShowMode: "Tree",
                      isDeepSearchNeeded: true,
                    }}
                  />
                </div>
                <IconButton
                  className="jarvisIconHover"
                  size="small"
                  icon={<PostAdd />}
                  color="primary"
                  tooltip={_.get(langData, "AddToList")}
                  onClick={handleAddDetail}
                  disabled={!showAccDetails}
                />
              </div>
            )}
          </div>
          <DynamicColumn
            columns={_.get(preData, "GTCD.AccDetails")}
            control={control}
            errors={errors}
            setValue={setValue}
            rules={preData.rules}
          />
          <div className="row mt-2">
            {AccDetails.map((detail) => (
              <Chip
                className="mx-1"
                key={_.get(detail, "DetailId")}
                value={detail}
                color="default"
                label={_.get(detail, "DetailLangName")}
                onRightIconClick={handleDeleteDetail}
              />
            ))}
          </div>
        </Fragment>
      )}
      <div
        className={checkClassName(
          "row mt-2 d-flex align-items-center",
          "PatronGroupTypeLangName",
          preData
        )}
      >
        <div className="col-md-5 col-sm-12">
          <PatronGroupTypeSystemSelect
            label={_.get(langData, "PatronGroupTypeLangName")}
            control={control}
            name="PatronGroupTypeSystem"
            columnName="PatronGroupTypeLangName"
            errors={errors}
            rules={preData?.rules}
            checkRules={false}
          />
        </div>
        <IconButton
          className="jarvisIconHover"
          size="small"
          icon={<PostAdd />}
          color="primary"
          tooltip={_.get(langData, "AddToList")}
          onClick={handleAddPatronGroup}
        />
      </div>
      <div className="row mt-2">
        {PatronGroups.map((patronGroup) => (
          <Chip
            className="mx-1"
            key={_.get(patronGroup, "PatronGroupTypeSystemId")}
            value={patronGroup}
            color="default"
            label={_.get(patronGroup, "PatronGroupTypeSystemLangName")}
            leftIcon={getPatronGroupIsActiveIcon(patronGroup)}
            onRightIconClick={handleDeletePatronGroup}
          />
        ))}
      </div>
    </div>
  );
}
