import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";

export const Authenticate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/Account/Authenticate",
    data: _data,
    toastContainerId: "loginToastContainerId",
  });

  return data;
};

export const UserRequestResetPassword = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/Account/RequestResetPassword",
    data: _data,
    toastContainerId: "loginToastContainerId",
  });

  return data;
};

export const ForgetPassword = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/Account/ResetPassword",
    data: _data,
    toastContainerId: "loginToastContainerId",
  });

  return data;
};

export const PasswordExpired = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/Account/ChangePassword",
    data: _data,
    toastContainerId: "loginToastContainerId",
  });

  return data;
};
export const ConcurrentLoginDelete = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/Account/ConcurrentLoginDelete",
    data: _data,
    toastContainerId: "loginToastContainerId",
  });

  return data;
};
