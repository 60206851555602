import _ from "lodash";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import { getGraphQLUrl } from "Jarvis/JarvisServices/JarvisGraphQLService";

export const FinancialTermRetrieve = async ({
  pageIndex,
  pageSize,
  orderBy,
  orderBys,
  orderMethod,
  where,
  retrieveAsList,
  companyId,
  VTCompanyId,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pub/FinancialTerm/RetrieveAsList"
      : "Pub/FinancialTerm/Retrieve";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      orderBy,
      orderBys,
      orderMethod,
      where,
      companyId,
      VTCompanyId,
    },
  });

  return data;
};

export const FinancialTermCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/FinancialTerm/Create",
    data: _data,
  });
  return data;
};

export const FinancialTermUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/FinancialTerm/Update",
    data: _data,
  });
  return data;
};

export const FinancialTermDelete = async ({
  FinancialTermId,
  VT,
  FinancialTermLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/FinancialTerm/Delete",
    data: { FinancialTermId, VT, FinancialTermLastUpdateDateTime },
  });
  return data;
};

export const FinancialTermPrerequisitesGraphQL = async () => {
  const { data } = await JarvisHttpService.post({
    url: getGraphQLUrl(),
    data: {
      query: `
        {
          prerequisites:financialTermPrerequisites(){data},
          langData:pageElementRetrievePublic(iModel:{moduleCodeStrings:"PubFinancialTerm"}){data}
        }`,
    },
  });

  return {
    errorHandling: _.get(data, "ErrorHandling"),
    prerequisites: JSON.parse(
      ifNullOrWhiteSpace(_.get(data, "Data.data.prerequisites.data"), null)
    ),
    langData: JSON.parse(
      ifNullOrWhiteSpace(_.get(data, "Data.data.langData.data"), null)
    ),
  };
};
