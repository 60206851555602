// Signin Form Container
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import SignIn from "./SignIn";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationContainer } from "Jarvis/JarvisComponents/Notification";
import {
  showAuthLoader,
  userSignIn,
  hideAuthLoader,
  userSignOut,
} from "Jarvis/JarvisActions/Auth";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";

const FormContainer = (props) => {
  const {
    configs,
    langData,
    loader,
    location,
    isDirectionRTL,
    isInternalSignIn,
  } = props;
  if (
    isInternalSignIn === false &&
    location.pathname.toLowerCase() !== "/account/signin" &&
    location.pathname.toLowerCase() !== "/account/forgetpassword" &&
    location.pathname.toLowerCase() !== "/account/resetpassword"
  )
    return <Redirect to={"/Account/Signin"} />;

  return (
    <Fragment>
      {isInternalSignIn === false ? (
        <Switch>
          <Route path="/Account/Signin">
            <SignIn {...props} langData={langData} />
          </Route>
          <Route path="/Account/ForgetPassword">
            <ForgetPassword {...props} langData={langData} />
          </Route>
          <Route path="/Account/ResetPassword">
            <ResetPassword {...props} configs={configs} langData={langData} />
          </Route>
        </Switch>
      ) : (
        <SignIn {...props} langData={langData} />
      )}
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      <NotificationContainer
        position={isDirectionRTL ? "top-left" : "top-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={isDirectionRTL}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        containerId="loginToastContainerId"
        enableMultiContainer={true}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ auth, settings }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { language, isDirectionRTL } = settings;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    language,
    isDirectionRTL,
  };
};
export default connect(mapStateToProps, {
  userSignIn,
  showAuthLoader,
  hideAuthLoader,
  userSignOut,
})(FormContainer);
