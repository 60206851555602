import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { JobActivityTypeRetrieve } from "Jarvis/JarvisSystems/Pms/Services/JobActivityTypeService";

export default function JobActivityTypeSelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={JobActivityTypeRetrieve}
      serviceParams={{
        ...serviceParams,
        retrieveAsList: true,
        isDeepSearchNeeded: true,
      }}
      displayFieldName="JobActivityTypeLangName"
      valueFieldName="JobActivityTypeId"
      selectOnFocus
      autoSelect
      typeOnly
      {...rest}
    />
  );
}
