import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const SubSystemRetrieve = async ({
  pageIndex,
  pageSize,
  orderBy,
  orderBys,
  orderMethod,
  where,
  retrieveAsList,
  SystemId,
  VTSystemId,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pub/SubSystem/RetrieveAsList"
      : "Pub/SubSystem/Retrieve";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      orderBy,
      orderMethod,
      where,
      orderBys,
      SystemId,
      VTSystemId,
    },
  });

  return data;
};

export const SubSystemCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/SubSystem/Create",
    data: _data,
  });
  return data;
};

export const SubSystemUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/SubSystem/Update",
    data: _data,
  });
  return data;
};

export const SubSystemDelete = async ({
  SubSystemId,
  VT,
  SubSystemLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/SubSystem/Delete",
    data: { SubSystemId, VT, SubSystemLastUpdateDateTime },
  });
  return data;
};
