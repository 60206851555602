import React, { useState, useEffect } from "react";
import _ from "lodash";
import { SystemRetrieveIncludeSubSystem } from "Jarvis/JarvisSystems/Pub/Services/SystemService";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { setSubSystem, getSubSystem, getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import CustomScrollbars from "Jarvis/JarvisComponents/Scrollbars/CustomScrollbars";
import { jarvisComponentMustBeExcluded } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { isEqual } from "Jarvis/JarvisServices/JarvisCommonService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";

export default function index(props) {
  if (jarvisComponentMustBeExcluded("SystemSelection")) return null;
  return <SystemSelection {...props} />;
}

function SystemSelection({ handleClick }) {
  const selectedSubSystem = getSubSystem();
  const language = getLanguage();
  const [stateObject, setStateObject] = useState({
    data: null,
    subSystemLangDescription: "",
  });

  useEffect(() => {
    (async () => {
      const response = await SystemRetrieveIncludeSubSystem();
      if (response) {
        const { ErrorHandling, Data } = _.get(response, "data") || response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          let subSystemLangDescription = "";
          for (let i = 0; i < Data?.length; i++) {
            const sub = _.find(Data[i].SubSystems, (y) => {
              /*eslint-disable eqeqeq*/
              return y.SubSystemId == selectedSubSystem?.subSystemId && (y.LangId === language.LangId || isEqual(y.LangKey, language.LangKey)) /*For JarvisCustom use*/;
            });
            if (sub) {
              subSystemLangDescription = sub.SubSystemLangDescription;
              break;
            }
          }
          localStorage.removeItem("isReloadPageNeeded");
          setStateObject({ data: Data, subSystemLangDescription });
        } else {
          localStorage.setItem("isReloadPageNeeded", true);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (stateObject.data === null) return "";

  const handleClickDropdown = () => {
    //setIsOpen(isOpen);
  };

  const handleClickSubSystem = (subSystemSelected) => {
    document.title = subSystemSelected.subSystemLangDescription;
    let stateObj = { ...stateObject };
    stateObj.subSystemLangDescription = subSystemSelected.subSystemLangDescription;
    setSubSystem(subSystemSelected);
    setStateObject(stateObj);
    handleClick(subSystemSelected);
  };
  const handleOpenInNewTab = (e, subSystem) => {
    e.stopPropagation();
    const env = JarvisBaseSettingsService.getEnvironment();
    let urlToChangeSubSystem = `${window.location.protocol}//${window.location.host}/${env}?`;
    urlToChangeSubSystem += `SSId=${subSystem.subSystemId}&VTSSId=${subSystem.vtSubSystemId}&SSKey=${subSystem.subSystemKey}`;
    window.open(urlToChangeSubSystem);
    return false;
  };

  const generateLILink = ({ systemIcon, subSystemId, vtSubSystemId, subSystemLangDescription, subSystemKey }) => {
    return (
      <li
        onClick={() =>
          handleClickSubSystem({
            subSystemId,
            vtSubSystemId,
            subSystemKey,
            subSystemLangDescription,
          })
        }
        className={` ${language.JarvisFontClass} pointer ${subSystemId === _.get(selectedSubSystem, "subSystemId") ? "font-weight-bold" : ""}`}
        key={subSystemId}
      >
        <div className="row">
          <div className="col-10">
            <i className={`pr-2 ${systemIcon}`} />
            {subSystemLangDescription} ({subSystemKey})
          </div>
          <div className="col-2">
            <i
              onClick={(e) =>
                handleOpenInNewTab(e, {
                  subSystemId,
                  subSystemKey,
                  vtSubSystemId,
                })
              }
              className="zmdi zmdi-open-in-new"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </li>
    );
  };

  const generateSystems = () => {
    const _data = _.filter(stateObject.data, (system) => system.LangId === language.LangId || isEqual(system.LangKey, language.LangKey) /*For JarvisCustom use*/);

    return _data?.map((system) => {
      const subSystems = _.filter(system.SubSystems, (subSystem) => subSystem.LangId === language.LangId || isEqual(subSystem.LangKey, language.LangKey));

      if (subSystems?.length === 1) {
        return generateLILink({
          subSystemId: subSystems[0].SubSystemId,
          vtSubSystemId: subSystems[0].VT,
          subSystemLangDescription: subSystems[0].SubSystemLangDescription,
          subSystemKey: subSystems[0].SubSystemKey,
          systemIcon: system.SystemIcon,
        });
      } else if (subSystems?.length > 1) {
        return (
          <li key={system.SystemId} className={`${language.JarvisFontClass}`}>
            {system.SystemLangDescription}
            <ul>
              {subSystems?.map((ss) => {
                return generateLILink({
                  subSystemId: ss.SubSystemId,
                  vtSubSystemId: ss.VT,
                  subSystemLangDescription: ss.SubSystemLangDescription,
                  subSystemKey: ss.SubSystemKey,
                  systemIcon: system.SystemIcon,
                });
              })}
            </ul>
          </li>
        );
      } else return undefined;
    });
  };

  return (
    <Dropdown className="quick-menu app-notification1" toggle={handleClickDropdown}>
      <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
        <span className="app-notification-menu">
          <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
          <span className={`${language.JarvisFontClass}`}>{stateObject.subSystemLangDescription}</span>
        </span>
      </DropdownToggle>

      <DropdownMenu>
        {stateObject.data && stateObject.data.length > 0 ? (
          <CustomScrollbars className="messages-list scrollbar" style={{ height: 400 }}>
            <ul style={{ listStyle: "none", padding: 0 }}>{generateSystems()}</ul>
          </CustomScrollbars>
        ) : (
          "Reload data"
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
