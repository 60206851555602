import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete,
} from "Jarvis/JarvisRedux/store";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axiosGet,
  post: axiosPost,
  put: axiosPut,
  delete: axiosDelete,
};
