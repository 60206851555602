import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";

export const PatronGroupTypeRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  node,
  retrieveAsList,
  hierarchyShowMode,
  latestNodeLevel,
  isDeepSearchNeeded,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pms/PatronGroupType/RetrieveAsList"
      : "Pms/PatronGroupType/Retrieve";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    NodeId: node?.["PatronGroupTypeId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      hierarchy,
      orderBy,
      orderBys,
      orderMethod,
      where,
    },
  });
  return data;
};

export const PatronGroupTypeCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronGroupType/Create",
    data: _data,
  });
  return data;
};

export const PatronGroupTypeUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronGroupType/Update",
    data: _data,
  });
  return data;
};

export const PatronGroupTypeDelete = async ({
  PatronGroupTypeId,
  VT,
  LastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronGroupType/Delete",
    data: { PatronGroupTypeId, VT, LastUpdateDateTime },
  });
  return data;
};

export const PatronGroupTypeSystemRetrieve = async ({ where }) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronGroupType/PatronGroupTypeSystemRetrieve",
    data: {
      where,
    },
  });
  return data;
};
