import React, { useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Button from "Jarvis/JarvisComponents/Button";
import CompanySelect from "../CustomSelect/CompanySelect";
import FinancialTermSelect from "../CustomSelect/FinancialTermSelect";

export default function InputForm({
  langData,
  onClose,
  onSubmitParent,
  selectedCompanyFinancialTerm,
  showInputForm,
}) {
  const { control, setValue, handleSubmit } = useForm();
  const [selectedCompany, setSelectedCompany] = useState({
    CompanyId: _.get(selectedCompanyFinancialTerm, "CompanyId"),
    CompanyLangDescription: _.get(
      selectedCompanyFinancialTerm,
      "CompanyLangDescription"
    ),
    VT: _.get(selectedCompanyFinancialTerm, "VTCompanyId"),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const result = {};
    const extraData = {};
    const company = data.Company;
    if (company) {
      result.CompanyId = company.CompanyId;
      result.VTCompanyId = company.VT;
      extraData.CompanyLangDescription = company.CompanyLangDescription;
    }

    const financialTerm = data.FinancialTerm;
    if (financialTerm) {
      result.FinancialTermId = financialTerm.FinancialTermId;
      result.VTFinancialTermId = financialTerm.VT;
      extraData.FinancialTermLangDescription =
        financialTerm.FinancialTermLangDescription;
    }
    setIsLoading(true);
    const isSuccessful = await onSubmitParent(result, extraData);
    setIsLoading(false);
    if (isSuccessful) onClose();
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    setValue("FinancialTerm", null);
  };

  return (
    <Dialog
      width="40%"
      open={showInputForm}
      onClose={onClose}
      showDialogTitle={false}
    >
      <form
        className="border border-info py-3 mb-2 px-4"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col">
            <CompanySelect
              label={_.get(langData, "PubCompany")}
              control={control}
              name="Company"
              value={{
                CompanyId: _.get(selectedCompany, "CompanyId"),
                CompanyLangDescription: _.get(
                  selectedCompany,
                  "CompanyLangDescription"
                ),
                VT: _.get(selectedCompany, "VT"),
              }}
              onChange={handleCompanyChange}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <FinancialTermSelect
              label={_.get(langData, "PubFinancialTerm")}
              control={control}
              name="FinancialTerm"
              dependencies={[_.get(selectedCompany, "CompanyId")]}
              serviceParams={{
                companyId: _.get(selectedCompany, "CompanyId"),
                VTCompanyId: _.get(selectedCompany, "VT"),
              }}
              value={{
                FinancialTermId: _.get(
                  selectedCompanyFinancialTerm,
                  "FinancialTermId"
                ),
                FinancialTermLangDescription: _.get(
                  selectedCompanyFinancialTerm,
                  "FinancialTermLangDescription"
                ),
                VT: _.get(selectedCompanyFinancialTerm, "VTFinancialTermId"),
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <Button mode="save" showLoading={isLoading} />
            <Button className="mx-3" mode="close" onClick={onClose} />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
