import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import MainContainer from "Jarvis/JarvisComponents/Containers/MainContainer";
import MainContainerBody from "Jarvis/JarvisComponents/Containers/MainContainerBody";
import Table from "Jarvis/JarvisComponents/Table";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import RadioList from "Jarvis/JarvisComponents/RadioList";
import Button from "Jarvis/JarvisComponents/Button";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Wait from "Jarvis/JarvisComponents/Wait";
import {
  generateRules,
  generateColumns,
  flatten,
} from "Jarvis/JarvisServices/JarvisCommonService";
import {
  PatronCreate,
  PatronDelete,
  PatronPrerequisitesGraphQL,
  PatronRetrieve,
  PatronUpdate,
} from "Jarvis/JarvisSystems/Pms/Services/PatronService";
import InputForm from "./InputForm";
import PatronView from "./PatronView";

export default function Patron({
  showForm,
  onShowForm,
  patronTypeForm,
  displayFieldName,
  setNewItem,
}) {
  const { language, isDirectionRTL } = useSelector((state) => state.settings);
  const { PE } = useContext(GlobalContext);
  const [columnsReal, setColumnsReal] = useState([]);
  const [columnsLegal, setColumnsLegal] = useState([]);
  const [columnsOther, setColumnsOther] = useState([]);
  const [editData, setEditData] = useState(null);
  const [pagination, setPagination] = useState({});
  const [patronType, setPatronType] = useState(patronTypeForm || "R");
  const [patronViewProps, setPatronViewProps] = useState(null);
  const [preData, setPreData] = useState({
    langData: null,
    rules: null,
    GTCD: null,
    IGTC: null,
    configs: null,
    configModule: null,
    prerequisites: null,
  });
  const [retrieveIsLoading, setIsLoadingRetrieve] = useState(false);
  const [showInputForm, setShowInputForm] = useState(false);
  const [showPatronView, setShowPatronView] = useState(false);
  const [tableDataInfo, setTableDataInfo] = useState(null);
  const [tableRefreshInfo, setTableRefreshInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await PatronPrerequisitesGraphQL();
      if (_.get(response, "errorHandling.IsSuccessful")) {
        setPreData({
          langData: _.merge(
            _.pick(PE.PubPublicElems, [
              "IsActive",
              "Save",
              "Next",
              "Back",
              "Add",
              "AddToList",
              "CreationDateTime",
              "NoRowsSelected",
              "Required",
            ]),
            _.get(response, "langData.PE.PmsPatron"),
            _.get(response, "langData.PE.AccTTMSPatron"),
            flatten(_.get(response, "langData.GTC"))
          ),
          rules: generateRules(
            _.get(response, "langData.Rules"),
            _.get(response, "langData.IGTC")
          ),
          GTCD: _.get(response, "langData.GTCD"),
          IGTC: _.get(response, "langData.IGTC"),
          configs: _.get(response, "langData.Configs") || {},
          configModule: _.get(response, "ConfigModule") || {},
          prerequisites: _.get(response, "prerequisites"),
        });
      }
    })();
  }, [language.LangId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    setIsLoadingRetrieve(true);
    if (!_.isEmpty(pagination.where)) {
      let fieldName;
      for (let i = 0; i < pagination.where.length; i++) {
        fieldName = pagination.where[i].FieldName;
        switch (patronType) {
          case "R":
            if (
              fieldName === "PatronLegalLangDescription" ||
              fieldName === "PatronOtherLangDescription"
            )
              pagination.where[i].FieldName = "PatronRealLangLastName";

            if (fieldName === "PatronLegalIDNo")
              pagination.where[i].FieldName = "PatronRealIDNo";
            break;
          case "L":
            if (
              fieldName === "PatronRealLangFirstName" ||
              fieldName === "PatronRealLangLastName"
            )
              pagination.where[i].FieldName = "PatronLegalLangDescription";

            if (fieldName === "PatronRealIDNo")
              pagination.where[i].FieldName = "PatronLegalIDNo";
            break;

          default:
            if (
              fieldName === "PatronRealLangFirstName" ||
              fieldName === "PatronRealLangLastName" ||
              fieldName === "PatronLegalLangDescription"
            )
              pagination.where[i].FieldName = "PatronOtherLangDescription";
            break;
        }
      }
    }
    const response = await PatronRetrieve({ ...pagination, patronType });
    setIsLoadingRetrieve(false);
    const { ErrorHandling } = response;
    if (_.get(ErrorHandling, "IsSuccessful")) {
      setTableDataInfo({
        data: response.Data,
        totalRecords: response.TotalRecords,
      });
    }
  }, [pagination, patronType]);

  useEffect(() => {
    if (!showForm) fetchData();
  }, [fetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async ({ data, extraData }) => {
    let _data = editData
      ? {
          ...data,
          PatronId: editData.PatronId,
          VT: editData.VT,
          PatronLastUpdateDateTime: editData.PatronLastUpdateDateTime,
        }
      : data;
    const response = editData
      ? await PatronUpdate(_data)
      : await PatronCreate(_data);

    if (_.get(response, "ErrorHandling.IsSuccessful")) {
      if (_.first(_.get(response, "Data"))?.IsDuplicate) {
        _.set(response, "ErrorHandling.IsDuplicate", true);
        return response;
      }
      const { Data } = response;
      if (onShowForm) {
        if (setNewItem)
          setNewItem({
            PatronId: _.get(Data, "PatronId"),
            VT: _.get(Data, "VT"),
            [displayFieldName]: _.get(Data, "PatronLangFullName"),
          });
        onShowForm(false);
      } else {
        if (patronType === data.PatronType) {
          let Cellphone = "";
          let Phone = "";
          let Email = "";
          if (data.PatronCellphones)
            Cellphone = data.PatronCellphones.find(
              (item) => item.PatronCellphoneIsMain === true
            )?.Cellphone;
          if (data.PatronPhones)
            Phone = data.PatronPhones.find(
              (item) => item.PatronPhoneIsMain === true
            )?.Phone;
          if (data.PatronEmails)
            Email = data.PatronEmails.find(
              (item) => item.PatronEmailIsMain === true
            )?.Email;

          let newRecord = {
            ..._.pick(data, [
              "PatronType",
              "PatronCode",
              "PatronSearchKey",
              "PatronIsActive",
            ]),
            Cellphone,
            Phone,
            Email,
          };
          if (newRecord.PatronIsActive === undefined)
            newRecord.PatronIsActive = true;
          if (patronType === "R") {
            newRecord = _.merge(newRecord, data.PatronReal);
            newRecord.GenderLangDescription = extraData.GenderLangDescription;
            newRecord.PatronRealBirthPlace = extraData.PatronRealBirthPlace;
          } else if (patronType === "L") {
            newRecord = _.merge(newRecord, data.PatronLegal);
            newRecord.PatronLegalRegisterPlace =
              extraData.PatronLegalRegisterPlace;
          } else {
            newRecord = _.merge(newRecord, data.PatronOther);
          }

          setTableRefreshInfo({
            isEditMode: editData ? true : false,
            newRecord: editData
              ? _.merge(
                  {
                    ...newRecord,
                    PatronId: editData.PatronId,
                    VT: editData.VT,
                  },
                  Data
                )
              : _.merge(newRecord, Data),
          });
        }
      }
    }
    return response;
  };

  if (!preData.langData) return <Wait showProgressOnly={showForm} />;

  const inputForm = (
    <InputForm
      editData={editData}
      isDirectionRTL={isDirectionRTL}
      langData={preData.langData}
      fetchData={fetchData}
      onShowInputForm={onShowForm || setShowInputForm}
      onSubmitParent={onSubmit}
      patronType={patronType}
      preData={preData}
      showInputForm={showForm || showInputForm}
    />
  );

  if (showForm) {
    return inputForm;
  }

  const handleLastNameClick = (PatronId, VT) => {
    setPatronViewProps({ PatronId, VT, PatronType: patronType });
    setShowPatronView(true);
  };

  if (patronType === "R" && columnsReal.length === 0)
    setColumnsReal(
      generateColumns(
        [
          {
            label: _.get(preData.langData, "PatronRealLangFirstName"),
            path: "PatronRealLangFirstName",
          },
          {
            label: _.get(preData.langData, "PatronRealLangLastName"),
            path: "PatronRealLangLastName",
            content: (patron) => (
              <Button
                style={{ textTransform: "none" }}
                label={patron.PatronRealLangLastName}
                onClick={() => handleLastNameClick(patron.PatronId, patron.VT)}
              />
            ),
          },
          {
            label: _.get(preData.langData, "PatronCode"),
            path: "PatronCode",
            cellAlign: "center",
          },
          {
            label: _.get(preData.langData, "PatronRealIDNo"),
            path: "PatronRealIDNo",
            textAlign: "left",
          },
          {
            label: _.get(preData.langData, "Cellphone"),
            path: "Cellphone",
          },
          {
            label: _.get(preData.langData, "Phone"),
            path: "Phone",
            visible: false,
          },
          {
            label: _.get(preData.langData, "Email"),
            path: "Email",
            visible: false,
          },
          {
            label: _.get(preData.langData, "PatronAddressLangDescription"),
            path: "PatronAddressLangDescription",
            visible: false,
          },
          {
            label: _.get(preData.langData, "IsActive"),
            path: "PatronIsActive",
            type: "checkbox",
            widthScale: 0.5,
          },
        ],
        preData
      )
    );
  else if (patronType === "L" && columnsLegal.length === 0)
    setColumnsLegal(
      generateColumns(
        [
          {
            label: _.get(preData.langData, "PatronLegalLangDescription"),
            path: "PatronLegalLangDescription",
            content: (patron) => (
              <Button
                style={{ textTransform: "none" }}
                label={patron.PatronLegalLangDescription}
                onClick={() => handleLastNameClick(patron.PatronId, patron.VT)}
              />
            ),
          },
          {
            label: _.get(preData.langData, "PatronCode"),
            path: "PatronCode",
            cellAlign: "center",
          },
          {
            label: _.get(preData.langData, "PatronLegalIDNo"),
            path: "PatronLegalIDNo",
            textAlign: "left",
          },
          {
            label: _.get(preData.langData, "PatronLegalMinistryOfFinanceNo"),
            path: "PatronLegalMinistryOfFinanceNo",
            textAlign: "left",
          },
          {
            label: _.get(preData.langData, "PatronLegalRegistrationNo"),
            path: "PatronLegalRegistrationNo",
            visible: false,
            textAlign: "left",
          },
          {
            label: _.get(preData.langData, "PatronLegalRegisterDate"),
            path: "PatronLegalRegisterDate",
            visible: false,
          },
          {
            label: _.get(
              preData.langData,
              "PatronLegalRegisterPlaceStateDivision"
            ),
            path: "PatronLegalRegisterPlace",
            visible: false,
          },
          {
            label: _.get(preData.langData, "Phone"),
            path: "Phone",
            visible: false,
          },
          {
            label: _.get(preData.langData, "Email"),
            path: "Email",
            visible: false,
          },
          {
            label: _.get(preData.langData, "Cellphone"),
            path: "Cellphone",
            visible: false,
          },
          {
            label: _.get(preData.langData, "PatronAddressLangDescription"),
            path: "PatronAddressLangDescription",
            visible: false,
          },
          {
            label: _.get(preData.langData, "IsActive"),
            path: "PatronIsActive",
            type: "checkbox",
          },
        ],
        preData
      )
    );
  else if (patronType === "O" && columnsOther.length === 0)
    setColumnsOther(
      generateColumns(
        [
          {
            label: _.get(preData.langData, "PatronOtherLangDescription"),
            path: "PatronOtherLangDescription",
          },
          {
            label: _.get(preData.langData, "PatronCode"),
            path: "PatronCode",
            cellAlign: "center",
          },
          {
            label: _.get(preData.langData, "IsActive"),
            path: "PatronIsActive",
            type: "checkbox",
          },
        ],
        preData
      )
    );

  const handleAddClick = () => {
    if (editData) setEditData(null);
    setShowInputForm(true);
  };

  const handleEditClick = (row) => {
    setEditData(row);
    setShowInputForm(true);
  };

  return (
    <MainContainer>
      {showInputForm && inputForm}
      {showPatronView && (
        <Dialog
          width="50%"
          open={showPatronView}
          title={_.get(preData.langData, "PmsPatron_PatronView")}
          showDialogAction={false}
          maxWidth={false}
          onClose={() => setShowPatronView(false)}
        >
          <PatronView
            langData={preData.langData}
            patronProps={patronViewProps}
            isDirectionRTL={isDirectionRTL}
          />
        </Dialog>
      )}
      <div className="row">
        <div className="col-md-5 mx-md-2">
          <RadioList
            data={[
              {
                label: _.get(preData.langData, "PmsPatron_RealType"),
                value: "R",
              },
              {
                label: _.get(preData.langData, "PmsPatron_LegalType"),
                value: "L",
              },
              {
                label: _.get(preData.langData, "Other"),
                value: "O",
              },
            ]}
            isHorizontal
            onChange={setPatronType}
          />
        </div>
      </div>
      <MainContainerBody>
        <Table
          columns={
            patronType === "R"
              ? columnsReal
              : patronType === "L"
              ? columnsLegal
              : columnsOther
          }
          columnsStackSize={6}
          configs={preData.configModule}
          dataInfo={tableDataInfo}
          deleteExtraContent={
            patronType === "R"
              ? "PatronRealLangLastName"
              : patronType === "L"
              ? "PatronLegalLangDescription"
              : "PatronOtherLangDescription"
          }
          deleteService={PatronDelete}
          deleteTitle={_.get(preData.langData, "PmsPatronDelete")}
          hasColumnSelect={true}
          idFieldName="PatronId"
          isLoading={retrieveIsLoading}
          moduleCodeString={
            patronType === "R"
              ? "PmsPatronReal"
              : patronType === "L"
              ? "PmsPatronLegal"
              : "PmsPatronOther"
          }
          onAddButtonClick={handleAddClick}
          onEditButtonClick={handleEditClick}
          onPaginationChange={setPagination}
          refreshInfo={tableRefreshInfo}
          showRowFilter
          title={_.get(preData.langData, "PmsPatronRetrieve")}
        />
      </MainContainerBody>
    </MainContainer>
  );
}
