import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { PatronGroupTypeSystemRetrieve } from "Jarvis/JarvisSystems/Pms/Services/PatronGroupTypeService";

export default function PatronGroupTypeSystemSelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={PatronGroupTypeSystemRetrieve}
      serviceParams={{
        ...serviceParams,
      }}
      displayFieldName="PatronGroupTypeSystemLangName"
      valueFieldName="PatrongroupTypeSystemId"
      {...rest}
    />
  );
}
