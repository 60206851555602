/*------------
Version: 1.7
------------*/
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Box, Typography as MuiTypography } from "@material-ui/core";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import Tooltip from "../Tooltip";

export default function Typography({
  align,
  className,
  component,
  color,
  disabled,
  dir,
  display,
  fontSize,
  fontWeight,
  gutterBottom,
  isDigit,
  italic,
  label,
  noWrap,
  tooltip,
  variant,
  ...rest
}) {
  const { language } = useSelector((state) => state.settings);
  const typographyProps = {
    align,
    color: disabled ? "textSecondary" : color,
    component,
    display,
    noWrap,
    variant,
    gutterBottom,
    ...rest,
  };

  return (
    <MuiTypography
      className={clsx(
        isDigit ? "Jarvis-Font-default" : language?.JarvisFontClass,
        isNullOrWhiteSpace(variant) &&
          `Jarvis-Font-Size-${isDigit ? fontSize : fontSize - 1}rem`,
        "jarvisLabel"
      )}
      {...typographyProps}
    >
      <Box
        className={className}
        dir={dir}
        component={component}
        fontWeight={`fontWeight${fontWeight}`}
        fontStyle={italic ? "italic" : "normal"}
      >
        {!tooltip ? (
          label
        ) : (
          <Tooltip label={tooltip}>
            <span>{label}</span>
          </Tooltip>
        )}
      </Box>
    </MuiTypography>
  );
}

Typography.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right", "justify", "inherit"]),
  color: PropTypes.oneOf([
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "error",
  ]),
  dir: PropTypes.oneOf(["ltr", "rtl"]),
  display: PropTypes.oneOf(["initial", "block", "inline"]),
  fontSize: PropTypes.number,
  fontWeight: PropTypes.oneOf(["Light", "Regular", "Medium", "Bold"]),
  gutterBottom: PropTypes.bool,
  isDigit: PropTypes.bool,
  italic: PropTypes.bool,
  noWrap: PropTypes.bool,
  showTooltip: PropTypes.bool,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "srOnly",
    "inherit",
  ]),
};
Typography.defaultProps = {
  align: "inherit",
  component: "span",
  fontSize: 9,
  fontWeight: "Regular",
  gutterBottom: false,
  isDigit: false,
  italic: false,
  noWrap: false,
};
