import React, { useState, Fragment } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import Button from "Jarvis/JarvisComponents/Button";
import Checkbox from "Jarvis/JarvisComponents/Checkbox";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import ColorPicker from "Jarvis/JarvisComponents/ColorPicker";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Select from "Jarvis/JarvisComponents/Select";
import TextField from "Jarvis/JarvisComponents/TextField";
import { MenuRetrieve } from "Jarvis/JarvisSystems/Pub/Services/MenuService";
import ModuleSelect from "../CustomSelect/ModuleSelect";
import PublicCodeSelect from "../CustomSelect/PublicCodeSelect";

export default function InputForm({
  editData,
  isLoading,
  langData,
  onClose,
  onSubmitParent,
  parent,
  selectedSystem,
  selectedSubSystem,
  showInputForm,
}) {
  const { control, errors, setValue, getValues, trigger } = useForm();
  const [iconCode, setIconCode] = useState(_.get(editData, "MenuIcon") || null);
  const [iconColor, setIconColor] = useState(
    _.get(editData, "MenuIconColor") || null
  );
  const [reset, setReset] = useState(false);
  const isEditMode = editData ? true : false;

  const onSubmit = async (resetForm) => {
    const valid = await trigger(["MenuLangDescription"]);
    if (valid) {
      let data = getValues({ nest: true });
      let extraData = {};
      let result = {};
      //***************
      if (editData) {
        _.set(result, "MenuIdParent", _.get(data.MenuParent, "MenuId"));
        _.set(result, "VTParent", _.get(data.MenuParent, "VT"));
      } else {
        _.set(result, "MenuIdParent", _.get(parent, "MenuId"));
        _.set(result, "VTParent", _.get(parent, "VT"));
      }
      //------- SubSystem --------
      if (selectedSystem) {
        _.set(result, "SystemId", _.get(selectedSystem, "SystemId"));
        _.set(result, "VTSystemId", _.get(selectedSystem, "VT"));
      }
      //------- SubSystem --------
      if (selectedSubSystem) {
        _.set(result, "SubSystemId", _.get(selectedSubSystem, "SubSystemId"));
        _.set(result, "VTSubSystemId", _.get(selectedSubSystem, "VT"));
      }

      _.set(result, "MenuLangDescription", _.get(data, "MenuLangDescription"));
      _.set(result, "MenuIsActive", _.get(data, "MenuIsActive"));
      _.set(result, "MenuCode", _.get(data, "MenuCode"));
      _.set(result, "MenuExternalUrl", _.get(data, "MenuExternalUrl"));
      _.set(result, "MenuIcon", _.get(data, "MenuIcon"));
      _.set(result, "MenuIconColor", _.get(data, "MenuIconColor"));
      _.set(result, "MenuIsQuickAccess", _.get(data, "MenuIsQuickAccess"));
      _.set(result, "MenuCodeMustBeShown", _.get(data, "MenuCodeMustBeShown"));

      //------ Module ---------
      if (_.get(data, "Module")) {
        _.set(result, "ModuleId", _.get(data, "Module.ModuleId"));
        _.set(
          extraData,
          "ModuleLangDescription",
          _.get(data, "Module.ModuleLangDescription")
        );
        _.set(result, "VTModuleId", _.get(data, "Module.VT"));
      }
      //------ Platform ---------
      if (_.get(data, "Platform")) {
        _.set(result, "PlatformId", _.get(data, "Platform.PublicCodeId"));
        _.set(
          extraData,
          "PlatformLangDescription",
          _.get(data, "Platform.PublicCodeLangDescription")
        );
        _.set(result, "VTPlatformId", _.get(data, "Platform.VT"));
      }

      const { isSuccessful } = await onSubmitParent(result, extraData);
      if (isSuccessful) {
        if (resetForm) clearForm();
        else onClose();
      }
    }
  };

  const clearForm = () => {
    setValue("MenuLangDescription", "");
    setValue("MenuIcon", "");
    setValue("MenuIconColor", "");
    setValue("MenuExternalUrl", "");
    setValue("MenuCode", "");
    setValue("MenuCodeMustBeShown", false);
    setValue("MenuIsActive", true);
    setValue("MenuIsQuickAccess", false);
    setValue("Module", null);
    setValue("Platform", null);
    const input = document.getElementsByName("MenuLangDescription")[0];
    input.focus();
  };

  const handleSaveAndNew = (e) => {
    e.preventDefault();
    setReset(true);
    onSubmit(true);
  };

  const handleSaveAndClose = (e) => {
    e.preventDefault();
    setReset(false);
    onSubmit(false);
  };

  const handleMenuIconColorBlur = (e) => {
    setIconColor(e.target.value);
  };

  const handleMenuIconChange = (e) => {
    setIconCode(e.target.value);
  };

  const dialogTitle = () => {
    let newDialogTitle = isEditMode
      ? _.get(langData, "PubMenuUpdate")
      : _.get(langData, "PubMenuCreate");

    if (parent)
      newDialogTitle =
        newDialogTitle + ` - ${_.get(parent, "MenuLangDescription")}`;

    return _.trim(newDialogTitle, " - ");
  };

  return (
    <Dialog
      width="50%"
      disableBackdropClick
      open={showInputForm}
      title={dialogTitle()}
      onClose={onClose}
      showDialogAction={false}
      maxWidth={false}
    >
      <form
        className="border border-info py-3 mb-2 px-4"
        noValidate
        onSubmit={isEditMode ? handleSaveAndClose : handleSaveAndNew}
      >
        {isEditMode && !editData ? (
          <div className="container">
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            {isEditMode && (
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Select
                    label={_.get(langData, "PubMenu_MenuParentLabel")}
                    control={control}
                    name="MenuParent"
                    service={MenuRetrieve}
                    serviceParams={{
                      systemId: _.get(selectedSystem, "SystemId"),
                      VTSystemId: _.get(selectedSystem, "VT"),
                      subSystemId: _.get(selectedSubSystem, "SubSystemId"),
                      VTSubSystemId: _.get(selectedSubSystem, "VT"),
                      hierarchyShowMode: "Table",
                      isDeepSearchNeeded: true,
                      retrieveAsList: true,
                      exceptedMenuId: _.get(editData, "MenuId"),
                    }}
                    displayFieldName="MenuLangDescription"
                    valueFieldName="MenuId"
                    displayItem={(item) => item.FullPath}
                    searchFieldNames={["MenuLangDescription", "MenuCode"]}
                    value={{
                      MenuId: _.get(editData.parent, "MenuId"),
                      VT: _.get(editData.parent, "VT"),
                      MenuLangDescription: _.get(
                        editData.parent,
                        "MenuLangDescription"
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row mt-3 d-flex">
              <div className="col-sm-12 col-md-6">
                <TextField
                  label={_.get(langData, "MenuLangDescription")}
                  control={control}
                  name="MenuLangDescription"
                  errors={errors}
                  required
                  rules={{
                    required: _.get(langData, "MenuLangDescription_IsNull"),
                  }}
                  value={_.get(editData, "MenuLangDescription")}
                  fullWidth
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-2">
                <Checkbox
                  label={_.get(langData, "IsActive")}
                  control={control}
                  name={"MenuIsActive"}
                  value={isEditMode ? _.get(editData, "MenuIsActive") : true}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-6">
                <ModuleSelect
                  label={_.get(langData, "PubModule")}
                  control={control}
                  name="Module"
                  serviceParams={{
                    systemId: _.get(selectedSystem, "SystemId"),
                    VTSystemId: _.get(selectedSystem, "VT"),
                    subSystemId: _.get(selectedSubSystem, "SubSystemId"),
                    VTSubSystemId: _.get(selectedSubSystem, "VT"),
                  }}
                  value={{
                    ModuleId: _.get(editData, "ModuleId"),
                    ModuleLangDescription: _.get(
                      editData,
                      "ModuleLangDescription"
                    ),
                    VT: _.get(editData, "VTModuleId"),
                  }}
                  fullWidth
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <TextField
                  label={_.get(langData, "MenuCode")}
                  control={control}
                  name="MenuCode"
                  value={_.get(editData, "MenuCode")}
                  textAlign="left"
                  fullWidth
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-6">
                <PublicCodeSelect
                  label={_.get(langData, "PubPlatform")}
                  control={control}
                  name="Platform"
                  code="PubPlatform"
                  value={{
                    PublicCodeId: _.get(editData, "PlatformId"),
                    PublicCodeLangDescription: _.get(
                      editData,
                      "PlatformLangDescription"
                    ),
                    VT: _.get(editData, "VTPlatformId"),
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <TextField
                  label={_.get(langData, "MenuExternalUrl")}
                  control={control}
                  name="MenuExternalUrl"
                  value={_.get(editData, "MenuExternalUrl")}
                  textAlign="left"
                  fullWidth
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-6">
                <ColorPicker
                  label={_.get(langData, "MenuIconColor")}
                  control={control}
                  name="MenuIconColor"
                  color={_.get(editData, "MenuIconColor")}
                  setValue={setValue}
                  onBlur={handleMenuIconColorBlur}
                  fullWidth
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <TextField
                  label={_.get(langData, "MenuIcon")}
                  control={control}
                  name="MenuIcon"
                  value={_.get(editData, "MenuIcon")}
                  textAlign="left"
                  showIcon
                  icon={
                    <span
                      className={`${iconCode} zmdi-hc-fw zmdi-hc-2x`}
                      style={{ color: iconColor }}
                    />
                  }
                  onChange={handleMenuIconChange}
                  fullWidth
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-6">
                <Checkbox
                  label={_.get(langData, "MenuCodeMustBeShown")}
                  control={control}
                  name={"MenuCodeMustBeShown"}
                  value={_.get(editData, "MenuCodeMustBeShown") || false}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <Checkbox
                  label={_.get(langData, "MenuIsQuickAccess")}
                  control={control}
                  name={"MenuIsQuickAccess"}
                  value={_.get(editData, "MenuIsQuickAccess") || false}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 d-flex justify-content-center align-items-center">
                {!isEditMode && (
                  <Button
                    label={_.get(langData, "SaveAndNew")}
                    variant="contained"
                    color="primary"
                    showLoading={isLoading && reset}
                    disabled={isLoading && !reset}
                    type="submit"
                    // onClick={handleSaveAndNew}
                  />
                )}
                <Button
                  className="mx-3"
                  label={_.get(langData, "SaveAndClose")}
                  variant="contained"
                  color="primary"
                  showLoading={isLoading && !reset}
                  disabled={isLoading && reset}
                  onClick={handleSaveAndClose}
                />
                <Button className="mx-3" mode="close" onClick={onClose} />
              </div>
            </div>
          </Fragment>
        )}
      </form>
    </Dialog>
  );
}
