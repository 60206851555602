import _ from "lodash";
import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  SWITCH_FINANCIALTERM,
} from "Jarvis/JarvisConstants/ActionTypes";
import { DARK_INDIGO } from "Jarvis/JarvisConstants/ThemeColors";
import { getCompany } from "Jarvis/JarvisServices/JarvisLocalStorageService";

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: DARK_INDIGO,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  language: {
    LangFlagIcon: "us",
    LangName: "English",
    LangKey: "En",
    LangDir: "LTR",
  },
  financialTermId: _.get(getCompany(), "financialTermId"),
};

const settings = (state = initialSettings, action) => {
  if (action === undefined) return state;
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case SWITCH_LANGUAGE:
      let stateTmp = action.payload ? action.payload : state;
      stateTmp.JarvisFontClass = "Jarvis-Font-" + (stateTmp.LangFontName ? stateTmp.LangFontName.toLowerCase() : "Default");
      localStorage.setItem("selectedLanguage", JSON.stringify(stateTmp));
      return {
        ...state,
        language: stateTmp,
        isDirectionRTL: stateTmp.LangDir === "RTL",
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload,
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };

    case SWITCH_FINANCIALTERM:
      return {
        ...state,
        financialTermId: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
