import React, { useState } from "react";
import {
  getDateString,
  ifNullOrWhiteSpace,
  isValidDate,
} from "Jarvis/JarvisServices/JarvisCommonService";
import DateFnsUtils from "@date-io/date-fns";
import { Controller } from "react-hook-form";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Typography from "../Typography";

const DatePickerGregorian = ({
  name,
  value,
  dateFormat,
  label,
  hint,
  hintColor,
  onChange,
  variant /*dialog, inline, static*/,
  control,
  errors,
  setValue,
  rules,
  inputVariant, //standard | outlined | filled
  placeholder,
  setTodayDate,
  ...rest
}) => {
  if (setTodayDate === true) value = getDateString();
  const [date, setDate] = useState(ifNullOrWhiteSpace(value, null));
  variant = ifNullOrWhiteSpace(variant, "inline");
  inputVariant = ifNullOrWhiteSpace(inputVariant, "outlined");
  dateFormat = ifNullOrWhiteSpace(dateFormat, "yyyy/MM/dd");
  placeholder = ifNullOrWhiteSpace(placeholder, dateFormat);

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return (
      <Typography
        fontSize={7}
        component={"span"}
        label={helperText}
        color={hintColor}
      />
    );
  };
  let commonProps = {
    name,
    variant,
    label,
    inputVariant,
    format: dateFormat,
    margin: "normal",
    autoOk: true,
    disableToolbar: false,
    error: errors && errors[name] ? true : false,
    helperText: renderHelperText(),
    placeholder,
    ...rest,
  };
  if (control) {
    return (
      <Controller
        name={name}
        defaultValue={date}
        control={control}
        rules={rules}
        render={({ value, name, onChange: onChangeController, onBlur }) => {
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={value}
                name={name}
                {...commonProps}
                onChange={(orginalDate, date) => {
                  if (isValidDate(date, dateFormat) === false) {
                    /*Do not change the value to null otherwise during typing if the data is invalid then the textfield will be cleared. I do not know why. Please let me know if you realized*/
                    const val = "";
                    setValue(name, val);
                    onChangeController(val);
                  } else {
                    if (onChange) onChange(date);
                    onChangeController(date);
                  }
                }}
                onBlur={(e) => {
                  if (isValidDate(e.target.value, dateFormat) === false) {
                    setValue(name, null);
                  }
                  onBlur(e);
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
      /* <KeyboardTimePicker
margin="normal"
id="time-picker"
label="Time picker"
KeyboardButtonProps={{
  "aria-label": "change time",
}}
/> */
    );
  } else {
    commonProps.onChange = (orginalDate, date) => {
      setDate(date);
      if (onChange)
        onChange(isValidDate(date, dateFormat) === false ? null : date);
    };
    commonProps.value = date;
    commonProps.onBlur = (e) => {
      if (isValidDate(e.target.value, dateFormat) === false) {
        setDate(null);
      }
    };
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker {...commonProps} placeholder={dateFormat} />
      </MuiPickersUtilsProvider>
    );
  }
};
export default DatePickerGregorian;
