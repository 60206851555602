import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export const getLangStyles = (langKey) => {
  const language = getLanguage() || {};
  const _langKey = ifNullOrWhiteSpace(langKey, language?.LangKey);
  let result = {
    fontNameClassName:
      "Jarvis-Font-" +
      (language.LangFontName ? language.LangFontName.toLowerCase() : "default"),
    textFieldSizeClassName: "",
    labelSizeClassName: "",
  };

  switch (_langKey?.toUpperCase()) {
    case "FA":
      result.textFieldSizeClassName = "j-font-size-9rem";
      result.labelSizeClassName = "j-font-size-8rem";
      break;
    case "AR":
      result.textFieldSizeClassName = "eeeeee";
      result.labelSizeClassName = 1.4;
      break;
    default:
      break;
  }
  return result;
};
