import React from "react";
import clsx from "clsx";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { ListSubheader } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Searchbox from "Jarvis/JarvisComponents/Searchbox";
import Typography from "../Typography";
import Tooltip from "../Tooltip";
import IconButton from "../IconButton";
import Button from "../Button";

export default function ListHeader({
  backgroundColor,
  checkboxList,
  customHeaderItems,
  hasRefresh,
  hasSearchbox,
  headerContentAlignCenter,
  headerText,
  onRefresh,
  onSearch,
  onSelectAllClick,
  PE,
  searchBoxText,
  setSearchBoxText,
  selectAll,
}) {
  const { control, setValue } = useForm();

  return (
    <ListSubheader
      style={
        backgroundColor
          ? {
              backgroundColor: backgroundColor,
            }
          : {}
      }
      className="border-bottom border-primary jarvisCheckboxListHeader"
    >
      <div
        className={
          headerContentAlignCenter
            ? "row align-items-center justify-content-center"
            : "row align-items-center"
        }
      >
        <Typography className="mx-2" label={headerText} display="inline" />
        {hasRefresh && (
          <IconButton
            className={clsx("searchBox", "jarvisLabel")}
            edge="end"
            onClick={onRefresh}
          >
            <Tooltip label={_.get(PE, "PubPublicElems.Refresh") || "Refresh"}>
              <RefreshIcon />
            </Tooltip>
          </IconButton>
        )}
        {checkboxList && !selectAll && (
          <IconButton
            className={clsx("searchBox", "jarvisLabel")}
            edge="end"
            onClick={() => onSelectAllClick(true)}
          >
            <Tooltip
              label={_.get(PE, "PubPublicElems.SelectAll") || "Select All"}
            >
              <SelectAllIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        {selectAll && (
          <IconButton
            className={clsx("searchBox", "jarvisLabel")}
            edge="end"
            onClick={() => onSelectAllClick(false)}
          >
            <Tooltip
              label={
                _.get(PE, "PubPublicElems.CancelSelect") || "Cancel Select"
              }
            >
              <CheckBoxOutlineBlankIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        {customHeaderItems?.length > 0 &&
          customHeaderItems.map((item) =>
            item.label ? (
              <Button
                key={item.name}
                label={item.label}
                endIcon={item.icon}
                tooltip={item.toolTip ?? ""}
                onClick={item.onClick}
                color={item.color}
              />
            ) : (
              <IconButton
                className={clsx("searchBox", "jarvisLabel")}
                key={item.name}
                edge="end"
                icon={item.icon}
                tooltip={item.toolTip ?? ""}
                onClick={item.onClick}
              />
            )
          )}
        {hasSearchbox && (
          <Searchbox
            className="mx-3 mt-2"
            clearSearch
            control={control}
            name="cblSearchbox"
            onSearch={onSearch}
            value={searchBoxText}
            setValue={setValue}
            width="190px"
            showSearchIcon
          />
        )}
      </div>
    </ListSubheader>
  );
}
