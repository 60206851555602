/*------------
Version: 4.3
------------*/
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import {
  List as MuiList,
  ListItem,
  Grid,
  FormHelperText,
  IconButton,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import {
  isNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import "./index.scss";
import Typography from "../Typography";
import Checkbox from "../Checkbox";
import ListHeader from "./ListHeader";
import Tooltip from "../Tooltip";
import { isBrowser } from "react-device-detect";

const defaultStateObject = {
  active: false,
  items: [],
  serviceParams: null,
  totalRecords: 0,
};

export default function List({
  checkboxList,
  control,
  customHeaderItems,
  customOperations,
  data,
  displayFieldName,
  fixHeight,
  hasBorder,
  hasDelete,
  hasHeader,
  hasRefresh,
  hasSearchbox,
  headerBackgroundColor,
  headerContentAlignCenter,
  headerText,
  height,
  hint,
  hintColor,
  idFieldName,
  isDense,
  isLoading,
  isTriple,
  name,
  numberOfColumns,
  onClick,
  onDelete,
  onItemIconClick,
  onRefresh,
  onSearch,
  pageSize,
  url,
  secondaryTextPath,
  secondaryTextDir,
  service,
  serviceParams,
  rules,
  size,
  value,
  valueFieldName,
  width,
}) {
  const [id, setId] = useState(null);
  const [itemsCached, setItemsCached] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const [searchBoxText, setSearchBoxText] = useState("");
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [selectAll, setSelectAll] = useState(false);
  const [showError, setShowError] = useState(false);
  const [stateObject, setStateObject] = useState(defaultStateObject);
  const [totalRecordsCached, setTotalRecordsCached] = useState(0);
  const [where, setWhere] = useState([]);

  const { darkTheme } = useSelector((state) => state.settings);
  const { PE } = useContext(GlobalContext);
  const isLoadingService =
    !stateObject.active ||
    !_.isEqual(serviceParams, stateObject.serviceParams) ||
    isLoading;

  let listStyle = {
    width: "100%",
    maxHeight: height,
    display: "block",
    overflow: "auto",
  };
  if (fixHeight) listStyle.minHeight = height;
  let col = 12;
  if (isBrowser) {
    if (numberOfColumns > 1) listStyle.maxWidth = width ?? "100%";
    else listStyle.maxWidth = width ?? "400px";
    if (isLoadingService) listStyle.overflow = "hidden";

    if (numberOfColumns > 0 && numberOfColumns < 12) col = 12 / numberOfColumns;
  }

  useEffect(() => {
    setId(`checkboxList-${uuid()}`);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isArray(data)) {
      setStateObject({
        active: true,
        items: data,
        totalRecords: data.length,
        serviceParams: serviceParams,
      });
      return;
    }
    if (!isLoadingService) {
      return undefined;
    }
    (async () => {
      let params = { pageIndex, pageSize, where };
      if (serviceParams) params = { ...params, ...serviceParams };
      const response = !isNullOrWhiteSpace(service)
        ? await service(params)
        : !isNullOrWhiteSpace(url)
        ? await JarvisHttpService.post({
            url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + url,
            data: { pageIndex, pageSize, where },
          })
        : null;

      if (response) {
        const { ErrorHandling, Data, TotalRecords } =
          _.get(response, "data") || response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          let itemsTemp = [];
          const newItems = Data ?? [];

          if (_.isEqual(serviceParams, stateObject.serviceParams))
            itemsTemp = [...stateObject.items, ...newItems];
          else itemsTemp = [...newItems];
          setStateObject((stateObject) => ({
            active: true,
            items: itemsTemp,
            totalRecords:
              where.length === 0 ? TotalRecords : stateObject.totalRecords,
            serviceParams: serviceParams,
          }));
          if (scrollTop && id && document.getElementById(id))
            document.getElementById(id).scrollTop = scrollTop;
        }
      }
    })();
  }, [isLoadingService, data, serviceParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollTop && id && document.getElementById(id)) {
      document.getElementById(id).scrollTop = scrollTop;
    }
  });

  const handleScroll = (e) => {
    if (stateObject.items.length !== stateObject.totalRecords) {
      const bottom =
        e.target.scrollHeight -
          Math.ceil(e.target.scrollTop) -
          e.target.clientHeight <=
        1;
      if (bottom) {
        if (pageIndex < Math.ceil(stateObject.totalRecords / pageSize)) {
          setStateObject((stateObject) => ({ ...stateObject, active: false }));
          setPageIndex(pageIndex + 1);
          setScrollTop(e.target.scrollTop);
        }
      }
    }
  };

  const getValue = (item) => {
    if (valueFieldName) return _.get(item, valueFieldName);

    const index = selectedItems.findIndex(
      (selectedItem) => selectedItem[idFieldName] === item[idFieldName]
    );
    if (index !== -1) {
      return _.get(item, "value") || true;
    }
    if (!isTriple) return false;
  };

  const handleChange = (item, value, onChangeCtrl) => {
    let selectedItemsTemp = [...selectedItems];
    const index = selectedItemsTemp.findIndex(
      (selectedItem) => selectedItem[idFieldName] === item[idFieldName]
    );

    let itemTemp = { ...item };
    if (checkboxList) {
      if (valueFieldName) {
        itemTemp[valueFieldName] = value;
      } else if (isTriple) itemTemp.checkStatus = value;

      if (isTriple) {
        if (value === true || value === false)
          if (index === -1) selectedItemsTemp.push(itemTemp);
          else selectedItemsTemp.splice(index, 1, itemTemp);
        else selectedItemsTemp.splice(index, 1);
      } else {
        if (valueFieldName === undefined) {
          if (value) selectedItemsTemp.push(itemTemp);
          else selectedItemsTemp.splice(index, 1);
        } else {
          if (index === -1) selectedItemsTemp.push(itemTemp);
          else selectedItemsTemp.splice(index, 1, itemTemp);
        }
      }
    }

    onChangeCtrl(selectedItemsTemp);
    const indexMain = stateObject.items.findIndex(
      (itemMain) => itemMain[idFieldName] === item[idFieldName]
    );
    let itemsTemp = [...stateObject.items];
    itemsTemp.splice(indexMain, 1, itemTemp);
    setStateObject((stateObject) => ({ ...stateObject, items: itemsTemp }));
    setSelectedItems(selectedItemsTemp);
    setScrollTop(document.getElementById(id).scrollTop);
  };

  const handleDeleteItem = (item) => {
    if (data) {
      if (onDelete) onDelete(item);
    } else {
      const index = stateObject.items.findIndex(
        (itemMain) => itemMain[idFieldName] === item[idFieldName]
      );
      let itemsTemp = [...stateObject.items];
      itemsTemp.splice(index, 1);
      setStateObject((stateObject) => ({ ...stateObject, items: itemsTemp }));
    }
  };

  const handleRefresh = () => {
    if (onRefresh) onRefresh();
    setStateObject((stateObject) => ({
      ...stateObject,
      active: false,
      items: [],
    }));
    setWhere([]);
    setPageIndex(1);
    setScrollTop(0);
    setSearchBoxText("");
  };

  const handleSearch = (searchText) => {
    if (onSearch) {
      setSearchBoxText(searchText);
      onSearch(searchText);
    } else {
      if (searchText) {
        setSearchBoxText(searchText);
        if (!_.isEmpty(data)) {
          const searchResult = stateObject.items.filter((item) =>
            item[displayFieldName]
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
          if (itemsCached.length === 0) {
            setItemsCached([...stateObject.items]);
            setTotalRecordsCached(stateObject.totalRecords);
          }
          setStateObject((stateObject) => ({
            ...stateObject,
            items: searchResult,
          }));
        } else {
          let FieldName = displayFieldName;
          let DataType = "STRING";
          let Operator = "LIKE";
          let Value = searchText;
          setWhere([{ FieldName, DataType, Operator, Value }]);
          if (itemsCached.length === 0) {
            setItemsCached([...stateObject.items]);
            setTotalRecordsCached(stateObject.totalRecords);
          }
          setStateObject((stateObject) => ({
            ...stateObject,
            active: false,
            items: [],
          }));
        }
      } else {
        setSearchBoxText("");
        setStateObject((stateObject) => ({
          ...stateObject,
          active: true,
          items: [...itemsCached],
          totalRecords: totalRecordsCached,
        }));
        setItemsCached([]);
        setWhere([]);
        setScrollTop(0);
      }

      setPageIndex(1);
    }
  };

  const handleSelectAll = (selectAll) => {
    if (selectAll) {
      setSelectedItems([...stateObject.items]);
    } else {
      setSelectedItems([]);
    }
    setSelectAll(selectAll);
  };

  let checkboxListHeaderProps = {
    backgroundColor: darkTheme ? null : headerBackgroundColor,
    checkboxList,
    headerText,
    hasRefresh: hasRefresh,
    onRefresh: handleRefresh,
    onSelectAllClick: handleSelectAll,
    PE,
    selectAll,
  };

  if (hasSearchbox) {
    checkboxListHeaderProps.searchBoxText = searchBoxText;
    checkboxListHeaderProps.setSearchBoxText = setSearchBoxText;
    checkboxListHeaderProps.onSearch = handleSearch;
  }

  const CustomList = ({ onChange }) => (
    <React.Fragment>
      <MuiList
        className={
          hasBorder && `border border-${showError ? "danger" : "primary"}`
        }
        style={listStyle}
        onScroll={handleScroll}
        id={id}
        subheader={
          hasHeader && (
            <ListHeader
              customHeaderItems={customHeaderItems}
              hasSearchbox={hasSearchbox}
              headerContentAlignCenter={headerContentAlignCenter}
              {...checkboxListHeaderProps}
            />
          )
        }
      >
        <div style={{ padding: hasHeader ? 30 : "0 30px" }}>
          <Grid container>
            {stateObject.items.length === 0 ? (
              <Grid item xs={col} key={"loading"}>
                <ListItem dense={isDense}>
                  <Typography
                    label={
                      isLoadingService
                        ? _.get(PE, "PubPublicElems.Loading") || "Loading..."
                        : where.length > 0 &&
                          (_.get(PE, "PubPublicElems.NoItems") ||
                            "No items found")
                    }
                  />
                </ListItem>
              </Grid>
            ) : (
              stateObject.items.map((item) => (
                <Grid item sm={12} md={col} key={item[idFieldName]}>
                  <ListItem
                    dense={isDense}
                    onClick={onClick ? () => onClick(item) : undefined}
                  >
                    {!checkboxList && item.icon && (
                      <ListItemIcon
                        className={onItemIconClick ? "cursor-pointer" : ""}
                        onClick={
                          onItemIconClick
                            ? () => onItemIconClick(item)
                            : undefined
                        }
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    {checkboxList ? (
                      <Checkbox
                        edge="start"
                        disableRipple
                        size={size}
                        isTriple={isTriple}
                        value={getValue(item)}
                        onChange={(_, value) => {
                          handleChange(item, value, onChange);
                        }}
                        label={item[displayFieldName]}
                      />
                    ) : (
                      <ListItemText
                        primary={<Typography label={item[displayFieldName]} />}
                        secondary={
                          secondaryTextPath && (
                            <Typography
                              label={_.get(item, secondaryTextPath)}
                              dir={secondaryTextDir}
                            />
                          )
                        }
                      />
                    )}
                    {!checkboxList && (
                      <ListItemSecondaryAction>
                        {customOperations?.length > 0 &&
                          customOperations.map(
                            (op) =>
                              (_.isNil(op.displayIf) || op.displayIf(item)) && (
                                <IconButton
                                  className="jarvisIconHover"
                                  key={op.name}
                                  aria-label={op.name}
                                  size="small"
                                  onClick={
                                    op.onClick
                                      ? () => op.onClick(item)
                                      : undefined
                                  }
                                >
                                  {op.toolTip ? (
                                    <Tooltip label={op.toolTip}>
                                      {_.isFunction(op.icon)
                                        ? op.icon(item)
                                        : op.icon}
                                    </Tooltip>
                                  ) : _.isFunction(op.icon) ? (
                                    op.icon(item)
                                  ) : (
                                    op.icon
                                  )}
                                </IconButton>
                              )
                          )}
                        {hasDelete && (
                          <IconButton
                            className="jarvisIconHover"
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={() => handleDeleteItem(item)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Grid>
              ))
            )}
          </Grid>
        </div>
        {isLoadingService && (
          <div className="progress-bar">
            <CircularProgress />
          </div>
        )}
      </MuiList>
      {(showError || hint) && (
        <FormHelperText>
          <Typography
            className="m-2"
            label={showError ? _.get(rules, "required") : hint}
            fontSize={7}
            color={showError ? "error" : hintColor || "textSecondary"}
          />
        </FormHelperText>
      )}
    </React.Fragment>
  );

  return (
    <Controller
      control={control}
      render={({ onChange, value, name }) => {
        return (
          <CustomList
            onChange={(data) => {
              return onChange(data);
            }}
            value={value}
            name={name}
          />
        );
      }}
      name={name}
      rules={{
        validate: (data) => {
          if (_.get(rules, "required") && data.length === 0) {
            setShowError(true);
            return false;
          } else {
            if (showError) setShowError(false);
            return true;
          }
        },
      }}
      defaultValue={value ?? []}
    />
  );
}

List.propTypes = {
  checkboxList: PropTypes.bool,
  customHeaderItems: PropTypes.array, // [{name: "", icon: </>, toolTip: "", onClick: ()=>{}}]
  customOperations: PropTypes.array, // [{name: "", icon: </>, toolTip: "", onClick: ()=>{}, displayIf:()=>{}}]
  data: PropTypes.array,
  displayFieldName: PropTypes.string,
  fixHeight: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasHeader: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasRefresh: PropTypes.bool,
  hasSearchbox: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  headerContentAlignCenter: PropTypes.bool,
  headerText: PropTypes.string,
  height: PropTypes.string,
  hint: PropTypes.string,
  hintColor: PropTypes.string,
  idFieldName: PropTypes.string,
  isDense: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTriple: PropTypes.bool,
  name: PropTypes.string,
  numberOfColumns: PropTypes.number,
  onItemIconClick: PropTypes.func,
  onRefresh: PropTypes.func,
  onSearch: PropTypes.func,
  pageSize: PropTypes.number,
  secondaryTextDir: PropTypes.oneOf(["ltr", "rtl"]),
  secondaryTextPath: PropTypes.string,
  service: PropTypes.func,
  serviceParams: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  url: PropTypes.string,
  valueFieldName: PropTypes.string,
  width: PropTypes.string,
};

List.defaultProps = {
  checkboxList: false,
  fixHeight: false,
  hasBorder: true,
  hasDelete: true,
  hasHeader: true,
  hasRefresh: true,
  hasSearchbox: true,
  headerBackgroundColor: "#F4F4F7",
  headerContentAlignCenter: false,
  height: "300px",
  isDense: true,
  isLoading: false,
  isTriple: false,
  numberOfColumns: 2,
  pageSize: 25,
  size: "small",
};
