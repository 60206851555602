/*------------
Version: 1.5
------------*/
import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import clsx from "clsx";
import "react-toastify/dist/ReactToastify.css";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";

export function NotificationContainer(props) {
  return <ToastContainer {...props} />;
}

let notificationStyle = {
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  width: "100%",
  height: "100%",
  color: "black",
};

export const dismissAll = () => toast.dismiss();

export const notify = ({ className, message, timeout, type, fontSize, extraMessage, containerId, ...rest }) => {
  const language = getLanguage();
  const notificationClassName = clsx(className, language?.JarvisFontClass, `Jarvis-Font-Size-${fontSize || 8}rem`);
  const _containerId = ifNullOrWhiteSpace(containerId, "normalToastContainerId");
  switch (type.toLowerCase()) {
    case "info":
      toast.info(<div style={notificationStyle}>{message}</div>, {
        className: notificationClassName,
        autoClose: timeout,
        containerId: _containerId,
        ...rest,
      });
      break;
    case "success":
      toast.success(<div style={notificationStyle}>{message}</div>, {
        className: notificationClassName,
        autoClose: timeout,
        containerId: _containerId,
        ...rest,
      });
      break;
    case "warning":
      toast.warn(<div style={notificationStyle}>{message}</div>, {
        className: notificationClassName,
        autoClose: timeout,
        containerId: _containerId,
        ...rest,
      });
      break;
    case "error":
    case "critical":
      toast.error(
        <div style={{ notificationStyle }}>
          {message}
          {extraMessage ? <MoreInfo>{extraMessage}</MoreInfo> : ""}
        </div>,
        {
          className: notificationClassName,
          autoClose: timeout,
          containerId: _containerId,
          ...rest,
        }
      );
      break;
    default:
      toast(<div style={notificationStyle}>{message}</div>, {
        className: notificationClassName,
        autoClose: timeout,
        containerId: _containerId,
        ...rest,
      });
      break;
  }
};

const MoreInfo = ({ children }) => {
  const { PE } = useContext(GlobalContext) ?? {};
  const [hide, setHide] = useState(true);

  const handleMouseEnter = () => {
    if (hide) setHide(false);
  };

  return (
    <div>
      <div className="my-2" onMouseEnter={handleMouseEnter}>
        {`${_.get(PE, "PubPublicElems.More") ?? "More"}...`}
      </div>
      <div style={{ wordBreak: "break-all" }} className={hide ? "d-none" : ""}>
        {children}
      </div>
    </div>
  );
};
