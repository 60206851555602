import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import { switchLanguage } from "Jarvis/JarvisActions/Setting";
import LanguageSelector from "./LanguageSelector";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { jarvisComponentMustBeExcluded } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import LanguageContext from "Jarvis/JarvisContexts/LanguageContext";

export default function LanguageSwitcher(props) {
  if (jarvisComponentMustBeExcluded("LanguageSwitcher")) return null;
  return <Index {...props} />;
}

function Index(props) {
  const langContext = useContext(LanguageContext) ?? [];
  const [langs, setLangs] = useState(langContext);
  const [setAnchorEl] = useState(undefined);
  const [langSwitcher, setLangSwitcher] = useState(false);
  const language = useSelector((state) => state.settings.language);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (_.isEmpty(langs)) {
        const { data } = await JarvisHttpService.post({
          url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Lang/RetrievePublic",
        });
        if (_.get(data, "ErrorHandling.IsSuccessful")) {
          setLangs(data.Data);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.isEmpty(langs) || langs.length === 1) return null;

  const onLangSwitcherSelect = (event) => {
    setLangSwitcher(!langSwitcher);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setLangSwitcher(false);
  };

  const onChangeLanguage = () => {
    window.location.reload();
  };

  const { style, withBorder } = props;
  var borderStyle = {};
  if (withBorder) borderStyle = { borderRadius: "100%", border: "1px white solid" };

  return (
    <ul className={`${language.JarvisFontClass} header-notifications list-inline ml-auto`} style={style}>
      <li className="list-inline-item">
        <Dropdown
          style={borderStyle}
          className="quick-menu"
          isOpen={langSwitcher}
          toggle={(e) => {
            onLangSwitcherSelect.bind(e);
          }}
        >
          <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
            <IconButton className="icon-btn">
              <i className={`flag flag-24 flag-${language.LangFlagIcon}`} />
            </IconButton>
          </DropdownToggle>
          <DropdownMenu right className="w-50">
            <LanguageSelector
              onChangeLanguage={props.onChangeLanguage ? props.onChangeLanguage : onChangeLanguage}
              switchLanguage={(lang) => dispatch(switchLanguage(lang))}
              handleRequestClose={handleRequestClose}
              languageData={langs}
            />
          </DropdownMenu>
        </Dropdown>
      </li>
    </ul>
  );
}
