/*------------
Version: 1.1
------------*/
import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import { LinearProgress } from "@material-ui/core";
import Typography from "../Typography";

export default function Wait({ showProgressOnly }) {
  const { PE } = useContext(GlobalContext) ?? {};
  return (
    <div>
      <LinearProgress />
      {_.isEmpty(PE) ? (
        !showProgressOnly && "Please Wait..."
      ) : (
        <Typography
          className="m-2"
          color="textSecondary"
          label={
            !showProgressOnly &&
            (_.get(PE, "PubPublicElems.Wait") || "Please Wait...")
          }
        />
      )}
    </div>
  );
}

Wait.propTypes = {
  showProgressOnly: PropTypes.bool,
};
Wait.defaultProps = {
  showProgressOnly: false,
};
