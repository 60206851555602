import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.css";
import "bootstrap/js/dist/dropdown";

const rootEl = document.getElementById("root");

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const Prerequisites = require("Jarvis/JarvisApp/Prerequisites").default;
  ReactDOM.render(<Prerequisites />, rootEl);
};

if (module.hot) {
  module.hot.accept("Jarvis/JarvisApp/Prerequisites", () => {
    const Prerequisites = require("Jarvis/JarvisApp/Prerequisites").default;
    render(<Prerequisites />, rootEl);
  });
}

render();
