import React, { useContext } from "react";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
export default function Footer({ language }) {
  const data = useContext(GlobalContext);
  const jarvisVer = localStorage.getItem("jarvisVer");
  return (
    <footer className="app-footer">
      <span className={`d-inline-block ${language.JarvisFontClass}`}>
        {data.Config?.CompanyName} &copy; {new Date().getFullYear()}
        {jarvisVer ? `, ver: ${jarvisVer}` : ""}
      </span>
      <div id="footerVersionAlert"></div>      
    </footer>
  );
}
