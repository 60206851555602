import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TreeGridTopPanel from "./TreeGridTopPanel";

export default function TreeGridTopPanelMenu({
  topPanelProps,
  isDirectionRTL,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <MenuIcon
        style={{
          float: isDirectionRTL ? "right" : "left",
          position: "relative",
        }}
        className="topPanel"
        fontSize="small"
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: isDirectionRTL ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: isDirectionRTL ? "right" : "left",
        }}
        onClose={handleClose}
      >
        <TreeGridTopPanel onClose={handleClose} {...topPanelProps} />
      </Popover>
    </React.Fragment>
  );
}
