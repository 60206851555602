import React, { useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SwitchCompanyFinancialTerm } from "Jarvis/JarvisSystems/Pub/Services/CompanyService";
import { getCompany } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "Jarvis/JarvisConstants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import { NotificationContainer } from "Jarvis/JarvisComponents/Notification/index";
import { RetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/PageElementService";
import Wait from "Jarvis/JarvisComponents/Wait";
import {
  getSubSystem,
  setSubSystem,
} from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { LoadMenuByPermission } from "Jarvis/JarvisServices/JarvisWebCoreService";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import { GenerateRoutes } from "Jarvis/JarvisSystems/Pub/Services/MenuService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import ColorOption from "Jarvis/JarvisComponents/Containers/Customizer/ColorOption";
import TopNav from "Jarvis/JarvisComponents/Containers/TopNav";
import Header from "Jarvis/JarvisComponents/Containers/Header";
import Footer from "Jarvis/JarvisComponents/Containers/Footer";
import SideNav from "Jarvis/JarvisComponents/Containers/SideNav";

function App(props) {
  const {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    language,
    isDirectionRTL,
  } = props;
  const [stateObject, setStateObject] = useState({
    menuData: null,
    response: null,
    showUserSettings: null,
    selectedSubSystem: null,
  });

  useEffect(() => {
    async function callApi() {
      const data = await RetrievePublic({
        moduleCodeStrings:
          "PubPublicElems,SecUser{!,^SecUser_UserProfile,^SecUser_UserSetting,^SecUserChangePassword,^TwoFactorAuth}",
        configs: "CompanyName",
        withSeparateModuleCodeString: true,
      });
      let stateObj = {
        response: data,
      };
      const subSystem = getSubSystem();
      if (
        !isNullOrWhiteSpace(subSystem?.subSystemId) &&
        !isNullOrWhiteSpace(subSystem?.vtSubSystemId) &&
        !isNullOrWhiteSpace(subSystem?.subSystemKey)
      ) {
        setSubSystem(subSystem);
        stateObj.selectedSubSystem = subSystem;
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?SSId=${subSystem.subSystemId}&VTSSId=${subSystem.vtSubSystemId}&SSKey=${subSystem.subSystemKey}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
      setStateObject(stateObj);
    }
    callApi();
  }, [language.LangId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isNullOrWhiteSpace(stateObject.selectedSubSystem?.subSystemKey)) return;
    (async () => {
      const res = await LoadMenuByPermission({
        subSystemKey: stateObject.selectedSubSystem.subSystemKey,
      });
      if (res) {
        const { ErrorHandling, Data } = _.get(res, "data") ?? res;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          let stateObj = { ...stateObject };
          stateObj.menuData = Data;
          localStorage.removeItem("isReloadPageNeeded");
          setStateObject(stateObj);
          const selectedCompany = getCompany();
          if (_.get(selectedCompany, "FinancialTermId"))
            await SwitchCompanyFinancialTerm(selectedCompany);
        } else {
          localStorage.setItem("isReloadPageNeeded", true);
        }
      }
    })();
  }, [stateObject.selectedSubSystem]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stateObject.response)
    return (
      <div className="app-main-container">
        <Wait />
      </div>
    );

  if (!_.get(stateObject.response, "ErrorHandling.IsSuccessful"))
    return (
      <pre>{JSON.stringify(stateObject.response.ErrorHandling, null, 2)}</pre>
    );

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }
  const handleClickSubSystemSelection = (subSystemSelected) => {
    /*eslint-disable eqeqeq*/
    if (
      stateObject.selectedSubSystem?.subSystemId !=
      subSystemSelected.subSystemId
    ) {
      const env = JarvisBaseSettingsService.getEnvironment();
      const newurl = `${window.location.protocol}//${window.location.host}/${env}?SSId=${subSystemSelected.subSystemId}&VTSSId=${subSystemSelected.vtSubSystemId}&SSKey=${subSystemSelected.subSystemKey}`;
      window.history.pushState({ path: newurl }, "", newurl);
      let stateObj = { ...stateObject };
      stateObj.selectedSubSystem = subSystemSelected;
      stateObj.menuData = null;
      setStateObject(stateObj);
    }
  };

  return (
    <GlobalContext.Provider value={stateObject.response.Data}>
      <div className={clsx("app-container", drawerStyle)}>
        <SideNav
          menuData={stateObject.menuData}
          onShowUserSettings={setStateObject}
        />
        <div className="app-main-container">
          <div
            className={clsx(
              "app-header",
              navigationStyle === HORIZONTAL_NAVIGATION &&
                "app-header-horizontal"
            )}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header
              handleClickSubSystemSelection={handleClickSubSystemSelection}
            />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <GenerateRoutes menuData={stateObject.menuData} />
            </div>
            <Footer {...props} />
          </main>
        </div>
        <ColorOption
          open={stateObject.showUserSettings}
          onClose={() =>
            setStateObject((stateObject) => ({
              ...stateObject,
              showUserSettings: false,
            }))
          }
        />
      </div>
      <NotificationContainer
        position={isDirectionRTL ? "bottom-right" : "bottom-left"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={isDirectionRTL}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        containerId="normalToastContainerId"
        enableMultiContainer={true}
      />
      <NotificationContainer
        position={isDirectionRTL ? "top-right" : "top-left"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={isDirectionRTL}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        containerId="fullscreenToastContainer"
        enableMultiContainer={true}
        style={{
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          right: "0",
        }}
      />
    </GlobalContext.Provider>
  );
}

const mapStateToProps = ({ settings }) => {
  const {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    language,
    isDirectionRTL,
  } = settings;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    language,
    isDirectionRTL,
  };
};
export default withRouter(connect(mapStateToProps)(App));
