import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";

export const JobPositionTypeRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  node,
  retrieveAsList,
  hierarchyShowMode,
  latestNodeLevel,
  isDeepSearchNeeded,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pms/JobPositionType/RetrieveAsList"
      : "Pms/JobPositionType/Retrieve";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    NodeId: node?.["JobPositionTypeId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      hierarchy,
      orderBy,
      orderBys,
      orderMethod,
      where,
    },
  });
  return data;
};
export const JobPositionTypeCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/JobPositionType/Create",
    data: _data,
  });
  return data;
};

export const JobPositionTypeUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/JobPositionType/Update",
    data: _data,
  });
  return data;
};

export const JobPositionTypeDelete = async ({
  JobPositionTypeId,
  VT,
  LastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/JobPositionType/Delete",
    data: { JobPositionTypeId, VT, LastUpdateDateTime },
  });
  return data;
};
