import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";

export const PatronCellphoneNotVerifiedRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  patronId,
  VT,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronCellphone/RetrieveNotVerified",
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      orderBy,
      orderBys,
      orderMethod,
      where,
      patronId,
      VT,
    },
  });
  return data;
};

export const SendVerificationCode = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronCellphone/SendVerificationCode",
    data: _data,
  });
  return data;
};

export const CellphoneVerify = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/PatronCellphone/CellphoneVerify",
    data: _data,
  });
  return data;
};
