import React, { useEffect } from "react";
import _ from "lodash";
import CustomScrollbars from "Jarvis/JarvisComponents/Scrollbars/CustomScrollbars";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import { GenerateMenu } from "Jarvis/JarvisSystems/Pub/Services/MenuService";

export default function SidenavContent({ data, history }) {
  const handleClickLI = (e) => {
    const elem = e.currentTarget;
    e.stopPropagation();
    const url = elem.getAttribute("url");
    const parentLiElem = closest(elem, "li");
    if (elem.classList.contains("menu") && parentLiElem !== null) {
      if (elem.classList.contains("open")) {
        elem.classList.remove("open", "active");
      } else {
        elem.classList.add("open", "active");
      }
    } else {
      if (elem.classList.contains("open")) {
        if (!url) elem.classList.remove("open", "active");
      } else elem.classList.add("open", "active");
    }
    const activeLi = document.querySelector(`a[href="${url ?? ""}"]`); // select current a element
    try {
      const activeNav = closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        closest(activeNav, "li").classList.add("open", "active");
      } else {
        closest(activeLi, "li").classList.add("open", "active");
      }
    } catch (error) {}
  };

  const setActiveMenu = () => {
    const pathname = `${history.location.pathname}`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const parents = closest(activeLi, "ul", true); // select closest ul

      if (parents === null) return;

      _.each(parents, (item) => {
        if (item.classList.contains("sub-menu")) {
          closest(item, "li").classList.add("open");
        } else {
          closest(item, "li").classList.add("open");
        }
      });
    } catch (error) {}
  };

  const closest = (el, selector, allParentsNeeded) => {
    allParentsNeeded = ifNullOrWhiteSpace(allParentsNeeded, false);
    let parents = [];
    try {
      let matchesFn;
      // find vendor prefix
      ["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          if (!allParentsNeeded) return parent;
          parents.push(parent);
        }
        el = parent;
      }
    } catch (e) {}
    return parents;
  };

  useEffect(() => {
    setActiveMenu();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data) return null;

  return (
    <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
        <li className="nav-header"></li>
        <GenerateMenu menuData={data} handleClick={handleClickLI} />
      </ul>
    </CustomScrollbars>
  );
}
