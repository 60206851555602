import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  getCompany,
  getLanguage,
} from "Jarvis/JarvisServices/JarvisLocalStorageService";
import queryString from "query-string";
import { useForm } from "react-hook-form";
import Button from "Jarvis/JarvisComponents/Button";
import TextField from "Jarvis/JarvisComponents/TextField";
import Typography from "Jarvis/JarvisComponents/Typography";
import { Authenticate } from "../../Services/AccountService";
import { convertToBoolean } from "Jarvis/JarvisServices/JarvisCommonService";
import ChangePassword from "./ChangePassword";

export default function SignIn(props) {
  const {
    isInternalSignIn,
    location,
    langData,
    language,
    onLoadConcurrentLogins,
  } = props;
  const { control, handleSubmit, errors } = useForm();
  const [changePasswordInfo, setChangePasswordInfo] = useState(null);
  const queryInfo = queryString.parse(location?.search);
  const float =
    "float-" + (language.LangDir?.toLowerCase() === "rtl" ? "left" : "right");
  const [showPinCode, setShowPinCode] = useState(false);

  useEffect(() => {
    if (isInternalSignIn === false) {
      if (convertToBoolean(queryInfo.independentLogin)) props.userSignOut();
      // this codes have been removed as remove url in PLDS in the login page
      // if (authUser !== null) {
      //   history.push("/");
      // }
    }
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    props.showAuthLoader();
    const selectedCompany = getCompany();
    const response = await Authenticate({
      LangId: getLanguage().LangId,
      Username: data.username,
      Password: data.password,
      PlatformKey: "WebBrowser",
      JarvisIntegratedSystem: queryInfo.jarvisIntegratedSystem
        ? queryInfo.jarvisIntegratedSystem
        : true,
      SystemKey: queryInfo.systemKey,
      CookieIsAlsoNeeded: queryInfo.cookieIsAlsoNeeded,
      pinCode: data.pinCode,
      companyId: selectedCompany?.CompanyId,
      vtCompanyId: selectedCompany?.VTCompanyId,
      financialTermId: selectedCompany?.FinancialTermId,
      vTFinancialTermId: selectedCompany?.VTFinancialTermId,
      financialTermStartDate: selectedCompany?.FinancialTermStartDate,
      financialTermEndDate: selectedCompany?.FinancialTermEndDate,
    });
    props.hideAuthLoader();

    switch (_.get(response, "ErrorHandling.ErrorKey")) {
      case "ChangePassword":
        setChangePasswordInfo({
          UserId: _.get(response, "Data.UserId"),
          VT: _.get(response, "Data.VT"),
        });
        break;
      case "UserLogin_ConcurrentLoginError":
        onLoadConcurrentLogins(_.get(response, "Data.ConcurrentLogins"));
        break;
      case "UserTwoFactorAuthIsNeeded":
        setShowPinCode(true);
        break;
      default:
        if (_.get(response, "ErrorHandling.IsSuccessful")) {
          const newData = _.merge(response, { username: data.username });
          props._SetLoginResultInfo(newData);
        }
        break;
    }
  };

  return (
    <Fragment>
      {changePasswordInfo ? (
        <ChangePassword
          hasCloseBtn={false}
          hideAuthLoader={props.hideAuthLoader}
          onClose={() => setChangePasswordInfo(null)}
          showAuthLoader={props.showAuthLoader}
          userInfo={changePasswordInfo}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <TextField
              className="my-sm-3"
              label={_.get(langData, "Username")}
              name="username"
              control={control}
              errors={errors}
              rules={{
                required: _.get(langData, "Username_IsNull") || "required",
              }}
              textAlign="left"
              autoFocus
              fullWidth
            />
          </fieldset>
          <fieldset>
            <TextField
              className="my-sm-3"
              label={_.get(langData, "Password")}
              control={control}
              name="password"
              errors={errors}
              rules={{
                required: _.get(langData, "Password_IsNull") || "required",
              }}
              type="password"
              fullWidth
            />
          </fieldset>
          {showPinCode && (
            <fieldset>
              <TextField
                className="my-sm-3"
                label="Two-Factor Auth Pin Code"
                control={control}
                name="pinCode"
                errors={errors}
                rules={{
                  required: "required",
                }}
                type="password"
                fullWidth
              />
            </fieldset>
          )}
          <fieldset>
            <div className="mt-4">
              <Button
                label={_.get(langData, "SignIn")}
                variant="contained"
                color="primary"
                type="submit"
              />
              {queryInfo.returnUrl && (
                <Link
                  style={{ textDecoration: "none" }}
                  className="mx-4"
                  to={queryInfo.returnUrl}
                >
                  <Typography label={_.get(langData, "GoBack")} />
                </Link>
              )}
            </div>
          </fieldset>
          {isInternalSignIn === false && (
            <div className={float + " d-flex justify-content-between"}>
              <Link
                to="/Account/ForgetPassword"
                style={{ textDecoration: "none" }}
              >
                <Typography label={_.get(langData, "ForgetPassword")} />
              </Link>
            </div>
          )}
        </form>
      )}
    </Fragment>
  );
}
