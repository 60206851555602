/*------------
Version: 1.0
------------*/
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { CircularProgress } from "@material-ui/core";
import MuiToggleButton from "@material-ui/lab/ToggleButton";
import Tooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ToggleButton({
  className,
  icon,
  onClick,
  showLoading,
  selected,
  tooltip,
  value,
  ...rest
}) {
  const classes = useStyles();
  const buttonClassName = clsx(className, "jarvisButton");

  let buttonProps = { selected, value };

  if (tooltip)
    return (
      <Tooltip label={tooltip}>
        <MuiToggleButton
          className={buttonClassName}
          disabled={showLoading}
          onClick={onClick}
          {...buttonProps}
          {...rest}
        >
          {icon}
          {showLoading && (
            <CircularProgress className={classes.buttonProgress} size={24} />
          )}
        </MuiToggleButton>
      </Tooltip>
    );

  return (
    <MuiToggleButton
      className={buttonClassName}
      disabled={showLoading}
      onClick={onClick}
      {...buttonProps}
      {...rest}
    >
      {icon}
      {showLoading && (
        <CircularProgress className={classes.buttonProgress} size={24} />
      )}
    </MuiToggleButton>
  );
}

ToggleButton.propTypes = {
  showLoading: PropTypes.bool,
  tooltip: PropTypes.string,
};
