import React from "react";
import _ from "lodash";
import TreeGridTopPanelMenu from "./TreeGridTopPanelMenu";
import Typography from "../Typography";
import TreeGridRowFiltering from "./TreeGridRowFiltering";
import { Build } from "@material-ui/icons";

const headerClass = "cursor-context text-center align-middle";

export default function TreeGridHeader({
  columns,
  customHeader,
  expandableColumnIndex,
  fontSize,
  hasFiltering,
  hasTopPanelMenu,
  isDirectionRTL,
  onRowFiltering,
  PE,
  showOperationsColumn,
  theadClass,
  topPanelProps,
  whereClause,
  wrapHeader,
}) {
  let headClass = theadClass ?? "thead-dark";
  const isColumnFiltered = (column) => {
    if (whereClause.length === 0) return false;
    if (
      whereClause.find(
        (item) => item.FieldName === (column.searchFieldName ?? column.path)
      )
    )
      return true;

    return false;
  };

  const handelRowFilteringIcon = (column) => {
    if (column.hasFiltering === undefined || column.hasFiltering)
      return (
        <TreeGridRowFiltering
          column={column}
          isDirectionRTL={isDirectionRTL}
          isFiltered={isColumnFiltered(column)}
          onRowFiltering={onRowFiltering}
          PE={PE}
          whereClause={whereClause}
        />
      );
    return null;
  };

  const renderCell = (column) => {
    if (showOperationsColumn && column.path === "operationsColumn")
      return <Build fontSize="small" />;

    return (
      <React.Fragment>
        {hasTopPanelMenu &&
          columns.indexOf(column) === expandableColumnIndex && (
            <TreeGridTopPanelMenu
              topPanelProps={topPanelProps}
              isDirectionRTL={isDirectionRTL}
            />
          )}
        <Typography
          label={column.label}
          display="inline"
          fontSize={fontSize}
          fontWeight="Medium"
          noWrap={!wrapHeader}
          tooltip={!wrapHeader ? column.label : undefined}
        />
        {hasFiltering && handelRowFilteringIcon(column)}
      </React.Fragment>
    );
  };

  return (
    <thead>
      {!_.isEmpty(customHeader) &&
        customHeader.map((header, index) => (
          <tr
            style={{
              display: "block",
            }}
            className={header[0].className || headClass}
            key={index}
          >
            {header.map((column) => (
              <th
                style={{
                  wordBreak: "break-word",
                  width: `${column.width}px`,
                }}
                className={headerClass}
                key={column.key}
              >
                <Typography
                  label={column.label}
                  display="inline"
                  fontSize={fontSize}
                  fontWeight="Medium"
                />
              </th>
            ))}
          </tr>
        ))}
      <tr className={headClass} style={{ display: "block" }}>
        {columns?.map((column) => (
          <th
            style={
              wrapHeader
                ? { wordBreak: "break-word", width: `${column.width}px` }
                : {
                    width: `${column.width}px`,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: `${column.width}px`,
                  }
            }
            className={headerClass}
            key={column.path}
          >
            {renderCell(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
}

// let pageX, curCol, nxtCol, curColWidth, nxtColWidth;

//   const paddingDiff = col => {
//     if (getStyleVal(col, "box-sizing") == "border-box") {
//       return 0;
//     }

//     var padLeft = getStyleVal(col, "padding-left");
//     var padRight = getStyleVal(col, "padding-right");
//     return parseInt(padLeft) + parseInt(padRight);
//   };

//   const getStyleVal = (elm, css) => {
//     return window.getComputedStyle(elm, null).getPropertyValue(css);
//   };
// }
// const handleMouseDown = e => {
//   curCol = e.target.parentElement;
//   nxtCol = curCol.nextElementSibling;
//   pageX = e.pageX;

//   const padding = paddingDiff(curCol);

//   curColWidth = curCol.offsetWidth - padding;
//   if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
// };

// const handleMouseUp = e => {
//   curCol = undefined;
//   nxtCol = undefined;
//   pageX = undefined;
//   nxtColWidth = undefined;
//   curColWidth = undefined;
// };

// const handleMouseMove = e => {
//   if (curCol) {
//     var diffX = e.pageX - pageX;

//     if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

//     curCol.style.width = curColWidth + diffX + "px";
//   }
// };

// const test = () => {
//   table.style.overflow = "hidden";

//   var tableHeight = table.offsetHeight;

//   for (var i = 0; i < cols.length; i++) {
//     var div = createDiv(tableHeight);
//     cols[i].appendChild(div);
//     cols[i].style.position = "relative";
//     setListeners(div);
//   }

//   function setListeners(div) {
//     function createDiv(height) {
//       var div = document.createElement("div");
//       div.style.top = 0;
//       div.style.right = 0;
//       div.style.width = "5px";
//       div.style.position = "absolute";
//       div.style.cursor = "col-resize";
//       div.style.userSelect = "none";
//       div.style.height = height + "px";
//       return div;
//     }
//   }
