import React, { Fragment } from "react";
import clsx from "clsx";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    backgroundColor: "LightGray",
    boxShadow: "1px 2px #888888",
    borderRadius: "10%",
    position: "absolute",
    right: 0,
    padding: "0px 10px",
    verticalAlign: "baseline",
  },
}));

export default function OperationalPanel({
  currentRow,
  customOperationItems,
  hasOperationalPanel,
  onRefreshClick,
  onAddClick,
  onEditClick,
  onDeleteClick,
  PE,
  setSelectedNode,
  showSearch,
}) {
  const classes = useStyles();

  const handleRefreshClick = (e) => {
    e.stopPropagation();
    onRefreshClick();
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    onAddClick();
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    onEditClick();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick();
  };

  return (
    <span className={clsx(classes.toggleContainer, "main-panel")}>
      {hasOperationalPanel && (
        <Fragment>
          <Tooltip label={_.get(PE, "Create") || "Create"}>
            <AddIcon
              className="operational-panel"
              fontSize="small"
              onClick={handleAddClick}
            />
          </Tooltip>
          <Tooltip label={_.get(PE, "Edit") || "Edit"}>
            <EditIcon
              className="mx-1 operational-panel"
              fontSize="small"
              onClick={handleEditClick}
            />
          </Tooltip>
          <Tooltip label={_.get(PE, "Delete") || "Delete"}>
            <DeleteIcon
              className="operational-panel"
              fontSize="small"
              onClick={handleDeleteClick}
            />
          </Tooltip>
        </Fragment>
      )}
      {!showSearch && currentRow.hasChildren && (
        <Tooltip label={_.get(PE, "Refresh") || "Refresh"}>
          <RefreshIcon
            className="mx-1 operational-panel"
            fontSize="small"
            onClick={handleRefreshClick}
          />
        </Tooltip>
      )}
      {customOperationItems?.map(
        (item) =>
          (!item.displayIf || item.displayIf(currentRow)) && (
            <Tooltip label={item.toolTip || ""} key={item.name}>
              <span
                className="mx-1 operational-panel"
                onClick={() => {
                  item.onClick(currentRow);
                  setSelectedNode(currentRow.parent);
                }}
              >
                {item.icon || ""}
              </span>
            </Tooltip>
          )
      )}
    </span>
  );
}
