import _ from "lodash";
import { ifNullOrWhiteSpace, isNullOrWhiteSpace } from "./JarvisCommonService";
import queryString from "query-string";

export const setToken = ({ jAToken, tokenExpirationUtc }) => {
  if (jAToken === null || jAToken === undefined) return;
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) {
    userInfo = {
      ...userInfo,
      JAToken: jAToken,
      TokenExpirationUtc: tokenExpirationUtc,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }
};
export const getToken = () => {
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) return _.get(userInfo, "JAToken");
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem("userInfo"));
};

export const setPrevUsername = (username) => {
  localStorage.setItem("prevUsername", username);
};

export const getPrevUsername = () => {
  return localStorage.getItem("prevUsername");
};

export const getTokenExpirationUtc = () => {
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) return userInfo.TokenExpirationUtc;
};

export const clearToken = () => {
  localStorage.removeItem("userInfo");
};

export const getLanguage = () => {
  var langInfo = ifNullOrWhiteSpace(
    JSON.parse(localStorage.getItem("selectedLanguage")),
    {}
  );
  const fontName = langInfo?.LangFontName
    ? langInfo?.LangFontName.toLowerCase()
    : "Default";
  langInfo.JarvisFontClass = `Jarvis-Font-${fontName}`;
  if (isNullOrWhiteSpace(langInfo.LangKey)) langInfo.LangKey = "EN";
  return langInfo;
};

export const setCalendar = ({ calendarId, calendarKey, calendarFormat }) => {
  if (isNullOrWhiteSpace(calendarId) || isNullOrWhiteSpace(calendarKey)) return;
  localStorage.setItem(
    "calendar",
    JSON.stringify({ calendarId, calendarKey, calendarFormat })
  );
};

export const getCalendar = () => {
  var { calendarId, calendarKey, calendarFormat } = ifNullOrWhiteSpace(
    JSON.parse(localStorage.getItem("calendar")),
    {}
  );
  return { calendarId, calendarKey, calendarFormat };
};

export const getBvt_Old = () => {
  const queryInfo = queryString.parseUrl(window.location.href);
  return queryInfo?.query?.bvt;
};

export const setSubSystem = ({ subSystemId, vtSubSystemId, subSystemKey }) => {
  let subSystem = {};
  // if (
  //   isNullOrWhiteSpace(subSystemId) === false &&
  //   isNullOrWhiteSpace(vtSubSystemId) === false &&
  //   isNullOrWhiteSpace(subSystemKey) === false
  // )
  subSystem = {
    subSystemId,
    vtSubSystemId,
    subSystemKey,
  };
  localStorage.setItem("subSystem", JSON.stringify(subSystem));
};
// const toMinutes = (milliseconds) => {
//   return Math.ceil(milliseconds / (1000 * 60 * 60 * 24 * 60));
// };

export const getSubSystem = () => {
  const queryInfo = queryString.parseUrl(window.location.href);
  const subSystemId = queryInfo?.query?.SSId;
  const vtSubSystemId = queryInfo?.query?.VTSSId;
  const subSystemKey = queryInfo?.query?.SSKey;
  let data = {
    subSystemId,
    vtSubSystemId,
    subSystemKey,
  };
  if (
    isNullOrWhiteSpace(subSystemId) ||
    isNullOrWhiteSpace(vtSubSystemId) ||
    isNullOrWhiteSpace(subSystemKey)
  ) {
    data = JSON.parse(localStorage.getItem("subSystem"));
  }
  return data;
};

// export const clearBvts = (keepLatest) => {
//   let bvts = [];
//   keepLatest = keepLatest ?? true;
//   if (keepLatest === true) bvts.push(getLatestBvtObject());
//   localStorage.setItem("bvts", JSON.stringify(bvts));
// };
// export const getLatestBvtObject = () => {
//   const bvts = JSON.parse(localStorage.getItem("bvts")) ?? [];
//   const orderList = _.orderBy(bvts, ["creationDateTime"], ["desc"]);
//   const last = _.first(orderList);
//   return last;
// };

// export const findBvtBySubSystem = (subSystem) => {
//   const bvts = JSON.parse(localStorage.getItem("bvts")) ?? [];
//   const index = _.findIndex(bvts, (item) => item.selectedSubSystem.subSystemId === subSystem.subSystemId && item.selectedSubSystem.subSystemKey === subSystem.subSystemKey);
//   return bvts[index]?.code;
// };

export const setCompany = ({
  CompanyId,
  VTCompanyId,
  CompanyLangDescription,
  FinancialTermId,
  VTFinancialTermId,
  FinancialTermLangDescription,
}) => {
  let obj = {};
  if (!isNullOrWhiteSpace(CompanyId) && !isNullOrWhiteSpace(VTCompanyId))
    obj = {
      CompanyId,
      VTCompanyId,
      CompanyLangDescription,
    };
  if (
    !isNullOrWhiteSpace(FinancialTermId) &&
    !isNullOrWhiteSpace(VTFinancialTermId)
  ) {
    obj.FinancialTermId = FinancialTermId;
    obj.VTFinancialTermId = VTFinancialTermId;
    obj.FinancialTermLangDescription = FinancialTermLangDescription;
  }

  localStorage.setItem("company", JSON.stringify(obj));
};

export const getCompany = () => {
  const {
    CompanyId,
    VTCompanyId,
    CompanyLangDescription,
    FinancialTermId,
    VTFinancialTermId,
    FinancialTermLangDescription,
  } = ifNullOrWhiteSpace(JSON.parse(localStorage.getItem("company")), {});

  return {
    CompanyId,
    VTCompanyId,
    CompanyLangDescription,
    FinancialTermId,
    VTFinancialTermId,
    FinancialTermLangDescription,
  };
};

export const setPatron = ({ PatronId, Fullname }) => {
  if (_.isNil(PatronId)) return;
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) {
    userInfo = { ...userInfo, PatronId, Fullname };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }
};

export const checkIfNewJarvisVersionReceived = (jarvisVer) => {
  if (isNullOrWhiteSpace(jarvisVer) === true) {
    return false;
  }
  const jarvisVerKey = "jarvisVer";

  const lSJarvisVer = ifNullOrWhiteSpace(
    localStorage.getItem(jarvisVerKey),
    ""
  );
  if (lSJarvisVer.toLowerCase() !== jarvisVer.toLowerCase()) {
    if (isNullOrWhiteSpace(lSJarvisVer)) {
      setJarvisVersion(jarvisVer);
      return false;
    }

    // const jarvisVerLastAlterDateTime = parseInt(ifNullOrWhiteSpace(localStorage.getItem("jarvisVerLastAlterDateTime"), 0));

    // if (jarvisVerLastAlterDateTime === 0) {
    //   const dateTime = new Date().getTime();
    //   localStorage.setItem("jarvisVerLastAlterDateTime", dateTime);
    //   return true;
    // }

    //const getTime = new Date().getTime();
    // if (jarvisVerLastAlterDateTime + 30000 > getTime) return false;
    // localStorage.setItem("jarvisVerLastAlterDateTime", getTime);

    return true;
  }
  return false;
};

export const setJarvisVersion = (jarvisVer) => {
  const jarvisVerKey = "jarvisVer";
  localStorage.setItem(jarvisVerKey, jarvisVer);
};
