import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService, {
  getJarvisCustomLocale,
  jarvisCustomServiceMustBeReplaced,
} from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { generateJarvisOutput } from "Jarvis/JarvisServices/JarvisCommonService";

/*
moduleCodeStrings is mandatory
config is not mandatory and contains [{ConfigCode(Mandatory),SystemKey}]
langId is not mandatory
*/
export const RetrievePublic = async ({
  moduleCodeStrings,
  config,
  configs,
  langId,
  withSeparateModuleCodeString,
  systemMessageCodeStrings,
  configModuleFilter,
}) => {
  const customServiceName = "PageElementService.RetrievePublic";
  const res = jarvisCustomServiceMustBeReplaced(customServiceName);
  if (res.mustBeReplaced === false) {
    const { data } = await JarvisHttpService.post({
      url:
        JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
        "Pub/PageElementProperty/RetrievePublic",
      data: {
        langId,
        moduleCodeStrings,
        systemMessageCodeStrings,
        Config: {
          Configs: configs,
        },
        withSeparateModuleCodeString,
        configModuleFilter,
      },
    });
    return data;
  }
  return generateJarvisOutput({
    data: getJarvisCustomLocale({
      moduleCodeStrings,
      config,
      withSeparateModuleCodeString,
      systemMessageCodeStrings,
    }),
  });
};
