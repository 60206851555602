import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from "Jarvis/JarvisConstants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "Jarvis/JarvisActions/Setting";
import UserMenu from "Jarvis/JarvisSystems/Sec/Components/Account/UserMenu";
import SidenavContent from "./SidenavContent";

function SideNav({ drawerType, history, menuData, navCollapsed, navigationStyle, onShowUserSettings, toggleCollapsedNav, updateWindowWidth, width }) {
  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    toggleCollapsedNav(val);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      updateWindowWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        updateWindowWidth(window.innerWidth);
      });
    };
  });

  let drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-xl-flex" : drawerType.includes(COLLAPSED_DRAWER) ? "" : "d-flex";
  let type = "permanent";
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
    type = "temporary";
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = "";
    type = "temporary";
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes("temporary") ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: "side-nav",
        }}
      >
        <UserMenu onShowUserSettings={onShowUserSettings} history={history} />
        <SidenavContent data={menuData} history={history} />
      </Drawer>
    </div>
  );
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return { navCollapsed, drawerType, width, navigationStyle };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav));
