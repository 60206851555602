import React, { useState } from "react";
import { Controller } from "react-hook-form";
import jMoment from "moment-jalaali";
import JalaliUtils from "@date-io/jalaali";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
  isValidDate,
  getDateString,
} from "Jarvis/JarvisServices/JarvisCommonService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Typography from "../Typography";

const DatePickerHijriShamsi = ({
  name,
  value,
  usePersianDigits,
  variant /*dialog, inline, static*/,
  onChange,
  label,
  hint,
  hintColor,
  control,
  errors,
  setValue,
  size,
  rules,
  inputVariant, //standard | outlined | filled
  placeholder,
  setTodayDate,
  ...rest
}) => {
  const dialect = "persian-modern";
  jMoment.loadPersian({
    dialect: dialect,
    usePersianDigits: false, //usePersianDigits,
  });

  jMoment.updateLocale("fa", {
    ...rest,
    jMonthsShort: {
      persian: "فروردین_اردیبهشت_خرداد_تیر_مرداد_شهریور_مهر_آبان_آذر_دی_بهمن_اسفند".split(
        "_"
      ),
      "persian-modern": "فروردین_اردیبهشت_خرداد_تیر_مرداد_شهریور_مهر_آبان_آذر_دی_بهمن_اسفند".split(
        "_"
      ),
    }[dialect],
  });
  if (setTodayDate === true) value = getDateString();
  //convert hijrishamsi to gregorian
  else
    value = isNullOrWhiteSpace(value)
      ? null
      : jMoment(value, "jYYYY/jMM/jDD", true).format("YYYY/MM/DD");

  const [date, setDate] = useState(ifNullOrWhiteSpace(value, null));

  variant = ifNullOrWhiteSpace(variant, "inline");
  inputVariant = ifNullOrWhiteSpace(inputVariant, "outlined");
  usePersianDigits = ifNullOrWhiteSpace(usePersianDigits, false);
  placeholder = ifNullOrWhiteSpace(placeholder, "روز / ماه / سال");

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return (
      <Typography
        fontSize={7}
        component={"span"}
        label={helperText}
        color={hintColor}
      />
    );
  };

  let commonProps = {
    name,
    variant,
    label,
    inputVariant,
    format: "YYYY/MM/DD",
    margin: "normal",
    labelFunc: (_date) => {
      const date = _date ? _date.format("jYYYY/jMM/jDD") : "";
      return date;
    },
    disableToolbar: false,
    autoOk: true,
    invalidDateMessage: "فرمت وارد شده معتبر نمی باشد",
    maxDateMessage: "تاریخ وارد شده بزرگتر از تاریخ استاندارد می باشد",
    minDateMessage: "تاریخ وارد شده کوچکتر از تاریخ استاندارد می باشد",
    error: errors && errors[name] ? true : false,
    helperText: renderHelperText(),
    placeholder,
    size,
    ...rest,
  };
  if (control) {
    return (
      <Controller
        name={name}
        defaultValue={date}
        control={control}
        rules={rules}
        render={({ value, name, onChange: onChangeController, onBlur }) => {
          return (
            <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
              <KeyboardDatePicker
                value={value}
                name={name}
                {...commonProps}
                onChange={(orginalDate, _date) => {
                  if (isNullOrWhiteSpace(orginalDate?._f) === false) {
                    const date = jMoment(_date, "jYYYY/jMM/jDD", true).format(
                      "YYYY/MM/DD"
                    );
                    if (date !== "Invalid date") _date = date;
                  }
                  let isValid = orginalDate?.isValid();
                  if (isValid === false) {
                    setValue(name, null);
                    onChangeController("");
                  } else {
                    if (onChange) onChange(_date);
                    onChangeController(_date);
                  }
                }}
                onBlur={(e) => {
                  const _date = e.target.value;
                  const date = jMoment(_date, "jYYYY/jMM/jDD", true).format(
                    "YYYY/MM/DD"
                  );
                  if (isValidDate(date, "yyyy/MM/dd") === false) {
                    setValue(name, null);
                  } else setValue(name, date);
                  onBlur(e);
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    );
  } else {
    const dateFormat = "YYYY/MM/DD";
    commonProps.onChange = (orginalDate, date) => {
      if (isNullOrWhiteSpace(orginalDate?._f) === false) {
        date = jMoment(date, "jYYYY/jMM/jDD", true).format("YYYY/MM/DD");
      }
      setDate(date);
      if (onChange) onChange(date === "Invalid date" ? null : date);
    };
    commonProps.value = date;
    commonProps.onBlur = (e) => {
      if (isValidDate(e.target.value, dateFormat) === false) setDate(null);
    };
    return (
      <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
        <KeyboardDatePicker {...commonProps} placeholder={dateFormat} />
      </MuiPickersUtilsProvider>
    );
  }
};

export default DatePickerHijriShamsi;
