import React, { useState, Fragment } from "react";
import _ from "lodash";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import Typography from "../Typography";

export default function TableRowSelection({
  customSelectMenuItems,
  multiSelect,
  onSelect,
  PE,
  selectedRows,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    setAnchorEl(null);
    onSelect(null, true);
  };

  const handleCancelSelect = () => {
    setAnchorEl(null);
    onSelect(null, false);
  };

  return (
    <Fragment>
      <MoreVertIcon
        className="cursor-pointer"
        aria-haspopup="true"
        fontSize="small"
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {multiSelect && (
          <div>
            <MenuItem onClick={handleSelectAll}>
              <ListItemIcon>
                <SelectAllIcon fontSize="small" />
              </ListItemIcon>
              <Typography label={_.get(PE, "SelectAll") ?? "Select All"} />
            </MenuItem>
            <MenuItem onClick={handleCancelSelect}>
              <ListItemIcon>
                <CheckBoxOutlineBlankIcon fontSize="small" />
              </ListItemIcon>
              <Typography
                label={_.get(PE, "CancelSelect") || "Cancel Select"}
              />
            </MenuItem>
          </div>
        )}
        {customSelectMenuItems?.map((item) => (
          <MenuItem
            key={item.name}
            disabled={item.enableOnSelect && selectedRows.length === 0}
            onClick={() => {
              item.onClick(selectedRows);
              handleClose();
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography label={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
