import React, { useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { useForm } from "react-hook-form";
import TextField from "Jarvis/JarvisComponents/TextField";
import Button from "Jarvis/JarvisComponents/Button";
import Typography from "Jarvis/JarvisComponents/Typography";
import { UserRequestResetPassword } from "../../Services/AccountService";
import RadioList from "Jarvis/JarvisComponents/RadioList";
import CellphoneConfirmation from "Jarvis/JarvisSystems/Pms/Components/CellphoneConfirmation";

export default function ForgetPassword(props) {
  const { control, errors, handleSubmit } = useForm();
  const [cellphoneData, setCellphoneData] = useState(null);
  const [isUsernameNeeded, setIsUsernameNeeded] = useState(false);
  const [inputType, setInputType] = useState("E");
  const [
    showCellphoneConfirmationDialog,
    setShowCellphoneConfirmationDialog,
  ] = useState(false);
  const { langData, language } = props;
  const float =
    "float-" + (language.LangDir.toLowerCase() === "rtl" ? "left" : "right");

  const onSubmit = async (data) => {
    props.showAuthLoader();
    let iModel = {
      LangId: getLanguage().LangId,
      OriginUrl: window.location.origin,
      isDirectionRTL: props.isDirectionRTL,
    };
    iModel.Username = data.Username;
    if (data.Email) {
      iModel.Email = data.Email;
    } else {
      props.hideAuthLoader();
      if (inputType === "C" && data.Cellphone) {
        iModel.Cellphone = data.Cellphone;
        setCellphoneData({ Cellphone: data.Cellphone });
        setShowCellphoneConfirmationDialog(true);
        return 0;
      }
    }

    const { ErrorHandling } = await UserRequestResetPassword(iModel);
    props.hideAuthLoader();
    if (_.get(ErrorHandling, "IsSuccessful")) {
      props.history.push("/Account/Signin");
    } else if (
      _.get(ErrorHandling, "ErrorKey")?.toLowerCase() === "usernameisneeded"
    )
      setIsUsernameNeeded(true);
  };
  return (
    <div>
      {showCellphoneConfirmationDialog && (
        <CellphoneConfirmation
          cellphoneData={cellphoneData}
          isIndependentForm={true}
          langDataParent={langData}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <RadioList
            data={[
              {
                label: _.get(langData, "Email") || "Email",
                value: "E",
              },
              {
                label: _.get(langData, "Cellphone") || "Cellphone",
                value: "C",
              },
            ]}
            isHorizontal
            onChange={setInputType}
          />
        </div>
        <div className="row">
          {isUsernameNeeded && (
            <TextField
              className="mt-1 my-sm-3"
              label={_.get(langData, "Username")}
              control={control}
              name="Username"
              errors={errors}
              rules={{
                required: _.get(langData, "Username_IsNull") || "required",
              }}
              textAlign="left"
              autoFocus
              fullWidth
            />
          )}
          {inputType === "C" ? (
            <TextField
              className="mt-1 my-sm-3"
              label={_.get(langData, "Cellphone")}
              control={control}
              name="Cellphone"
              errors={errors}
              rules={{
                required: _.get(langData, "Cellphone_IsNull") || "required",
              }}
              type="mobile"
              textAlign="left"
              autoFocus
              fullWidth
            />
          ) : (
            <TextField
              className="mt-1 my-sm-3"
              label={_.get(langData, "Email")}
              control={control}
              name="Email"
              errors={errors}
              rules={{
                required: _.get(langData, "Email_IsNull") || "required",
              }}
              type="email"
              textAlign="left"
              autoFocus
              fullWidth
            />
          )}
        </div>
        <div
          className={
            float + " mb-3 d-flex align-items-center justify-content-between"
          }
        >
          <Link to="/Account/Signin" style={{ textDecoration: "none" }}>
            <Typography label={_.get(langData, "BackToLogin")} />
          </Link>
        </div>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            label={_.get(langData, "RequestPassword")}
            variant="contained"
            color="primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
