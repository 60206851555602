import React, { useState } from "react";
import _ from "lodash";
import BuildIcon from "@material-ui/icons/Build";
import Typography from "../Typography";
import TableRowSelection from "./TableRowSelection";
import TableRowFiltering from "./TableRowFiltering";
import SortPanel from "./SortPanel";
import TableTopPanelMenu from "./TableTopPanelMenu";
import { uuid } from "Jarvis/JarvisServices/JarvisCommonService";

const headerClass = "cursor-context text-center align-middle";

export default function TableHeader({
  columns,
  customHeader,
  customSelectMenuItems,
  direction,
  hasSorting,
  fontSize,
  hasFiltering,
  hasSelectColumn,
  hasSelectMenu,
  hasTopPanelMenu,
  multiSelect,
  onRowFiltering,
  onRowSelectionClick,
  onSortChange,
  PE,
  selectedRows,
  showOperationsColumn,
  sortedColumns,
  theadClass,
  topPanelProps,
  whereClause,
}) {
  const [sortPanelPath, setSortPanelPath] = useState("");

  let headClass = theadClass ?? "thead-dark";

  const isSortable = (column) => {
    if (
      !hasSorting ||
      column.sortable === false ||
      column.type === "checkbox" ||
      column.type === "switch"
    )
      return false;
    return true;
  };

  const isColumnFiltered = (column) => {
    if (whereClause.length === 0) return false;
    if (
      whereClause.find(
        (item) => item.FieldName === (column.searchFieldName || column.path)
      )
    )
      return true;

    return false;
  };

  const handleColumnClick = (column, sortIndex) => {
    if (hasSorting && isSortable(column))
      if (column.path === sortPanelPath) {
        setSortPanelPath("");
        if (sortIndex !== -1)
          onSortChange(
            { columnName: column.path, orderMethod: null },
            sortIndex
          );
      } else {
        setSortPanelPath(column.path);
      }
    if (column.onClick) column.onClick();
  };

  const getSortIcon = (column, sortIndex) => {
    return (
      <SortPanel
        column={column}
        orderMethod={sortIndex !== -1 && sortedColumns[sortIndex].orderMethod}
        onSort={handleSort}
      />
    );
  };

  const handleSort = (column, orderMethod) => {
    let sortCol = {};
    const index = sortedColumns.findIndex(
      (col) => col.columnName === column.path
    );
    if (index !== -1) {
      sortCol = sortedColumns[index];
      if (sortCol.orderMethod === orderMethod) {
        sortCol = { columnName: column.path, orderMethod: null };
      } else sortCol.orderMethod = orderMethod;
    } else {
      sortCol = { columnName: column.path, orderMethod: orderMethod };
    }
    onSortChange(sortCol, index);
  };

  const handelRowFilteringIcon = (column) => {
    if (column.hasFiltering === undefined || column.hasFiltering)
      return (
        <TableRowFiltering
          column={column}
          isDirectionRTL={direction === "rtl" ? true : false}
          isFiltered={isColumnFiltered(column)}
          onRowFiltering={onRowFiltering}
          PE={PE}
          whereClause={whereClause}
        />
      );
    return null;
  };

  const renderCell = (column) => {
    if (hasSelectColumn && column.path === "selectColumn")
      return (
        hasSelectMenu && (
          <TableRowSelection
            customSelectMenuItems={customSelectMenuItems}
            hasSelectMenu={hasSelectMenu}
            multiSelect={multiSelect}
            onSelect={onRowSelectionClick}
            PE={PE}
            selectedRows={selectedRows}
          />
        )
      );
    if (showOperationsColumn && column.path === "operationsColumn")
      return <BuildIcon fontSize="small" />;

    let sortIndex = -1;
    if (sortedColumns.length > 0)
      sortIndex = sortedColumns.findIndex(
        (col) => col.columnName === column.path
      );
    return (
      <React.Fragment>
        {hasTopPanelMenu && columns.indexOf(column) === 0 && (
          <TableTopPanelMenu
            topPanelProps={topPanelProps}
            direction={direction}
          />
        )}
        <Typography
          className={
            isSortable(column) || column.onClick ? "cursor-pointer" : ""
          }
          label={column.label}
          display="inline"
          fontSize={fontSize}
          fontWeight="Medium"
          onClick={() => handleColumnClick(column, sortIndex)}
        />
        {(column.path === sortPanelPath || sortIndex !== -1) &&
          getSortIcon(column, sortIndex)}
        {hasFiltering && handelRowFilteringIcon(column)}
      </React.Fragment>
    );
  };

  return (
    <thead>
      {!_.isEmpty(customHeader) &&
        customHeader.map((header, index) => (
          <tr
            style={{
              display: "block",
            }}
            className={header[0].className || headClass}
            key={index}
          >
            {header.map((column) => (
              <th
                style={{
                  wordBreak: "break-word",
                  width: `${column.width}px`,
                }}
                className={headerClass}
                key={column.key}
              >
                <Typography
                  label={column.label}
                  display="inline"
                  fontSize={fontSize}
                  fontWeight="Medium"
                />
              </th>
            ))}
          </tr>
        ))}
      <tr
        style={{
          display: "block",
        }}
        className={headClass}
      >
        {columns?.map((column) => (
          <th
            style={{ wordBreak: "break-word", width: `${column.width}px` }}
            className={headerClass}
            key={column.path ?? uuid()}
          >
            {renderCell(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
}
