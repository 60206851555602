import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import TextField from "Jarvis/JarvisComponents/TextField";
import Button from "Jarvis/JarvisComponents/Button";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Typography from "Jarvis/JarvisComponents/Typography";
import { notify } from "Jarvis/JarvisComponents/Notification";
import {
  SendVerificationCode,
  CellphoneVerify,
} from "../../Services/PatronCellphoneService";

export default function InputForm({
  cellphoneData,
  isIndependentForm,
  langData,
  onShowInputForm,
  onSubmitParent,
  selectedPatronParent,
  setShowInputForm,
  showInputForm,
}) {
  const { control, errors } = useForm();
  const [counter, setCounter] = useState(5);
  const [enteredVerificationCode, setEnteredVerificationCode] = useState(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  const onSubmit = async () => {
    setIsLoading(true);
    debugger;
    const { ErrorHandling } = await CellphoneVerify({
      PatronCellphoneId: cellphoneData.PatronCellphoneId,
      VT: cellphoneData.VT,
      PatronCellphoneLastUpdateDateTime:
        cellphoneData.PatronCellphoneLastUpdateDateTime,
      VerificationCode: enteredVerificationCode,
    });
    setIsLoading(false);

    if (_.get(ErrorHandling, "IsSuccessful")) {
      if (!isIndependentForm) onSubmitParent();
      setShowInputForm(false);
    }
    return _.get(ErrorHandling, "IsSuccessful");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const getCellphoneLabel = () => {
    return `${_.get(cellphoneData, "PhoneCode") || ""} ${_.get(
      cellphoneData,
      "Cellphone"
    )}`;
  };

  const getButtonLabel = () => {
    return `${_.get(langData, "Resend") || "Resend"} ${
      counter > 0 ? counter : ""
    }`;
  };

  const handleResendClick = (e) => {
    e.preventDefault();
    debugger;
    (async () => {
      setIsLoadingResend(true);
      const response = await SendVerificationCode({
        patronId: selectedPatronParent.PatronId || cellphoneData.PatronId,
        VT: selectedPatronParent.VT || cellphoneData.VT,
        patronCellphoneId: cellphoneData.PatronCellphoneId,
        VTPatronCellphoneId: cellphoneData.VT,
      });
      setIsLoadingResend(false);
      const { ErrorHandling } = response;
      if (_.get(ErrorHandling, "IsSuccessful")) {
        setCounter(59);
        _.set(
          cellphoneData,
          "PatronCellphoneLastUpdateDateTime",
          _.get(response, "Data.PatronCellphoneLastUpdateDateTime")
        );
        notify({
          type: "success",
          message:
            _.get(langData, "SendSMS_IsSuccessful") ??
            "Sending SMS was Successful",
        });
      }
    })();
  };

  const handleVerificationCodeChange = (e) => {
    const newCode = e.target.value;
    if (newCode.length === 6) setEnteredVerificationCode(newCode);
    // else setEnteredVerificationCode(null);
  };

  const handleClose = () => {
    onShowInputForm(false);
  };

  return (
    <Dialog
      width="30%"
      open={showInputForm}
      title={_.get(langData, "Cellphone")}
      onClose={handleClose}
      showDialogAction={false}
      showCloseBtn={true}
      maxWidth={false}
    >
      <form
        className="border border-info py-3 mb-2 px-4"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="row d-flex justify-content-center align-items-center mt-3">
          <Typography color="primary" label={getCellphoneLabel()} />
        </div>
        <div className="row d-flex justify-content-center align-items-center mt-3">
          <Typography
            label={
              _.get(langData, "InputSMSCode_Label") ||
              "Enter the SMS code in the box below:"
            }
          />
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <TextField
              className="my-sm-3"
              label={_.get(langData, "VerificationCode")}
              control={control}
              name="VerificationCode"
              errors={errors}
              rules={{
                required:
                  _.get(langData, "VerificationCode_IsNull") || "required",
              }}
              required
              type="number"
              textAlign="center"
              autoFocus
              onChange={handleVerificationCodeChange}
            />
            <Button
              label={getButtonLabel()}
              color="primary"
              showLoading={isLoadingResend}
              variant="contained"
              disabled={counter > 0}
              onClick={handleResendClick}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <Button
              label={_.get(langData, "Confirm") || "Confirm"}
              color="primary"
              showLoading={isLoading}
              variant="contained"
              disabled={!enteredVerificationCode}
              type="submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
