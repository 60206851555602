import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { NativeSelect } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Typography from "../Typography";

export default function TablePagination({
  id,
  direction,
  onPageChange,
  onPageSizeChange,
  pageIndex,
  pageSize,
  pageSizeText,
  showPageCount,
  totalRecords,
  totalRecordsText,
}) {
  const { register, setValue, getValues } = useForm();

  const pagesCount = Math.ceil(totalRecords / pageSize);

  let pagesCountClass =
    "col-4 d-flex flex-nowrap mb-1 align-items-center justify-content-";
  pagesCountClass +=
    direction === "ltr" ? "start order-end" : "start align-content-end";

  let paginationClass = "col-5 d-flex pt-2 mb-1 justify-content-end";
  if (direction === "ltr") paginationClass += " order-start";

  useEffect(() => {
    setValue("pageindex", pageIndex);
    return () => {};
  }, [pageIndex, setValue]);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      let value = Number(e.target.value);
      if (value > 0 && value <= pagesCount) onPageChange(value);
    }
  };

  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  const handlePageSizeChange = () => {
    onPageSizeChange(Number(getValues("pagesize")));
  };

  if (totalRecords < 1) return null;

  return (
    <div id={id} className="row pagination">
      <div className={pagesCountClass}>
        {showPageCount && (
          <React.Fragment>
            <div dir="ltr">
              <input
                style={{ width: "50px" }}
                className="text-center"
                type="number"
                min={1}
                max={pagesCount}
                name="pageindex"
                ref={register}
                defaultValue={pageIndex}
                onKeyPress={handleEnter}
              />
              <span>&nbsp;/&nbsp; {pagesCount}&nbsp; </span>
            </div>
            <span className="mx-1 d-none d-md-inline-block">
              <Typography
                label={`(${totalRecordsText}: ${totalRecords})`}
                fontSize={8}
                color="textSecondary"
              />
            </span>
          </React.Fragment>
        )}
      </div>
      <div className="col d-flex mb-1 justify-content-center align-items-center">
        <span className="mx-2 d-none d-sm-inline-block">
          <Typography
            label={`${pageSizeText}: `}
            fontSize={8}
            color="textSecondary"
          />
        </span>
        <NativeSelect
          id="select-pagesize"
          value={pageSize}
          name="pagesize"
          inputRef={register}
          onChange={handlePageSizeChange}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </NativeSelect>
      </div>
      <div className={paginationClass}>
        <Pagination
          count={pagesCount}
          page={pageIndex}
          color="primary"
          disabled={pagesCount === 1}
          onChange={handlePageChange}
        ></Pagination>
      </div>
    </div>
  );
}

TablePagination.propType = {
  pageSizeText: PropTypes.string,
  totalRecordsText: PropTypes.string,
};

TablePagination.defaultProps = {
  pageSizeText: "Display",
  totalRecordsText: "Records",
};
