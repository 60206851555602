import React, { Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";

export default function asyncComponent(importComponent, externalParams) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        found: true,
      };
    }

    UNSAFE_componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      try {
        const { default: Component } = await importComponent();
        if (this.mounted) {
          this.setState({
            component: <Component {...externalParams} {...this.props} />,
            found: true,
          });
        }
      } catch (e) {
        this.setState({
          component: null,
          found: false,
        });
      }
      Nprogress.done();
    }

    render() {
      const Component = this.state.component || this.state.found === false || (
        <div className="loader-view" style={{ height: "calc(100vh - 200px)" }}>
          <CircularProgress />
        </div>
      );
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
