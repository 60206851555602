import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Checkbox } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import {
  digitGrouping,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import Tooltip from "../Tooltip";

let totalRowHeight = 0;
const rowHeights = [];

export default function TableBody({
  columns,
  columnWidthTolerance,
  currentRow,
  data,
  direction,
  editOnDoubleClick,
  fontSize,
  footer,
  hasCounterColumn,
  hasPagination,
  hasRowSelect,
  hasSelectColumn,
  height,
  idFieldName,
  isDirectionRTL,
  isLoading,
  isLoadingText,
  noItemsText,
  onAddRowClick,
  onDelete,
  onEditRowClick,
  onRowClick,
  onRowDoubleClick,
  onRowSelectionClick,
  pageIndex,
  pageSize,
  rowCanBeSelectedIf,
  selectedRows,
  setCellContent,
  setCurrentRow,
  showFilter,
  trStyle,
  width,
  wrapText,
}) {
  const { language } = useSelector((state) => state.settings);
  const bodyRef = useRef(null);
  const bodyStyle = {
    height: `${height}px`,
    width: `${width}px`,
    display: "block",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "none",
  };

  let isCurrentRowFound = false;
  let counter = (pageIndex - 1) * pageSize;

  const paginate = () => {
    if (hasPagination) {
      if (data?.length > pageSize) {
        const startIndex = (pageIndex - 1) * pageSize;
        return _(data).slice(startIndex).take(pageSize).value();
      }
    }

    return data ?? [];
  };

  const tableData = paginate();

  useEffect(() => {
    if (data?.length > 0) {
      if (bodyRef.curren) {
        if (wrapText) {
          for (let i = 0; i < tableData.length; i++) {
            rowHeights[i] = parseInt(
              window.getComputedStyle(bodyRef.curren.rows[i]).height
            );
          }
        } else {
          rowHeights[0] = parseInt(
            window.getComputedStyle(bodyRef.current?.rows[0]).height
          );
        }
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStyle = (column) => {
    let tdStyle = { width: `${column.width}px` };
    if (wrapText) tdStyle.wordBreak = "break-word";
    else tdStyle.whiteSpace = "nowrap";
    if (column.type === "date") tdStyle.direction = "ltr";
    else if (column.cellDir !== undefined) tdStyle.direction = column.cellDir;

    return tdStyle;
  };

  const getRowStyle = (row) => {
    let currentRowStyle;
    if (
      currentRow &&
      !isCurrentRowFound &&
      idFieldName &&
      row[idFieldName] === currentRow[idFieldName]
    ) {
      isCurrentRowFound = true;
      currentRowStyle = { border: "2px solid #217346" };
    }
    if (row.Style)
      if (_.isObject(row.Style))
        return _.merge(trStyle, row.Style, currentRowStyle);
      else return _.merge(trStyle, JSON.parse(row.Style), currentRowStyle);

    return _.merge(trStyle, currentRowStyle);
  };

  const renderCell = (row, column) => {
    if (hasCounterColumn && column.path === "counterColumn") {
      return counter;
    }
    if (
      hasSelectColumn &&
      column.path === "selectColumn" &&
      (_.isUndefined(rowCanBeSelectedIf) || rowCanBeSelectedIf(row))
    ) {
      return (
        <Checkbox
          checked={
            selectedRows.findIndex(
              (selectedRow) => selectedRow[idFieldName] === row[idFieldName]
            ) !== -1
          }
          style={{ width: "20px", padding: 0 }}
          size="small"
          onChange={() => onRowSelectionClick(row)}
        />
      );
    }
    if (column.type === "checkbox" && !column.content) {
      if (_.get(row, column.path))
        return (
          <CheckIcon
            style={{
              color: green[500],
            }}
            fontSize="small"
          />
        );
      else return null;
    }
    if (column.content) return column.content(row);

    let text = column.content ? column.content(row) : "";
    if (column.type === "numberFormat")
      text = !isNullOrWhiteSpace(_.get(row, column.path))
        ? digitGrouping(_.get(row, column.path))
        : "";
    else text = _.get(row, column.path) ?? "";

    if (column.decimalScale && _.isNumber(text))
      text = text.toFixed(column.decimalScale).replace(/\.?0+$/, "");

    if (wrapText) return text;

    let textWidth = text.length * fontSize * columnWidthTolerance;
    switch (language.LangKey) {
      case "FA":
      case "AR":
        textWidth *= 0.96;
        break;
      case "ZH":
        textWidth *= 1.552;
        break;
      default:
        textWidth *= 0.768;
        break;
    }
    //Math.ceil(context.measureText(text).width);
    if (textWidth > column.width) {
      const length = Math.floor((text.length * column.width) / textWidth);
      if (column.wrapTextInDialog) {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setCellContent({ text, wrapTextInDialog: true })}
          >
            {_.truncate(text, { length })}
          </span>
        );
      } else {
        return (
          <Tooltip label={text} fontSize={fontSize}>
            <span>{_.truncate(text, { length })}</span>
          </Tooltip>
        );
      }
    }

    return text;
  };

  const createKey = (index, column) => {
    return index + column.path;
  };

  const increaseCounter = () => {
    ++counter;
  };

  const handleRowClick = (row) => {
    const index = data.indexOf(row);
    totalRowHeight = 0;
    for (let i = 0; i <= index; i++) {
      totalRowHeight += wrapText ? rowHeights[i] : rowHeights[0];
    }

    setCurrentRow(row);
    if (onRowClick) onRowClick(row);
  };

  const handleRowDoubleClick = (row) => {
    if (editOnDoubleClick) {
      if (onEditRowClick) onEditRowClick(row);
    } else if (onRowDoubleClick) onRowDoubleClick(row);
  };

  const getCellAlign = (column) => {
    if (column.type === "checkbox" && !column.cellAlign) return "text-center";
    if (isDirectionRTL) {
      if (column.cellAlign === undefined)
        return direction === "ltr" ? "text-right" : "text-left";
      else {
        switch (column.cellAlign) {
          case "left":
            return "text-right";
          case "right":
            return "text-left";
          default:
            return "text-center";
        }
      }
    } else {
      if (column.cellAlign === undefined)
        return direction === "ltr" ? "text-left" : "text-right";
      else {
        if (direction === "ltr") return `text-${column.cellAlign}`;
        else {
          switch (column.cellAlign) {
            case "left":
              return "text-right";
            case "right":
              return "text-left";
            default:
              return "text-center";
          }
        }
      }
    }
  };

  const getFontClass = (column) => {
    if (
      column.type === "number" ||
      column.type === "numberFormat" ||
      column.type === "date" ||
      column.type === "code"
    )
      return `Jarvis-Font-default Jarvis-Font-Size-${fontSize + 1}rem`;

    return `${_.get(
      language,
      "JarvisFontClass"
    )} Jarvis-Font-Size-${fontSize}rem`;
  };

  const getFooterValue = (column) => {
    return (
      _.get(
        footer.find((col) => col.path === column.path),
        "value"
      ) || ""
    );
  };

  const handleKeyDown = (e) => {
    if (currentRow) {
      let index = 0;
      const len = tableData.length;
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          index = data.indexOf(currentRow);
          if (index === len - 1) {
            totalRowHeight = e.target.scrollHeight;
            e.target.scrollTop = totalRowHeight;
            setCurrentRow(data[index]);
          } else {
            let nextRowHeight = 0;
            nextRowHeight = wrapText ? rowHeights[index + 1] : rowHeights[0];
            totalRowHeight += nextRowHeight;

            if (totalRowHeight - e.target.scrollTop > e.target.clientHeight) {
              e.target.scrollTop += nextRowHeight + 1;
            }
            setCurrentRow(data[index + 1]);
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          index = data.indexOf(currentRow);
          if (index === 0) {
            totalRowHeight = wrapText ? rowHeights[index] : rowHeights[0];
            e.target.scrollTop = 0;
            setCurrentRow(data[index]);
          } else {
            totalRowHeight -= rowHeights[index];
            let preRowHeight = 0;
            preRowHeight = wrapText ? rowHeights[index - 1] : rowHeights[0];

            if (totalRowHeight - e.target.scrollTop < preRowHeight) {
              e.target.scrollTop -= preRowHeight;
            }
            setCurrentRow(data[index - 1]);
          }
          break;
        case "Home":
          totalRowHeight = rowHeights[0];
          setCurrentRow(data[0]);
          break;
        case "End":
          totalRowHeight = e.target.scrollHeight;
          setCurrentRow(data[len - 1]);
          break;
        case "Delete":
          onDelete(currentRow);
          break;
        case "Enter":
          e.preventDefault();
          handleRowDoubleClick(currentRow);
          break;
        case "Insert":
          onAddRowClick(true);
          break;
        case " ":
          e.preventDefault();
          if (hasRowSelect) onRowSelectionClick(currentRow);
          break;
        default:
          break;
      }
    }
  };

  if (isLoading || data?.length === 0)
    return (
      <tbody className="table-borderless" style={bodyStyle}>
        <tr>
          <td
            className={clsx(
              language.JarvisFontClass,
              `Jarvis-Font-Size-${fontSize}rem`,
              "jarvisBody"
            )}
            key="noItems"
          >
            {isLoading ? isLoadingText : showFilter ? noItemsText : ""}
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody
      style={bodyStyle}
      ref={bodyRef}
      tabIndex="1"
      onKeyDown={handleKeyDown}
    >
      {tableData.map((row) => {
        increaseCounter();
        return (
          <tr
            key={row[idFieldName] ?? counter}
            style={getRowStyle(row)}
            className={
              hasSelectColumn &&
              selectedRows.findIndex(
                (selectedRow) => selectedRow[idFieldName] === row[idFieldName]
              ) !== -1
                ? "row-selected"
                : ""
            }
            onClick={() => handleRowClick(row)}
            onDoubleClick={() => handleRowDoubleClick(row)}
          >
            {columns?.map((column, index) => (
              <td
                style={getStyle(column)}
                className={clsx(
                  "align-middle",
                  getCellAlign(column),
                  getFontClass(column),
                  "jarvisBody"
                )}
                key={createKey(index, column)}
              >
                {renderCell(row, column)}
              </td>
            ))}
          </tr>
        );
      })}
      {footer && (
        <tr key="footer">
          {columns?.map((column, index) => (
            <td
              style={getStyle(column)}
              className={clsx(
                "align-middle",
                getCellAlign(column),
                language.JarvisFontClass,
                `Jarvis-Font-Size-${fontSize}rem`,
                "jarvisBody"
              )}
              key={`footer${index}`}
            >
              {getFooterValue(column)}
            </td>
          ))}
        </tr>
      )}
    </tbody>
  );
}
