import React, { useState } from "react";
import _ from "lodash";
import Button from "Jarvis/JarvisComponents/Button";
import { ConcurrentLoginDelete } from "../../Services/AccountService";
import { NotificationContainer } from "Jarvis/JarvisComponents/Notification";

const ConcurrentLogin = ({ concurrentLogins, onLoadConcurrentLogins, isDirectionRTL, isBackToLoginNeeded, isInternalSignIn = false }) => {
  const [stateObj, setStateObj] = useState({
    selectedItem: null,
    concurrentLogins,
  });
  const renderRow = () => {
    return stateObj.concurrentLogins.map((item) => {
      return (
        <tr key={item.UserConcurrentLoginManagerId}>
          <td>{item.EventLoginSpecificLoginToken}</td>
          <td>{item.LastRequestDateTime}</td>
          <td>{item.IpAddress}</td>
          <td>{item.DeviceInfo}</td>
          <td>{item.SystemLangDescription}</td>
          <td>
            <Button
              label="Delete"
              color="primary"
              variant="contained"
              onClick={async () => {
                await handleDeleteSession(item);
              }}
              showLoading={item.UserConcurrentLoginManagerId === stateObj.selectedItem?.UserConcurrentLoginManagerId ? true : false}
            />
          </td>
        </tr>
      );
    });
  };
  const handleDeleteSession = async (item) => {
    if (stateObj.selectedItem) return;
    setStateObj({ ...stateObj, selectedItem: item });
    const { ErrorHandling } = await ConcurrentLoginDelete({
      UserConcurrentLoginManagerId: item.UserConcurrentLoginManagerId,
      EventLoginSpecificLoginToken: item.EventLoginSpecificLoginToken,
    });
    let concurrentLogins = [];
    if (_.get(ErrorHandling, "IsSuccessful")) {
      concurrentLogins = stateObj.concurrentLogins.filter((_item) => _item.UserConcurrentLoginManagerId !== item.UserConcurrentLoginManagerId);
    }
    setStateObj({ ...stateObj, selectedItem: null, concurrentLogins });
    if (concurrentLogins.length === 0) onLoadConcurrentLogins(null);
  };
  return (
    <>
      <div className="col text-center">
        <strong>List of concurrent logins</strong>
      </div>
      <table className="table" width={"100%"}>
        <thead>
          <tr>
            <th>Login Token</th>
            <th>Last Request DateTime</th>
            <th>Ip Address</th>
            <th>Device Info</th>
            <th>System</th>
            <th>Operation</th>
          </tr>
        </thead>
        <tbody>{renderRow()}</tbody>
        {isBackToLoginNeeded === true && (
          <tfoot>
            <tr className="text-center">
              <td colSpan={6}>
                <Button label="Back to Login" color="primary" variant="contained" onClick={() => onLoadConcurrentLogins(null)} />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      <NotificationContainer
        position={isDirectionRTL ? "top-left" : "top-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={isDirectionRTL}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        containerId="loginToastContainerId"
        enableMultiContainer={true}
      />
    </>
  );
};

export default ConcurrentLogin;
