import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { CompanyRetrieve } from "Jarvis/JarvisSystems/Pub/Services/CompanyService";

export default function CompanySelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={CompanyRetrieve}
      serviceParams={{
        ...serviceParams,
        CompanyIsOperational: true,
        retrieveAsList: true,
        hierarchyShowMode: "Tree",
        isDeepSearchNeeded: true,
      }}
      displayFieldName="CompanyLangDescription"
      valueFieldName="CompanyId"
      {...rest}
    />
  );
}
