import React, { useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import FilterListIcon from "@material-ui/icons/FilterList";
import Checkbox from "Jarvis/JarvisComponents/Checkbox";
import Button from "Jarvis/JarvisComponents/Button";
import DatePicker from "../DatePicker";
import Searchbox from "../Searchbox";
import { convertGregorianTo } from "Jarvis/JarvisServices/JarvisCommonService";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export default function TableRowFiltering({
  column,
  isDirectionRTL,
  isFiltered,
  onRowFiltering,
  PE,
  whereClause,
}) {
  const classes = useStyles();
  const { control, setValue, getValues, handleSubmit } = useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [operator, setOperator] = useState("");
  const [selectedValues, setSelectedValues] = useState({});

  const open = Boolean(anchorEl);
  const id = open ? "filterForm" : undefined;

  const filterIconStyle = {
    color: isFiltered ? "#11cb5f" : grey[500],
    position: "relative",
    float: isDirectionRTL ? "left" : "right",
  };

  const handleInputRowFilter = () => {
    if (column.filterComponent) {
      const enhancedFilter = React.cloneElement(column.filterComponent, {
        label: column.label,
        name: column.path,
        control,
        value: selectedValues[column.path],
        onChange: (value) =>
          setSelectedValues({ ...selectedValues, [column.path]: value }),
      });

      return enhancedFilter;
    }

    switch (column.type) {
      case "checkbox":
        return (
          <div className={classes.root} align="center">
            <Checkbox
              label={column.label}
              control={control}
              name={column.path}
              value={
                whereClause
                  ? _.get(
                      whereClause.find(
                        (where) =>
                          where.FieldName ===
                          (column.searchFieldName ?? column.path)
                      ),
                      "Value"
                    )
                  : false
              }
            />
          </div>
        );
      case "date":
        return (
          <React.Fragment>
            <DatePicker
              label={_.get(PE, "From") || "From"}
              control={control}
              name={`${column.path}_From`}
              setValue={setValue}
              size="small"
              autoFocus
              value={convertGregorianTo(
                _.get(
                  _.find(
                    whereClause,
                    (where) =>
                      where.FieldName === `${column.path}` &&
                      where.Operator === ">="
                  ),
                  "Value"
                )
              )}
            />
            <DatePicker
              label={_.get(PE, "Till") || "Till"}
              control={control}
              name={`${column.path}_Till`}
              setValue={setValue}
              size="small"
              value={convertGregorianTo(
                _.get(
                  _.find(
                    whereClause,
                    (where) =>
                      where.FieldName === `${column.path}` &&
                      where.Operator === "<="
                  ),
                  "Value"
                )
              )}
            />
          </React.Fragment>
        );
      default:
        return (
          <Searchbox
            control={control}
            name={column.path}
            placeholder={column.label}
            textAlign={
              column.type === "number" || column.type === "numberFormat"
                ? "left"
                : column.textAlign ?? column.cellAlign
            }
            type={column.type ?? "text"}
            onSearch={onSubmit}
            clearSearch={false}
            PE={PE}
            variant="outlined"
            operator={operator}
            setOperator={setOperator}
            setValue={setValue}
            hasOperators
            autoFocus
            value={
              whereClause
                ? _.get(
                    whereClause.find(
                      (where) =>
                        where.FieldName ===
                        (column.searchFieldName ?? column.path)
                    ),
                    "Value"
                  )
                : undefined
            }
          />
        );
    }
  };

  const handleRowFilteringClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const onSubmit = (data) => {
    let whereClause = null;
    if (column.type === "date") {
      whereClause = [];
      if (getValues(`${column.path}_From`)) {
        const fromDate = {
          FieldName: column.path,
          DataType: "DATE",
          Operator: ">=",
          Value: getValues(`${column.path}_From`),
        };
        whereClause.push(fromDate);
      }
      if (getValues(`${column.path}_Till`)) {
        const tillDate = {
          FieldName: column.path,
          DataType: "DATE",
          Operator: "<=",
          Value: getValues(`${column.path}_Till`),
        };
        whereClause.push(tillDate);
      }
    } else {
      let FieldName = column.searchFieldName ?? column.path;

      let DataType = "STRING";
      let Operator = "";
      let Value = _.isString(data) ? data : _.get(data, column.path);
      if (column.filterComponent)
        Value = _.get(Value, column.filterId ?? column.searchFieldName);

      switch (operator) {
        case "equals":
          Operator = "=";
          break;
        case "notContain":
          Operator = "NOT LIKE";
          break;
        case "contains":
          Operator = "LIKE";
          break;
        case "startsWith":
          Operator = "StartsWith";
          break;
        case "endsWith":
          Operator = "EndsWith";
          break;
        case "null":
          Value = "IsNull";
          Operator = "";
          break;
        case "isNotNull":
          Value = "IsNotNull";
          Operator = "";
          break;
        default:
          if (column.filterComponent) DataType = "NUMBER";
          break;
      }
      switch (column.type) {
        case "checkbox":
        case "switch":
          DataType = "BIT";
          Operator = "=";
          break;
        case "number":
        case "numberFormat":
          DataType = "NUMBER";
          Value = Value.replaceAll(",", "");
          if (operator === "") Operator = "=";
          else Operator = operator;
          break;
        default:
          if (column.filterComponent) Operator = "=";
          else Operator = operator === "" ? "LIKE" : Operator;
          break;
      }
      whereClause = {
        FieldName,
        DataType,
        Operator,
        Value,
      };
    }
    onRowFiltering(column, whereClause);
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    onRowFiltering(column, {
      FieldName: column.path,
    });
    setAnchorEl(null);
    setOperator("");
  };

  return (
    <React.Fragment>
      <FilterListIcon
        style={filterIconStyle}
        className="cursor-pointer"
        aria-haspopup="true"
        fontSize="small"
        onClick={handleRowFilteringClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form
          className={classes.root}
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmit(onSubmit)}
        >
          {handleInputRowFilter()}
          <div className={classes.root} align="center">
            <Button
              label={_.get(PE, "Filter") || "Filter"}
              className="mx-2"
              size="small"
              variant="contained"
              color="primary"
              type="submit"
            />
            <Button
              label={_.get(PE, "ClearFilter") || "Cancel filter"}
              size="small"
              variant="outlined"
              onClick={handleClearFilter}
            />
          </div>
        </form>
      </Popover>
    </React.Fragment>
  );
}
