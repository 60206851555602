/*------------
Version: 4.2.1
------------*/
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { HotKeys } from "react-keyboard";
import { isIOS, isMobile } from "react-device-detect";
import { ConfirmationDialog } from "Jarvis/JarvisComponents/Confirmation";
import {
  isNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import { notify } from "../Notification";
import TreeGridHeader from "./TreeGridHeader";
import TreeGridBody from "./TreeGridBody";
import "./index.scss";
import ColumnSelect from "../Table/ColumnSelect";
import TreeGridOperations from "./TreeGridOperations";

const defaultRetrieveInput = {
  where: null, // []
  isDeepSearchNeeded: false,
};
const defaultStateObject = {
  active: false,
  dataToDisplay: [],
  cachedArray: [],
  hasSelectColumn: false,
  serviceParams: null,
};
const _uuid = `treeGrid-${uuid()}`;

export default function TreeGrid({
  columns,
  columnsStackSize,
  configs,
  columnFixedWidth,
  customHeader,
  customMenuItems,
  customOperationItems,
  data,
  hasBorder,
  hasDeleteConfirm,
  hasOperationsButton,
  deleteExtraContent,
  deleteService,
  deleteTitle,
  displayMode,
  editOnDoubleClick,
  expandableColumnPath,
  fontSize,
  footer,
  gridClass,
  hasAddButton,
  hasAddMenuButton,
  hasBadge,
  hasColumnSelect,
  hasEditButton,
  hasExportToExcel,
  hasDeleteButton,
  hasHeader,
  hasOperations,
  hasRoot,
  hasRowSelect,
  hasRowFilter,
  height,
  isChildrenSelection,
  idFieldName,
  inputForm,
  isDraggable,
  isExpanded,
  isLeafSelectableOnly,
  isTriple,
  langData,
  levelOffset,
  moduleCodeString,
  multiSelect,
  nodeCanBeSelectedIf,
  onAddButtonClick,
  onEditButtonClick,
  onEditMode,
  onRowDoubleClick,
  onRowSelect,
  onShowInputForm,
  refreshInfo,
  selectNodeIf,
  service,
  serviceParams,
  showRowFilter,
  showInputForm,
  showOperationalPanel,
  showOperations,
  showSearchRow,
  showSelectColumn,
  tableClass,
  theadClass,
  title,
  trStyle,
  wrapHeader,
  wrapText,
}) {
  const { isDirectionRTL } = useSelector((state) => state.settings);
  let PE = _.get(useContext(GlobalContext), "PE.PubPublicElems");
  if (langData) PE = langData;
  const [cachedArrayTemp, setCachedArrayTemp] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [dataToDisplayTemp, setDataToDiasplayTemp] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [expandingNode, setExpandingNode] = useState(null);
  const [isLoadingExpand, setIsLoadingExpand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [retrieveInput, setRetrieveInput] = useState(defaultRetrieveInput);
  const [selectAll, setSelectAll] = useState(false);
  const [selectChildren, setSelectChildren] = useState(isChildrenSelection);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [showColumnSelect, setShowColumnSelect] = useState(false);
  const [showDragHandler, setShowDragHandler] = useState(false);
  const [showOperationsColumn, setShowOperationsColumn] = useState(
    showOperations
  );
  const [showFilter, setShowFilter] = useState(showRowFilter);
  const [stateObject, setStateObject] = useState(defaultStateObject);
  const [tableInfo, setTableInfo] = useState({});

  const gridRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(height);

  const keyMap = {
    add: "ins",
    refresh: "shift+r",
  };
  const handlers = {
    add: () => handleAddClick(true),
    refresh: () => handleRefreshGrid(),
  };
  if (customMenuItems) {
    for (let i = 0; i < customMenuItems.length; i++) {
      if (customMenuItems[i].shortcut) {
        keyMap[customMenuItems[i].name] = customMenuItems[i].shortcut;
        handlers[customMenuItems[i].name] = (e) => {
          e.preventDefault();
          customMenuItems[i].onClick();
        };
      }
    }
  }
  let gridClassName =
    tableClass === undefined ? "table " : `table ${tableClass}`;

  gridClassName += hasBorder
    ? " table-striped table-bordered table-sm"
    : " table-borderless table-sm";
  gridClassName = clsx(gridClass, gridClassName);

  const hasOperationalPanel = displayMode === "full" ? hasOperations : false;
  const hasTopPanelMenu = displayMode === "view" ? false : true;
  const expandableColumnIndex = expandableColumnPath
    ? _.get(tableInfo, "columns")?.findIndex(
        (col) => col.path === expandableColumnPath
      )
    : showOperationsColumn
    ? 1
    : 0;

  const operationsColumn = {
    path: "operationsColumn",
    content: (row) => (
      <TreeGridOperations
        currentRow={row}
        customOperationItems={customOperationItems}
        addTooltip={_.get(PE, "Create") || "Create"}
        deleteTooltip={_.get(PE, "Delete") || "Delete"}
        editTooltip={_.get(PE, "Edit") || "Edit"}
        hasAddButton={hasAddButton}
        hasEditButton={hasEditButton}
        hasDeleteButton={hasDeleteButton}
        isDirectionRTL={isDirectionRTL}
        onAddClick={() => handleAddClick(true, row)}
        onDeleteClick={() => handleShowDeleteConfirmationDialog(row)}
        onEditClick={() => handleEditClick(row)}
      />
    ),
    cellAlign: "center",
    hasFiltering: false,
    width: 60,
    fixed: true,
    sortable: false,
  };

  const getColumns = useCallback(() => {
    let containerWidth = gridRef.current ? gridRef.current.offsetWidth : 1019;

    let resultColumns = [];
    let scale = 0;
    let len = 0;
    let occupiedWidth = 0;

    if (showOperationsColumn) {
      resultColumns.push(operationsColumn);
      occupiedWidth += operationsColumn.width;
    }

    const columnsOrder = _.get(configs, `${moduleCodeString}.Table.Columns`);
    if (!isNullOrWhiteSpace(columnsOrder)) {
      let column = {};
      columnsOrder.split(",").forEach((columnName) => {
        column = columns.find((col) => col.path === columnName);
        if (column) {
          ++len;
          resultColumns.push(column);
          scale += column.widthScale ?? 1;
        }
      });
    } else if (columns) {
      columns.forEach((column) => {
        if (column.visible !== false) {
          ++len;
          resultColumns.push(column);
          scale += column.widthScale ?? 1;
        }
      });
    }

    const remainedWidth = containerWidth - occupiedWidth - 19;
    let columnWidth =
      isMobile || isIOS ? columnFixedWidth ?? 150 : remainedWidth / scale;
    let totalWidth = occupiedWidth;
    if (len > columnsStackSize ?? 5)
      columnWidth = (remainedWidth + (len - 5) * columnWidth) / len;

    resultColumns.forEach((column) => {
      if (!column.fixed) {
        column.width =
          columnWidth * (isMobile || isIOS ? 1 : column.widthScale ?? 1);
        totalWidth += column.width;
      }
    });

    const header = [];
    if (!isNullOrWhiteSpace(title))
      header.push([
        {
          label: title,
          key: `${moduleCodeString ?? idFieldName}-title`,
          width: totalWidth,
          className: "bg-white",
        },
      ]);
    if (customHeader) {
      let count = 0,
        n = customHeader.length;

      for (let i = 0, sum = 0, k = 0; i < n; i++) {
        count = 0;
        sum = 0;
        for (let j = k; j < resultColumns.length; j++) {
          sum += resultColumns[j].width;
          ++k;
          if (i < n - 1 && ++count === (customHeader[i].mergeCells ?? 1)) break;
        }
        customHeader[i].width = sum;
      }
      header.push(customHeader);
    }
    setTableInfo({
      header: header,
      columns: resultColumns,
      width: totalWidth + 19,
    });
  }, [columns, customHeader, showOperationsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFlattenArray = (dataArray, parent) => {
    let resultArray = [];
    let elemToAdd = {};
    let node = {};
    let hasChildren = false;

    if (_.isArray(dataArray)) {
      for (let i = 0; i < dataArray.length; i++) {
        hasChildren = dataArray[i].ChildrenCount > 0;
        node = dataArray[i];
        elemToAdd = {
          ...node,
          hasChildren: hasChildren,
        };

        if (retrieveInput.where?.length > 0 || isExpanded) {
          elemToAdd.cached = hasChildren;
          elemToAdd.expanded =
            hasChildren &&
            _.get(dataArray[i + 1], `${idFieldName}Parent`) ===
              node[idFieldName];

          elemToAdd.parent = node[`${idFieldName}Parent`]
            ? { [idFieldName]: node[`${idFieldName}Parent`], VT: node.VTParent }
            : null;
        } else {
          elemToAdd.cached = !hasChildren;
          elemToAdd.expanded = node.expanded ?? false;
          elemToAdd.parent = parent;
        }
        elemToAdd.selected = selectNodeIf
          ? selectNodeIf(node)
          : selectChildren
          ? !!elemToAdd.parent?.selected
          : false;

        resultArray.push(elemToAdd);
      }
    }

    return resultArray;
  };

  const fetchData = useCallback(async () => {
    if (service) {
      if (serviceParams?.refreshParent === true) {
        serviceParams.refreshParent = false;
        setExpandingNode(currentRow.parent);
        if (currentRow.parent) handleRefreshNodeClick(currentRow.parent);
        return;
      }
      setIsLoading(true);
      let params = retrieveInput;
      if (serviceParams) params = { ...params, ...serviceParams };
      if (params.where?.length === 0) params.where = null;

      const response = await service(params);
      setIsLoading(false);
      if (response) {
        const { ErrorHandling, Data } = _.get(response, "data") ?? response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          const dataArray = getFlattenArray(Data, null);
          setStateObject({
            dataToDisplay: dataArray,
            cachedArray: [...dataArray],
            active: true,
            serviceParams: serviceParams,
            hasSelectColumn: showSelectColumn,
          });
        } else setStateObject(defaultStateObject);
      }
    } else {
      if (data) {
        const firstLevelNodes = getFlattenArray(data, null);
        setStateObject({
          active: true,
          dataToDisplay: firstLevelNodes,
          cachedArray: firstLevelNodes,
          hasSelectColumn: false,
          serviceParams: null,
        });
      } else setStateObject(defaultStateObject);
    }
  }, [retrieveInput, service, serviceParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !stateObject.active ||
      !_.isEqual(serviceParams, stateObject.serviceParams)
    ) {
      fetchData();
    }
  }, [stateObject.active, serviceParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getColumns();
  }, [columns, showOperationsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refreshInfo) {
      let { isEditMode, newRecord, refresh } = refreshInfo;
      if (refresh) {
        fetchData();
        return;
      }
      const isArray = _.isArray(newRecord);
      let result = [];
      let tableDataTemp = [...stateObject.dataToDisplay];
      let cachedDataTemp = [...stateObject.cachedArray];
      let index = 0;
      if (isEditMode) {
        if (isArray) {
          let indexTemp = 0;
          let parent = null;
          let nodeProps = {};
          for (let i = 0; i < newRecord.length; i++) {
            const editedRecord = newRecord[i];
            index = tableDataTemp.findIndex(
              (row) => row[idFieldName] === editedRecord[idFieldName]
            );
            if (index !== -1) {
              parent = stateObject.dataToDisplay[index].parent;
              nodeProps = _.pick(stateObject.dataToDisplay[index], [
                "cached",
                "expanded",
                "hasChildren",
                "parent",
                "selected",
              ]);
              result = getFlattenArray(
                [_.merge(newRecord[i], nodeProps)],
                parent
              );
              tableDataTemp.splice(index, 1, ...result);
              cachedDataTemp.splice(index, 1, ...result);
            } else {
              newRecord = newRecord.slice(1);
              for (let i = 0; i < newRecord.length; i++) {
                newRecord[i] = _.merge(newRecord[i], nodeProps);
              }
              result = getFlattenArray(newRecord, parent);
              tableDataTemp.splice(indexTemp + 1, 0, ...result);
              cachedDataTemp.splice(indexTemp + 1, 0, ...result);
              break;
            }
            indexTemp = index;
          }

          setCurrentRow(tableDataTemp[indexTemp]);
        } else {
          index = tableDataTemp.findIndex(
            (row) => row[idFieldName] === newRecord[idFieldName]
          );
          const parent = stateObject.dataToDisplay[index].parent;
          const nodeProps = _.pick(stateObject.dataToDisplay[index], [
            "cached",
            "expanded",
            "hasChildren",
            "parent",
            "selected",
          ]);
          result = getFlattenArray([_.merge(newRecord, nodeProps)], parent);
          tableDataTemp.splice(index, 1, ...result);
          cachedDataTemp.splice(index, 1, ...result);

          setCurrentRow(tableDataTemp[index]);
        }

        setStateObject((stateObject) => ({
          ...stateObject,
          dataToDisplay: tableDataTemp,
          cachedArray: cachedDataTemp,
        }));
      } else {
        if (
          !selectedNode ||
          !selectedNode.hasChildren ||
          selectedNode.expanded
        ) {
          if (isArray) result = getFlattenArray(newRecord, selectedNode);
          else result = getFlattenArray([newRecord], selectedNode);
          let count = 0;
          let selectedLevel = selectedNode ? selectedNode.Level : 1;
          if (selectedNode) {
            index = tableDataTemp.findIndex(
              (node) => node[idFieldName] === selectedNode[idFieldName]
            );
            selectedNode.cached = true;
            selectedNode.expanded = true;
            selectedNode.hasChildren = true;
            if (isArray)
              selectedNode.ChildrenCount =
                selectedNode.ChildrenCount + newRecord.length;
            else ++selectedNode.ChildrenCount;

            let len = tableDataTemp.length;
            let i = index + 1;
            while (i < len && tableDataTemp[i++].Level > selectedLevel) ++count;
            if (isArray) tableDataTemp.splice(index + count + 1, 0, ...result);
            else tableDataTemp.splice(index + count + 1, 0, result[0]);
          } else {
            if (isArray) tableDataTemp.push(...result);
            else tableDataTemp.push(result[0]);
          }

          if (selectedNode) {
            index = cachedDataTemp.findIndex((node) =>
              _.isEqual(node, selectedNode)
            );
            let len = cachedDataTemp.length;
            let i = index + 1;
            while (i < len && cachedDataTemp[i++].Level > selectedLevel)
              ++count;
            if (isArray) cachedDataTemp.splice(index + count + 1, 0, ...result);
            else cachedDataTemp.splice(index + count + 1, 0, result[0]);
          } else {
            if (isArray) cachedDataTemp.push(...result);
            else cachedDataTemp.push(result[0]);
          }

          setStateObject((stateObject) => ({
            ...stateObject,
            dataToDisplay: tableDataTemp,
            cachedArray: cachedDataTemp,
          }));
        } else {
          if (selectedNode.cached) selectedNode.cached = false;

          ++selectedNode.ChildrenCount;
        }
      }
    }
  }, [refreshInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridRef.current) {
      const table = gridRef.current;
      const tbody = document.getElementById(_uuid).children[hasHeader ? 1 : 0];
      const height =
        table.offsetParent.offsetHeight -
        table.offsetTop -
        tbody.offsetTop -
        30;
      setTableHeight(height);
    }
  }, [gridRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.isEmpty(columns)) return <div />;

  const handleToggleExpand = async (node) => {
    let tempData = [...stateObject.dataToDisplay];
    let cachedDataTemp = [...stateObject.cachedArray];
    const index = tempData.findIndex(
      (item) => item[idFieldName] === node[idFieldName]
    );
    let selectedNode = tempData[index];

    if (!node.expanded) {
      const cacheIndex = stateObject.cachedArray.findIndex(
        (item) => item[idFieldName] === node[idFieldName]
      );
      if (!selectedNode.cached) {
        setIsLoadingExpand(true);
        let params = retrieveInput;
        if (serviceParams) params = { ...params, ...serviceParams };
        const response = await service({
          ...params,
          node: node,
        });
        setIsLoadingExpand(false);
        if (response) {
          const { ErrorHandling, Data } = _.get(response, "data") ?? response;
          if (_.get(ErrorHandling, "IsSuccessful")) {
            if (Data?.length > 0) {
              let children = getFlattenArray(Data, selectedNode);
              tempData.splice(index + 1, 0, ...children);
              // update cache
              cachedDataTemp.splice(cacheIndex + 1, 0, ...children);
            }
          }
        }
      } else {
        // index of cache array to be inserted
        let indexArray = [];
        const selectedLevel = selectedNode.Level;
        let i = cacheIndex + 1;
        const len = stateObject.cachedArray.length;
        let cacheSelectedNode = stateObject.cachedArray[i];
        while (i < len && cacheSelectedNode.Level > selectedLevel) {
          indexArray.push(i);
          if (
            cacheSelectedNode.hasChildren &&
            cacheSelectedNode.cached &&
            !cacheSelectedNode.expanded
          ) {
            // skip the children of a cached collapsed node
            const cacheSelectedNodeLevel = cacheSelectedNode.Level;
            let j = i + 1;
            while (
              j < len &&
              stateObject.cachedArray[j++].Level > cacheSelectedNodeLevel
            ) {
              ++i;
            }
          }
          ++i;
          cacheSelectedNode = stateObject.cachedArray[i];
        }
        for (let i = 0; i < indexArray.length; i++) {
          tempData.splice(
            index + i + 1,
            0,
            stateObject.cachedArray[indexArray[i]]
          );
        }
      }
    } else {
      // how many rows are expanded that are children of selected node
      let count = 0;
      let selectedLevel = selectedNode.Level;
      let len = tempData.length;
      let i = index + 1;
      while (i < len && tempData[i++].Level > selectedLevel) ++count;
      tempData.splice(index + 1, count);
    }

    selectedNode.expanded = !selectedNode.expanded;
    if (!selectedNode.cached) {
      selectedNode.cached = true;
      setStateObject((stateObject) => ({
        ...stateObject,
        dataToDisplay: tempData,
        cachedArray: cachedDataTemp,
      }));
    } else {
      setStateObject((stateObject) => ({
        ...stateObject,
        dataToDisplay: tempData,
      }));
    }
  };

  const handleCollapseAllClick = () => {
    let tempData = [];
    stateObject.dataToDisplay.forEach((node) => {
      if (node.Level === 1) {
        node.expanded = false;
        tempData.push(node);
      }
    });
    let cachedDataTemp = [...stateObject.cachedArray];
    cachedDataTemp.forEach((node) => {
      if (node.expanded) {
        node.expanded = false;
      }
    });
    setStateObject((stateObject) => ({
      ...stateObject,
      dataToDisplay: tempData,
      cachedArray: cachedDataTemp,
    }));
  };

  const renderDeleteConfirmation = () => {
    let extraContent = "";

    if (_.isFunction(deleteExtraContent)) {
      extraContent = deleteExtraContent(currentRow);
    } else {
      if (deleteExtraContent?.includes(",")) {
        deleteExtraContent.split(",").forEach((str) => {
          if (!isNullOrWhiteSpace(str))
            extraContent += `${_.get(deleteData, str) ?? str} `;
        });
      } else
        extraContent =
          _.get(deleteData, deleteExtraContent) ?? deleteExtraContent;
    }

    return (
      <ConfirmationDialog
        open={showDeleteConfirmationDialog}
        title={deleteTitle ?? _.get(PE, "Delete") ?? "Delete"}
        content={
          _.get(PE, "DeleteConfirmation") ?? "Are you sure you want to delete?"
        }
        extraContent={extraContent}
        isLoading={isLoadingDelete}
        acceptText={_.get(PE, "Yes")}
        closeText={_.get(PE, "No")}
        onAcceptBtnClick={handleDelete}
        onCloseBtnClick={() => setShowDeleteConfirmationDialog(false)}
        onClose={() => setShowDeleteConfirmationDialog(false)}
        showDeleteIcon
      />
    );
  };

  const handleRefreshGrid = () => {
    setStateObject({
      ...defaultStateObject,
      active: false,
    });
    setRetrieveInput({ ...defaultRetrieveInput });
    setCurrentRow(null);
  };

  const handleRefreshNodeClick = async (node) => {
    let tempData = [...stateObject.dataToDisplay];
    let cachedDataTemp = [...stateObject.cachedArray];
    const index = tempData.findIndex(
      (item) => item[idFieldName] === node[idFieldName]
    );
    let selectedNode = tempData[index];
    let count = 0,
      selectedLevel = 0,
      len = 0,
      i = 0;
    // fetch data
    setIsLoadingExpand(true);
    let children = null;
    let params = retrieveInput;
    if (serviceParams) params = { ...params, ...serviceParams };
    const response = await service({
      ...params,
      node: node,
    });
    setIsLoadingExpand(false);
    if (response) {
      const { ErrorHandling, Data } = _.get(response, "data") ?? response;
      if (_.get(ErrorHandling, "IsSuccessful")) {
        if (Data?.length > 0) {
          children = getFlattenArray(Data, selectedNode);
        }
      }
    }

    if (node.expanded) {
      // clear children of selected node
      count = 0;
      selectedLevel = selectedNode.Level;
      len = tempData.length;
      i = index + 1;
      while (i < len && tempData[i++].Level > selectedLevel) ++count;
      tempData.splice(index + 1, count);
      // update display
      if (children) tempData.splice(index + 1, 0, ...children);
    }

    const cacheIndex = stateObject.cachedArray.findIndex(
      (item) => item[idFieldName] === node[idFieldName]
    );
    if (selectedNode.cached) {
      // clear children of cached selected node
      count = 0;
      selectedLevel = selectedNode.Level;
      len = cachedDataTemp.length;
      i = cacheIndex + 1;
      while (i < len && cachedDataTemp[i++].Level > selectedLevel) ++count;
      cachedDataTemp.splice(index + 1, count);
    } else selectedNode.cached = true;

    // update cache
    cachedDataTemp.splice(cacheIndex + 1, 0, ...children);

    setStateObject((stateObject) => ({
      ...stateObject,
      dataToDisplay: tempData,
      cachedArray: cachedDataTemp,
    }));
  };

  const handleRowFiltering = (whereObj) => {
    let whereClauseTemp = retrieveInput.where ? [...retrieveInput.where] : [];
    const index = whereClauseTemp.findIndex(
      (item) => item.FieldName === whereObj.FieldName
    );

    if (index !== -1) {
      whereClauseTemp.splice(index, 1);
      if (whereClauseTemp.length === 0 && !whereObj.Value) {
        setStateObject((stateObject) => ({
          ...stateObject,
          active: true,
          dataToDisplay: dataToDisplayTemp,
          cachedArray: cachedArrayTemp,
        }));
        setRetrieveInput({
          where: null,
          isDeepSearchNeeded: false,
        });
      } else {
        if (whereObj.Value !== undefined) whereClauseTemp.push(whereObj);

        setRetrieveInput({
          where: whereClauseTemp,
          isDeepSearchNeeded: true,
        });
        setStateObject((stateObject) => ({
          ...stateObject,
          active: false,
        }));
      }
    } else {
      if (!_.isNil(whereObj.Value)) {
        if (whereClauseTemp.length === 0) {
          setDataToDiasplayTemp(stateObject.dataToDisplay);
          setCachedArrayTemp(stateObject.cachedArray);
        }
        whereClauseTemp.push(whereObj);
        setRetrieveInput({
          where: whereClauseTemp,
          isDeepSearchNeeded: true,
        });
        setStateObject({
          ...defaultStateObject,
          active: false,
        });
      }
    }
  };

  const handleRowFilterClick = (showRowFilter) => {
    if (showFilter && retrieveInput.where?.length > 0) {
      setStateObject((stateObject) => ({
        ...stateObject,
        active: true,
        dataToDisplay: dataToDisplayTemp,
        cachedArray: cachedArrayTemp,
      }));
      setRetrieveInput({
        where: null,
        isDeepSearchNeeded: false,
      });
    }
    setShowFilter(showRowFilter);
  };

  const handleRowSelectClick = () => {
    if (stateObject.hasSelectColumn && selectedNodes.length > 0) {
      selectedNodes.forEach((node) => {
        stateObject.dataToDisplay.find(
          (data) => data[idFieldName] === node[idFieldName]
        ).selected = false;
      });
      selectedNodes.forEach((node) => {
        stateObject.cachedArray.find(
          (data) => data[idFieldName] === node[idFieldName]
        ).selected = false;
      });
      setStateObject((stateObject) => ({
        ...stateObject,
        hasSelectColumn: !stateObject.hasSelectColumn,
      }));
      setSelectedNodes([]);
    } else
      setStateObject((stateObject) => ({
        ...stateObject,
        hasSelectColumn: !stateObject.hasSelectColumn,
      }));
  };

  const handleRowSelectionClick = (row, selectAll) => {
    debugger;
    let tempData = [...stateObject.dataToDisplay];
    let selectedNodesTemp = [...selectedNodes];
    if (row) {
      const index = tempData.findIndex(
        (item) => item[idFieldName] === row[idFieldName]
      );
      const indexSelected = selectedNodesTemp.findIndex(
        (node) => node && node[idFieldName] === row[idFieldName]
      );

      if (!multiSelect) {
        if (row.selected) {
          row.selected = false;
          selectedNodesTemp.splice(indexSelected, 1);
        } else if (!nodeCanBeSelectedIf || nodeCanBeSelectedIf(row)) {
          for (let i = 0; i < tempData.length; i++)
            if (tempData[i].selected) {
              tempData[i].selected = false;
              break;
            }
          if (isTriple && row.selected === false) row.selected = "";
          else row.selected = true;
          selectedNodesTemp[index] = row;
        }
      } else {
        if (row.selected) {
          row.selected = false;
          selectedNodesTemp.splice(indexSelected, 1);
          if (selectChildren) {
            let i = index + 1;
            let j = -1;
            if (row.expanded) {
              while (tempData[i].parent?.[idFieldName] === row[idFieldName]) {
                tempData[i].selected = false;
                j = selectedNodesTemp.findIndex(
                  // eslint-disable-next-line
                  (node) =>
                    node && node[idFieldName] === tempData[i][idFieldName]
                );
                selectedNodesTemp.splice(j, 1);
                ++i;
              }
            }
          }
        } else if (!nodeCanBeSelectedIf || nodeCanBeSelectedIf(row)) {
          if (isTriple && row.selected === false) row.selected = "";
          else row.selected = true;
          selectedNodesTemp[index] = row;
          if (selectChildren) {
            let i = index + 1;
            while (tempData[i]?.parent?.[idFieldName] === row[idFieldName]) {
              tempData[i].selected = true;
              selectedNodesTemp[i] = tempData[i];
              ++i;
            }
          }
        }
      }
    } else if (selectAll) {
      setSelectAll(true);
      selectedNodesTemp = [];
      tempData.forEach((node) => {
        if (isLeafSelectableOnly) {
          if (
            !node.hasChildren &&
            (!nodeCanBeSelectedIf || nodeCanBeSelectedIf(node))
          ) {
            node.selected = true;
            selectedNodesTemp.push(node);
          }
        } else if (!nodeCanBeSelectedIf || nodeCanBeSelectedIf(node)) {
          node.selected = true;
          selectedNodesTemp.push(node);
        }
      });
    } else {
      setSelectAll(false);
      tempData.forEach((node) => (node.selected = false));
      selectedNodesTemp = [];
    }
    setStateObject((stateObject) => ({
      ...stateObject,
      dataToDisplay: tempData,
    }));

    setSelectedNodes(selectedNodesTemp);
  };

  const handleAddClick = (open, parent) => {
    if (onAddButtonClick) onAddButtonClick(parent);
    else if (inputForm) {
      onEditMode(null);
      onShowInputForm(open, parent);
    }
    setSelectedNode(parent);
  };

  const handleEditClick = (row) => {
    if (onEditButtonClick) onEditButtonClick(row);
    else if (inputForm) {
      onEditMode(row);
      onShowInputForm(true);
    }
  };

  const handleShowDeleteConfirmationDialog = (row) => {
    if (hasDeleteConfirm) {
      setDeleteData(row);
      setShowDeleteConfirmationDialog(true);
    } else handleDelete(row);
  };

  const handleDelete = async (deleteRow) => {
    const _data = deleteData ?? deleteRow;
    if (!_data.hasChildren) {
      setIsLoadingDelete(true);
      const { ErrorHandling } = await deleteService(_data);
      setIsLoadingDelete(false);
      if (_.get(ErrorHandling, "IsSuccessful")) {
        if (hasDeleteConfirm) setShowDeleteConfirmationDialog(false);
        let tableDataTemp = [...stateObject.dataToDisplay];
        let index = tableDataTemp.findIndex(
          (item) => item[idFieldName] === _data[idFieldName]
        );
        const temp = tableDataTemp[index];

        if (temp.parent) {
          const indexParent = tableDataTemp.findIndex(
            (node) => node[idFieldName] === temp.parent[idFieldName]
          );
          --tableDataTemp[indexParent].ChildrenCount;
          const childrenCount = tableDataTemp[indexParent].ChildrenCount;
          tableDataTemp[indexParent].expanded = childrenCount > 0;
          tableDataTemp[indexParent].hasChildren = childrenCount > 0;
        }
        tableDataTemp.splice(index, 1);
        let cachedDataTemp = [...stateObject.cachedArray];
        index = cachedDataTemp.findIndex(
          (item) => item[idFieldName] === _data[idFieldName]
        );

        cachedDataTemp.splice(index, 1);
        setStateObject((stateObject) => ({
          ...stateObject,
          dataToDisplay: tableDataTemp,
          cachedArray: cachedDataTemp,
        }));

        if (stateObject.dataToDisplay.length > 0)
          setCurrentRow(tableDataTemp[index]);
        else setCurrentRow(null);
      }
    } else
      notify({
        message: _.get(PE, "DeleteIsNotAllowed"),
        type: "warning",
      });
  };

  const handleExport = () => {
    //exportToCSV("data");
    // return ExportToPdf();
  };

  let topPanelProps = {};

  if (displayMode === "full") {
    topPanelProps = {
      currentRow,
      customMenuItems,
      hasBadge,
      hasColumnSelect,
      hasExportToExcel,
      hasOperations: hasOperationsButton,
      hasRowSelect,
      hasRowFilter,
      isDraggable,
      multiSelect,
      onAddClick: handleAddClick,
      onBadgeClick: setShowBadge,
      onCollapseAllClick: handleCollapseAllClick,
      onColumnSelectClick: setShowColumnSelect,
      onDragClick: setShowDragHandler,
      onExportClick: handleExport,
      onOperationsClick: setShowOperationsColumn,
      onRowFilterClick: handleRowFilterClick,
      onRowSelectClick: handleRowSelectClick,
      onRefreshGridClick: handleRefreshGrid,
      onSelectAllClick: handleRowSelectionClick,
      onSelectChildrenClick: setSelectChildren,
      PE,
      rowFilter: showFilter,
      rowSelect: stateObject.hasSelectColumn,
      selectAll,
      selectChildren,
      selectedNodes,
      showAdd: hasRoot
        ? hasAddMenuButton && _.get(stateObject, "dataToDisplay").length === 0
        : hasAddMenuButton,
      showBadge,
      showDragHandler,
      showOperationsColumn,
    };
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div>
        {showInputForm && inputForm}
        {showDeleteConfirmationDialog && renderDeleteConfirmation()}
        {showColumnSelect && (
          <ColumnSelect
            columns={columns}
            configs={configs}
            getColumns={getColumns}
            moduleCodeString={moduleCodeString}
            onClose={() => setShowColumnSelect(false)}
            PE={PE}
            showColumnSelect={showColumnSelect}
          />
        )}

        <div style={{ outline: "none" }} tabIndex="0" ref={gridRef}>
          <div className="table-responsive">
            <table id={_uuid} className={gridClassName}>
              {hasHeader && (
                <TreeGridHeader
                  columns={_.get(tableInfo, "columns")}
                  customHeader={_.get(tableInfo, "header")}
                  expandableColumnIndex={expandableColumnIndex}
                  fontSize={fontSize}
                  hasFiltering={showFilter}
                  hasTopPanelMenu={hasTopPanelMenu}
                  isDirectionRTL={isDirectionRTL}
                  onRowFiltering={handleRowFiltering}
                  PE={PE}
                  showOperationsColumn={showOperationsColumn}
                  theadClass={theadClass}
                  topPanelProps={topPanelProps}
                  whereClause={retrieveInput.where ?? []}
                  wrapHeader={wrapHeader}
                />
              )}
              <TreeGridBody
                columns={_.get(tableInfo, "columns")}
                currentRow={currentRow}
                customOperationItems={customOperationItems}
                data={stateObject.dataToDisplay}
                displayMode={displayMode}
                editOnDoubleClick={editOnDoubleClick}
                expandableColumnIndex={expandableColumnIndex}
                expandingNode={expandingNode}
                fontSize={fontSize}
                footer={footer}
                hasOperationalPanel={hasOperationalPanel}
                hasRowSelection={stateObject.hasSelectColumn}
                height={tableHeight}
                idFieldName={idFieldName}
                isDirectionRTL={isDirectionRTL}
                isLeafSelectableOnly={isLeafSelectableOnly}
                isLoading={isLoading}
                isLoadingExpand={isLoadingExpand}
                isLoadingText={_.get(PE, "Loading") ?? "Loading..."}
                multiSelect={multiSelect}
                isTriple={isTriple}
                levelOffset={levelOffset}
                nodeCanBeSelectedIf={nodeCanBeSelectedIf}
                noItemsText={_.get(PE, "NoItems") ?? "No items found"}
                onAddClick={handleAddClick}
                onDeleteClick={handleShowDeleteConfirmationDialog}
                onEditClick={handleEditClick}
                onRefreshClick={handleRefreshNodeClick}
                onRowDoubleClick={onRowDoubleClick}
                onRowSelect={onRowSelect}
                onRowSelectClick={setStateObject}
                onRowSelectionClick={handleRowSelectionClick}
                onRowFiltering={handleRowFiltering}
                onToggleExpand={handleToggleExpand}
                PE={PE}
                setCurrentRow={setCurrentRow}
                setExpandingNode={setExpandingNode}
                setSelectedNode={setSelectedNode}
                showBadge={showBadge}
                showDragHandler={showDragHandler}
                showOperationalPanel={showOperationalPanel}
                showSearchRow={showSearchRow}
                trStyle={trStyle}
                whereClause={retrieveInput.where ?? []}
                width={_.get(tableInfo, "width")}
                wrapText={wrapText}
              />
            </table>
          </div>
          {isLoading && (
            <div
              style={{ top: gridRef.current?.offsetTop + 50 }}
              className="progress-bar"
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </HotKeys>
  );
}

TreeGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsStackSize: PropTypes.number,
  configs: PropTypes.object,
  customHeader: PropTypes.array, // [[{label: "", key: "", mergeCells: 2}]]
  customMenuItems: PropTypes.array, // [{name: "", icon: </>, toolTip: "", onClick: ()=>{}, displayIf: ()=>{}}]
  customOperationItems: PropTypes.array, // [{name: "", icon: </>, toolTip: "", displayIf: ()=>{}, onClick: ()=>{}}]
  data: PropTypes.array,
  deleteExtraContent: PropTypes.string,
  deleteTitle: PropTypes.string,
  displayMode: PropTypes.oneOf(["full", "view", "tree"]),
  editOnDoubleClick: PropTypes.bool,
  fontSize: PropTypes.number,
  footer: PropTypes.array, // [{path: "", value: ""}]
  formDialog: PropTypes.bool,
  hasAddButton: PropTypes.bool,
  hasAddMenuButton: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasColumnSelect: PropTypes.bool,
  hasBadge: PropTypes.bool,
  hasDeleteButton: PropTypes.bool,
  hasDeleteConfirm: PropTypes.bool,
  hasEditButton: PropTypes.bool,
  hasExportToExcel: PropTypes.bool,
  hasHeader: PropTypes.bool,
  hasOperations: PropTypes.bool,
  hasOperationsButton: PropTypes.bool,
  hasRoot: PropTypes.bool,
  hasRowFilter: PropTypes.bool,
  hasRowSelect: PropTypes.bool,
  hasSelectColumn: PropTypes.bool,
  idFieldName: PropTypes.string,
  isDraggable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isChildrenSelection: PropTypes.bool,
  isLeafSelectableOnly: PropTypes.bool,
  multiSelect: PropTypes.bool,
  levelOffset: PropTypes.number,
  moduleCodeString: PropTypes.string,
  nodeCanBeSelectedIf: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onShowInputForm: PropTypes.func,
  selectNodeIf: PropTypes.func,
  service: PropTypes.func,
  serviceParams: PropTypes.object,
  showOperationalPanel: PropTypes.bool,
  showOperations: PropTypes.bool,
  showRowFilter: PropTypes.bool,
  showSearchRow: PropTypes.bool,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  trStyle: PropTypes.object,
  wrapHeader: PropTypes.bool,
  wrapText: PropTypes.bool,
};

TreeGrid.defaultProps = {
  columnsStackSize: 5,
  displayMode: "full",
  editOnDoubleClick: true,
  fontSize: 8,
  formDialog: false,
  hasAddButton: true,
  hasAddMenuButton: true,
  hasBorder: true,
  hasBadge: true,
  hasColumnSelect: false,
  hasDeleteButton: true,
  hasDeleteConfirm: true,
  hasEditButton: true,
  hasExportToExcel: true,
  hasHeader: true,
  hasOperations: true,
  hasOperationsButton: true,
  hasRoot: false,
  hasRowFilter: true,
  hasRowSelect: false,
  hasSelectColumn: false,
  height: 250,
  isChildrenSelection: true,
  isDraggable: false,
  isExpanded: false,
  isLeafSelectableOnly: false,
  multiSelect: false,
  levelOffset: 20,
  showOperationalPanel: true,
  showOperations: false,
  showRowFilter: true,
  showSearchRow: false,
  wrapHeader: true,
  wrapText: true,
};
