import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";

export const ConfigModuleCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "JWebCore/ServerApi/ConfigModuleSetValue",
    data: _data,
  });
  return data;
};
