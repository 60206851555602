/*------------
Version: 2.1
------------*/
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MuiDialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Fade,
} from "@material-ui/core";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import {
  ifNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import Button from "Jarvis/JarvisComponents/Button";
import Typography from "Jarvis/JarvisComponents/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: "white",
    },
  },
  rootWarning: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
    "& h6": {
      color: "white",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { title, classes, onClose, warningColor, ...rest } = props;
  return (
    <MuiDialogTitle
      style={{ height: "20px" }}
      disableTypography
      className={clsx(
        "jarvisDialogTitle",
        warningColor ? classes.rootWarning : classes.root
      )}
      {...rest}
    >
      <Typography label={title} className="text-white" variant="body2" />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const _uuid = `draggable-dialog-${uuid()}`;

function PaperComponent(props) {
  return (
    <Draggable handle={`#${_uuid}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function Dialog({
  acceptBtnClassName,
  acceptBtnFormId,
  acceptBtnText,
  children,
  closeBtnText,
  closeBtnClassName,
  contentText,
  disableBackdropClick,
  disableEscapeKeyDown,
  hasTransition,
  isDraggable,
  fullScreen,
  maxWidth,
  onAcceptBtnClick,
  onClose,
  onCloseBtnClick,
  open,
  showAcceptBtn,
  showCloseBtn,
  showDialogAction,
  showDialogTitle,
  title,
  titleClassName,
  titleStyle,
  warningColor,
  width,
  ...rest
}) {
  let paperStyle = rest.style ?? {};
  if (width !== undefined) paperStyle.width = width;

  titleStyle = ifNullOrWhiteSpace(titleStyle, { textAlign: "justify" });

  closeBtnText = ifNullOrWhiteSpace(closeBtnText, "Close");
  onCloseBtnClick = onCloseBtnClick ?? onClose;

  acceptBtnText = ifNullOrWhiteSpace(acceptBtnText, "Submit");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const dialogProps = {
    className: "jarvisDialog",
    disableEscapeKeyDown,
    fullScreen,
    maxWidth,
    open,
    PaperProps: {
      style: paperStyle,
    },
    TransitionComponent: fullScreen && hasTransition ? Transition : Fade,
    ...rest,
  };

  if (isDraggable && !fullScreen) dialogProps.PaperComponent = PaperComponent;

  return (
    <MuiDialog
      {...dialogProps}
      onClose={(event, reason) => {
        if (
          (reason === "escapeKeyDown" && !disableEscapeKeyDown) ||
          (reason === "backdropClick" && !disableBackdropClick)
        )
          onClose();
      }}
    >
      {showDialogTitle && (
        <DialogTitle
          title={title}
          style={isDraggable ? { cursor: "move" } : {}}
          warningColor={warningColor}
          onClose={onClose}
          id={_uuid}
        />
      )}
      <DialogContent dividers className="jarvisDialogContent">
        {contentText && (
          <DialogContentText>
            <Typography
              style={{ whiteSpace: "pre-wrap" }}
              label={contentText}
            />
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {showDialogAction && (
        <DialogActions className="jarvisDialogAction">
          {showCloseBtn && (
            <Button
              className={closeBtnClassName}
              variant="outlined"
              size="small"
              label={closeBtnText}
              onClick={onCloseBtnClick}
            />
          )}
          {showAcceptBtn && (
            <Button
              className={acceptBtnClassName}
              variant="contained"
              size="small"
              color="primary"
              label={acceptBtnText}
              onClick={onAcceptBtnClick}
              form={acceptBtnFormId}
              type="submit"
            />
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

Dialog.propTypes = {
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  hasTransition: PropTypes.bool,
  isDraggable: PropTypes.bool,
  showAcceptBtn: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  showDialogAction: PropTypes.bool,
  showDialogTitle: PropTypes.bool,
  titleClassName: PropTypes.string,
  warningColor: PropTypes.bool,
};
Dialog.defaultProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: false,
  hasTransition: true,
  isDraggable: true,
  maxWidth: false,
  showAcceptBtn: true,
  showCloseBtn: true,
  showDialogAction: false,
  showDialogTitle: true,
  titleClassName: "Jarvis-Font-Size-9rem",
  warningColor: false,
};
