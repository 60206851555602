import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { DetailRetrieve } from "Jarvis/JarvisSystems/Acc/Services/DetailService";

export default function DetailSelect({
  control,
  label,
  name,
  serviceParams,
  retrieveLastChild,
  detailKeyName,
  typeOnly,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={DetailRetrieve}
      serviceParams={{
        ...serviceParams,
        retrieveAsList: retrieveLastChild ? false : true,
        retrieveLastChild: retrieveLastChild || detailKeyName ? true : false,
        detailKeyName,
      }}
      displayFieldName="DetailLangName"
      valueFieldName={retrieveLastChild ? "DetailKey" : "DetailId"}
      displayItem={(item) =>
        `${item.CustomFullPath ?? ""}${item.CustomFullPath ? "-" : ""}${
          item.DetailLangName
        }`
      }
      searchFieldNames={["DetailLangName"]}
      typeOnly={typeOnly ?? true}
      {...rest}
    />
  );
}
