import React, { useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import TextField from "Jarvis/JarvisComponents/TextField";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import List from "Jarvis/JarvisComponents/List";
import { PublicCodeCreateList } from "Jarvis/JarvisSystems/Pub/Services/PublicCodeService";
import Button from "Jarvis/JarvisComponents/Button";
import IconButton from "Jarvis/JarvisComponents/IconButton";
import { PostAdd } from "@material-ui/icons";

export default function InputForm({ langData, showForm, onShowForm }) {
  const { control, errors, getValues, handleSubmit, setValue } = useForm();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputData) => {
    let children = [];
    data.forEach((item) => children.push(item.PublicCodeLangDescription));
    setIsLoading(true);
    const { ErrorHandling } = await PublicCodeCreateList({
      ...inputData,
      Children: children.join(),
    });
    setIsLoading(false);

    if (_.get(ErrorHandling, "IsSuccessful")) {
      handleClose();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  };

  const handleClose = () => onShowForm(false);

  const handleAdd = () => {
    const listMember = getValues("ListMember");
    if (listMember) {
      const index = data.findIndex(
        (item) => item.PublicCodeLangDescription === listMember
      );
      if (index === -1)
        setData([
          ...data,
          { id: data.length + 1, PublicCodeLangDescription: listMember },
        ]);
      setValue("ListMember", "");
    }
  };

  const handleDelete = (publicCode) => {
    const dataTemp = [...data];
    const index = dataTemp.findIndex((item) => item.Id === publicCode.Id);
    dataTemp.splice(index, 1);
    setData(dataTemp);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAdd();
    }
  };

  return (
    <Dialog
      width="50%"
      open={showForm}
      title={_.get(langData, "PubPublicCodeCreate")}
      onClose={handleClose}
    >
      <form
        className="border border-info py-3 mb-2 px-4"
        noValidate
        onSubmit={handleOnSubmit}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <TextField
              label={_.get(langData, "ListName")}
              control={control}
              name="PublicCodeLangDescription"
              errors={errors}
              required
              rules={{
                required: _.get(langData, "Name_IsNull") || "required",
              }}
              autoFocus
              fullWidth
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <TextField
              label={_.get(langData, "Code")}
              control={control}
              name="PublicCode"
              textAlign="left"
              type="code"
              fullWidth
            />
          </div>
        </div>
        <div className="row d-flex mt-2 mb-3">
          <div className="col-md-6 col-sm-12">
            <TextField
              label={_.get(langData, "ListMembers")}
              control={control}
              name="ListMember"
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </div>
          <IconButton
            className="jarvisIconHover mt-2"
            icon={<PostAdd />}
            color="primary"
            tooltip={_.get(langData, "AddToList")}
            onClick={handleAdd}
          />
        </div>

        <List
          control={control}
          name="PublicCode"
          hasHeader={false}
          idFieldName="Id"
          displayFieldName="PublicCodeLangDescription"
          data={data}
          onDelete={handleDelete}
        />

        <div className="row mt-5">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <Button mode="save" showLoading={isLoading} />
            <Button className="mx-3" mode="close" onClick={handleClose} />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
