import { getElemDistance } from "Jarvis/JarvisServices/JarvisHtmlService";
import React, { useEffect, useRef, useState } from "react";

const MainContainerBody = React.forwardRef(({ children }, ref) => {
  const [height, setHeight] = useState();
  ref = ref ?? useRef();
  useEffect(() => {
    const elem = ref.current;
    const elemTop = getElemDistance(elem);
    const footer = document.querySelector(".app-footer");
    const footerTop = getElemDistance(footer);
    const h = footerTop - elemTop - 20;
    setHeight(h);
  }, [ref]);
  return (
    <div
      ref={ref}
      className="jr-card"
      style={{
        padding: "10px",
        height: height,
      }}
    >
      <div style={{ height: "inherit" }} className="js-card-body">
        {children}
      </div>
    </div>
  );
});
export default MainContainerBody;
