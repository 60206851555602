/*------------
Version: 1.7
------------*/
import React, { useContext } from "react";
import _ from "lodash";
import clsx from "clsx";
import { getCalendar } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { getLangStyles } from "Jarvis/JarvisServices/JarvisStyleService";
import DatePickerHijriShamsi from "./hijriShamsi";
import DatePickerHijriGhamari from "./hijriGhamari";
import DatePickerGregorian from "./gregorian";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";

const DatePicker = ({
  autoFocus,
  calendarType, //Gregorian | HijriShamsi | HijriGhamari
  checkRules = true,
  control,
  errors,
  fullWidth,
  hint,
  hintColor,
  inputVariant, //standard | outlined | filled
  label,
  langData,
  maxDate,
  minDate,
  name,
  onChange,
  placeholder,
  rules,
  setValue,
  size,
  value,
  variant /*dialog, inline, static*/,
  required,
  setTodayDate,
  disabled,
}) => {
  let PE = _.get(useContext(GlobalContext), "PE.PubPublicElems");
  if (langData) PE = langData;

  let ruleIndex = -1;
  if (_.isArray(rules)) {
    ruleIndex = rules.findIndex((rule) => _.get(rule, "ColumnName") === name);
  }

  let calendarKey;
  // let calendarFormat;
  if (calendarType) {
    calendarKey = calendarType;
  } else {
    const calendar = getCalendar();
    calendarKey = calendar.calendarKey;
    // calendarFormat = calendar.calendarFormat;
  }

  const langStyles = getLangStyles();

  const inputProps = {
    className: clsx("Jarvis-Font-default", langStyles?.textFieldSizeClassName),
    style: {
      direction: "ltr",
    },
  };

  const inputLabelProps = {
    className: clsx(
      langStyles?.fontNameClassName,
      langStyles?.labelSizeClassName
    ),
  };

  setTodayDate = ifNullOrWhiteSpace(setTodayDate, false);

  const getRules = () => {
    if (checkRules) {
      let customRules = {};
      if (ruleIndex !== -1) {
        if (
          rules[ruleIndex].IsRequired &&
          isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
        )
          customRules.required =
            _.get(PE, "PubPublicElems.Required") || "required";
      } else customRules = rules;

      if (_.isEmpty(customRules)) return undefined;
      return customRules;
    }
  };

  const datePickerProps = {
    autoFocus,
    control,
    errors,
    fullWidth,
    hint,
    hintColor,
    InputLabelProps: inputLabelProps,
    inputProps: inputProps,
    inputVariant,
    label,
    maxDate,
    minDate,
    name,
    onChange,
    placeholder,
    rules: getRules(),
    setValue,
    size,
    value,
    variant,
    required:
      ruleIndex !== -1
        ? rules[ruleIndex].IsRequired &&
          isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
        : required,
    setTodayDate,
    disabled,
  };

  if (ruleIndex !== -1 && rules[ruleIndex].Display === false) return null;

  switch (calendarKey?.toUpperCase()) {
    case "HIJRISHAMSI":
      return (
        <div style={{ textAlign: "left" }} className="jarvisDatepicker">
          <DatePickerHijriShamsi {...datePickerProps} />
        </div>
      );
    case "HIJRIGHAMARI":
      return (
        <div style={{ textAlign: "left" }} className="jarvisDatepicker">
          <DatePickerHijriGhamari {...datePickerProps} />
        </div>
      );
    default:
      return (
        <div className="jarvisDatepicker">
          <DatePickerGregorian {...datePickerProps} />
        </div>
      );
  }
};
export default DatePicker;
