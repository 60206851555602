import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import TextField from "Jarvis/JarvisComponents/TextField";
import Button from "Jarvis/JarvisComponents/Button";
import {
  UserExtraInfoRetrieve2FactorAuth,
  SetTwoFactorAuth,
} from "Jarvis/JarvisSystems/Sec/Services/UserService";
import Typography from "Jarvis/JarvisComponents/Typography";
import Wait from "Jarvis/JarvisComponents/Wait";
import Checkbox from "Jarvis/JarvisComponents/Checkbox";
export default function TwoFactorAuth({
  hasCloseBtn = true,
  hideAuthLoader,
  onClose,
  showAuthLoader,
  iData,
  showPinCodeSection,
}) {
  const { control, errors, handleSubmit } = useForm();
  const [stateObj, setStateObj] = useState({
    isLoading: false,
    userExtraInfo: null,
  });
  showPinCodeSection = showPinCodeSection ?? true;
  useEffect(() => {
    (async () => {
      if (showAuthLoader) showAuthLoader();
      const response = await UserExtraInfoRetrieve2FactorAuth(
        _.pick(iData, ["UserId", "VT"])
      );
      if (hideAuthLoader) hideAuthLoader();
      if (_.get(response, "ErrorHandling.IsSuccessful")) {
        setStateObj((state) => ({
          ...state,
          userExtraInfo: _.get(response, "Data"),
        }));
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stateObj.userExtraInfo) {
    return <Wait />;
  }

  const onSubmit = async (data) => {
    setStateObj((state) => ({ ...state, isLoading: true }));
    const { ErrorHandling } = await SetTwoFactorAuth({
      ...data,
      ..._.pick(iData, ["UserId", "VT"]),
      TwoFactorAuthEntryKey: stateObj.userExtraInfo?.TwoFactorAuthEntryKey,
      TwoFactorAuthAccountSecretKey:
        stateObj.userExtraInfo?.TwoFactorAuthAccountSecretKey,
      TwoFactorAuthQRCodeBase64:
        stateObj.userExtraInfo?.TwoFactorAuthQRCodeBase64,
    });

    setStateObj((state) => ({ ...state, isLoading: false }));

    if (_.get(ErrorHandling, "IsSuccessful")) {
      onClose();
    }
  };

  return (
    <form
      className="border border-info py-3 mb-2 px-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      {!stateObj.userExtraInfo?.isNew && (
        <div>
          <strong style={{ color: "red" }}>
            You have allready activated two factor authentication for your
            account
          </strong>
        </div>
      )}
      <div className="row d-flex justify-content-center align-items-center">
        <img
          src={stateObj.userExtraInfo?.TwoFactorAuthQRCodeBase64}
          alt="userInfo"
        />
      </div>
      <div className="row mt-2" dir="ltr">
        <Typography label="Your account entry key is:" />
      </div>
      <div className="row mt-2" dir="ltr">
        <Typography
          style={{ wordBreak: "break-word" }}
          label={stateObj.userExtraInfo?.TwoFactorAuthEntryKey}
          fontWeight="Bold"
        />
      </div>
      <div className="row mt-4" dir="ltr">
        <p>Step 1: Install Google Authenticator or Microsoft Authenticator</p>
        <p>Step 2: Scan QRCode or input the account entry key manually</p>
        <p>Step 3: Input the Pin Code given by the authenticator app</p>
      </div>
      {showPinCodeSection && (
        <div className="row mt-2">
          <div className="col-6">
            <TextField
              label="Pin Code"
              control={control}
              name="PinCode"
              errors={errors}
              rules={{
                required: "required",
              }}
              hint="Please enter the Pin Code that generated by Google/Microsoft Authenticator"
              hintColor="error"
              fullWidth
              type="number"
            />
          </div>
          <div className="col-6">
            <Checkbox
              variant="standard"
              name="IsTwoFactorAuthNeededForLogin"
              label={"Is Two Factor Auth Needed For Login"}
              control={control}
              value={stateObj.userExtraInfo?.IsTwoFactorAuthNeededForLogin}
            />
            <br/>
            {stateObj.userExtraInfo?.isNew ? (
              ""
            ) : (
              <small style={{ color: "red" }}>
                Please note that if you tick this item you will not be able to
                login anymore into the system as you do not have the active 2FA key
              </small>
            )}
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <Button
            mode="save"
            label={!stateObj.userExtraInfo?.isNew ? "Deactivate" : "Activate"}
            showLoading={stateObj.isLoading}
          />
          {hasCloseBtn && (
            <Button className="mx-3" mode="close" onClick={onClose} />
          )}
        </div>
      </div>
    </form>
  );
}
