import _ from "lodash";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import { getGraphQLUrl } from "Jarvis/JarvisServices/JarvisGraphQLService";

export const PatronRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  patronType,
  retrieveAsList,
  PatronGroupCode,
}) => {
  const retrieveService =
    !isNullOrWhiteSpace(retrieveAsList) && retrieveAsList === true
      ? "Pms/Patron/RetrieveAsList"
      : "Pms/Patron/Retrieve";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      orderBy,
      orderBys,
      orderMethod,
      where,
      patronType,
      PatronGroupCode,
    },
  });
  return data;
};

export const PatronCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pms/Patron/Create",
    data: _data,
  });
  return data;
};

export const PatronUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pms/Patron/Update",
    data: _data,
  });
  return data;
};

export const PatronDelete = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pms/Patron/Delete",
    data: _data,
  });
  return data;
};

export const PatronViewRetrieve = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/Patron/Retrieve",
    data: _data,
  });
  return data;
};

export const PatronRetrieveNewCode = async () => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/Patron/RetrieveNewCode",
  });
  return data;
};

export const PatronRetrieveExtraInfo = async ({ PatronId, VT }) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/Patron/RetrieveExtraInfo",
    data: { PatronId, VT },
  });
  return data;
};

export const PatronPrerequisitesGraphQL = async () => {
  const { data } = await JarvisHttpService.post({
    url: getGraphQLUrl(),
    data: {
      query: `
        {
          prerequisites:patronPrerequisites(){data},
          langData:pageElementRetrievePublic(iModel:{moduleCodeStrings:"PmsPatron",withSeparateModuleCodeString:true,configModuleFilter:"PmsPatronReal,PmsPatronLegal"}){data}
        }`,
    },
  });

  return {
    errorHandling: _.get(data, "ErrorHandling"),
    prerequisites: JSON.parse(
      ifNullOrWhiteSpace(_.get(data, "Data.data.prerequisites.data"), null)
    ),
    langData: JSON.parse(
      ifNullOrWhiteSpace(_.get(data, "Data.data.langData.data"), null)
    ),
  };
};

export const PatronRelationRetrieve = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pms/Patron/RelationRetrieve",
    data: _data,
  });
  return data;
};
