import React from "react";
import _ from "lodash";
import TextField from "Jarvis/JarvisComponents/TextField";
import RadioList from "Jarvis/JarvisComponents/RadioList";
import Checkbox from "Jarvis/JarvisComponents/Checkbox";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import RealMainInfo from "./RealMainInfo";
import LegalMainInfo from "./LegalMainInfo";

export default function PatronCommonInfo({
  control,
  errors,
  isEditMode,
  langData,
  patronData,
  setPatronData,
  preData,
  setValue,
}) {
  const handleChangePatronType = (value) => {
    setPatronData((patronData) => ({
      ...patronData,
      PatronType: value,
    }));
  };

  return (
    <div>
      {!isEditMode && (
        <div className="row">
          <div className="col-md-5">
            <RadioList
              control={control}
              name="PatronType"
              data={[
                { label: _.get(langData, "PmsPatron_RealType"), value: "R" },
                { label: _.get(langData, "PmsPatron_LegalType"), value: "L" },
                { label: _.get(langData, "Other"), value: "O" },
              ]}
              label={_.get(langData, "PatronType")}
              isHorizontal
              value={_.get(patronData, "PatronType")}
              onChange={handleChangePatronType}
            />
          </div>
        </div>
      )}
      <div className="row d-flex">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronCode")}
            control={control}
            name="PatronCode"
            errors={errors}
            required
            rules={{
              required: _.get(langData, "Required") || "required",
            }}
            value={_.get(patronData, "PatronCode")}
            type="code"
            textAlign="left"
            fullWidth
          />
        </div>
        <div className="col-md-5 col-sm-12">
          <Checkbox
            className="mt-2"
            label={_.get(langData, "IsActive")}
            control={control}
            name="PatronIsActive"
            value={_.get(patronData, "PatronIsActive") ?? true}
          />
        </div>
      </div>
      {_.get(patronData, "PatronType") === "R" ? (
        <RealMainInfo
          control={control}
          errors={errors}
          isEditMode={isEditMode}
          langData={langData}
          patronData={patronData}
          preData={preData}
          setValue={setValue}
        />
      ) : _.get(patronData, "PatronType") === "L" ? (
        <LegalMainInfo
          control={control}
          errors={errors}
          isEditMode={isEditMode}
          langData={langData}
          patronData={patronData}
          preData={preData}
          setValue={setValue}
        />
      ) : (
        <div className="row mt-1">
          <div className="col-md-5 col-sm-12">
            <TextField
              label={_.get(langData, "PatronOtherLangDescription")}
              control={control}
              name="PatronOtherLangDescription"
              errors={errors}
              required
              rules={{
                required: _.get(langData, "Required") || "required",
              }}
              value={_.get(patronData, "PatronOtherLangDescription")}
              autoFocus
              fullWidth
            />
          </div>
        </div>
      )}

      <DynamicColumn
        columns={_.get(preData, "GTCD.PmsPatron")}
        control={control}
        errors={errors}
        setValue={setValue}
        rules={preData.rules}
      />
    </div>
  );
}
