import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { useForm } from "react-hook-form";
import TextField from "Jarvis/JarvisComponents/TextField";
import Button from "Jarvis/JarvisComponents/Button";
import Typography from "Jarvis/JarvisComponents/Typography";
import {
  Authenticate,
  ForgetPassword,
  UserRequestResetPassword,
} from "../../Services/AccountService";

export default function ResetPassword(props) {
  const { control, errors, handleSubmit } = useForm();
  const [originUrl, setOriginUrl] = useState(null);
  const [showNotMatchPasswordHint, setShowNotMatchPasswordHint] = useState(
    false
  );

  const { langData, language } = props;
  const float =
    "float-" + (language.LangDir.toLowerCase() === "rtl" ? "left" : "right");
  const queryInfo = queryString.parse(props.location.search);

  useEffect(() => {
    if (!_.isEmpty(queryInfo)) {
      (async () => {
        props.showAuthLoader();
        const { ErrorHandling, Data } = await UserRequestResetPassword({
          LangId: getLanguage().LangId,
          Key: queryInfo.key,
        });
        if (_.get(ErrorHandling, "IsSuccessful")) {
          setOriginUrl(_.get(Data, "OriginUrl"));
        } else {
          props.history.push("/Account/Signin");
        }
        props.hideAuthLoader();
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    if (data.PasswordRepeat === data.Password) {
      props.showAuthLoader();
      if (showNotMatchPasswordHint) setShowNotMatchPasswordHint(false);
      let iModel = {
        LangId: getLanguage().LangId,
        NewPassword: data.Password,
        ConfirmPassword: data.PasswordRepeat,
      };
      if (queryInfo.key) iModel.EncryptionKey = queryInfo.key;
      const { ErrorHandling, Data } = await ForgetPassword(iModel);
      if (_.get(ErrorHandling, "IsSuccessful")) {
        const response = await Authenticate({
          LangId: getLanguage().LangId,
          Username: _.get(Data, "Username"),
          Password: data.Password,
          PlatformKey: "WebBrowser",
          JarvisIntegratedSystem: queryInfo.jarvisIntegratedSystem
            ? queryInfo.jarvisIntegratedSystem
            : true,
          SystemKey: queryInfo.systemKey,
          CookieIsAlsoNeeded: queryInfo.cookieIsAlsoNeeded,
        });

        if (_.get(response, "ErrorHandling.IsSuccessful")) {
          props._SetLoginResultInfo(response);
        }

        props.hideAuthLoader();
      }
    } else setShowNotMatchPasswordHint(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className="mt-1 my-sm-3"
        label={_.get(langData, "NewPassword")}
        control={control}
        name="Password"
        errors={errors}
        rules={{
          required: _.get(langData, "Password_IsNull") || "required",
        }}
        type="password"
        hintColor="error"
        autoFocus
        fullWidth
      />
      <TextField
        className="mb-4"
        label={_.get(langData, "SecUser_UserPasswordRepeat")}
        control={control}
        name="PasswordRepeat"
        errors={errors}
        rules={{
          required: _.get(langData, "Password_IsNull") || "required",
        }}
        type="password"
        hint={
          showNotMatchPasswordHint
            ? _.get(langData, "UserPasswordRepeat_NotEqual") || "Not Match"
            : undefined
        }
        hintColor="error"
        fullWidth
      />
      <div
        className={
          float + " mb-3 d-flex align-items-center justify-content-between"
        }
      >
        <Link
          to={`${originUrl || ""}/Account/Signin`}
          style={{ textDecoration: "none" }}
        >
          <Typography label={_.get(langData, "BackToLogin")} />
        </Link>
      </div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <Button label={_.get(langData, "SecUserChangePassword")} mode="save" />
      </div>
    </form>
  );
}
