/*------------
Version: 1.4
------------*/
import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import Typography from "../Typography";

export default function RadioList({
  className,
  control,
  data,
  errors,
  fontSize,
  hint,
  isHorizontal,
  label,
  name,
  onChange,
  size,
  value,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState(value ?? data[0].value);

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return <Typography component={"span"} fontSize={7} label={helperText} />;
  };

  const handleOnChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  const handleOnChangeUncontrolled = (e) => {
    if (onChange) onChange(e.target.value);
    setSelectedValue(e.target.value);
  };

  if (control)
    return (
      <FormControl
        component="fieldset"
        size={size}
        error={errors && errors[name] ? true : false}
      >
        <FormLabel component="legend">
          <Typography label={label} fontSize={fontSize} />
        </FormLabel>
        <Controller
          control={control}
          name={name}
          defaultValue={value ?? data[0].value}
          as={
            <RadioGroup row={isHorizontal}>
              {data.map((item, index) => (
                <FormControlLabel
                  className={clsx(className, "jarvisCheckbox")}
                  key={_.get(item, "value")}
                  value={_.get(item, "value")}
                  control={
                    <Radio
                      className="jarvisCheckbox"
                      onChange={handleOnChange}
                      {...rest}
                    />
                  }
                  label={
                    <Typography
                      fontSize={fontSize}
                      label={_.get(item, "label")}
                    />
                  }
                  disabled={_.get(item, "disabled")}
                />
              ))}
            </RadioGroup>
          }
        />
        <FormHelperText error={errors && errors[name] ? true : false}>
          {renderHelperText()}
        </FormHelperText>
      </FormControl>
    );

  return (
    <FormControl component="fieldset" size={size}>
      <FormLabel component="legend">
        <Typography label={label} fontSize={fontSize} />
      </FormLabel>
      <RadioGroup row={isHorizontal}>
        {data.map((item, index) => (
          <FormControlLabel
            className={clsx(className, "jarvisCheckbox")}
            key={item.value}
            value={item.value}
            control={
              <Radio
                className="jarvisCheckbox"
                onChange={handleOnChangeUncontrolled}
                checked={selectedValue === item.value}
                {...rest}
              />
            }
            label={<Typography fontSize={fontSize} label={item.label} />}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{renderHelperText()}</FormHelperText>
    </FormControl>
  );
}

RadioList.propTypes = {
  data: PropTypes.array,
  fontSize: PropTypes.number,
  hint: PropTypes.string,
  isHorizontal: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
};
RadioList.defaultValue = {
  fontSize: 8,
  size: "small",
};
