import React, { useRef, useEffect, useState } from "react";

const MainContainer = React.forwardRef(({ children }, ref) => {
  const [height, setHeight] = useState(null);
  if (!ref) ref = useRef();
  useEffect(() => {
    const top = ref.current ? ref.current.offsetTop : 65;
    const footer = document.querySelector(".app-footer");
    const restHeight = window.innerHeight - top - (footer && footer.offsetHeight);
    setHeight(restHeight);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="app-wrapper" ref={ref} style={{ height: height }}>
      <div className="animated slideInUpTiny animation-duration-1">{children}</div>
    </div>
  );
});
export default MainContainer;
