import { applyMiddleware, compose, createStore } from "redux";
import reducers from "../reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import { renderToString } from "react-dom/server";
/*axios*/
import React from "react";
import axios from "axios";
import _ from "lodash";
import {
  getToken,
  setToken,
  clearToken,
  getSubSystem,
  checkIfNewJarvisVersionReceived,
} from "Jarvis/JarvisServices/JarvisLocalStorageService";
import Account from "Jarvis/JarvisSystems/Sec/Components/Account/index";
import {
  notify,
  dismissAll as toastDismissAll,
} from "Jarvis/JarvisComponents/Notification";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import Version from "Jarvis/JarvisComponents/Version";
import { setJarvisVersion } from "Jarvis/JarvisServices/JarvisLocalStorageService";
/*axios*/

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(
    reducers(history),
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.dispatch(initialState);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };

/*axios*/
let _withNotification = true;
let _toastContainerId = "";
//axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    let extraInfo = {};
    const subSystem = getSubSystem();
    if (
      !isNullOrWhiteSpace(subSystem?.subSystemId) &&
      !isNullOrWhiteSpace(subSystem?.vtSubSystemId)
    ) {
      extraInfo = {
        ...extraInfo,
        ...{ SSId: subSystem.subSystemId, VTSSId: subSystem.vtSubSystemId },
      };
    }
    config.headers["ExtraInfo"] = JSON.stringify(extraInfo);
    config.maxBodyLength = Infinity;
    config.maxContentLength = Infinity;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    let data = null;
    if (!response.data.ErrorHandling) {
      data = {
        data: {
          ErrorHandling: {
            IsSuccessful: true,
            ErrorMessage: null,
            ErrorCode: 0,
          },
          Data: response.data,
        },
        response: response,
      };
    } else data = { data: response.data };

    const jarvisVersion = response.headers
      ? response.headers["jarvis-ver"]
      : null;
    if (checkIfNewJarvisVersionReceived(jarvisVersion) === true) {
      const elem = document.getElementById("footerVersionAlert");
      if (elem) {
        elem.innerHTML = renderToString(
          <Version jarvisVersion={jarvisVersion} />
        );
        elem.onclick = () => {
          setJarvisVersion(jarvisVersion);
          window.location.reload(true);
        };
      }
    }

    if (
      _withNotification === true &&
      data.data?.ErrorHandling?.ErrorMustBeSeenByUser
    ) {
      let message = data.data.ErrorHandling.ErrorMessage;
      let errorType = data.data.ErrorHandling.IsSuccessful
        ? "success"
        : "error";

      if (!isNullOrWhiteSpace(data.data.ErrorHandling?.ErrorLevel))
        errorType = data.data.ErrorHandling.ErrorLevel?.toLowerCase();

      let extraMessage = "";
      if (!data.data?.ErrorHandling?.IsSuccessful) {
        if (data.data?.ErrorHandling?.ErrorTechnicalMessage)
          extraMessage = data.data?.ErrorHandling?.ErrorTechnicalMessage;
        if (data.data?.ErrorHandling?.ErrorKey)
          extraMessage += "\r\n" + data.data?.ErrorHandling?.ErrorKey;
      }
      notify({
        type: errorType,
        message: message,
        extraMessage,
        containerId: _toastContainerId,
      });
    }

    const jAToken = response.headers ? response.headers["jatoken"] : null;
    // const isTokenValid = response.headers
    //   ? response.headers["is-token-valid"]
    //   : null;
    const tokenExpirationUtc = response.headers
      ? response.headers["token-expiration-utc"]
      : null;
    setToken({ jAToken, tokenExpirationUtc });
    //if (isTokenValid === "false") dispatch(userSignOut());
    return data;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      const dialogs = document.getElementsByClassName("MuiDialog-container");
      _.forEach(dialogs, (dialog) => {
        dialog.removeAttribute("tabindex");
      });

      const sideBarModal = document.querySelectorAll(
        ".MuiDrawer-root.MuiDrawer-modal.app-sidebar-content .MuiPaper-root"
      );
      _.forEach(sideBarModal, (item) => {
        item.removeAttribute("tabindex");
      });

      const toastContainer = document.getElementById(
        "fullscreenToastContainer"
      );

      clearToken();

      console.log("error:", error.response, "toastContainer", toastContainer);

      if (toastContainer) {
        notify({
          type: "success",
          timeout: false,
          message: (
            <Account
              isInternalSignIn={true}
              toastDismissAll={toastDismissAll}
            />
          ),
          closeOnClick: false,
          closeButton: false,
          draggable: false,
          containerId: "fullscreenToastContainer",
          style: {
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            color: "gray",
            cursor: "default",
            maxHeight: "none",
          },
          bodyStyle: {
            width: `100%`,
            height: `100%`,
          },
        });
      } else {
        window.location.reload(true);
      }
    }

    if (error.response) {
      const jAToken = error.response.headers["jatoken"];
      const tokenExpirationUtc = error.response.headers["token-expiration-utc"];
      if (jAToken) setToken({ jAToken, tokenExpirationUtc });
    }

    const errorMessage =
      error.message + (error.config ? ` (${error.config.url})` : "");
    const extraMessage = JSON.stringify(error.response?.data);
    const data = {
      data: {
        ErrorHandling: {
          IsSuccessful: false,
          ErrorMessage: errorMessage,
          ErrorCode: error.response ? error.response.status : -1,
        },
      },
      error: error,
    };
    // const style = {
    //   "overflow-wrap": "break-word",
    //   "word-wrap": "break-word",
    //   hyphens: "auto"
    // };
    console.log("Logging the error", data);
    // JarvisNotification({
    //   type: "error",
    //   message: <div style={style}>{errorMessage}</div>
    // });
    if (_withNotification === true) {
      notify({
        type: "error",
        message: errorMessage,
        containerId: _toastContainerId,
        extraMessage,
      });
    }
    return data;
    //return Promise.reject(error);
  }
);

export async function axiosGet({
  url,
  data,
  withNotification = true,
  toastContainerId = "",
}) {
  _withNotification = withNotification;
  _toastContainerId = toastContainerId;
  return axios.get(url, data);
}
export async function axiosPost({
  url,
  data,
  withNotification = true,
  config,
  omitEmptyProps = true,
  toastContainerId = "",
}) {
  _withNotification = withNotification;
  _toastContainerId = toastContainerId;
  return axios.post(
    url,
    omitEmptyProps ? _.omitBy(data, (prop) => isNullOrWhiteSpace(prop)) : data,
    config
  );
}
export async function axiosPut({
  url,
  data,
  withNotification = true,
  toastContainerId = "",
}) {
  _withNotification = withNotification;
  _toastContainerId = toastContainerId;
  return axios.put(
    url,
    _.omitBy(data, (prop) => isNullOrWhiteSpace(prop))
  );
}
export async function axiosDelete({
  url,
  data,
  withNotification = true,
  toastContainerId = "",
}) {
  _withNotification = withNotification;
  _toastContainerId = toastContainerId;
  return axios.delete(url, data);
}
