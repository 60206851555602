import React from "react";
import Select from "Jarvis/JarvisComponents/Select";
import { ModuleRetrieve } from "Jarvis/JarvisSystems/Pub/Services/ModuleService";

export default function ModuleSelect({
  control,
  label,
  name,
  serviceParams,
  ...rest
}) {
  return (
    <Select
      label={label}
      control={control}
      name={name}
      service={ModuleRetrieve}
      serviceParams={{
        retrieveAsList: true,
        ...serviceParams,
      }}
      displayFieldName="ModuleLangDescription"
      valueFieldName="ModuleId"
      {...rest}
    />
  );
}
