import React, { useState } from "react";
import _ from "lodash";
import { Popover } from "@material-ui/core";
import { MenuItem, ListItemIcon } from "@material-ui/core";
import Typography from "../Typography";
import MainOperations from "./MainOperations";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";
import IconButton from "../IconButton";

export default function TreeGridOperations({
  currentRow,
  customOperationItems,
  addTooltip,
  deleteTooltip,
  editTooltip,
  hasAddButton,
  hasEditButton,
  hasDeleteButton,
  horizontalOperationAlignment,
  isDirectionRTL,
  onAddClick,
  onDeleteClick,
  onEditClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const mainOperationsProps = {
    addTooltip,
    deleteTooltip,
    editTooltip,
    hasAddButton,
    hasEditButton,
    hasDeleteButton,
    onAddClick,
    onDeleteClick,
    onEditClick,
  };

  const handleAddClick = () => {
    onAddClick();
    handleClose();
  };

  const handleDeleteClick = () => {
    onDeleteClick();
    handleClose();
  };

  const handleEditClick = () => {
    onEditClick();
    handleClose();
  };

  if (customOperationItems) {
    mainOperationsProps.onAddClick = handleAddClick;
    mainOperationsProps.onDeleteClick = handleDeleteClick;
    mainOperationsProps.onEditClick = handleEditClick;
  }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (customOperationItems?.length > 0) {
    if (horizontalOperationAlignment) {
      return (
        <>
          <IconButton
            size="small"
            component="span"
            edge={false}
            icon={<MoreVert className="jarvisIconHover" fontSize="small" />}
            onClick={handleClick}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            <div
              style={{
                width: `${40 * (customOperationItems.length + 2)}px`,
                position: "relative",
              }}
              className="row d-flex justify-content-center align-items-center"
            >
              {customOperationItems.map(
                (item) =>
                  (_.isNil(item.displayIf) || item.displayIf(currentRow)) && (
                    <IconButton
                      key={item.name}
                      size="small"
                      component="span"
                      edge={false}
                      icon={
                        <span className="operational-panel">{item.icon}</span>
                      }
                      tooltip={item.toolTip ?? ""}
                      onClick={() => {
                        item.onClick(currentRow);
                        handleClose();
                      }}
                    />
                  )
              )}
              <MainOperations {...mainOperationsProps} />
            </div>
          </Popover>
        </>
      );
    } else {
      return (
        <>
          <IconButton
            size="small"
            component="span"
            edge={false}
            icon={<MoreVert className="jarvisIconHover" fontSize="small" />}
            onClick={handleClick}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            tabIndex={-1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: isDirectionRTL ? "right" : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: isDirectionRTL ? "right" : "left",
            }}
            onClose={handleClose}
          >
            {hasAddButton && (
              <MenuItem onClick={handleAddClick}>
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <Typography label={addTooltip ?? "Create"} />
              </MenuItem>
            )}
            {hasEditButton && (
              <MenuItem onClick={handleEditClick}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography label={editTooltip ?? "Edit"} />
              </MenuItem>
            )}
            {hasDeleteButton && (
              <MenuItem onClick={handleDeleteClick}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography label={deleteTooltip ?? "Delete"} />
              </MenuItem>
            )}
            {customOperationItems?.map(
              (item) =>
                (_.isNil(item.displayIf) || item.displayIf(currentRow)) && (
                  <MenuItem
                    key={item.name}
                    onClick={() => {
                      item.onClick(currentRow);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography label={item.toolTip} />
                  </MenuItem>
                )
            )}
          </Popover>
        </>
      );
    }
  }

  return (
    <div
      style={{ position: "relative" }}
      className="row d-flex justify-content-center align-items-center"
    >
      <MainOperations {...mainOperationsProps} />
    </div>
  );
}
