import React from "react";

export default function SortPanel({ column, orderMethod, onSort }) {
  const ascIconStyle = {
    color: orderMethod === "ASC" ? "#11cb5f" : "white",
  };
  const descIconStyle = {
    color: orderMethod === "DESC" ? "#11cb5f" : "white",
  };

  return (
    <span
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "middle",
      }}
      className="mx-2"
    >
      <i
        style={ascIconStyle}
        className="cursor-pointer fa fa-sort-asc"
        onClick={() => onSort(column, "ASC")}
      />
      <i
        style={descIconStyle}
        className="cursor-pointer sort-icon fa fa-sort-desc"
        onClick={() => onSort(column, "DESC")}
      />
    </span>
  );
}
