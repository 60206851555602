import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const StateDivisionRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  hierarchyShowMode,
  latestNodeLevel,
  nodeLevel,
  isDeepSearchNeeded,
  node,
  retrieveAsList,
  retrieveAsTree,
}) => {
  let retrieveService = "Pub/StateDivision/Retrieve";
  if (retrieveAsList) retrieveService = "Pub/StateDivision/RetrieveAsList";
  if (retrieveAsTree) retrieveService = "Pub/StateDivision/RetrieveAsTree";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(hierarchyShowMode, "Table"),
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    nodeLevel,
    NodeId: node?.["StateDivisionId"],
    VT: node?.VT,
    isDeepSearchNeeded,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      orderBy,
      orderMethod,
      where,
      orderBys,
      hierarchy,
    },
  });

  return data;
};
