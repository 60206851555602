import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TableTopPanel from "./TableTopPanel";

export default function TableTopPanelMenu({ topPanelProps, direction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <MenuIcon className="topPanel" fontSize="small" onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: direction === "ltr" ? "left" : "right",
        }}
        onClose={handleClose}
      >
        <TableTopPanel onClose={handleClose} {...topPanelProps} />
      </Popover>
    </React.Fragment>
  );
}
