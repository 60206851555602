import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "Jarvis/JarvisComponents/Button";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import Typography from "Jarvis/JarvisComponents/Typography";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import {
  addGTCD,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
import {
  PatronRetrieveExtraInfo,
  PatronRetrieveNewCode,
  PatronUpdate,
} from "Jarvis/JarvisSystems/Pms/Services/PatronService";
// import PatronView from "./PatronView";
import CommonInfo from "./CommonInfo";
import ContactInfo from "./ContactInfo";
import PatronDuplicate from "./PatronDuplicate";
import FurtherInformation from "./FurtherInformation";
import SystemCommunication from "./SystemCommunication";
import TTMSPatron from "./TTMSPatron";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const defaultPatronData = {
  PatronCellphones: [],
  PatronPhones: [],
  PatronEmails: [],
  PatronAddresses: [],
  AccDetails: [],
  PatronGroups: [],
  PatronJobs: [],
  patronRelations: [],
};

const defaultCompleted = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
};

export default function PatronInputForm({
  editData,
  fetchData,
  isDirectionRTL,
  langData,
  onShowInputForm,
  onSubmitParent,
  patronType,
  preData,
  showInputForm,
}) {
  const classes = useStyles();
  const { control, errors, getValues, setValue, trigger } = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({ ...defaultCompleted });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [patronData, setPatronData] = useState({ ...defaultPatronData });
  const [duplicateData, setDuplicateData] = useState(null);
  const [saveData, setSaveData] = useState(null);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const isEditMode = editData ? true : false;
  const dbJarvisAccExists = _.get(preData, "prerequisites.dbJarvisAccExists");
  const steps =
    patronData.PatronType === "O"
      ? [
          _.get(langData, "MainInfo"),
          _.get(langData, "SystemCommunication"),
          //_.get(langData, "PmsPatron_Summary"),
        ]
      : [
          _.get(langData, "MainInfo"),
          _.get(langData, "ContactInfo"),
          _.get(langData, "SystemCommunication"),
          ...(dbJarvisAccExists && preData.configs.AccEnableTTMS
            ? [_.get(langData, "AccTTMSPatron")]
            : []),
          _.get(langData, "FurtherInfo"),
          //_.get(langData, "PmsPatron_Summary"),
        ];

  useEffect(() => {
    if (isEditMode) {
      (async () => {
        setIsLoadingData(true);
        const { ErrorHandling, Data } = await PatronRetrieveExtraInfo(editData);
        if (_.get(ErrorHandling, "IsSuccessful")) {
          setPatronData(_.merge(editData, Data));
        }
        setIsLoadingData(false);
      })();
    } else {
      (async () => {
        setIsLoadingData(true);
        const { ErrorHandling, PatronCode } = await PatronRetrieveNewCode();
        if (_.get(ErrorHandling, "IsSuccessful")) {
          setPatronData((patronData) => ({
            ...patronData,
            PatronCode: PatronCode,
            PatronType: patronType,
          }));
        } else {
          setPatronData((patronData) => ({
            ...patronData,
            PatronType: patronType,
          }));
        }
        setIsLoadingData(false);
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDialogTitle = () => {
    let result = isEditMode
      ? _.get(langData, "PmsPatronUpdate")
      : _.get(langData, "PmsPatronCreate");

    switch (patronData.PatronType) {
      case "R":
        if (patronData.PatronRealLangLastName)
          return (
            result +
            ` - ${patronData.PatronRealLangFirstName || ""} ${
              patronData.PatronRealLangLastName
            }`
          );
        break;
      case "L":
        if (patronData.PatronLegalLangDescription)
          return result + ` - ${patronData.PatronLegalLangDescription || ""}`;
        break;
      case "O":
        if (patronData.PatronOtherLangDescription)
          return result + ` - ${patronData.PatronOtherLangDescription || ""}`;
        break;
      default:
        break;
    }

    return result;
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <CommonInfo
            control={control}
            errors={errors}
            getValues={getValues}
            isEditMode={isEditMode}
            langData={langData}
            patronData={patronData}
            setPatronData={setPatronData}
            preData={preData}
            setValue={setValue}
          />
        );
      case 1:
        if (patronData.PatronType === "O") {
          return (
            <SystemCommunication
              control={control}
              data={patronData}
              errors={errors}
              getValues={getValues}
              isDirectionRTL={isDirectionRTL}
              langData={langData}
              patronData={patronData}
              preData={preData}
              setPatronData={setPatronData}
              setValue={setValue}
            />
          );
        } else
          return (
            <ContactInfo
              control={control}
              errors={errors}
              getValues={getValues}
              isDirectionRTL={isDirectionRTL}
              isEditMode={isEditMode}
              langData={langData}
              patronData={patronData}
              preData={preData}
              setPatronData={setPatronData}
              setValue={setValue}
            />
          );
      case 2:
        return (
          <SystemCommunication
            control={control}
            errors={errors}
            getValues={getValues}
            langData={langData}
            patronData={patronData}
            preData={preData}
            setPatronData={setPatronData}
            setValue={setValue}
          />
        );
      case 3:
        if (dbJarvisAccExists && preData.configs.AccEnableTTMS)
          return (
            <TTMSPatron
              control={control}
              errors={errors}
              getValues={getValues}
              isEditMode={isEditMode}
              langData={langData}
              patronData={patronData}
              preData={preData}
            />
          );
        else
          return (
            <FurtherInformation
              control={control}
              errors={errors}
              getValues={getValues}
              langData={langData}
              patronData={patronData}
              preData={preData}
              setPatronData={setPatronData}
              setValue={setValue}
            />
          );
      case 4:
        if (preData.configs.AccEnableTTMS)
          return (
            <FurtherInformation
              control={control}
              errors={errors}
              getValues={getValues}
              langData={langData}
              patronData={patronData}
              preData={preData}
              setPatronData={setPatronData}
              setValue={setValue}
            />
          );
        break;
      // else
      //   return (
      //     <PatronView
      //       data={patronData}
      //       langData={langData}
      //       isDirectionRTL={isDirectionRTL}
      //     />
      //   );
      // case 6:
      //   return (
      //     <PatronView
      //       data={patronData}
      //       langData={langData}
      //       isDirectionRTL={isDirectionRTL}
      //     />
      //   );
      default:
        break;
    }
  };

  const getValidationFields = () => {
    if (
      activeStep === 3 &&
      dbJarvisAccExists &&
      patronData.PatronType !== "O" &&
      preData.configs.AccEnableTTMS
    ) {
      return [
        "BuyerCommodity",
        "BuyerDefaultProduct",
        "SellerCommodity",
        "SellerDefaultProduct",
      ];
    }
  };

  const generateFinalPatronData = (input, step) => {
    let data = {};
    let extraData = {};
    let extraInfo = {};
    let index = 0;
    if (_.isNil(step) || activeStep === 0) {
      data = _.pick(input, [
        "PatronType",
        "PatronCode",
        "PatronSearchKey",
        "PatronIsActive",
      ]);

      if (data.PatronType === "R") {
        //#region ------------ PatronReal ------------
        let patronReal = _.pick(input, [
          "PatronRealLangTitle",
          "PatronRealLangFirstName",
          "PatronRealLangMiddleName",
          "PatronRealLangLastName",
          "PatronRealLangNickName",
          "PatronRealLangFatherName",
          "PatronRealIDNo",
          "PatronRealRegistrationNo",
          "PatronRealBirthDate",
          "PatronRealIssueDate",
        ]);
        const GenderId =
          _.get(input, "Gender.PublicCodeId") || _.get(input, "GenderId");
        if (GenderId) {
          _.set(patronReal, "GenderId", GenderId);
          _.set(
            patronReal,
            "VTGender",
            _.get(input, "Gender.VT") || _.get(input, "VTGender")
          );
          _.set(
            extraData,
            "GenderLangDescription",
            _.get(input, "Gender.PublicCodeLangDescription") ||
              _.get(input, "GenderLangDescription")
          );
        }
        const patronRealBirthPlaceStateDivisionId =
          _.get(input, "PatronRealBirthPlace.StateDivisionId") ||
          _.get(input, "PatronRealBirthPlaceStateDivisionId");
        if (patronRealBirthPlaceStateDivisionId) {
          _.set(
            patronReal,
            "PatronRealBirthPlaceStateDivisionId",
            patronRealBirthPlaceStateDivisionId
          );
          _.set(
            patronReal,
            "VTPatronRealBirthPlace",
            _.get(input, "PatronRealBirthPlace.VT") ||
              _.get(input, "VTPatronRealBirthPlace")
          );
          _.set(
            extraData,
            "PatronRealBirthPlace",
            _.get(input, "PatronRealBirthPlace.StateDivisionLangDescription") ||
              _.get(input, "PatronRealBirthPlace")
          );
        }

        const PatronRealCitizenshipStateDivisionId =
          _.get(input, "PatronRealCitizenship.StateDivisionId") ||
          _.get(input, "PatronRealCitizenshipStateDivisionId");
        if (PatronRealCitizenshipStateDivisionId) {
          _.set(
            patronReal,
            "PatronRealCitizenshipStateDivisionId",
            PatronRealCitizenshipStateDivisionId
          );
          _.set(
            patronReal,
            "VTPatronRealCitizenship",
            _.get(input, "PatronRealCitizenship.VT") ??
              _.get(input, "VTPatronRealCitizenship")
          );
          _.set(
            extraData,
            "PatronRealCitizenship",
            _.get(
              input,
              "PatronRealCitizenship.StateDivisionLangDescription"
            ) ?? _.get(input, "PatronRealCitizenship")
          );
        }
        patronReal = _.omitBy(
          patronReal,
          (prop) => _.isNil(prop) || prop === ""
        );
        data.PatronReal = patronReal;
        //#endregion
      } else if (data.PatronType === "L") {
        //#region ------------ PatronLegal ------------
        let patronLegal = _.pick(input, [
          "PatronLegalLangDescription",
          "PatronLegalIDNo",
          "PatronLegalRegistrationNo",
          "PatronLegalMinistryOfFinanceNo",
          "PatronLegalRegisterDate",
        ]);
        const patronLegalRegisterPlaceStateDivisionId =
          _.get(input, "PatronLegalRegisterPlace.StateDivisionId") ||
          _.get(input, "PatronLegalRegisterPlaceStateDivisionId");
        if (patronLegalRegisterPlaceStateDivisionId) {
          _.set(
            patronLegal,
            "PatronLegalRegisterPlaceStateDivisionId",
            patronLegalRegisterPlaceStateDivisionId
          );
          _.set(
            patronLegal,
            "VTPatronLegalRegisterPlace",
            _.get(input, "PatronLegalRegisterPlace.VT") ||
              _.get(input, "VTPatronLegalRegisterPlace")
          );
          _.set(
            extraData,
            "PatronLegalRegisterPlace",
            _.get(
              patronData,
              "PatronLegalRegisterPlace.StateDivisionLangDescription"
            ) || _.get(patronData, "PatronLegalRegisterPlace")
          );
        }

        patronLegal = _.omitBy(
          patronLegal,
          (prop) => _.isNil(prop) || prop === ""
        );
        data.PatronLegal = patronLegal;
        //#endregion
      } else {
        let patronOther = {
          PatronOtherLangDescription: input.PatronOtherLangDescription,
        };
        data.PatronOther = patronOther;
      }
    }
    if (_.isNil(step) || activeStep === 1) {
      if (data.PatronType !== "O") {
        //#region ------------ Cellphone ------------
        let PatronCellphones = input.PatronCellphones
          ? [...patronData.PatronCellphones]
          : [];
        const cellphone = input.Cellphone;
        if (cellphone?.length >= 5) {
          index = PatronCellphones.findIndex(
            (patronCellphone) =>
              patronCellphone.Cellphone.toString() === cellphone
          );
          if (index === -1) {
            const {
              StateDivisionId: CellphoneCallingCodeStateDivisionId,
              StateDivisionLangDescription: CellphoneCallingCode,
              VT: VTCellphoneCallingCode,
              StateDivisionJsonModel,
            } = input.CellphoneCallingCode || {};
            const patronCellphone = {
              Cellphone: parseInt(cellphone),
              CellphoneCallingCodeStateDivisionId,
              CellphoneCallingCode,
              VTCellphoneCallingCode,
              StateDivisionJsonModel,
              PatronCellphoneIsMain: PatronCellphones.length === 0,
            };
            PatronCellphones.push(patronCellphone);
          }
        }
        if (PatronCellphones.length > 0) {
          extraInfo.PatronCellphones = [...PatronCellphones];
          for (let i = 0; i < PatronCellphones.length; i++) {
            PatronCellphones[i] = _.omit(PatronCellphones[i], [
              "CellphoneCallingCode",
              "StateDivisionJsonModel",
              "CellphoneId",
            ]);
          }
          data.PatronCellphones = PatronCellphones;
        }
        //#endregion

        //#region ------------ Phone ------------
        let PatronPhones = input.PatronPhones
          ? [...patronData.PatronPhones]
          : [];
        const phone = input.Phone;
        if (phone?.length >= 3) {
          index = PatronPhones.findIndex(
            (patronPhone) => patronPhone.Phone.toString() === phone
          );
          if (index === -1) {
            const {
              PublicCodeId: LocationTypeId,
              PublicCodeLangDescription: PhoneLocationType,
              VT: VTLocationTypeId,
            } = input.PhoneLocationType || {};
            const patronPhone = {
              Phone: parseInt(phone),
              PhoneCallingCode: _.get(input, "PhoneCallingCode"),
              LocationTypeId,
              PhoneLocationType,
              VTLocationTypeId,
              PatronPhoneIsMain: PatronPhones.length === 0,
            };
            PatronPhones.push(patronPhone);
          }
        }
        if (PatronPhones.length > 0) {
          extraInfo.PatronPhones = [...PatronPhones];
          for (let i = 0; i < PatronPhones.length; i++) {
            PatronPhones[i] = _.omit(PatronPhones[i], [
              "PhoneLocationType",
              "PhoneId",
            ]);
          }

          data.PatronPhones = PatronPhones;
        }
        //#endregion

        //#region ------------ Email ------------
        let PatronEmails = input.PatronEmails
          ? [...patronData.PatronEmails]
          : [];
        const email = input.Email;
        if (!isNullOrWhiteSpace(email)) {
          const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (emailPattern.test(email)) {
            index = PatronEmails.findIndex(
              (patronEmail) => patronEmail.Email === email
            );
            if (index === -1) {
              const patronEmail = {
                Email: email,
                PatronEmailIsMain: PatronEmails.length === 0,
              };
              PatronEmails.push(patronEmail);
            }
          }
        }
        if (PatronEmails.length > 0) {
          data.PatronEmails = PatronEmails;
          extraInfo.PatronEmails = PatronEmails;
        }
        //#endregion

        //#region ------------ Address ------------
        let PatronAddresses = input.PatronAddresses
          ? [...patronData.PatronAddresses]
          : [];
        const patronAddressLangDescription = input.PatronAddressLangDescription;
        if (!isNullOrWhiteSpace(patronAddressLangDescription)) {
          const index = PatronAddresses.findIndex(
            (patronAddress) =>
              patronAddress.PatronAddressLangDescription ===
              patronAddressLangDescription
          );
          if (index === -1) {
            const {
              StateDivisionId: PatronAddressCityStateDivisionId,
              StateDivisionLangDescription: PatronAddressCity,
              VT: VTPatronAddressCity,
            } = input.PatronAddressCity || {};
            const {
              PublicCodeId: LocationTypeId,
              PublicCodeLangDescription: PatronAddressLocationType,
              VT: VTLocationTypeId,
            } = input.PatronAddressLocationType || {};
            const patronAddress = {
              PatronAddressCityStateDivisionId,
              PatronAddressCity,
              VTPatronAddressCity,
              PatronAddressLangDescription: patronAddressLangDescription,
              PatronAddressPostalCode: parseInt(
                getValues("PatronAddressPostalCode")
              ),
              LocationTypeId,
              PatronAddressLocationType,
              VTLocationTypeId,
              PatronAddressIsMain: PatronAddresses.length === 0,
            };

            PatronAddresses.push(patronAddress);
          }
        }
        if (PatronAddresses.length > 0) {
          extraInfo.PatronAddresses = [...PatronAddresses];
          for (let i = 0; i < PatronAddresses.length; i++) {
            PatronAddresses[i] = _.omit(PatronAddresses[i], [
              "PatronAddressCity",
              "PatronAddressLocationType",
            ]);
          }

          data.PatronAddresses = PatronAddresses;
        }
        //#endregion
      }
    }
    if (_.isNil(step) || activeStep === 2) {
      //#region ------------- Detail -------------
      if (dbJarvisAccExists) {
        let AccDetails = input.AccDetails ? [...patronData.AccDetails] : [];
        const Detail = getValues("Detail");
        if (Detail) {
          const index = AccDetails.findIndex(
            (detail) => detail.DetailId === Detail.DetailId
          );
          if (index === -1) {
            AccDetails.push(Detail);
          }
        }

        if (AccDetails.length > 0) {
          extraInfo.AccDetails = [...AccDetails];
          for (let i = 0; i < AccDetails.length; i++) {
            AccDetails[i] = _.pick(AccDetails[i], ["DetailId", "VT"]);
          }
          data.AccDetails = AccDetails;
        } else if (input.IsAccSelected) data.IsAccSelected = true;
      }
      //#endregion ------------- Detail -------------

      //#region ------------ Group ------------
      let PatronGroups = input.PatronGroups ? [...patronData.PatronGroups] : [];
      const group = input.PatronGroupTypeSystem;
      if (group) {
        const index = PatronGroups.findIndex(
          (patronGroup) =>
            patronGroup.PatronGroupTypeSystemId ===
            group.PatronGroupTypeSystemId
        );
        if (index === -1) {
          const patronGroup = {
            PatronGroupTypeSystemId: group.PatronGroupTypeSystemId,
            VTPatronGroupTypeSystemId: group.VT,
            IsActive: true,
          };
          PatronGroups.push(patronGroup);
        }
      }
      if (PatronGroups.length > 0) {
        extraInfo.PatronGroups = [...PatronGroups];
        for (let i = 0; i < PatronGroups.length; i++) {
          PatronGroups[i] = _.omit(
            PatronGroups[i],
            "PatronGroupTypeSystemLangName"
          );
        }
        data.PatronGroups = PatronGroups;
      }
      //#endregion ------------ Group ------------
    }
    if (_.isNil(step) || activeStep === 3) {
      //#region ------------- TTMS -------------
      if (dbJarvisAccExists) {
        if (patronData.PatronType !== "O" && preData.configs.AccEnableTTMS) {
          let TTMSPatron = _.pick(input, [
            "BuyerDefaultProduct",
            "SellerDefaultProduct",
          ]);
          const buyerType = input.BuyerType;
          if (buyerType) {
            TTMSPatron.BuyerTypeId = buyerType.PublicCodeId;
            TTMSPatron.VTBuyerTypeId = buyerType.VT;
          }
          const buyerCommodity = input.BuyerCommodity;
          if (buyerCommodity) {
            TTMSPatron.BuyerCommodityTypeId = buyerCommodity.PublicCodeId;
            TTMSPatron.VTBuyerCommodityTypeId = buyerCommodity.VT;
          }
          const sellerType = input.SellerType;
          if (sellerType) {
            TTMSPatron.SellerTypeId = sellerType.PublicCodeId;
            TTMSPatron.VTSellerTypeId = sellerType.VT;
          }
          const sellerCommodity = input.SellerCommodity;
          if (sellerCommodity) {
            TTMSPatron.SellerCommodityTypeId = sellerCommodity.PublicCodeId;
            TTMSPatron.VTSellerCommodityTypeId = sellerCommodity.VT;
          }

          TTMSPatron = _.omitBy(
            TTMSPatron,
            (prop) => _.isNil(prop) || prop === ""
          );
          if (!_.isEmpty(TTMSPatron)) data.TTMSPatron = TTMSPatron;
        }
      }
      //#endregion ------------ TTMS ------------
    }
    if (_.isNil(step) || activeStep === 4) {
      //#region ------------- Job -------------
      if (data.PatronType !== "O") {
        let PatronJobs = input.PatronJobs ? [...patronData.PatronJobs] : [];
        const JobActivityType = input.JobActivityType;
        if (JobActivityType) {
          const index = PatronJobs.findIndex(
            (patronJob) =>
              patronJob.JobActivityTypeId === JobActivityType.JobActivityTypeId
          );
          if (index === -1) {
            let patronJob = {
              ..._.pick(JobActivityType, [
                "JobActivityTypeId",
                "JobActivityTypeLangName",
              ]),
            };
            patronJob.VTJobActivityType = JobActivityType.VT;
            if (patronData.PatronType === "R") {
              const {
                JobPositionType,
                PatronRealJobPlaceLangName,
                PatronRealJobLangDescription,
              } = input;
              patronJob = _.merge(patronJob, {
                ..._.pick(JobPositionType, [
                  "JobPositionTypeId",
                  "JobPositionTypeLangName",
                ]),
                PatronRealJobPlaceLangName,
                PatronRealJobLangDescription,
                IsActive: true,
              });
              patronJob.VTJobPositionType = JobPositionType?.VT;
            }
            PatronJobs.push(patronJob);
          }
        }
        if (PatronJobs.length > 0) {
          extraInfo.PatronJobs = [...PatronJobs];
          for (let i = 0; i < PatronJobs.length; i++) {
            PatronJobs[i] = _.omitBy(
              PatronJobs[i],
              (prop) => _.isNil(prop) || prop === ""
            );
            PatronJobs[i] = _.omit(PatronJobs[i], [
              "JobActivityTypeLangName",
              "JobPositionTypeLangName",
              "PatronLegalLangDescription",
            ]);
          }
          data.PatronJobs = PatronJobs;
        }
        //#endregion

        //#region ------------- Relation -------------
        let PatronRelations = input.PatronRelations
          ? [...patronData.PatronRelations]
          : [];
        const Patron = input.Patron;
        if (Patron) {
          const index = PatronRelations.findIndex(
            (patronRelation) => patronRelation.PatronIdTo === Patron.PatronId
          );
          if (index === -1) {
            const { PatronRelationType } = input;
            const patronRelation = {
              PatronIdTo: Patron.PatronId,
              PatronLangFullNameTo: Patron.PatronLangFullName,
              VTPatronTo: Patron.VT,
              PatronRelationTypeId: PatronRelationType.PublicCodeId,
              PatronRelationTypeLangName:
                PatronRelationType.PublicCodeLangDescription,
              VTPatronRelationType: PatronRelationType.VT,
              IsActive: true,
            };
            PatronRelations.push(patronRelation);
          }
        }
        if (PatronRelations.length > 0) {
          extraInfo.PatronRelations = PatronRelations;
          for (let i = 0; i < PatronRelations.length; i++) {
            PatronRelations[i] = _.omit(PatronRelations[i], [
              "PatronLangFullNameTo",
              "PatronRelationTypeLangName",
            ]);
          }
          data.PatronRelations = PatronRelations;
        }
      }
      //#endregion
    }

    addGTCD(input, preData.GTCD, data);

    return { extraInfo, data, extraData };
  };

  const completeStep = (data) => {
    if (activeStep === 0) {
      setPatronData(_.merge(patronData, data));
    } else {
      const { extraInfo } = generateFinalPatronData(
        _.merge(patronData, data),
        activeStep
      );
      setPatronData(_.merge(patronData, extraInfo));
    }

    if (activeStep < steps.length - 1) {
      let completedTemp = { ...completed };
      completedTemp[activeStep] = true;
      setCompleted(completedTemp);
    }
  };

  const handleClose = () => {
    onShowInputForm(false);
  };

  const onSubmit = async (save, index, isNext) => {
    const valid = await trigger(getValidationFields());
    if (valid) {
      const data = getValues({ nest: true });
      if (save) {
        const finalPatronData = generateFinalPatronData(
          _.merge({ ...patronData }, data)
        );
        setIsLoading(true);
        const { ErrorHandling, Data } = await onSubmitParent(finalPatronData);
        setIsLoading(false);
        if (_.get(ErrorHandling, "IsSuccessful")) {
          if (_.get(ErrorHandling, "IsDuplicate")) {
            setDuplicateData(Data || []);
            setSaveData(finalPatronData);
            setShowDuplicate(true);
          } else {
            // if (isEditMode || isIndependent) {
            handleClose();
            // } else {
            //   handleReset();
            // }
          }
        }
      } else {
        if (!_.isNil(index)) {
          completeStep(data);
          setActiveStep(index);
        } else {
          completeStep(data);
          if (isNext) setActiveStep(activeStep + 1);
          else setActiveStep(activeStep - 1);
        }
      }
    }
  };

  // in the case of duplicate
  const handleSubmit = async (createMode, selectedRow) => {
    let data = _.get(saveData, "data");
    data.Mode = createMode;
    if (createMode === "Replace" || createMode === "Merge") {
      data.PatronId = _.get(selectedRow, "PatronId");
      data.VT = _.get(selectedRow, "VT");
      data.PatronLastUpdateDateTime = _.get(
        selectedRow,
        "PatronLastUpdateDateTime"
      );
      setIsLoading(true);
      const { ErrorHandling } = await PatronUpdate(data);
      setIsLoading(false);
      if (_.get(ErrorHandling, "IsSuccessful")) {
        fetchData();
        handleClose();
      }
    } else {
      const { ErrorHandling } = await onSubmitParent(saveData);
      if (_.get(ErrorHandling, "IsSuccessful")) {
        // if (isEditMode) {
        handleClose();
        // } else {
        //   handleReset();
        // }
      }
    }
  };

  const handleformKeyDown = (e) => {
    if (e.key === "Enter" && e.ctrlKey) onSubmit(false, null, true);
    else if (e.key === "Enter" && e.shiftKey) onSubmit(false, null, false);
  };

  return (
    <div>
      <Dialog
        open={showInputForm}
        title={getDialogTitle()}
        onClose={handleClose}
        hasTransition={false}
        isDraggable={false}
        fullScreen
      >
        <div className={classes.root}>
          {isLoadingData ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step key={index}>
                      <StepButton
                        onClick={() => onSubmit(false, index)}
                        completed={completed[index]}
                      >
                        <Typography label={label} />
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <form
                className="border border-info py-3 mb-2 px-4"
                noValidate
                onKeyDown={(e) => handleformKeyDown(e)}
              >
                {renderStep()}
                <div className="row m-3 d-flex justify-content-end align-items-center">
                  <div className="row">
                    <Button
                      label={_.get(langData, "Back")}
                      disabled={activeStep === 0}
                      variant="contained"
                      onClick={() => onSubmit(false, null, false)}
                    />
                    <Button
                      className="mx-2"
                      label={_.get(langData, "Next")}
                      variant="contained"
                      disabled={activeStep === steps.length - 1}
                      onClick={() => onSubmit(false, null, true)}
                    />
                    <Button
                      className={classes.button}
                      label={_.get(langData, "Save")}
                      variant="contained"
                      color="primary"
                      showLoading={isLoading}
                      onClick={() => onSubmit(true)}
                    />
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
      </Dialog>
      {showDuplicate && (
        <Dialog
          width="70%"
          open={showDuplicate}
          title={_.get(langData, "PmsPatron_Duplicate")}
          showDialogAction={false}
          maxWidth={false}
          disableBackdropClick
          warningColor
          onClose={() => setShowDuplicate(false)}
        >
          <PatronDuplicate
            duplicateData={duplicateData}
            isEditMode={isEditMode}
            langData={langData}
            onClose={() => setShowDuplicate(false)}
            onSubmit={handleSubmit}
            partonType={patronData.PatronType}
          />
        </Dialog>
      )}
    </div>
  );
}
