import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "Jarvis/JarvisStyles/themes/indigoTheme";
import cyanTheme from "Jarvis/JarvisStyles/themes/cyanTheme";
import amberTheme from "Jarvis/JarvisStyles/themes/amberTheme";
import pinkTheme from "Jarvis/JarvisStyles/themes/pinkTheme";
import blueTheme from "Jarvis/JarvisStyles/themes/blueTheme";
import purpleTheme from "Jarvis/JarvisStyles/themes/purpleTheme";
import greenTheme from "Jarvis/JarvisStyles/themes/greenTheme";
import darkTheme from "Jarvis/JarvisStyles/themes/darkTheme";
import orangeTheme from "Jarvis/JarvisStyles/themes/orangeTheme";
import JarvisBaseSettingsService, { jarvisComponentMustBeExcluded } from "../JarvisServices/JarvisBaseSettingsService";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from "../JarvisConstants/ThemeColors";

import App from "Jarvis/JarvisApp/index";
import { setInitUrl } from "../JarvisActions/Auth";
import RTL from "Jarvis/JarvisUtil/RTL";
import asyncComponent from "Jarvis/JarvisUtil/asyncComponent";
import { setDarkTheme, setThemeColor } from "../JarvisActions/Setting";
import Account from "../JarvisSystems/Sec/Components/Account";

const env = JarvisBaseSettingsService.getEnvironment();

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/account/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function Routes({ history, match, location, isDarkTheme, authUser, initURL, isDirectionRTL, themeColor, setInitUrl, setThemeColor, setDarkTheme }) {
  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === "") {
      setInitUrl(history.location.pathname);
    }
    const params = new URLSearchParams(location.search);
    if (params.has("theme-name")) {
      setThemeColor(params.get("theme-name"));
    }
    if (params.has("dark-theme")) {
      setDarkTheme();
    }
  }, [initURL, history.location.pathname, location.search, setDarkTheme, setInitUrl, setThemeColor]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: "rtl" });
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add("dark-theme");
    applyTheme = createMuiTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (jarvisComponentMustBeExcluded("Login") === true) authUser = "Ehsan, Majid1,Majid2";
  if (location.pathname === "/") {
    initURL = "";
    if (authUser === null) {
      return <Redirect to={"/Account/Signin"} />;
    } else if (initURL === "" || initURL === "/" || initURL.toLowerCase() === "/account/signin") {
      return <Redirect to={`/${env}/`} />;
    } else {
      return <Redirect to={initURL} />;
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale="en" messages={null}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}${env}/`} authUser={authUser} component={App} />
                <Route path="/Account/" component={Account} />
                <Route component={asyncComponent(() => import("Jarvis/JarvisComponents/Error404"))} />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ settings, auth }) => {
  const { themeColor, sideNavColor, darkTheme, language, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    language,
    isDirectionRTL,
    authUser,
    initURL,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  setThemeColor,
  setDarkTheme,
})(Routes);
