import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LanguageSwitcher from "Jarvis/JarvisComponents/LanguageSwitcher";
import { RetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/PageElementService";
import Wait from "Jarvis/JarvisComponents/Wait";
import Typography from "Jarvis/JarvisComponents/Typography";
import FormContainer from "./FormContainer";
import SignInUserInfo from "./SignInUserInfo";
import ConcurrentLogin from "./ConcurrentLogin";

const Account = (props) => {
  const { language, isInternalSignIn = false } = props;
  const [stateObj, setStateObj] = useState({
    backgroundImageName: null,
    loginResultInfo: null,
    response: null,
    errorMessage: "",
    concurrentLogins: [],
  });
  const backgroundImageCount = 6;

  useEffect(() => {
    (async () => {
      const { ErrorHandling, Data } = await RetrievePublic({
        langId: language.LangId,
        moduleCodeStrings: "SecAuthentication",
        configs: "PubCompanyName",
      });
      let response = null;
      let errorMessage = null;
      if (_.get(ErrorHandling, "IsSuccessful")) response = Data;
      else errorMessage = _.get(ErrorHandling, "ErrorMessage");
      setStateObj({
        ...stateObj,
        response: response,
        errorMessage: errorMessage,
        backgroundImageName:
          "0" + Math.ceil(Math.random() * backgroundImageCount),
      });
    })();
  }, [language.LangId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stateObj?.response)
    return (
      <div className="app-main-container">
        <Wait />
      </div>
    );

  if (stateObj.errorMessage)
    return (
      <pre style={{ wordBreak: "break-word" }}>
        {JSON.stringify(stateObj.errorMessage, null, 2)}
      </pre>
    );

  const langData = _.get(stateObj.response, "PE");
  const configs = _.get(stateObj.response, "Configs");

  if (stateObj.loginResultInfo) {
    return (
      <div
        className="login-full-page"
        style={{
          backgroundImage:
            "url(" +
            require("assets/images/login/" +
              stateObj.backgroundImageName +
              ".jpg") +
            ")",
        }}
      >
        <SignInUserInfo
          langData={langData}
          data={stateObj.loginResultInfo}
          isInternalSignIn={isInternalSignIn}
          concurrentLogins={stateObj.concurrentLogins}
          {...props}
        />
      </div>
    );
  }

  const _SetLoginResultInfo = (data) => {
    setStateObj({
      ...stateObj,
      concurrentLogins: _.get(data, "Data.ConcurrentLogins") ?? [],
      loginResultInfo: data,
    });
  };

  const onLoadConcurrentLogins = (data) => {
    setStateObj({ ...stateObj, concurrentLogins: data ?? [] });
  };

  return (
    <div
      className="login-full-page"
      style={{
        backgroundImage:
          "url(" +
          require("assets/images/login/" +
            stateObj.backgroundImageName +
            ".jpg") +
          ")",
      }}
    >
      {stateObj.concurrentLogins.length > 0 ? (
        <div
          style={{ maxWidth: "none" }}
          className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        >
          <div className="app-login-main-content">
            <ConcurrentLogin
              concurrentLogins={stateObj.concurrentLogins}
              onLoadConcurrentLogins={onLoadConcurrentLogins}
              isBackToLoginNeeded={true}
            />
          </div>
        </div>
      ) : (
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-logo-content">
              {isInternalSignIn === false && (
                <div className="d-flex align-items-center justify-content-center">
                  <LanguageSwitcher
                    withBorder={true}
                    accessMustBeChecked={false}
                    {...props}
                  />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <Link className="logo-lg" to="/" title="Jarvis">
                      <img
                        src={require("assets/images/logo.png")}
                        alt="Jarvis"
                        title="Jarvis"
                        width="100%"
                        height="100%"
                      />
                    </Link>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center col-md-12"
                    style={{ color: "white" }}
                  >
                    <Typography label={_.get(configs, "PubCompanyName")} />
                  </div>
                </div>
              </div>
            </div>
            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <Typography
                  label={_.get(langData, "SecAuthentication_Title")}
                  variant="h6"
                />
              </div>
              <div>
                <FormContainer
                  langData={langData}
                  configs={configs}
                  isInternalSignIn={isInternalSignIn}
                  _SetLoginResultInfo={_SetLoginResultInfo}
                  onLoadConcurrentLogins={onLoadConcurrentLogins}
                  {...props}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { language } = settings;
  return { language };
};

export default connect(mapStateToProps)(Account);
