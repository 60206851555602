import _ from "lodash";
import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService, { jarvisCustomServiceMustBeReplaced } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { generateJarvisOutput, isEqual, isNullOrWhiteSpace } from "./JarvisCommonService";
import { getLanguage } from "./JarvisLocalStorageService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const LoadMenuByPermission = async ({ subSystemKey }) => {
  const customServiceName = "JarvisWebCoreService.LoadMenuByPermission";
  const res = jarvisCustomServiceMustBeReplaced(customServiceName);
  if (res.mustBeReplaced === false) {
    const { data } = await JarvisHttpService.post({
      url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "JWebCore/ServerApi/LoadMenuByPermission",
      data: { subSystemKey },
    });
    return data;
  }
  if (isNullOrWhiteSpace(_.get(res, "apiKey")) === false && isNullOrWhiteSpace(_.get(res, "url")) === false) {
    const { data } = await JarvisHttpService.post({
      url: JarvisBaseSettingsService.getCustomApiEndpoint(res.apiKey) + res.url,
      data: { subSystemKey },
    });
    return data;
  } else {
    const langKey = getLanguage().LangKey;
    const _data = _.filter(require(`JarvisCustom/JarvisServices/Locale/${langKey}/${customServiceName}.json`), (x) => isEqual(x.SubSystemKey, subSystemKey));
    return generateJarvisOutput({ data: _data });
  }
};

export const JWebCoreGenerateDownloadLink = ({ guid, semmiPath }) => {
  semmiPath = ifNullOrWhiteSpace(semmiPath, "");
  const link = `${JarvisBaseSettingsService.getApiEndPoint("Jarvis")}JWebCore/PublicClientApi/DownloadFile?id=${guid}&semiPath=${semmiPath}`;
  return link;
};
