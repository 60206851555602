import React from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { getCalendar } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import jMoment from "moment-jalaali";
import iMoment from "moment-hijri";
import "moment/locale/ar-sa";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";

export const isNullOrWhiteSpace = (input) => {
  if (input === null || input === undefined) return true;
  if (typeof input === "string") if (input.trim() === "") return true;
  return false;
};

export const ifNullOrWhiteSpace = (input, replacement) => {
  if (isNullOrWhiteSpace(input)) return replacement;
  return input;
};

export const isTrue = (input) => {
  input = input?.toString();
  if (input?.toLowerCase() === "true" || input === "1") return true;
  return false;
};

export const uuid = () => {
  return uuidv4();
};

export const isEqual = (string1, string2) => {
  if (string1?.toUpperCase() === string2?.toUpperCase()) return true;
  return false;
};

export const isValidDate = (date, format) => {
  const calendar = getCalendar();

  if (isEqual(calendar.calendarKey, "Gregorian") === false)
    format = "yyyy/MM/dd";

  format = ifNullOrWhiteSpace(
    format,
    ifNullOrWhiteSpace(calendar?.calendarFormat, "yyyy/MM/dd")
  );

  const dt = moment(
    date?.replaceAll("/", "-"),
    format?.toUpperCase().replaceAll("/", "-"),
    true
  );
  return dt.isValid();
};

export const getDateString = (date) => {
  if (isNullOrWhiteSpace(date)) date = new Date();
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  return `${date.getFullYear()}/${mm}/${dd}`;
};

export const getTodayDateTime = () => {
  const dateTime = new Date();
  const date = getDateString(dateTime);
  const hh = String(dateTime.getHours()).padStart(2, "0");
  const mm = String(dateTime.getMinutes()).padStart(2, "0");
  return `${convertGregorianTo(date)} ${hh}:${mm}`;
};

export const convertGregorianTo = (date, calendar) => {
  if (isNullOrWhiteSpace(date)) return "";
  calendar = ifNullOrWhiteSpace(calendar, getCalendar().calendarKey);
  if (isEqual(calendar, "Gregorian")) return date;
  else if (isEqual(calendar, "HijriShamsi")) {
    const symbolMap = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      0: "0",
    };
    jMoment.updateLocale("ar-sa", {
      postformat: function (string) {
        return string
          .replace(/\d/g, function (match) {
            return symbolMap[match];
          })
          .replace(/,/g, "،");
      },
    });
    const _date = jMoment(
      date?.replaceAll("-", "/"),
      "YYYY/MM/DD",
      false
    ).format("jYYYY/jMM/jDD");
    if (_date !== "Invalid date") return _date;
    return "";
  } else if (isEqual(calendar, "HijriGhamari")) {
    const symbolMap = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      0: "0",
    };
    iMoment.updateLocale("ar-sa", {
      postformat: function (string) {
        return string
          .replace(/\d/g, function (match) {
            return symbolMap[match];
          })
          .replace(/,/g, "،");
      },
    });
    const _date = iMoment(date, "YYYY/MM/DD", true).format("iYYYY/iMM/iDD");
    if (_date !== "Invalid date") return _date;
    return "";
  } else return date;
};

export const convertToGregorian = (date, calendar) => {
  calendar = ifNullOrWhiteSpace(calendar, getCalendar().calendarKey);
  if (isEqual(calendar, "Gregorian")) return date;
  else if (isEqual(calendar, "HijriShamsi")) {
    const dialect = "persian-modern";
    jMoment.loadPersian({
      dialect: dialect,
      usePersianDigits: false, //usePersianDigits,
    });
    jMoment.updateLocale("fa", {
      jMonthsShort: {
        persian: "فروردین_اردیبهشت_خرداد_تیر_مرداد_شهریور_مهر_آبان_آذر_دی_بهمن_اسفند".split(
          "_"
        ),
        "persian-modern": "فروردین_اردیبهشت_خرداد_تیر_مرداد_شهریور_مهر_آبان_آذر_دی_بهمن_اسفند".split(
          "_"
        ),
      }[dialect],
    });
    const _date = jMoment(date, "jYYYY/jMM/jDD", true).format("YYYY/MM/DD");
    if (_date !== "Invalid date") return _date;
    return "";
  } else if (isEqual(calendar, "HijriGhamari")) {
    const symbolMap = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      0: "0",
    };
    iMoment.updateLocale("ar-sa", {
      postformat: function (string) {
        return string
          .replace(/\d/g, function (match) {
            return symbolMap[match];
          })
          .replace(/,/g, "،");
      },
    });
    const _date = iMoment(date, "iYYYY/iMM/iDD", true).format("YYYY/MM/DD");
    if (_date !== "Invalid date") return _date;
    return "";
  } else return date;
};
export const generateJarvisErrorHandling = ({
  errorCode,
  errorMessage,
  isSuccessful,
  mustBeSeenByUser,
}) => {
  return {
    ErrorCode: ifNullOrWhiteSpace(errorCode, 0),
    ErrorMessage: ifNullOrWhiteSpace(errorMessage, ""),
    IsSuccessful: ifNullOrWhiteSpace(isSuccessful, true),
    MustBeSeenByUser: ifNullOrWhiteSpace(mustBeSeenByUser, false),
  };
};
export const generateJarvisOutput = ({
  errorCode,
  errorMessage,
  isSuccessful,
  mustBeSeenByUser,
  data,
}) => {
  return {
    ErrorHandling: generateJarvisErrorHandling({
      errorCode,
      errorMessage,
      isSuccessful,
      mustBeSeenByUser,
    }),
    Data: data,
  };
};

export const digitGrouping = (number) =>
  number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const convertToBoolean = (value) => {
  if (isNullOrWhiteSpace(value)) return false;
  if (_.isBoolean(value)) return value;
  if (_.isString(value) && (value === "1" || value.toUpperCase() === "TRUE")) {
    return true;
  }
  if (_.isNumber(value) && value !== 0) return true;

  return false;
};

export const convertToNumber = (value) => {
  if (_.isString(value)) {
    return Number(value.replaceAll(",", ""));
  }

  return value;
};

export const flatten = (object) => {
  const values = _.values(object);
  if (_.isObject(values[0])) return _.merge(...values);
  if (_.isArray(values[0])) return _.flatten(values);

  return object;
};

export const generateRules = (rules, IGTC) => {
  const result = rules || [];
  let index = -1;
  if (IGTC) {
    flatten(IGTC).forEach((column) => {
      if (rules) {
        index = rules?.findIndex((rule) => rule.ColumnName === column);
        if (index !== -1) result.push({ ...rules[index], Dispaly: false });
        else result.push({ ColumnName: column, Dispaly: false });
      } else result.push({ ColumnName: column, Dispaly: false });
    });
  }

  return _.isArray(result) ? result : null;
};

export const generateColumns = (columns, prerequisites) => {
  let { GTCD, rules } = prerequisites;

  if (!_.isEmpty(GTCD)) {
    const newColumns = _.isArray(GTCD) ? GTCD : flatten(GTCD) || [];
    let column = {};
    newColumns.forEach((col) => {
      column = { visible: false };
      column.label = col.LangDescription;
      column.path = col.ColumnName;
      switch (col.DataType) {
        case "Bit": {
          column.type = "checkbox";
          column.widthScale = 0.5;
          break;
        }
        case "Date":
        case "DateTIme":
          column.type = "date";
          break;

        case "TinyInt":
        case "SmallInt":
        case "Int":
        case "BigInt":
        case "Real":
        case "Decimal":
          column.type = "number";
          break;

        default:
          break;
      }

      columns.push(column);
    });
  }

  if (!_.isEmpty(prerequisites.IGTC))
    prerequisites.IGTC = _.isArray(prerequisites.IGTC)
      ? prerequisites.IGTC
      : flatten(prerequisites.IGTC);

  if (!_.isEmpty(rules))
    rules.forEach((rule) => {
      if (
        rule.Display === false &&
        (!prerequisites.IGTC ||
          prerequisites.IGTC.indexOf(rule.ColumnName) === -1)
      ) {
        if (_.isNil(prerequisites.IGTC)) prerequisites.IGTC = [];
        prerequisites.IGTC.push(rule.ColumnName);
      }
    });

  if (!_.isEmpty(prerequisites.IGTC))
    return columns.filter(
      (column) => prerequisites.IGTC.indexOf(column.path) === -1
    );
  else return columns;
};

export const checkClassName = (className, columnName, prerequisites) => {
  let { IGTC } = prerequisites ?? {};

  if (_.isEmpty(IGTC)) return className;
  if (_.isArray(columnName)) {
    const picked = _.pickBy(
      IGTC,
      (col) => columnName.indexOf(col.ColumnName) !== -1
    );
    if (Object.keys(picked).length === columnName.length) return "d-none";

    return className;
  } else if (IGTC.indexOf(columnName) !== -1) return "d-none";

  return className;
};

export const addGTCD = (source, GTCD, dest) => {
  if (GTCD && !_.isArray(GTCD)) GTCD = flatten(GTCD);
  if (!_.isEmpty(GTCD)) {
    if (dest.GTCD === undefined) dest.GTCD = [];
    let gtcdModel = {};

    GTCD.forEach((col) => {
      if (!isNullOrWhiteSpace(source[col.ColumnName])) {
        gtcdModel.Id = col.Id;
        gtcdModel.VT = col.VT;
        gtcdModel.Value = source[col.ColumnName];
        gtcdModel.GTId = col.GTId;

        dest.GTCD.push(gtcdModel);
      }
    });
  }
};

export const getBase64Async = (filePath) => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    temporaryFileReader.onloadend = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(filePath);
  });
};

export const getBase64 = (filePath, callback) => {
  let reader = new FileReader();
  reader.readAsDataURL(filePath);
  reader.onload = function () {
    callback(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const json2Table = ({
  jsonArray,
  tableStyle = null,
  theadClassName = null,
}) => {
  // get table column
  if (jsonArray === null || jsonArray.length === 0) return null;
  const column = Object.keys(jsonArray[0]);

  // get table heading data
  const ThData = () => {
    return column.map((data) => {
      return <th key={uuidv4()}>{data}</th>;
    });
  };

  // get table row data
  const tdData = () => {
    return jsonArray.map((data) => {
      return (
        <tr key={uuidv4()}>
          {column.map((v) => {
            return <td key={uuidv4()}>{data[v]}</td>;
          })}
        </tr>
      );
    });
  };

  return (
    <table className="table" style={tableStyle}>
      <thead className={theadClassName ? theadClassName : ""}>
        <tr>{ThData()}</tr>
      </thead>
      <tbody>{tdData()}</tbody>
    </table>
  );
};

export const openFileViewer = ({ url, filename }) => {
  const fileExtension = filename.split(".").pop();
  let downloadLink = url;
  switch (fileExtension?.toLowerCase()) {
    case "pdf":
      downloadLink = `${JarvisBaseSettingsService.getJarvisUIXUrl()}?module=fileViewer&val=${encodeURIComponent(
        downloadLink
      )}&fileExtension=${fileExtension}`;
      break;
    default:
      break;
  }
  let alink = document.createElement("a");
  alink.href = downloadLink;
  alink.target = "_blank";
  alink.click();
};

export const copyToClipboard = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export const copyToClipboardApi = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      try {
        copyToClipboard(text);
      } catch (e) {
        console.error("Failed to copy text: ", { err, e });
      }
    });
};

export const getDataType = (value) => {
  switch (_.get(value, "DataTypeCode")) {
    case "TinyInt":
    case "SmallInt":
    case "Int":
    case "BigInt":
    case "Real":
    case "Decimal":
      return "Number";
    case "String64":
    case "String128":
    case "String512":
    case "String1024":
    case "StringMax":
      return "String";
    case "Date":
    case "DateTime":
    case "Time":
      return "Date";
    default:
      return _.get(value, "DataTypeCode");
  }
};
