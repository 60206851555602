import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService, { jarvisCustomServiceMustBeReplaced } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { generateJarvisOutput } from "Jarvis/JarvisServices/JarvisCommonService";
import { getLanguage } from "Jarvis/JarvisServices/JarvisLocalStorageService";

export const SwitchLanguage = async ({ langId, langKey, vt }) => {
  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Lang/SwitchLanguage",
    data: {
      langId,
      langKey,
      vt,
    },
  });
  return data;
};

export const LangRetrievePublic = async () => {
  const customServiceName = "LangService.RetrievePublic";
  const res = jarvisCustomServiceMustBeReplaced(customServiceName);
  if (res.mustBeReplaced === false) {
    const { data } = await JarvisHttpService.post({
      url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Lang/RetrievePublic",
    });
    return data;
  }
  const langKey = getLanguage().LangKey;
  return generateJarvisOutput({
    data: require(`JarvisCustom/JarvisServices/Locale/${langKey}/${customServiceName}.json`),
  });
};
