/*------------
Version: 1.1
------------*/
import React, { useContext } from "react";
import _ from "lodash";
// import { setJarvisVersion } from "Jarvis/JarvisServices/JarvisLocalStorageService";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";

export default function Version({ jarvisVersion }) {
  const { PE } = useContext(GlobalContext) || {};

  return (
    <div>
      <strong style={{ cursor: "pointer", color: "red" }}>
        {_.get(PE, "PubPublicElems.NewVersion")?.replace("1", jarvisVersion) ??
          `New update ${jarvisVersion}: Please click to receive the updates`}
      </strong>
    </div>
  );
}
