/*------------
Version: 5.3.5
------------*/
import React, { useState, useEffect, useContext, Fragment } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import {
  isNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import Typography from "../Typography";
import "./index.scss";

const defaultStateObject = {
  active: false,
  options: [],
  optionsTemp: [],
  pageIndex: 1,
  totalRecords: undefined,
  totalFilteredRecords: undefined,
  serviceParams: null,
};

export default function Select({
  autoFocus,
  autoSelect,
  buttonLike,
  checkRules,
  className,
  columnName,
  control,
  data,
  debug,
  dependencies,
  displayFieldName,
  displayItem,
  errors,
  error,
  fontSize,
  freeSolo,
  hint,
  hintColor,
  label,
  langData,
  limitTags,
  margin,
  multiSelect,
  name,
  onAddClick,
  onChange,
  onInputChange,
  pageSize,
  placeholder,
  popupIcon,
  required,
  roundedCorner,
  rules,
  searchFieldNames,
  service,
  serviceParams,
  showSearchIcon,
  size,
  totalRecordsInput,
  typeOnly,
  value,
  valueFieldName,
  variant,
  width,
  ...rest
}) {
  const { isDirectionRTL, language } =
    useSelector((state) => state.settings) ?? {};
  let PE = _.get(useContext(GlobalContext), "PE.PubPublicElems");
  if (langData) PE = langData;
  const [id, setId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [showRequired, setShowRequired] = useState(
    (required && isNullOrWhiteSpace(value)) || _.isArray(rules)
  );
  const [showEndAdornment, setShowEndAdornment] = useState(false);
  const [stateObject, setStateObject] = useState(defaultStateObject);
  const [where, setWhere] = useState([]);

  let ruleIndex = -1;
  if (_.isArray(rules)) {
    ruleIndex = rules.findIndex(
      (rule) => _.get(rule, "ColumnName") === columnName ?? name
    );
  }

  const isLoading =
    service &&
    open &&
    (!stateObject.active ||
      !_.isEqual(serviceParams, stateObject.serviceParams));

  let selectStyle = rest.style ?? {};
  if (!isNullOrWhiteSpace(width)) selectStyle.width = width;

  let customRequired =
    ruleIndex !== -1
      ? rules[ruleIndex].IsRequired &&
        isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
      : false;

  useEffect(() => {
    let delay;
    if (inputValue)
      delay = setTimeout(() => {
        handleSearch();
      }, 400);

    return () => {
      clearTimeout(delay);
    };
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    setId(`select-${uuid()}`);
    if (
      (value &&
        !isNullOrWhiteSpace(
          multiSelect ? _.get(value[0], valueFieldName) : value[valueFieldName]
        )) ||
      freeSolo
    ) {
      if (multiSelect)
        setStateObject((stateObject) => ({
          ...stateObject,
          options: [...value],
        }));
      else
        setStateObject((stateObject) => ({
          ...stateObject,
          options: [value],
        }));
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    if (!service || !_.isEqual(serviceParams, stateObject.serviceParams)) {
      setStateObject({
        ...defaultStateObject,
      });
    }
  }, [serviceParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!service && !data) {
      setStateObject({
        ...defaultStateObject,
      });
      return;
    }

    if (ruleIndex !== -1 && rules[ruleIndex].Display === false)
      return undefined;

    if (
      !isNullOrWhiteSpace(data) &&
      where.length === 0 &&
      (data.length === 0 || !isEqual(data, stateObject.options))
    ) {
      setStateObject({
        active: true,
        options: data,
        totalRecords: totalRecordsInput ?? data.length,
        serviceParams: serviceParams,
      });
      return undefined;
    }

    if (!isLoading) {
      return undefined;
    }

    if (dependencies) {
      for (let i = 0; i < dependencies.length; i++) {
        if (isNullOrWhiteSpace(dependencies[i])) {
          setStateObject({
            ...defaultStateObject,
            active: true,
            serviceParams: serviceParams,
          });
          return undefined;
        }
      }
    }

    if (typeOnly && where.length === 0) {
      setStateObject((stateObject) => ({
        ...stateObject,
        active: true,
        serviceParams: serviceParams,
      }));
      return undefined;
    }

    (async () => {
      let params = {
        pageIndex: stateObject.pageIndex,
        pageSize,
        totalRecords: where.length === 0 ? stateObject.totalRecords : undefined,
      };
      if (where.length > 0) params.where = where;
      params = { ...params, pagination: params };
      if (serviceParams) params = { ...params, ...serviceParams };
      const response = !isNullOrWhiteSpace(service)
        ? await service(params)
        : null;
      if (response) {
        const { ErrorHandling, Data, TotalRecords } =
          _.get(response, "data") ?? response;
        if (_.get(ErrorHandling, "IsSuccessful")) {
          let options = [...stateObject.options];
          let newItems = Data ?? [];

          // update value
          if (
            _.isNull(stateObject.serviceParams) ||
            stateObject.pageIndex > 1
          ) {
            if (value && !multiSelect && newItems.length > 0) {
              const index = newItems.findIndex(
                (item) => item[valueFieldName] === value[valueFieldName]
              );
              if (index !== -1) {
                options[0] = newItems[index];
                newItems.splice(index, 1);
              }
            }

            options.push(...newItems);
          } else options = [...newItems];

          setStateObject((stateObject) => ({
            ...stateObject,
            active: true,
            pageIndex: stateObject.pageIndex ?? 1,
            options: options,
            totalRecords:
              where.length === 0 ? TotalRecords : stateObject.totalRecords,
            totalFilteredRecords: where.length !== 0 ? TotalRecords : undefined,
            serviceParams: serviceParams,
          }));

          const newInputValue = document.getElementById(id)?.value;
          if (where.length > 0 && where[0]?.Value !== newInputValue) {
            setWhere(generateWhereClause(newInputValue));
            setStateObject((stateObject) => ({
              ...stateObject,
              optionsTemp:
                !typeOnly && stateObject.optionsTemp.length === 0
                  ? stateObject.options
                  : stateObject.optionsTemp,
              pageIndex: 1,
              active: false,
            }));
            return;
          }

          if (id && document.getElementById(id))
            document.getElementById(id).scrollTop = scrollTop;
        }
      } else {
        if (
          stateObject.options.length > 0 &&
          !_.isEqual(serviceParams, stateObject.serviceParams)
        )
          setStateObject({
            ...defaultStateObject,
            active: true,
            serviceParams: serviceParams,
          });
      }
    })();
  }, [isLoading, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (ruleIndex !== -1 && rules[ruleIndex].Display === false) return null;

  const isEqual = (data1, data2) => {
    if (data1.length !== data2?.length) return false;
    if (!_.isEqual(data1[0], data2[0])) return false;
    if (_.differenceWith(data1, data2, _.isEqual).length !== 0) return false;

    return true;
  };

  const displayItemFilterOptions = createFilterOptions({
    stringify: (option) => {
      return displayItem(option) ?? "";
    },
  });

  const filterOptions = (option) => option;

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;

    return <Typography fontSize={7} label={helperText} color={hintColor} />;
  };

  const getDefaultValue = () => {
    if (
      (value &&
        !isNullOrWhiteSpace(
          multiSelect ? _.get(value[0], valueFieldName) : value[valueFieldName]
        )) ||
      freeSolo
    )
      return value ?? null;
    return multiSelect ? [] : null;
  };

  const getPopupIcon = () => {
    if (typeOnly) return null;
    return popupIcon;
  };

  const getRules = () => {
    if (checkRules) {
      let customRules = {};
      if (ruleIndex !== -1) {
        if (
          rules[ruleIndex].IsRequired &&
          isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
        )
          customRules.required = _.get(PE, "Required") ?? "required";
      } else customRules = rules;

      if (_.isEmpty(customRules)) return undefined;

      if (multiSelect)
        return {
          validate: (data) => {
            if (_.get(rules, "required") && data.length === 0) {
              return _.get(rules, "required");
            }
            return true;
          },
        };

      return customRules;
    }
  };

  const generateWhereClause = (value) => {
    const where = [];
    let whereClause = {};
    if (searchFieldNames) {
      if (searchFieldNames.length === 1) {
        where.push({
          FieldName: searchFieldNames[0],
          Value: value,
          DataType: "STRING",
          Operator: "LIKE",
        });
      } else {
        for (let i = 0; i < searchFieldNames.length; i++) {
          whereClause = {
            FieldName: searchFieldNames[i],
            Value: value,
            DataType: "STRING",
            Operator: "LIKE",
            ParentheseKey: "S",
          };
          if (searchFieldNames[i + 1])
            whereClause = _.merge(whereClause, {
              LogicalOperator: "OR",
            });
          where.push(whereClause);
        }
      }
    } else {
      where.push({
        FieldName: displayFieldName,
        Value: value,
        DataType: "STRING",
        Operator: "LIKE",
      });
    }

    return where;
  };

  const handleSearch = () => {
    if (inputValue) {
      const bound =
        where.length === 0
          ? stateObject.totalRecords
          : stateObject.totalFilteredRecords;
      if (
        inputValue.length >= 3 &&
        (!inputValue.startsWith(where[0]?.Value) || bound > 0)
      ) {
        if (
          stateObject.optionsTemp?.length < stateObject.totalRecords ||
          typeOnly
        ) {
          setWhere(generateWhereClause(inputValue));
          setStateObject((stateObject) => ({
            ...stateObject,
            optionsTemp:
              !typeOnly && stateObject.optionsTemp?.length === 0
                ? stateObject.options
                : stateObject.optionsTemp,
            pageIndex: 1,
            active: false,
          }));
        }
      }
    }
  };

  const handleInputChange = (e, value, reason) => {
    if (reason === "input") {
      if (value === "") {
        setWhere([]);
        setStateObject((stateObject) => ({
          ...stateObject,
          options:
            (!typeOnly && stateObject.optionsTemp?.length === 0
              ? stateObject.options
              : stateObject.optionsTemp) ??
            data ??
            [],
          optionsTemp: [],
          totalFilteredRecords: 0,
        }));
      } else setSearchValue(value);
    } else if (reason === "reset") {
      if (where.length > 0) {
        setWhere([]);
        setInputValue("");
        setStateObject((stateObject) => ({
          ...stateObject,
          options:
            stateObject.optionsTemp?.length === 0
              ? stateObject.options
              : stateObject.optionsTemp,
          totalFilteredRecords: 0,
          active: true,
        }));
      }
    }
    if (required || customRequired)
      if (value !== "") {
        if (showRequired) setShowRequired(false);
      } else if (!showRequired) setShowRequired(true);
    if (onInputChange) onInputChange(value);
    setInputValue(value);
  };

  const handleRenderOption = (option) => {
    if (open) {
      const index = stateObject.options.findIndex(
        (item) => item[valueFieldName] === option[valueFieldName]
      );

      if (index !== -1) {
        if (displayItem) {
          return displayItem(stateObject.options[index]) ?? "";
        } else {
          return _.get(option, displayFieldName) ?? "";
        }
      }
    }
  };

  const handleScroll = (e) => {
    if (stateObject.active) {
      const bound =
        where.length === 0
          ? stateObject.totalRecords
          : stateObject.totalFilteredRecords;
      if (stateObject.options.length !== bound) {
        const bottom =
          e.target.scrollHeight -
            Math.ceil(e.target.scrollTop) -
            e.target.clientHeight <=
          1;

        if (bottom) {
          if (
            stateObject.pageIndex <
            Math.ceil(
              stateObject.totalFilteredRecords > 0
                ? stateObject.totalFilteredRecords
                : stateObject.totalRecords / pageSize
            )
          ) {
            setStateObject((stateObject) => ({
              ...stateObject,
              active: false,
              pageIndex: stateObject.pageIndex + 1,
            }));
            setScrollTop(e.target.scrollTop);
          }
        }
      }
    }
  };

  const handleRefresh = () => {
    setStateObject((stateObject) => ({
      ...stateObject,
      active: false,
      pageIndex: 1,
      options: [],
      totalFilteredRecords: undefined,
      totalRecords: undefined,
    }));
    setWhere([]);
    setInputValue("");
  };

  const handleMouseEnter = () => {
    if (isNullOrWhiteSpace(data) && !isLoading && stateObject.active)
      setShowEndAdornment(true);
  };

  const handleMouseLeave = () => {
    if (showEndAdornment) setShowEndAdornment(false);
  };

  const handleSearchIconClick = () => {
    if (stateObject.active && stateObject.totalFilteredRecords > 0)
      handleRefresh();
  };

  const autocompleteProps = {
    autoSelect: freeSolo || autoSelect,
    className: clsx(className, "jarvisSelect"),
    freeSolo,
    id,
    inputValue,
    loading: isLoading,
    loadingText: (
      <span
        className={clsx(
          language.JarvisFontClass,
          `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
        )}
      >
        {_.get(PE, "Loading") ?? "Loading..."}
      </span>
    ),
    ListboxProps: {
      onScroll: handleScroll,
      className: clsx(
        "MuiAutocomplete-listbox",
        language.JarvisFontClass,
        `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
      ),
    },
    ChipProps: {
      className: clsx(
        language.JarvisFontClass,
        `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
      ),
    },
    noOptionsText:
      inputValue.length > 2 && where.length > 0 ? (
        <span
          className={clsx(
            language.JarvisFontClass,
            `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
          )}
        >
          {_.get(PE, "NoItems") ?? "No items found"}
        </span>
      ) : (
        ""
      ),
    open,
    openText: "",
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    popupIcon: getPopupIcon(),
    selectOnFocus: typeOnly,
    size,
    style: selectStyle,
    ...rest,
    renderInput: (params) => (
      <TextField
        {...params}
        autoFocus={autoFocus}
        className={clsx(
          "jarvisTextField",
          buttonLike && "buttonLike",
          roundedCorner
            ? roundedCorner.left
              ? "inputRoundedLeft"
              : roundedCorner.right
              ? "inputRoundedRight"
              : "inputRounded"
            : ""
        )}
        error={errors && errors[name] ? true : error}
        fullWidth
        helperText={renderHelperText()}
        label={
          (required || customRequired) && showRequired ? `${label} *` : label
        }
        InputLabelProps={{
          className: clsx(
            language.JarvisFontClass,
            !isNullOrWhiteSpace(fontSize) &&
              `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
          ),
        }}
        InputProps={{
          ...params.InputProps,
          className: clsx(
            params.InputProps.className,
            language.JarvisFontClass,
            !isNullOrWhiteSpace(fontSize) &&
              `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
          ),
          endAdornment: (
            <Fragment>
              {showEndAdornment && (
                <Fragment>
                  <RefreshIcon
                    className="jarvisIconHover"
                    onClick={handleRefresh}
                  />
                </Fragment>
              )}
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {onAddClick && !inputValue && (
                <AddIcon className="jarvisIconHover" onClick={onAddClick} />
              )}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
          startAdornment: showSearchIcon ? (
            <SearchIcon
              style={{
                cursor:
                  stateObject.active && stateObject.totalFilteredRecords > 0
                    ? "pointer"
                    : "default",
                opacity: 0.3,
              }}
              onClick={handleSearchIconClick}
            />
          ) : (
            params.InputProps.startAdornment
          ),
        }}
        margin={margin}
        onMouseEnter={
          buttonLike || showSearchIcon ? undefined : handleMouseEnter
        }
        onMouseLeave={
          buttonLike || showSearchIcon ? undefined : handleMouseLeave
        }
        placeholder={
          placeholder ??
          (typeOnly && !showSearchIcon
            ? _.get(PE, "TypeOnlyPlaceholder") ?? "Type to search"
            : undefined)
        }
        variant={variant}
      />
    ),
  };

  if (stateObject.totalFilteredRecords > 0)
    autocompleteProps.filterOptions = filterOptions;
  else if (displayItem) {
    autocompleteProps.filterOptions = displayItemFilterOptions;
    autocompleteProps.renderOption = (option) => handleRenderOption(option);
    autocompleteProps.autoHighlight = true;
    autocompleteProps.clearOnBlur = true;
  }

  if (multiSelect) {
    autocompleteProps.multiple = true;
    autocompleteProps.limitTags = limitTags;
    autocompleteProps.disableCloseOnSelect = true;
    autocompleteProps.renderOption = (option, { selected }) => (
      <React.Fragment>
        {!isLoading && (
          <Checkbox
            checked={selected}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            size="small"
          />
        )}
        {displayItem ? displayItem(option) : option[displayFieldName]}
      </React.Fragment>
    );
  }

  if (freeSolo) {
    autocompleteProps.options = stateObject.options.map((option) =>
      displayItem ? displayItem(option) : option[displayFieldName]
    );
    autocompleteProps.onInputChange = (event, Value, reason) => {
      handleInputChange(event, Value, reason);
      return Value;
    };
  } else {
    autocompleteProps.getOptionLabel = (option) => {
      if (open) {
        if (displayItem) return displayItem(option) ?? "";
        return option[displayFieldName]?.toString();
      } else {
        if (displayFieldName) return option[displayFieldName]?.toString();
        return displayItem(option) ?? "";
      }
    };

    autocompleteProps.getOptionSelected = (option, value) =>
      _.isEqual(option[valueFieldName], value[valueFieldName]);

    autocompleteProps.onInputChange = (event, Value, reason) =>
      handleInputChange(event, Value, reason);

    autocompleteProps.options = stateObject.options;
  }

  if (control)
    return (
      <Controller
        control={control}
        render={(props) => {
          return (
            <Autocomplete
              {...props}
              {...autocompleteProps}
              onChange={(_, _data) => {
                if (onChange) onChange(_data);
                if (
                  !data &&
                  !typeOnly &&
                  _data &&
                  stateObject.optionsTemp.length > 0 &&
                  !stateObject.optionsTemp.find(
                    (option) => option[valueFieldName] === _data[valueFieldName]
                  )
                )
                  stateObject.optionsTemp.splice(0, 0, _data);
                return props.onChange(_data);
              }}
            />
          );
        }}
        name={name}
        rules={getRules()}
        defaultValue={getDefaultValue()}
      />
    );

  return (
    <Autocomplete onChange={onChange} value={value} {...autocompleteProps} />
  );
}

Select.propTypes = {
  autoFocus: PropTypes.bool,
  autoSelect: PropTypes.bool,
  checkRules: PropTypes.bool,
  columnName: PropTypes.string,
  displayFieldName: PropTypes.string,
  displayItem: PropTypes.func,
  dependencies: PropTypes.array,
  error: PropTypes.bool,
  errors: PropTypes.object,
  fontSize: PropTypes.number,
  freeSolo: PropTypes.bool,
  hint: PropTypes.string,
  hintColor: PropTypes.string,
  label: PropTypes.string,
  limitTags: PropTypes.number,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  multiSelect: PropTypes.bool,
  name: PropTypes.string,
  pageSize: PropTypes.number,
  required: PropTypes.bool,
  searchFieldNames: PropTypes.array,
  service: PropTypes.func,
  serviceParams: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  totalRecordsInput: PropTypes.number,
  typeOnly: PropTypes.bool,
  valueFieldName: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  width: PropTypes.string,
};

Select.defaultProps = {
  autoSelect: false,
  checkRules: true,
  error: false,
  fontSize: 9,
  freeSolo: false,
  limitTags: 2,
  multiSelect: false,
  pageSize: 25,
  required: false,
  roundedCorner: false,
  size: "small",
  typeOnly: false,
};
