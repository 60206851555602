/*------------
Version: 1.1
------------*/
import React from "react";
import PropTypes from "prop-types";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import Typography from "../Typography";

export default function Tooltip({ children, fontSize, label, ...rest }) {
  return (
    <MuiTooltip
      title={<Typography label={label} fontSize={fontSize} />}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = { fontSize: PropTypes.number };
Tooltip.defaultProps = { fontSize: 7 };
