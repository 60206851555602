/*------------
Version: 1.4
------------*/
import React from "react";
import PropTypes from "prop-types";
import { Chip as MuiChip, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Typography from "../Typography";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "31%",
    marginTop: -12,
  },
}));

export default function Chip({
  clickable,
  color,
  disabled,
  fontSize,
  label,
  leftIcon,
  onClick,
  onRightIconClick,
  rightIcon,
  showLoading,
  value,
  variant,
  ...rest
}) {
  const classes = useStyles();

  const handleRightIconClick = (value) => {
    if (onRightIconClick) onRightIconClick(value);
    else if (onClick) onClick(value);
  };

  return (
    <div style={{ position: "relative" }}>
      <MuiChip
        label={
          <Typography
            style={clickable ? { cursor: "pointer" } : { cursor: "auto" }}
            fontSize={fontSize}
            component={"span"}
            label={label}
          />
        }
        color={color}
        icon={leftIcon}
        onDelete={
          onRightIconClick ? (e) => handleRightIconClick(value) : undefined
        }
        deleteIcon={rightIcon}
        clickable={clickable}
        disabled={showLoading || disabled}
        onClick={clickable ? (e) => onClick(value) : undefined}
        variant={variant}
        {...rest}
      ></MuiChip>
      {showLoading && (
        <CircularProgress className={classes.buttonProgress} size={24} />
      )}
    </div>
  );
}

Chip.propTypes = {
  color: PropTypes.string,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  showLoading: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "outlined"]),
};
Chip.defaultProps = {
  clickable: false,
  color: "primary",
  disabled: false,
  fontSize: 9,
  showLoading: false,
  variant: "outlined",
};
