/*------------
Version: 6.5.6
------------*/
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { HotKeys } from "react-keyboard";
import { isIOS, isMobile } from "react-device-detect";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import CircularProgress from "Jarvis/JarvisComponents/CircularProgress";
import { ConfirmationDialog } from "Jarvis/JarvisComponents/Confirmation";
import {
  isNullOrWhiteSpace,
  uuid,
} from "Jarvis/JarvisServices/JarvisCommonService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TablePagination from "./TablePagination";
import ColumnSelect from "./ColumnSelect";
import TableOperations from "./TableOperations";
import "./index.scss";
import Dialog from "../Dialog";

const _uuid = `table-${uuid()}`;

const defaultPagination = {
  pageIndex: 1,
  pageSize: 25,
  totalRecords: null,
  orderBys: null,
  where: null,
};

const defaultTableInfo = {
  columns: [],
  startIndex: 0,
  width: 1019,
};

const counterColumn = {
  path: "counterColumn",
  label: "",
  hasFiltering: false,
  cellAlign: "center",
  width: 50,
  fixed: true,
  sortable: false,
};

const selectColumn = {
  path: "selectColumn",
  cellAlign: "center",
  hasFiltering: false,
  width: 50,
  fixed: true,
  sortable: false,
};

export default function Table({
  addToTop,
  apiEndPoint,
  className,
  columns,
  columnFixedWidth,
  columnsStackSize,
  columnWidthTolerance,
  configs,
  customHeader,
  customMenuItems,
  customOperationItems,
  customSelectMenuItems,
  dataInfo,
  deleteExtraContent,
  deleteService,
  deleteTitle,
  displayMode,
  editOnDoubleClick,
  exportToExcelSecvice,
  fontSize,
  footer,
  hasAddButton,
  hasEditButton,
  hasCounterColumn,
  hasColumnSelect,
  hasDeleteButton,
  hasDeleteConfirm,
  hasHeader,
  hasOperationsButton,
  hasRowFilter,
  hasRowSelect,
  hasSelectMenu,
  hasSorting,
  height,
  verticalOperationAlignment,
  idFieldName,
  inputForm,
  isLoading,
  langData,
  moduleCodeString,
  multiSelect,
  onAddButtonClick,
  onEditButtonClick,
  onPaginationChange,
  onRowClick,
  onRowDoubleClick,
  onRowSelect,
  onShowInputForm,
  refreshInfo,
  rowCanBeSelectedIf,
  showRowFilter,
  showInputForm,
  showOperations,
  showPageCount,
  showSelectColumn,
  tableClass,
  tableDirection,
  theadClass,
  title,
  trStyle,
  wrapText,
}) {
  const { isDirectionRTL } = useSelector((state) => state.settings);
  let PE = _.get(useContext(GlobalContext), "PE.PubPublicElems");
  if (langData) PE = langData;

  const tableRef = useRef(null);
  const [cellContent, setCellContent] = useState({
    text: "",
    showDialog: false,
  });
  const [currentRow, setCurrentRow] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [hasSelectColumn, setHasSelectColumn] = useState(
    displayMode === "select" || showSelectColumn
  );
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [showFilter, setShowFilter] = useState(showRowFilter);
  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState(false);
  const [showOperationsColumn, setShowOperationsColumn] = useState(
    showOperations
  );
  const [showColumnSelect, setShowColumnSelect] = useState(false);
  const [tableDataInfo, setTableDataInfo] = useState({});
  const [tableHeight, setTableHeight] = useState(height);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);

  const keyMap = {
    add: "ins",
    refresh: "shift+r",
    exportToExcel: "shift+x",
  };
  const handlers = {
    add: () => handleAddRowClick(true),
    refresh: () => handleRefresh(),
    exportToExcel: () => handleExportToExcel(),
  };
  if (customMenuItems) {
    for (let i = 0; i < customMenuItems.length; i++) {
      if (customMenuItems[i].shortcut) {
        keyMap[customMenuItems[i].name] = customMenuItems[i].shortcut;
        handlers[customMenuItems[i].name] = (e) => {
          e.preventDefault();
          customMenuItems[i].onClick();
        };
      }
    }
  }
  const hasPagination = onPaginationChange === undefined ? false : true;

  let direction = isDirectionRTL ? "rtl" : "ltr";
  if (tableDirection !== undefined) direction = tableDirection;

  let tableClassName =
    tableClass === undefined
      ? "table table-striped table-bordered table-sm"
      : `table ${tableClass}`;

  tableClassName = clsx(className, tableClassName, "fixed-Header");

  const hasTopPanelMenu = displayMode === "full" ? true : false;

  const operationsColumn = {
    path: "operationsColumn",
    content: (row) => (
      <TableOperations
        currentRow={row}
        customOperationItems={customOperationItems}
        deleteTooltip={_.get(PE, "Delete") || "Delete"}
        editTooltip={_.get(PE, "Edit") || "Edit"}
        hasEditButton={hasEditButton}
        hasDeleteButton={hasDeleteButton}
        verticalOperationAlignment={verticalOperationAlignment}
        isDirectionRTL={isDirectionRTL}
        onDeleteClick={() => handleShowDeleteConfirmationDialog(row)}
        onEditClick={() => handleEditRowClick(row)}
      />
    ),
    cellAlign: "center",
    hasFiltering: false,
    width: 60,
    fixed: true,
    sortable: false,
  };

  const getColumns = useCallback(() => {
    let containerWidth = tableRef.current ? tableRef.current.offsetWidth : 1019;

    let resultColumns = [];
    let startIndex = 0;
    if (hasCounterColumn) {
      resultColumns.push(counterColumn);
      ++startIndex;
    }

    if (hasSelectColumn) {
      resultColumns.push(selectColumn);
      ++startIndex;
    }

    if (showOperationsColumn) {
      resultColumns.push(operationsColumn);
      ++startIndex;
    }

    let occupiedWidth = 0;
    resultColumns.forEach((column) => {
      occupiedWidth += column.width;
    });

    let scale = 0;
    let len = 0;

    const columnsOrder = _.get(configs, `${moduleCodeString}.Table.Columns`);
    if (!isNullOrWhiteSpace(columnsOrder)) {
      let column = {};
      columnsOrder.split(",").forEach((columnName) => {
        column = columns.find((col) => col.path === columnName);
        if (column) {
          ++len;
          resultColumns.push(column);
          scale += column.widthScale ?? 1;
        }
      });
    } else {
      columns.forEach((column) => {
        if (column.visible !== false) {
          ++len;
          resultColumns.push(column);
          scale += column.widthScale ?? 1;
        }
      });
    }

    const remainedWidth = containerWidth - occupiedWidth - 19;
    let columnWidth =
      isMobile || isIOS ? columnFixedWidth ?? 150 : remainedWidth / scale;
    let totalWidth = occupiedWidth;
    if (len > columnsStackSize ?? 5)
      columnWidth = (remainedWidth + (len - 5) * columnWidth) / len;

    resultColumns.forEach((column) => {
      if (!column.fixed) {
        column.width =
          columnWidth * (isMobile || isIOS ? 1 : column.widthScale ?? 1);
        totalWidth += column.width;
      }
    });

    const header = [];
    if (!isNullOrWhiteSpace(title))
      header.push([
        {
          label: title,
          key: `${moduleCodeString || idFieldName}-title`,
          width: totalWidth,
          className: "bg-white",
        },
      ]);
    if (customHeader) {
      let count = 0,
        n = customHeader.length;

      for (let i = 0, sum = 0, k = startIndex; i < n; i++) {
        count = 0;
        sum = 0;
        for (let j = k; j < resultColumns.length; j++) {
          sum += resultColumns[j].width;
          ++k;
          if (i < n - 1 && ++count === (customHeader[i].mergeCells ?? 1)) break;
        }
        customHeader[i].width = sum;
      }
      for (let i = 0; i < startIndex; i++) {
        customHeader[0].width += resultColumns[i].width;
      }
      header.push(customHeader);
    }

    setTableInfo({
      header: header,
      columns: resultColumns,
      startIndex: startIndex,
      width: totalWidth + 19,
    });
  }, [columns, customHeader, hasSelectColumn, showOperationsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getColumns();
  }, [columns, hasSelectColumn, showOperationsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTableDataInfo((prev) => ({
      ...prev,
      data: _.get(dataInfo, "data") ?? [],
      totalRecords: _.get(dataInfo, "totalRecords") ?? 0,
    }));
  }, [dataInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refreshInfo) {
      const {
        isEditMode,
        newRecord,
        deleteSelectedRows,
        deleteCurrentRow,
      } = refreshInfo;
      const isArray = _.isArray(newRecord);
      if (!_.isUndefined(isEditMode)) {
        if (!isEditMode) {
          if (isArray)
            setTableDataInfo((prev) => ({
              ...prev,
              data: [...newRecord, ...tableDataInfo.data],
              totalRecords: tableDataInfo.totalRecords + newRecord.length,
            }));
          else {
            if (addToTop)
              setTableDataInfo((prev) => ({
                ...prev,
                data: [newRecord, ...tableDataInfo.data],
                totalRecords: tableDataInfo.totalRecords + 1,
              }));
            else
              setTableDataInfo((prev) => ({
                ...prev,
                data: [...tableDataInfo.data, newRecord],
                totalRecords: tableDataInfo.totalRecords + 1,
              }));
          }
          setCurrentRow(newRecord);
        } else {
          const tableDataTemp = [...tableDataInfo.data];
          let index = -1;
          if (isArray) {
            for (let i = 0; i < newRecord.length; i++) {
              index = tableDataTemp.findIndex(
                (row) => row[idFieldName] === newRecord[i][idFieldName]
              );
              if (index !== -1) tableDataTemp.splice(index, 1, newRecord[i]);
              else tableDataTemp.push(newRecord[i]);
            }
          } else {
            index = tableDataTemp.findIndex(
              (row) => row[idFieldName] === newRecord[idFieldName]
            );
            tableDataTemp.splice(index, 1, newRecord);
          }
          setTableDataInfo((prev) => ({ ...prev, data: tableDataTemp }));
          setCurrentRow(tableDataTemp[index]);
        }
      } else if (deleteSelectedRows) {
        let tableDataTemp = [...tableDataInfo.data];
        let index = -1;
        if (
          !tableDataInfo.selectedRows ||
          tableDataInfo.selectedRows.length === 0
        )
          if (currentRow) tableDataInfo.selectedRows = [currentRow];
        tableDataInfo.selectedRows.forEach((selectedRow) => {
          index = tableDataTemp.indexOf(selectedRow);
          tableDataTemp.splice(index, 1);
        });
        setTableDataInfo({
          data: tableDataTemp,
          totalRecords:
            tableDataInfo.totalRecords - tableDataInfo.selectedRows.length,
          selectedRows: [],
        });
      } else if (deleteCurrentRow) {
        let tableDataTemp = [...tableDataInfo.data];
        let index = tableDataTemp.findIndex(
          (data) => data[idFieldName] === currentRow[idFieldName]
        );
        tableDataTemp.splice(index, 1);
        setTableDataInfo((prev) => ({
          ...prev,
          data: tableDataTemp,
          totalRecords: tableDataInfo.totalRecords - 1,
        }));
      }
    }
  }, [refreshInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tableRef.current) {
      const table = tableRef.current;
      const tbody = document.getElementById(_uuid).children[hasHeader ? 1 : 0];
      const pagination = document.getElementById(`pagination-${_uuid}`);
      const paginationHeight = pagination ? pagination.offsetHeight : 0;
      let height =
        table.offsetParent.offsetHeight -
        table.offsetTop -
        tbody.offsetTop -
        paginationHeight -
        60;
      if (columns.length > columnsStackSize ?? 5) height -= 19;

      setTableHeight(height);
    }
  }, [tableRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  if (_.isEmpty(columns)) return null;

  const renderDeleteConfirmation = () => {
    let extraContent = "";
    if (
      !hasSelectColumn ||
      !tableDataInfo.selectedRows ||
      !tableDataInfo.selectedRows.find(
        (row) => row[idFieldName] === currentRow[idFieldName]
      )
    ) {
      if (_.isFunction(deleteExtraContent)) {
        extraContent = deleteExtraContent(currentRow);
      } else {
        if (deleteExtraContent?.includes(",")) {
          deleteExtraContent.split(",").forEach((str) => {
            if (!isNullOrWhiteSpace(str))
              extraContent += `${_.get(deleteData, str) ?? str} `;
          });
        } else
          extraContent =
            _.get(deleteData, deleteExtraContent) ?? deleteExtraContent;
      }
    }

    return (
      <ConfirmationDialog
        open={showDeleteConfirmationDialog}
        title={deleteTitle ?? _.get(PE, "Delete") ?? "Delete"}
        content={
          _.get(PE, "DeleteConfirmation") ?? "Are you sure you want to delete?"
        }
        isLoading={isLoadingDelete}
        extraContent={extraContent}
        acceptText={_.get(PE, "Yes")}
        closeText={_.get(PE, "No")}
        onAcceptBtnClick={handleDelete}
        onCloseBtnClick={() => setShowDeleteConfirmationDialog(false)}
        onClose={() => setShowDeleteConfirmationDialog(false)}
        showDeleteIcon
      />
    );
  };

  const renderCellContentDialog = () => {
    return (
      <Dialog
        width="30%"
        open={cellContent.showDialog}
        contentText={cellContent.text}
        showDialogTitle={false}
        showDialogAction={false}
        maxWidth={false}
        onClose={() =>
          setCellContent((cellContent) => ({
            ...cellContent,
            showDialog: false,
          }))
        }
      ></Dialog>
    );
  };

  const handleRefresh = () => {
    setTableDataInfo((prev) => ({ ...prev, data: [], totalRecords: 0 }));
    setCurrentRow(null);
    const newPagination = { ...pagination, pageIndex: 1, totalRecords: null };
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };

  const handlePageChange = (pageIndex) => {
    const newPagination = {
      ...pagination,
      pageIndex,
      totalRecords: tableDataInfo.totalRecords,
    };
    setTableDataInfo((prev) => ({ ...prev, data: [], totalRecords: 0 }));
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };

  const handlePageSizeChange = (pageSize) => {
    const newPagination = {
      ...pagination,
      pageIndex: 1,
      pageSize,
      totalRecords: tableDataInfo.totalRecords,
    };
    setTableDataInfo((prev) => ({ ...prev, data: [], totalRecords: 0 }));
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };

  const handleSortChange = (sortedColumn, index) => {
    let sortedColumnsTemp = pagination.orderBys ? [...pagination.orderBys] : [];
    if (index === -1) sortedColumnsTemp.push(sortedColumn);
    else {
      if (sortedColumn.orderMethod) {
        sortedColumnsTemp[index].orderMethod = sortedColumn.orderMethod;
      } else {
        sortedColumnsTemp.splice(index, 1);
      }
    }
    const newPagination = {
      ...pagination,
      orderBys: sortedColumnsTemp.length > 0 ? sortedColumnsTemp : null,
    };
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };

  const handleRowFiltering = (column, whereObj) => {
    let whereClauseTemp = pagination.where ? [...pagination.where] : [];
    const index = whereClauseTemp.findIndex(
      (item) => item.FieldName === (column.searchFieldName ?? column.path)
    );
    if (index !== -1) whereClauseTemp.splice(index, 1);
    if (column.type === "date") {
      if (whereObj.FieldName === undefined) {
        whereClauseTemp.push(...whereObj);
      }
    } else {
      if (whereObj.Value !== undefined) whereClauseTemp.push(whereObj);
    }
    const newPagination = {
      ...pagination,
      pageIndex: 1,
      where: whereClauseTemp.length > 0 ? whereClauseTemp : null,
    };
    setTableDataInfo((prev) => ({ ...prev, data: [], totalRecords: 0 }));
    onPaginationChange(newPagination);
    setPagination(newPagination);
  };

  const handleRowSelectClick = (rowSelect) => {
    if (!rowSelect && tableDataInfo?.selectedRows.length > 0)
      setTableDataInfo((tableDataInfo) => ({
        ...tableDataInfo,
        selectedRows: [],
      }));
    setHasSelectColumn(rowSelect);
  };

  const handleRowSelectionClick = (row, selectAll) => {
    const selectedRows = tableDataInfo.selectedRows || [];
    let newSelectedRows = [];
    if (row) {
      const index = selectedRows.indexOf(row);
      if (multiSelect) {
        if (index === -1) {
          newSelectedRows = [...selectedRows, row];
        } else {
          let selectedRow = selectedRows[index];
          newSelectedRows = selectedRows.filter((row) => row !== selectedRow);
        }
      } else {
        newSelectedRows = [...selectedRows];
        if (newSelectedRows.length > 0) newSelectedRows.pop();
        if (index === -1) newSelectedRows.push(row);
      }
    } else if (selectAll) {
      let notSelectedRows = [];
      tableDataInfo.data.forEach((row) => {
        if (selectedRows.indexOf(row) === -1) notSelectedRows.push(row);
      });
      newSelectedRows = [...selectedRows, ...notSelectedRows];
    }

    setTableDataInfo((prev) => ({
      ...prev,
      selectedRows: newSelectedRows,
    }));
    if (!hasSelectColumn) setHasSelectColumn(true);
    if (onRowSelect) onRowSelect(newSelectedRows);
  };

  const handleRowFilterClick = (showRowFilter) => {
    if (showFilter && pagination.where?.length > 0) {
      const newPagination = {
        ...pagination,
        pageIndex: 1,
        where: null,
      };
      setPagination(newPagination);
      onPaginationChange(newPagination);
    }
    setShowFilter(showRowFilter);
  };

  const handleAddRowClick = (showAddRow) => {
    if (onAddButtonClick) onAddButtonClick();
    else if (hasAddButton) onShowInputForm(showAddRow, null);
  };

  const handleEditRowClick = (row) => {
    if (onEditButtonClick) onEditButtonClick(row);
    else if (hasOperationsButton && hasEditButton)
      onShowInputForm(true, { ...row });
  };

  const handleShowDeleteConfirmationDialog = (row) => {
    if (hasDeleteConfirm && deleteService) {
      setDeleteData(row);
      setShowDeleteConfirmationDialog(true);
    } else handleDelete(row);
  };

  const handleDelete = async (deleteRow) => {
    if (deleteService) {
      setIsLoadingDelete(true);
      let _data = null;
      if (
        hasSelectColumn &&
        tableDataInfo.selectedRows?.length > 0 &&
        tableDataInfo.selectedRows.find(
          (row) => row[idFieldName] === currentRow[idFieldName]
        )
      )
        _data = tableDataInfo.selectedRows;
      else _data = deleteData || deleteRow;
      const { ErrorHandling } = await deleteService(_data);
      setIsLoadingDelete(false);
      if (_.get(ErrorHandling, "IsSuccessful")) {
        const tableDataTemp = [...tableDataInfo.data];
        const index = tableDataTemp.indexOf(_data);
        tableDataTemp.splice(index, 1);
        if (hasDeleteConfirm) setShowDeleteConfirmationDialog(false);
        setTableDataInfo((prev) => ({
          ...prev,
          data: tableDataTemp,
          totalRecords: tableDataInfo.totalRecords - 1,
        }));
        if (tableDataTemp.length > 0) setCurrentRow(tableDataTemp[index]);
        else setCurrentRow(null);
      }
    }
  };

  const handleExportToExcel = async () => {
    if (exportToExcelSecvice) {
      const { ErrorHandling, Data } = await exportToExcelSecvice();
      if (_.get(ErrorHandling, "IsSuccessful")) {
        window.location.href = `${
          apiEndPoint ?? JarvisBaseSettingsService.getApiEndPoint("Jarvis")
        }JWebCore/PublicClientApi/DownloadFile?id=${_.get(
          Data,
          "Guid"
        )}&FileContentType=XLSX`;
      }
    }
  };

  let topPanelProps = {};

  if (displayMode === "full") {
    topPanelProps = {
      apiEndPoint,
      currentRow,
      customMenuItems,
      hasExportToExcel: exportToExcelSecvice ? true : false,
      hasAddButton,
      hasColumnSelect,
      hasOperations: hasOperationsButton,
      hasRowFilter,
      hasRowSelect,
      onAddRowClick: handleAddRowClick,
      onExportToExcelClick: handleExportToExcel,
      onOperationsClick: setShowOperationsColumn,
      onRefreshClick: handleRefresh,
      onRowFilterClick: handleRowFilterClick,
      onRowSelectClick: handleRowSelectClick,
      onColumnSelectClick: setShowColumnSelect,
      PE,
      rowFilter: showFilter,
      rowSelect: hasSelectColumn,
      selectedRows: _.get(tableDataInfo, "selectedRows"),
      showOperationsColumn,
    };
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div
        style={{ outline: "none" }}
        tabIndex="0"
        dir={direction}
        ref={tableRef}
      >
        {showInputForm && inputForm}
        {showDeleteConfirmationDialog && renderDeleteConfirmation()}
        {cellContent.showDialog && renderCellContentDialog()}
        {showColumnSelect && (
          <ColumnSelect
            columns={columns}
            configs={configs}
            getColumns={getColumns}
            moduleCodeString={moduleCodeString}
            onClose={() => setShowColumnSelect(false)}
            PE={PE}
            showColumnSelect={showColumnSelect}
          />
        )}

        <div style={{ position: "relative" }}>
          <div className="table-responsive">
            <table id={_uuid} className={tableClassName}>
              {hasHeader && (
                <TableHeader
                  columns={_.get(tableInfo, "columns")}
                  customHeader={_.get(tableInfo, "header")}
                  customSelectMenuItems={customSelectMenuItems}
                  direction={direction}
                  displayMode={displayMode}
                  fontSize={fontSize}
                  hasFiltering={showFilter}
                  hasSelectColumn={hasSelectColumn}
                  hasSelectMenu={hasSelectMenu}
                  hasSorting={hasSorting}
                  hasTopPanelMenu={hasTopPanelMenu}
                  multiSelect={multiSelect}
                  onRowFiltering={handleRowFiltering}
                  onRowSelectionClick={handleRowSelectionClick}
                  onSortChange={handleSortChange}
                  PE={PE}
                  selectedRows={tableDataInfo.selectedRows || []}
                  showOperationsColumn={showOperationsColumn}
                  sortedColumns={pagination.orderBys ? pagination.orderBys : []}
                  theadClass={theadClass}
                  topPanelProps={topPanelProps}
                  whereClause={pagination.where || []}
                />
              )}
              <TableBody
                columns={_.get(tableInfo, "columns")}
                columnWidthTolerance={columnWidthTolerance}
                currentRow={currentRow}
                data={tableDataInfo.data}
                direction={direction}
                editOnDoubleClick={
                  displayMode !== "view" ? editOnDoubleClick : false
                }
                fontSize={fontSize}
                footer={footer}
                hasCounterColumn={hasCounterColumn}
                hasPagination={hasPagination}
                hasRowSelect={hasRowSelect}
                hasSelectColumn={hasSelectColumn}
                height={tableHeight}
                idFieldName={idFieldName}
                isDirectionRTL={isDirectionRTL}
                isLoading={isLoading}
                isLoadingText={_.get(PE, "Loading") || "Loading..."}
                noItemsText={_.get(PE, "NoItems") || "No items found"}
                onAddRowClick={handleAddRowClick}
                onDelete={handleShowDeleteConfirmationDialog}
                onEditRowClick={handleEditRowClick}
                onRowClick={onRowClick}
                onRowDoubleClick={onRowDoubleClick}
                onRowSelectionClick={handleRowSelectionClick}
                pageIndex={pagination.pageIndex}
                pageSize={pagination.pageSize}
                rowCanBeSelectedIf={rowCanBeSelectedIf}
                selectedRows={tableDataInfo.selectedRows || []}
                setCellContent={setCellContent}
                setCurrentRow={setCurrentRow}
                showFilter={showFilter}
                trStyle={trStyle}
                width={tableInfo.width}
                wrapText={wrapText}
              />
            </table>
          </div>
          {isLoading && (
            <div
              style={{ top: tableRef.current?.offsetTop ?? 0 + 100 }}
              className="progress-bar"
            >
              <CircularProgress />
            </div>
          )}
          {hasPagination &&
            tableDataInfo.totalRecords > pagination.pageSize && (
              <TablePagination
                id={`pagination-${_uuid}`}
                direction={direction}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageIndex={pagination.pageIndex}
                pageSize={pagination.pageSize}
                pageSizeText={_.get(PE, "Display")}
                showPageCount={showPageCount}
                totalRecords={_.get(tableDataInfo, "totalRecords")}
                totalRecordsText={_.get(PE, "TotalRecords")}
              />
            )}
        </div>
      </div>
    </HotKeys>
  );
}

Table.propTypes = {
  addToTop: PropTypes.bool,
  apiEndPoint: PropTypes.string,
  columns: PropTypes.array.isRequired,
  columnsOrder: PropTypes.string,
  columnsStackSize: PropTypes.number,
  columnWidthTolerance: PropTypes.number,
  configs: PropTypes.object,
  customHeader: PropTypes.array, // [[{label: "", key: "", mergeCells: 2}]]
  customMenuItems: PropTypes.array, // [{name: "", icon: </>, toolTip: "", onClick: ()=>{}, displayIf: ()=>{}, shortcut: ""}]
  customOperationItems: PropTypes.array, // [{name: "", icon: </>, toolTip: "", onClick: ()=>{}}]
  customSelectMenuItems: PropTypes.array, // [{name: "", icon: </> label: "", onClick: ()=>{}}]
  dataInfo: PropTypes.object, // {data: [{}], totalRecords: 10}
  deleteService: PropTypes.func,
  deleteTitle: PropTypes.string,
  displayMode: PropTypes.oneOf(["full", "select", "view"]),
  editOnDoubleClick: PropTypes.bool,
  fontSize: PropTypes.number,
  footer: PropTypes.array, // [{path: "", value: ""}]
  hasColumnSelect: PropTypes.bool,
  hasCounterColumn: PropTypes.bool,
  hasEditButton: PropTypes.bool,
  hasDeleteButton: PropTypes.bool,
  hasDeleteConfirm: PropTypes.bool,
  hasHeader: PropTypes.bool,
  hasOperationsButton: PropTypes.bool,
  hasRowFilter: PropTypes.bool,
  hasRowSelect: PropTypes.bool,
  hasSelectMenu: PropTypes.bool,
  hasSorting: PropTypes.bool,
  verticalOperationAlignment: PropTypes.bool,
  idFieldName: PropTypes.string,
  isLoading: PropTypes.bool,
  moduleCodeString: PropTypes.string,
  multiSelect: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  onShowInputForm: PropTypes.func,
  refreshInfo: PropTypes.object, // {isEditMode: ture, newRecord: {} }
  rowCanBeSelectedIf: PropTypes.func,
  showFilterRow: PropTypes.bool,
  showInputForm: PropTypes.bool,
  showOperations: PropTypes.bool,
  showPageCount: PropTypes.bool,
  showSelectColumn: PropTypes.bool,
  tableDirection: PropTypes.oneOf(["ltr", "rtl"]),
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  trStyle: PropTypes.object,
  wrapText: PropTypes.bool,
};

Table.defaultProps = {
  addToTop: true,
  columnsStackSize: 5,
  columnWidthTolerance: 1,
  displayMode: "full",
  editOnDoubleClick: true,
  fontSize: 8,
  hasAddButton: true,
  hasColumnSelect: false,
  hasCounterColumn: true,
  hasDeleteButton: true,
  hasEditButton: true,
  hasDeleteConfirm: true,
  hasOperationsButton: true,
  hasHeader: true,
  hasRowFilter: true,
  hasRowSelect: false,
  hasSelectMenu: true,
  hasSorting: true,
  height: 250,
  verticalOperationAlignment: false,
  isSubmitSuccessful: false,
  multiSelect: true,
  showRowFilter: true,
  showInputForm: false,
  showOperations: false,
  showPageCount: true,
  showSelectColumn: false,
  wrapText: true,
};
