/*------------
Version: 3.5.1
------------*/
import React, { useContext, useState, useEffect, useRef } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import { isNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import Typography from "../Typography";

export default function TextField({
  allowNegative,
  autoComplete,
  autoFocus,
  checkRules,
  className,
  control,
  decimalScale,
  error,
  errors,
  fontSize,
  hint,
  hintColor,
  icon,
  isUpperCase,
  label,
  mask,
  min,
  max,
  maxLength,
  multiline,
  name,
  onBlur,
  onChange,
  placeholder,
  readOnly,
  required,
  rows,
  rowsMax,
  rules,
  showIcon,
  size,
  step,
  textAlign,
  textColor,
  type,
  variant,
  value,
  ...rest
}) {
  const { isDirectionRTL, language } =
    useSelector((state) => state.settings) || {};
  const { PE } = useContext(GlobalContext) || {};

  let prevValue = usePreviousValue(value);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [shrink, setShrink] = useState(
    !isNullOrWhiteSpace(value) ? true : false
  );
  const [showRequired, setShowRequired] = useState(
    (required && isNullOrWhiteSpace(value)) || _.isArray(rules)
  );

  let ruleIndex = -1;
  if (_.isArray(rules)) {
    ruleIndex = rules.findIndex((rule) => _.get(rule, "ColumnName") === name);
    if (ruleIndex !== -1 && rules[ruleIndex].Display === false) return null;
  }

  let inputFieldStyle = rest.style ?? {};
  if (mask) inputFieldStyle.direction = "ltr";

  let customRequired =
    ruleIndex !== -1
      ? rules[ruleIndex].IsRequired &&
        isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
      : false;

  const getRules = () => {
    if (checkRules) {
      let customRules = {};
      if (ruleIndex !== -1) {
        if (
          rules[ruleIndex].IsRequired &&
          isNullOrWhiteSpace(rules[ruleIndex].RuleCode)
        ) {
          customRules.required =
            _.get(PE, "PubPublicElems.Required") || "required";
          customRules.validate = (data) => {
            if (_.isString(data))
              return !!data.trim() || _.get(customRules, "required");

            return !_.isNil(data);
          };
        }
        if (!isNullOrWhiteSpace(rules[ruleIndex].MinLength))
          customRules.minLength = {
            value: rules[ruleIndex].MinLength,
            message: `${
              _.get(PE, "PubPublicElems.MinLength") || "minimum length"
            }: ${rules[ruleIndex].MinLength}`,
          };
        if (!isNullOrWhiteSpace(rules[ruleIndex].MaxLength))
          customRules.maxLength = {
            value: rules[ruleIndex].MaxLength,
            message: `${
              _.get(PE, "PubPublicElems.MaxLength") || "maximum length"
            }: ${rules[ruleIndex].MaxLength}`,
          };
        if (!isNullOrWhiteSpace(rules[ruleIndex].Min))
          customRules.minLength = {
            value: rules[ruleIndex].Min,
            message: `${
              _.get(PE, "PubPublicElems.MinLength") || "minimum length"
            }: ${rules[ruleIndex].min}`,
          };
        if (!isNullOrWhiteSpace(rules[ruleIndex].Max))
          customRules.maxLength = {
            value: rules[ruleIndex].Max,
            message: `${
              _.get(PE, "PubPublicElems.MaxLength") || "maximum length"
            }: ${rules[ruleIndex].Max}`,
          };
        if (!isNullOrWhiteSpace(rules[ruleIndex].RegEx))
          customRules.pattern = {
            value: new RegExp(rules[ruleIndex].RegEx),
            message:
              _.get(PE, "PubPublicElems.InvalidRegEx") || "invalid format",
          };
      } else {
        customRules = rules || {};
        if (customRequired)
          customRules.validate = (data) => {
            if (_.isString(data))
              return !!data.trim() || _.get(rules, "required");
            return !_.isNil(data);
          };
      }

      if (type === "email") {
        customRules = {
          ...customRules,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message:
              _.get(PE, "PubPublicElems.Email_IsInvalid") ||
              "Email format is not correct",
          },
        };
      } else if (type === "code") {
        customRules = {
          ...customRules,
          pattern: {
            value: /^[A-Za-z0-9._-]+$/g,
            message:
              _.get(PE, "PubPublicElems.InvalidRegEx") ||
              "format is not correct",
          },
        };
      }

      if (_.isEmpty(customRules)) return undefined;

      return customRules;
    }
  };

  const getType = () => {
    switch (type.toLowerCase()) {
      case "numberSpin":
        return "number";
      case "tel":
      case "mobile":
        return "tel";
      case "password":
        return showPassword ? "text" : "password";
      case "file":
        return "file";
      case "time":
        return "time";
      default:
        return "text";
    }
  };

  const toggleShrink = (e, shrink) => {
    if (isNullOrWhiteSpace(e.target.value)) setShrink(shrink);
  };

  const renderAdornment = (icon) => {
    if (isDirectionRTL && (mask || textAlign === "left"))
      return {
        startAdornment: showIcon && (
          <InputAdornment className="px-1" style={{ position: "end" }}>
            {icon}
          </InputAdornment>
        ),
      };
    else
      return {
        endAdornment: showIcon && (
          <InputAdornment className="px-1" style={{ position: "end" }}>
            {icon}
          </InputAdornment>
        ),
      };
  };

  const renderPasswordAdornment = () => {
    return {
      endAdornment: showIcon && (
        <InputAdornment position="end">
          <div className={!showPasswordIcon ? "d-none" : ""}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              tabIndex="-1"
            >
              {showPassword ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility fontSize="small" />
              )}
            </IconButton>
          </div>
        </InputAdornment>
      ),
    };
  };

  const getFontClass = () => {
    if (type !== "text" && type !== "persianLetter")
      return `Jarvis-Font-default Jarvis-Font-Size-${fontSize}rem`;

    return `${language.JarvisFontClass} Jarvis-Font-Size-${
      isDirectionRTL ? fontSize - 1 : fontSize
    }rem`;
  };

  const getInputLabelProps = () => {
    let result = {
      className: clsx(
        language.JarvisFontClass,
        `Jarvis-Font-Size-${isDirectionRTL ? fontSize - 1 : fontSize}rem`
      ),
    };
    if (isDirectionRTL && textAlign === "left") {
      result.shrink = shrink;
    }

    return result;
  };

  const getinputProps = () => {
    let result = rest.inputProps ?? {};

    switch (type) {
      case "text":
        if (mask) result.mask = mask;
        else {
          if (maxLength !== undefined) result.maxLength = maxLength;
        }
        break;
      case "number":
        if (max !== undefined) result.max = max;
        if (maxLength !== undefined) result.maxLength = maxLength;
        if (decimalScale !== undefined) result.decimalScale = decimalScale;
        if (allowNegative !== undefined) result.allowNegative = allowNegative;
        result.thousandSeparator = false;
        break;
      case "numberSpin":
        if (min !== undefined) result.min = min;
        if (max !== undefined) result.max = max;
        if (step !== undefined) result.step = step;
        if (maxLength !== undefined) result.maxLength = maxLength;
        break;
      case "numberFormat":
        if (max !== undefined) result.max = max;
        if (decimalScale !== undefined) result.decimalScale = decimalScale;
        if (allowNegative !== undefined) result.allowNegative = allowNegative;
        if (maxLength !== undefined) result.maxLength = maxLength;
        result.thousandSeparator = true;
        break;
      case "tel":
      case "mobile":
        result.decimalScale = 0;
        result.allowNegative = false;
        result.thousandSeparator = false;
        break;
      default:
        break;
    }
    if (readOnly) result.readOnly = true;

    if (autoComplete !== undefined && !autoComplete)
      result.autoComplete = "off";

    if (textAlign !== undefined) result.style = { textAlign: textAlign };

    if (textColor) result.style = _.merge(result.style, { color: textColor });

    return result;
  };

  const getInputProps = () => {
    let result = rest.InputProps ?? {};
    switch (type) {
      case "text":
        if (mask) result.inputComponent = TextMaskCustom;
        break;
      case "number":
      case "numberFormat":
        result.inputComponent = NumberFormatCustom;
        break;
      case "tel":
      case "mobile":
        result.inputComponent = NumberFormatCustom;
        break;
      case "password":
        result = { ...renderPasswordAdornment() };
        break;
      default:
        break;
    }
    let defaultIcon = icon;
    if (!icon)
      switch (type) {
        case "tel":
          defaultIcon = <PhoneIcon color="disabled" />;
          break;
        case "mobile":
          defaultIcon = <PhoneAndroidIcon color="disabled" />;
          break;
        case "email":
          defaultIcon = <MailOutlineIcon color="disabled" />;
          break;
        default:
          break;
      }

    if (defaultIcon) result = { ...result, ...renderAdornment(defaultIcon) };

    result.className = getFontClass();

    return result;
  };

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return (
      <Typography
        fontSize={7}
        component={"span"}
        label={helperText}
        color={hintColor}
      />
    );
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleBlur = (e, onBlurConroller) => {
    if (type === "password") setShowPasswordIcon(false);
    else if (isDirectionRTL && textAlign === "left") toggleShrink(e, false);
    if (onBlurConroller) onBlurConroller(e);
    if (onBlur) onBlur(e);
  };

  const test = (value) => {
    if (value === "") return true;
    let regex;
    switch (type) {
      case "numberString":
        regex = /^[0-9\b]+$/;
        if (regex.test(value)) {
          return true;
        } else return false;
      case "persianLetter":
        regex = /^[\u0600-\u06FF\s]+$/;
        if (regex.test(value)) {
          return true;
        } else return false;
      default:
        return true;
    }
  };

  const handleChange = (e, onChangeProp) => {
    prevValue = value;
    if (test(e.target.value)) onChangeProp(e);
    if (onChange) onChange(e);
    if (required || customRequired)
      if (e.target.value.trim() !== "") {
        if (showRequired) setShowRequired(false);
      } else if (!showRequired) setShowRequired(true);
  };

  const getValue = (props) => {
    const result =
      prevValue !== undefined && prevValue !== value
        ? value ?? ""
        : props.value;

    if (isUpperCase) return result.toUpperCase();

    return result;
  };

  const textFieldProps = {
    autoFocus,
    className: clsx(className, "jarvisTextField"),
    error: errors && errors[name] ? true : error,
    label:
      (required || customRequired) && showRequired
        ? textAlign === "left"
          ? `* ${label ?? ""}`
          : `${label ?? ""} *`
        : label,
    helperText: renderHelperText(),
    InputLabelProps: getInputLabelProps(),
    inputProps: getinputProps(),
    InputProps: getInputProps(),
    multiline,
    placeholder,
    rows,
    rowsMax,
    size,
    style: inputFieldStyle,
    type: getType(),
    variant,
    ...rest,
  };
  if (isDirectionRTL && textAlign === "left") {
    textFieldProps.style = { direction: "ltr" };
    textFieldProps.onFocus = () => setShrink(true);
  }

  if (type === "password") {
    textFieldProps.style = { direction: "ltr" };
    textFieldProps.onFocus = () => setShowPasswordIcon(true);
  }

  if (control)
    return (
      <Controller
        control={control}
        render={({ name, onBlur, onChange, ...props }) => {
          return (
            <MuiTextField
              {...textFieldProps}
              name={name}
              value={getValue(props)}
              onChange={(e) => handleChange(e, onChange)}
              onBlur={(e) => handleBlur(e, onBlur)}
            />
          );
        }}
        rules={getRules()}
        name={name}
        defaultValue={value ?? ""}
      />
    );
  else
    return (
      <MuiTextField
        {...textFieldProps}
        value={value}
        onChange={onChange}
        name={name}
      />
    );
}

TextField.propTypes = {
  allowNegative: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  checkRules: PropTypes.bool,
  decimalScale: PropTypes.number,
  error: PropTypes.bool,
  fontSize: PropTypes.number,
  hint: PropTypes.string,
  hintColor: PropTypes.string,
  label: PropTypes.string,
  //mask: PropTypes.array, // ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, "-", /\d/, /\d/]
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  /*{ 
    minLength: { value: 5, message: "Minimum length is 5" },
    required: "Required"
  }*/
  step: PropTypes.number,
  showIcon: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  textAlign: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "number",
    "numberFormat",
    "numberSpin",
    "numberString",
    "password",
    "tel",
    "time",
    "mobile",
    "email",
    "code",
    "persianLetter",
  ]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

TextField.defaultProps = {
  allowNegative: false,
  autoComplete: false,
  checkRules: true,
  error: false,
  fontSize: 9,
  multiline: false,
  required: false,
  showIcon: true,
  size: "small",
  type: "text",
};

function NumberFormatCustom({
  inputRef,
  decimalScale,
  min,
  max,
  allowNegative,
  ...rest
}) {
  const allow = (val) => {
    if (max !== undefined && val.floatValue > max) return false;
    return true;
  };

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      isNumericString
      decimalScale={decimalScale && decimalScale}
      allowNegative={min >= 0 ? false : allowNegative}
      isAllowed={allow}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskCustom({ inputRef, ...rest }) {
  return (
    <MaskedInput
      {...rest}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const usePreviousValue = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
