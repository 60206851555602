/*------------
Version: 1.4
------------*/
import React, { useState, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { SketchPicker, CirclePicker, TwitterPicker } from "react-color";
import { Popover } from "@material-ui/core";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import ClearIcon from "@material-ui/icons/Clear";
import "./index.scss";
import TextField from "../TextField";

export default function ColorPicker({
  className,
  color,
  control,
  label,
  name,
  onChange,
  setValue,
  type,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColor, setSelectedColor] = useState(color || "");

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    if (!open) setAnchorEl(e.currentTarget);
    else setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  const handleClear = () => {
    setValue(name, "");
    setSelectedColor("");
  };

  const handleSelectColor = (color) => {
    setSelectedColor(color.hex);
    setValue(name, color.hex);
    if (type !== "sketch") setAnchorEl(null);
    if (onChange) onChange(color.hex);
  };

  const colorPrickerProps = {
    color: selectedColor,
    onChangeComplete: handleSelectColor,
  };

  return (
    <Fragment>
      <TextField
        className={clsx(className, "mt-3")}
        control={control}
        name={name}
        placeholder={label}
        value={selectedColor}
        readOnly
        textAlign="left"
        InputProps={{
          startAdornment: (
            <FormatColorFillIcon
              style={{
                color: selectedColor,
                cursor: "pointer",
              }}
              className="mx-2"
              onClick={handleClick}
            />
          ),
          endAdornment: (
            <Fragment>
              {selectedColor ? (
                <ClearIcon
                  className="clear-icon"
                  fontSize="small"
                  color="inherit"
                  onClick={handleClear}
                />
              ) : null}
            </Fragment>
          ),
        }}
        {...rest}
      />
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={type === "circle" ? "p-3" : "p1"}>
            {type === "circle" ? (
              <CirclePicker {...colorPrickerProps} />
            ) : type === "twitter" ? (
              <TwitterPicker {...colorPrickerProps} triangle="hide" />
            ) : (
              <SketchPicker {...colorPrickerProps} disableAlpha />
            )}
          </div>
        </Popover>
      )}
    </Fragment>
  );
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["circle", "twitter", "Sketch"]),
};
ColorPicker.defaultProps = { type: "circle" };
