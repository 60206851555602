import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const ModuleRetrieve = async ({
  where,
  hierarchyShowMode,
  isDeepSearchNeeded,
  node,
  systemId,
  VTSystemId,
  subSystemId,
  VTSubSystemId,
  retrieveAsList,
  retrieveAsTree,
}) => {
  let retrieveService = "Pub/Module/Retrieve";
  if (retrieveAsList) retrieveService = "Pub/Module/RetrieveAsList";
  else if (retrieveAsTree) retrieveService = "Pub/Module/RetrieveAsTree";

  const hierarchy = {
    HierarchyShowMode: ifNullOrWhiteSpace(
      hierarchyShowMode,
      retrieveAsList ? "Table" : "Tree"
    ),
    isDeepSearchNeeded,
    NodeId: node?.["ModuleId"],
    VT: node?.VT,
  };

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: {
      where,
      hierarchy,
      systemId,
      VTSystemId,
      subSystemId,
      VTSubSystemId,
    },
  });

  return data;
};

export const ModuleCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Module/Create",
    data: _data,
  });
  return data;
};

export const ModuleUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Module/Update",
    data: _data,
  });
  return data;
};

export const ModuleDelete = async ({
  ModuleId,
  VT,
  ModuleLastUpdateDateTime,
}) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") + "Pub/Module/Delete",
    data: { ModuleId, VT, ModuleLastUpdateDateTime },
  });
  return data;
};

export const ModuleRetrieveForm = async (_data) => {
  const retrieveService = "Pub/Module/RetrieveForm";

  const { data } = await JarvisHttpService.post({
    url: JarvisBaseSettingsService.getApiEndPoint("Jarvis") + retrieveService,
    data: _data,
  });

  return data;
};
