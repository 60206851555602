import React, { useState, Fragment } from "react";
import _ from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TextField from "Jarvis/JarvisComponents/TextField";
import Typography from "Jarvis/JarvisComponents/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import DatePicker from "Jarvis/JarvisComponents/DatePicker";
import {
  convertGregorianTo,
  checkClassName,
} from "Jarvis/JarvisServices/JarvisCommonService";
import DynamicColumn from "Jarvis/JarvisComponents/DynamicColumn";
import StateDivisionSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/StateDivisionSelect";
import PublicCodeSelect from "Jarvis/JarvisSystems/Pub/Components/CustomSelect/PublicCodeSelect";

export default function RealMainInfo({
  control,
  errors,
  isEditMode,
  langData,
  patronData,
  preData,
  setValue,
}) {
  const [showFullName, setShowFullName] = useState(false);
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const handleChangeExtraInfo = () => setShowExtraInfo(!showExtraInfo);

  return (
    <div>
      <div className="row d-flex align-items-center">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronRealLangFirstName")}
            control={control}
            name="PatronRealLangFirstName"
            errors={errors}
            rules={preData.rules}
            value={patronData.PatronRealLangFirstName}
            autoFocus
            fullWidth
          />
        </div>
        <IconButton
          className={checkClassName(
            "",
            [
              "PatronRealLangMiddleName",
              "PatronRealLangNickName",
              "PatronRealLangTitle",
            ],
            preData
          )}
          size="small"
          tabIndex={-1}
          onClick={() => setShowFullName(!showFullName)}
        >
          {showFullName ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {showFullName && (
          <div
            className={checkClassName(
              "col-md-5 col-sm-12",
              "PatronRealLangMiddleName",
              preData
            )}
          >
            <TextField
              label={_.get(langData, "PatronRealLangMiddleName")}
              control={control}
              name="PatronRealLangMiddleName"
              errors={errors}
              rules={preData.rules}
              value={patronData.PatronRealLangMiddleName}
              fullWidth
            />
          </div>
        )}
      </div>
      <div className="row mt-1">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronRealLangLastName")}
            control={control}
            name="PatronRealLangLastName"
            errors={errors}
            rules={preData.rules}
            value={patronData.PatronRealLangLastName}
            fullWidth
          />
        </div>
        {showFullName && (
          <Fragment>
            <div className="col-md-auto"></div>
            <div
              className={checkClassName(
                "col-md-5 col-sm-12",
                "PatronRealLangNickName",
                preData
              )}
            >
              <TextField
                label={_.get(langData, "PatronRealLangNickName")}
                control={control}
                name="PatronRealLangNickName"
                errors={errors}
                rules={preData.rules}
                value={patronData.PatronRealLangNickName}
                fullWidth
              />
            </div>
          </Fragment>
        )}
      </div>
      {showFullName && (
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <TextField
              label={_.get(langData, "PatronRealLangTitle")}
              control={control}
              name="PatronRealLangTitle"
              errors={errors}
              rules={preData.rules}
              value={patronData.PatronRealLangTitle}
              fullWidth
            />
          </div>
        </div>
      )}
      <div className="row mt-1">
        <div className="col-md-5 col-sm-12">
          <TextField
            label={_.get(langData, "PatronRealIDNo")}
            control={control}
            name="PatronRealIDNo"
            errors={errors}
            rules={preData.rules}
            value={patronData.PatronRealIDNo}
            type="number"
            textAlign="left"
            fullWidth
          />
        </div>
      </div>
      <Accordion className="mt-2" onChange={handleChangeExtraInfo}>
        <AccordionSummary>
          <Typography
            label={
              showExtraInfo ? _.get(langData, "Less") : _.get(langData, "More")
            }
            color="primary"
          />
          {showExtraInfo ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="primary" />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} className="row">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronRealLangFatherName",
                  preData
                )}
              >
                <TextField
                  label={_.get(langData, "PatronRealLangFatherName")}
                  control={control}
                  name="PatronRealLangFatherName"
                  errors={errors}
                  rules={preData.rules}
                  value={patronData.PatronRealLangFatherName}
                  fullWidth
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <TextField
                  label={_.get(langData, "PatronRealRegistrationNo")}
                  control={control}
                  name="PatronRealRegistrationNo"
                  errors={errors}
                  rules={preData.rules}
                  value={patronData.PatronRealRegistrationNo}
                  fullWidth
                />
              </div>
            </div>
            <div style={{ width: "100%" }} className="row mt-1">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "GenderLangDescription",
                  preData
                )}
              >
                <PublicCodeSelect
                  label={_.get(langData, "GenderLangDescription")}
                  control={control}
                  name="Gender"
                  columnName="GenderLangDescription"
                  errors={errors}
                  rules={preData.rules}
                  code="PubGender"
                  value={
                    isEditMode
                      ? {
                          PublicCodeId: _.get(patronData, "GenderId"),
                          PublicCodeLangDescription: _.get(
                            patronData,
                            "GenderLangDescription"
                          ),
                          VT: _.get(patronData, "VTGender"),
                        }
                      : patronData.Gender
                  }
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <StateDivisionSelect
                  label={_.get(langData, "PatronRealBirthPlace")}
                  control={control}
                  name="PatronRealBirthPlace"
                  columnName="PatronRealBirthPlace"
                  errors={errors}
                  rules={preData.rules}
                  value={
                    isEditMode
                      ? {
                          StateDivisionId:
                            patronData.PatronRealBirthPlaceStateDivisionId,
                          StateDivisionLangDescription:
                            patronData.PatronRealBirthPlace,
                        }
                      : patronData.PatronRealBirthPlaceStateDivision
                  }
                  latestNodeLevel={3}
                  showFullPath
                  typeOnly
                />
              </div>
            </div>
            <div style={{ width: "100%" }} className="row mt-1">
              <div
                className={checkClassName(
                  "col-md-5 col-sm-12",
                  "PatronRealBirthDate",
                  preData
                )}
              >
                <DatePicker
                  label={_.get(langData, "PatronRealBirthDate")}
                  control={control}
                  setValue={setValue}
                  name="PatronRealBirthDate"
                  errors={errors}
                  rules={preData.rules}
                  value={convertGregorianTo(patronData.PatronRealBirthDate)}
                  fullWidth
                  inputVariant="standard"
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <DatePicker
                  label={_.get(langData, "PatronRealIssueDate")}
                  control={control}
                  setValue={setValue}
                  name="PatronRealIssueDate"
                  errors={errors}
                  rules={preData.rules}
                  value={convertGregorianTo(patronData.PatronRealIssueDate)}
                  fullWidth
                  inputVariant="standard"
                />
              </div>
            </div>
            <div style={{ width: "100%" }} className="row mt-1">
              <div className="col-md-5 col-sm-12">
                <StateDivisionSelect
                  label={_.get(langData, "PatronRealCitizenship")}
                  control={control}
                  name="PatronRealCitizenship"
                  value={
                    isEditMode
                      ? {
                          StateDivisionId:
                            patronData.PatronRealCitizenshipStateDivisionId,
                          StateDivisionLangDescription:
                            patronData.PatronRealCitizenship,
                        }
                      : patronData.PatronRealCitizenship
                  }
                  typeOnly
                  showFlag
                />
              </div>
            </div>

            <DynamicColumn
              columns={_.get(preData, "GTCD.PmsPatronReal")}
              control={control}
              errors={errors}
              setValue={setValue}
              rules={preData.rules}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
