import React, { useState } from "react";
import { Controller } from "react-hook-form";
import HijriUtils from "@date-io/hijri";
import jMoment from "moment-hijri";
import {
  ifNullOrWhiteSpace,
  isNullOrWhiteSpace,
  isValidDate,
  getDateString,
} from "Jarvis/JarvisServices/JarvisCommonService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "moment/locale/ar-sa";
import Typography from "../Typography";

const DatePickerHijriGhamari = ({
  name,
  value,
  usePersianDigits,
  variant /*dialog, inline, static*/,
  onChange,
  label,
  hint,
  hintColor,
  control,
  errors,
  setValue,
  rules,
  inputVariant, //standard | outlined | filled
  placeholder,
  setTodayDate,
  ...rest
}) => {
  if (setTodayDate === true) value = getDateString();
  //convert hijrishamsi to gregorian
  else
    value = isNullOrWhiteSpace(value)
      ? null
      : jMoment(value, "iYYYY/iMM/iDD", true).format("YYYY/MM/DD");

  const [date, setDate] = useState(ifNullOrWhiteSpace(value, null));

  variant = ifNullOrWhiteSpace(variant, "inline");
  inputVariant = ifNullOrWhiteSpace(inputVariant, "outlined");
  usePersianDigits = ifNullOrWhiteSpace(usePersianDigits, false);
  placeholder = ifNullOrWhiteSpace(placeholder, "یوم / شهر / سنه");

  const symbolMap = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    0: "0",
  };
  jMoment.updateLocale("ar-sa", {
    ...rest,
    postformat: function (string) {
      return string
        .replace(/\d/g, function (match) {
          return symbolMap[match];
        })
        .replace(/,/g, "،");
    },
  });

  const renderHelperText = () => {
    const helperText = errors && errors[name] ? errors[name].message : hint;
    return (
      <Typography
        fontSize={7}
        component={"span"}
        label={helperText}
        color={hintColor}
      />
    );
  };

  let commonProps = {
    name,
    variant,
    label,
    inputVariant,
    format: "YYYY/MM/DD",
    margin: "normal",
    labelFunc: (_date) => {
      const date = _date ? _date.format("iYYYY/iMM/iDD") : "";
      return date;
    },
    disableToolbar: false,
    autoOk: true,
    error: errors && errors[name] ? true : false,
    helperText: renderHelperText(),
    placeholder,
    minDate: "1937-03-14",
    maxDate: "2076-11-26",
    ...rest,
  };

  if (control) {
    return (
      <Controller
        name={name}
        defaultValue={date}
        control={control}
        rules={rules}
        render={({ value, name, onChange: onChangeController, onBlur }) => {
          return (
            <MuiPickersUtilsProvider utils={HijriUtils} locale="ar-SA">
              <KeyboardDatePicker
                value={value}
                name={name}
                {...commonProps}
                onChange={(orginalDate, _date) => {
                  if (isNullOrWhiteSpace(orginalDate?._f) === false) {
                    const date = jMoment(_date, "iYYYY/iMM/iDD", true).format(
                      "YYYY/MM/DD"
                    );
                    if (date !== "Invalid date") _date = date;
                  }
                  let isValid = orginalDate?.isValid();
                  if (isValid === false) {
                    setValue(name, null);
                    onChangeController("");
                  } else {
                    if (onChange) onChange(_date);
                    onChangeController(_date);
                  }
                }}
                onBlur={(e) => {
                  const _date = e.target.value;
                  const date = jMoment(_date, "iYYYY/iMM/iDD", true).format(
                    "YYYY/MM/DD"
                  );
                  if (isValidDate(date, "yyyy/MM/dd") === false) {
                    setValue(name, null);
                  } else setValue(name, date);
                  onBlur(e);
                }}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    );
  } else {
    const dateFormat = "YYYY/MM/DD";
    commonProps.onChange = (orginalDate, date) => {
      const isValid = orginalDate?.isValid();
      if (isNullOrWhiteSpace(orginalDate?._f) === false) {
        if (isValid === true)
          date = jMoment(date, "iYYYY/iMM/iDD", true).format("YYYY/MM/DD");
      }
      setDate(date);
      if (onChange) onChange(isValid ? date : null);
    };
    commonProps.value = date;
    commonProps.onBlur = (e) => {
      if (isValidDate(e.target.value, dateFormat) === false) setDate(null);
    };
    return (
      <MuiPickersUtilsProvider utils={HijriUtils} locale="ar-SA">
        <KeyboardDatePicker {...commonProps} placeholder={dateFormat} />
      </MuiPickersUtilsProvider>
    );
  }
};

export default DatePickerHijriGhamari;
