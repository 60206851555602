import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import MainContainer from "Jarvis/JarvisComponents/Containers/MainContainer";
import MainContainerBody from "Jarvis/JarvisComponents/Containers/MainContainerBody";
import { RetrievePublic } from "Jarvis/JarvisSystems/Pub/Services/PageElementService";
import {
  MenuRetrieve,
  MenuCreate,
  MenuUpdate,
  MenuDelete,
} from "Jarvis/JarvisSystems/Pub/Services/MenuService";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import InputForm from "./InputForm";
import TreeGrid from "Jarvis/JarvisComponents/TreeGrid";
import Wait from "Jarvis/JarvisComponents/Wait";
import SystemSelect from "../CustomSelect/SystemSelect";
import SubSystemSelect from "../CustomSelect/SubSystemSelect";

export default function Menu() {
  const { language } = useSelector((state) => state.settings);
  const { PE } = useContext(GlobalContext);
  const { control, setValue } = useForm();
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [langData, setLangData] = useState();
  const [refreshInfo, setRefreshInfo] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedSubSystem, setSelectedSubSystem] = useState(null);
  const [showInputForm, setShowInputForm] = useState(false);
  const [showSubSystem, setShowSubSystem] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await RetrievePublic({
        langId: language.LangId,
        moduleCodeStrings: "PubMenu",
      });
      if (_.get(response, "ErrorHandling.IsSuccessful"))
        setLangData(
          _.merge(
            _.pick(PE.PubPublicElems, [
              "IsActive",
              "SaveAndNew",
              "SaveAndClose",
            ]),
            _.get(response, "Data.PE"),
            _.get(response, "Data.GTC")
          )
        );
    })();
  }, [language.LangId, PE]);

  const onSubmit = async (data, extraData) => {
    setIsLoading(true);
    const { ErrorHandling, Data } = editData
      ? await MenuUpdate({
          ...data,
          MenuId: editData.MenuId,
          VT: editData.VT,
          MenuLastUpdateDateTime: editData.MenuLastUpdateDateTime,
        })
      : await MenuCreate(data);
    setIsLoading(false);

    if (_.get(ErrorHandling, "IsSuccessful")) {
      setRefreshInfo({
        isEditMode: editData ? true : false,
        newRecord: editData
          ? _.merge(
              {
                ...data,
                ...extraData,
                MenuId: editData.MenuId,
                VT: editData.VT,
                MenuIdParent: editData.MenuIdParent,
                VTParent: editData.VTParent,
                MenuLastUpdateDateTime: editData.MenuLastUpdateDateTime,
              },
              Data
            )
          : _.merge({ ...data, ...extraData }, Data),
      });
    }

    return { isSuccessful: _.get(ErrorHandling, "IsSuccessful") };
  };
  if (!langData) return <Wait />;

  const inputForm = (
    <InputForm
      editData={editData}
      isLoading={isLoading}
      langData={langData}
      onClose={() => setShowInputForm(false)}
      onSubmitParent={onSubmit}
      parent={selectedParent}
      selectedSystem={selectedSystem}
      selectedSubSystem={selectedSubSystem}
      showInputForm={showInputForm}
    />
  );

  const columns = [
    {
      label: _.get(langData, "MenuLangDescription"),
      path: "MenuLangDescription",
      widthScale: 0.7,
    },
    {
      label: _.get(langData, "MenuIcon"),
      path: null,
      cellAlign: "center",
      sortable: false,
      hasFiltering: false,
      content: (menuData) => (
        <div style={{ color: menuData.MenuIconColor }}>
          <span className={`${menuData.MenuIcon} zmdi-hc-2x`} />
        </div>
      ),
      widthScale: 0.3,
    },
    {
      label: _.get(langData, "PubModule"),
      path: "ModuleLangDescription",
    },
    {
      label: _.get(langData, "MenuCode"),
      path: "MenuCode",
      visible: false,
    },
    {
      label: _.get(langData, "MenuExternalUrl"),
      path: "MenuExternalUrl",
      visible: false,
    },
    {
      label: _.get(langData, "PubPlatform"),
      path: "PlatformLangDescription",
      widthScale: 0.6,
    },
    {
      label: _.get(langData, "IsActive"),
      path: "MenuIsActive",
      type: "checkbox",
      widthScale: 0.3,
    },
    {
      label: _.get(langData, "MenuIsQuickAccess"),
      path: "MenuIsQuickAccess",
      type: "checkbox",
      widthScale: 0.5,
      visible: false,
    },
    {
      label: _.get(langData, "MenuCodeMustBeShown"),
      path: "MenuCodeMustBeShown",
      type: "checkbox",
      widthScale: 0.5,
      visible: false,
    },
  ];

  const handleEditMode = (editData) => {
    setEditData(editData);
  };

  const handleShowInputForm = (open, parent) => {
    setSelectedParent(parent);
    setShowInputForm(open);
  };

  const handleSystemChange = (value) => {
    if (selectedSubSystem) {
      setValue("SubSystem", null);
      setSelectedSubSystem(null);
    }
    if (_.get(value, "SubSystemCount") > 1) setShowSubSystem(true);
    setSelectedSystem(value);
  };

  const handleSubSystemChange = (value) => {
    setSelectedSubSystem(value);
  };

  return (
    <MainContainer>
      <MainContainerBody>
        <div className="row mb-2">
          <div className="col-md-5 col-sm-12">
            <SystemSelect
              label={_.get(langData, "PubSystem")}
              control={control}
              name="System"
              onChange={handleSystemChange}
            />
          </div>
          {showSubSystem && (
            <div className="col-md-5 col-sm-12">
              <SubSystemSelect
                label={_.get(langData, "PubSubSystem")}
                control={control}
                name="SubSystem"
                dependencies={[_.get(selectedSystem, "SystemId")]}
                serviceParams={{
                  SystemId: _.get(selectedSystem, "SystemId"),
                  VTSystemId: _.get(selectedSystem, "VT"),
                }}
                onChange={handleSubSystemChange}
              />
            </div>
          )}
        </div>
        <TreeGrid
          columns={columns}
          deleteExtraContent="MenuLangDescription"
          deleteService={MenuDelete}
          deleteTitle={_.get(langData, "PubMenuDelete")}
          hasAddMenuButton={showSubSystem ? selectedSubSystem : selectedSystem}
          hasColumnSelect={true}
          idFieldName="MenuId"
          inputForm={inputForm}
          onEditMode={handleEditMode}
          onShowInputForm={handleShowInputForm}
          moduleCodeString="PubMenu"
          refreshInfo={refreshInfo}
          service={
            showSubSystem
              ? selectedSubSystem
                ? MenuRetrieve
                : null
              : selectedSystem
              ? MenuRetrieve
              : null
          }
          serviceParams={{
            hierarchyShowMode: "Tree",
            systemId: showSubSystem ? null : _.get(selectedSystem, "SystemId"),
            VTSystemId: showSubSystem ? null : _.get(selectedSystem, "VT"),
            subSystemId: showSubSystem
              ? _.get(selectedSubSystem, "SubSystemId")
              : null,
            VTSubSystemId: showSubSystem
              ? _.get(selectedSubSystem, "VT")
              : null,
          }}
          showInputForm={showInputForm}
          title={_.get(langData, "PubMenuRetrieve")}
        />
      </MainContainerBody>
    </MainContainer>
  );
}
