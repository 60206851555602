import JarvisHttpService from "Jarvis/JarvisServices/JarvisHttpService";
import JarvisBaseSettingsService from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";

export const PublicCodeRetrieve = async ({
  pageIndex,
  pageSize,
  totalRecords,
  orderBy,
  orderBys,
  orderMethod,
  where,
  code,
  hierarchyShowMode,
  latestNodeLevel,
  isDeepSearchNeeded,
  node,
  nodeLevel,
  systemId,
  VTSystemId,
  subSystemId,
  VTSubSystemId,
  publicCodeId,
  VT,
  Json,
}) => {
  const hierarchy = {
    HierarchyShowMode: hierarchyShowMode,
    LatestNodeLevel: ifNullOrWhiteSpace(latestNodeLevel, null),
    NodeId: node?.["PublicCodeId"],
    VT: node?.VT,
    isDeepSearchNeeded: isDeepSearchNeeded,
    nodeLevel,
  };
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/PublicCode/Retrieve",
    data: {
      pageIndex,
      pageSize,
      totalRecords,
      where,
      orderBy,
      orderBys,
      orderMethod,
      hierarchy,
      code,
      systemId,
      VTSystemId,
      subSystemId,
      VTSubSystemId,
      publicCodeId,
      VT,
      Json,
    },
  });
  return data;
};

export const PublicCodeCreate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/PublicCode/Create",
    data: _data,
  });
  return data;
};

export const PublicCodeUpdate = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/PublicCode/Update",
    data: _data,
  });
  return data;
};

export const PublicCodeDelete = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/PublicCode/Delete",
    data: _data,
  });
  return data;
};

export const PublicCodeCreateList = async (_data) => {
  const { data } = await JarvisHttpService.post({
    url:
      JarvisBaseSettingsService.getApiEndPoint("Jarvis") +
      "Pub/PublicCode/CreateList",
    data: _data,
  });
  return data;
};
