import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { userSignIn } from "Jarvis/JarvisActions/Auth";
import queryString from "query-string";
import { notify } from "Jarvis/JarvisComponents/Notification";
import Typography from "Jarvis/JarvisComponents/Typography";
import Button from "Jarvis/JarvisComponents/Button";
import ConcurrentLogin from "./ConcurrentLogin";
import {
  getPrevUsername,
  setCompany,
  setSubSystem,
} from "Jarvis/JarvisServices/JarvisLocalStorageService";
import { isTrue } from "Jarvis/JarvisServices/JarvisCommonService";

const SignInUserInfo = (props) => {
  const { data, langData, isInternalSignIn, toastDismissAll } = props;
  const [concurrentLogins, setConcurrentLogins] = useState(
    props.concurrentLogins
  );

  const {
    Gender,
    Fullname,
    Description,
    LastEventLoginDateTime,
    LeftTimeToChangePassword,
    UserMustLoginWithDomainController,
    LastEventLoginIpAddress,
    UserImageBase64,
    JAToken,
    EventLoginSpecificLoginToken,
    UserId,
    PatronId,
    VTPatronId,
  } = data.Data;
  const fullname = Fullname?.trim();
  let userImageBase64 = UserImageBase64
    ? UserImageBase64
    : require(`assets/images/${
        Gender === "PubGender_Female" ? "female" : "male"
      }User.png`);
  const EncryptedData = _.get(data.ExtraInfo, "EncryptedData") ?? {};

  const handleClick = () => {
    const queryInfo = queryString.parse(props.location?.search);
    const jarvisIntegratedSystem = queryInfo?.jarvisIntegratedSystem
      ? queryInfo.jarvisIntegratedSystem
      : true;

    let isReloadPageNeeded = localStorage.getItem("isReloadPageNeeded");

    if (jarvisIntegratedSystem === true) {
      const prevUsername = getPrevUsername();
      if (data.username?.toLowerCase() !== prevUsername?.toLowerCase()) {
        setCompany({});
        setSubSystem({});
        isReloadPageNeeded = true;
      }

      props.userSignIn({
        Gender,
        Fullname,
        UserDescription: Description,
        LastEventLoginDateTime,
        LeftTimeToChangePassword,
        UserMustLoginWithDomainController,
        LastEventLoginIpAddress,
        UserImageBase64: userImageBase64,
        JAToken,
        username: data.username,
        EventLoginSpecificLoginToken,
        UserId,
        PatronId,
        VTPatronId,
      });

      if (isInternalSignIn === false || isTrue(isReloadPageNeeded) === true) {
        localStorage.removeItem("isReloadPageNeeded");
        try {
          props.history.push("/");
        } catch {
          window.location.href = "/";
          //window.location.reload();
        }
      } else {
        if (props.onClose) props.onClose();
        if (toastDismissAll) toastDismissAll();
      }
    } else {
      if (!queryInfo.systemUrl) {
        notify({
          type: "warning",
          message: `${_.get(langData, "SystemUrl_IsNull")} (${
            queryInfo.systemKey
          })`,
        });
        return;
      }
      if (isInternalSignIn === false) {
        const query = `jAToken=${JAToken}&systemKey=${
          queryInfo.systemKey
        }&systemUrl=${encodeURIComponent(
          queryInfo.systemUrl
        )}&returnSemiPath=${encodeURIComponent(
          queryInfo.returnSemiPath
        )}&encryptedData=${encodeURIComponent(EncryptedData)}`;
        window.location.href = `${queryInfo.systemUrl}/JWebCore/JarvisAuthInvestigator/RedirectTo?${query}`;
      } else if (toastDismissAll) toastDismissAll();
    }
  };
  const onLoadConcurrentLogins = (data) => {
    setConcurrentLogins(data ?? []);
  };
  return (
    <>
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div
            className=" d-flex align-items-center justify-content-center col-4"
            style={{ backgroundColor: "#2a2a2c" }}
          >
            <img
              width="180px"
              style={{ borderRadius: "50%" }}
              src={userImageBase64}
              alt="User Avatar"
              title={fullname || Description}
            />
          </div>

          <div className="col m-4">
            <div className="row mb-3">
              <Typography
                label={`${
                  _.get(langData, "SecAuthentication_WelcomeMessage_Title") ||
                  ""
                } ${_.get(langData, "SecAuthentication_WelcomeMessage") || ""}`}
                variant="h6"
                gutterBottom
                noWrap
              />
            </div>
            <div>
              <div>
                {fullname && (
                  <div className="row">
                    <Typography
                      label={`${_.get(langData, "UserInfo") || ""}: `}
                      display="inline"
                    />

                    <Typography className="mx-2" label={fullname} />
                  </div>
                )}
                {Description && (
                  <div className="row">
                    <Typography
                      label={`
                      ${_.get(langData, "Description") || ""}: `}
                      display="inline"
                    />
                    <Typography label={Description} />
                  </div>
                )}
                <div className="row my-2">
                  <Typography
                    label={`${_.get(langData, "LastLoginDate") || ""}: `}
                    display="inline"
                  />
                  <Typography
                    className="mx-2"
                    label={LastEventLoginDateTime}
                    style={{ direction: "ltr" }}
                    display="inline"
                  />
                </div>
                {LeftTimeToChangePassword && (
                  <div className="row">
                    <Typography
                      label={`${
                        _.get(langData, "LeftTimeToChangePassword") || ""
                      }: `}
                      display="inline"
                    />
                    <Typography
                      className="mx-2"
                      label={LeftTimeToChangePassword}
                      display="inline"
                    />
                  </div>
                )}
                <div className="row my-2">
                  <Typography
                    label={`${_.get(langData, "LastLoginIPAddress") || ""}: `}
                    display="inline"
                  />
                  <Typography
                    className="mx-2"
                    label={LastEventLoginIpAddress}
                    display="inline"
                  />
                </div>
              </div>
              <div className="mb-3 mt-4 d-flex justify-content-center align-items-center">
                <Button
                  label={_.get(langData, "Continue")}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {concurrentLogins.length > 0 && (
        <div
          style={{ maxWidth: "none" }}
          className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        >
          <div className="app-login-main-content">
            <ConcurrentLogin
              concurrentLogins={concurrentLogins}
              onLoadConcurrentLogins={onLoadConcurrentLogins}
              isInternalSignIn={isInternalSignIn}
            />
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignIn })(SignInUserInfo);
