import React, { useState } from "react";
import _ from "lodash";
import Select from "Jarvis/JarvisComponents/Select";
import {
  PatronRetrieve,
  PatronRelationRetrieve,
} from "Jarvis/JarvisSystems/Pms/Services/PatronService";
import Patron from "Jarvis/JarvisSystems/Pms/Components/Patron";

export default function PatronSelect({
  control,
  hasAddItem,
  label,
  name,
  patronType,
  serviceParams,
  typeOnly,
  value,
  relationType,
  patron,
  PatronGroupCode,
  ...rest
}) {
  const [showForm, setShowForm] = useState(false);
  const [newItem, setNewItem] = useState(null);

  const getDisplayFieldName = () => {
    if (relationType && patron) return "PatronName";
    switch (patronType) {
      case "R":
        return "PatronRealLangFullName";
      case "L":
        return "PatronLegalLangDescription";
      case "O":
        return "PatronOtherLangDescription";
      default:
        return "PatronLangFullName";
    }
  };

  const getSerivce = () => {
    if (relationType && patron) return PatronRelationRetrieve;
    return PatronRetrieve;
  };

  const getServiceParams = () => {
    if (relationType && patron)
      return {
        retrieveAsList: true,
        patron: {
          patronId: patron.PatronId,
          vt: patron.vt ?? patron.VTPatronId,
        },
        relationType,
      };
    return {
      ...serviceParams,
      retrieveAsList: true,
      patronType,
      PatronGroupCode,
    };
  };
  return (
    <div>
      {showForm ? (
        <Patron
          showForm={showForm}
          onShowForm={setShowForm}
          patronTypeForm={patronType}
          displayFieldName={getDisplayFieldName()}
          setNewItem={setNewItem}
        />
      ) : (
        <Select
          label={label}
          control={control}
          name={name ?? "patron"}
          service={getSerivce()}
          serviceParams={getServiceParams()}
          valueFieldName="PatronId"
          displayFieldName={getDisplayFieldName()}
          displayItem={(item) =>
            `${item.PatronCode ?? ""} - ${_.get(item, getDisplayFieldName())}`
          }
          value={newItem ?? value}
          selectOnFocus
          autoSelect
          onAddClick={hasAddItem ? () => setShowForm(true) : undefined}
          typeOnly={typeOnly ?? true}
          {...rest}
        />
      )}
    </div>
  );
}
